import { toast } from "react-toastify";

function formValidator(obj, activePos, activeBtob, activeBtoc) {
  console.log(obj, "objyyyyy");

  if (!obj?.image) {
    toast.error("Please select product images");
    return false;
  }
  if (!obj?.name) {
    toast.error("Please enter product name");
    return false;
  }
  if (!obj?.service_id) {
    toast.error("Please select master category");
    return false;
  }
  if (!obj?.category_id) {
    toast.error("Please select category");
    return false;
  }
  if (!obj?.sub_category_id) {
    toast.error("Please select sub-category");
    return false;
  }
  if (!obj?.brand_id) {
    toast.error("Please select brand");
    return false;
  }
  if (!obj?.description) {
    toast.error("Please enter product description");
    return false;
  }

  if (!obj?.type) {
    toast.error("Please enter product type");
    return false;
  }
  if (!obj?.barcode) {
    toast.error("Please enter barcode");
    return false;
  }
  if (
    (obj?.barcode && obj?.barcode?.length < 11) ||
    (obj?.barcode && obj?.barcode?.length > 14)
  ) {
    toast.error("Please enter valid barcode");
    return false;
  }

  if (!obj?.cost_price) {
    toast.error("Please enter cost price");
    return false;
  }

  if (!obj?.delivery_options || obj?.delivery_options.length === 0) {
    toast.error("Please select delivery options");
    return false;
  }

  if (obj?.delivery_options.includes("shipping") > 0) {
    const { weight, length, breadth, height } = obj?.dimensions || {};

    if (!weight) {
      toast.error("Please enter product weight");
      return false;
    }

    if (!length) {
      toast.error("Please enter product length");
      return false;
    }

    if (!breadth) {
      toast.error("Please enter product breadth");
      return false;
    }

    if (!height) {
      toast.error("Please enter product height");
      return false;
    }
  }

  if (!obj?.seller_address_ids || obj.seller_address_ids.length === 0) {
    toast.error("Please select store");
    return false;
  }

  const upcSet = new Set();

  if (!Array.isArray(obj.attributes) || obj.attributes.length <= 0) {
    for (const variant of obj.variants || []) {
      // Check each price object for missing selling_price
      for (const price of variant.prices || []) {
        if (
          price.app_name &&
          (!price.selling_price || String(price.selling_price).trim() === "")
        ) {
          toast.error(`Selling price is missing for ${price.app_name}`);
          return false;
        }
      }

      // Check for UPC
      if (!variant.barcode || variant.barcode.trim() === "") {
        toast.error("Please enter UPC");
        return false;
      }

      // Validate UPC length (11-14 characters)
      if (variant.barcode.length < 11 || variant.barcode.length > 14) {
        toast.error("Please enter a valid UPC ");
        return false;
      }
      if (!variant?.image || variant.image.trim() === "") {
        toast.error("Please upload an image");
        return false; // Halt further execution for this variant
      }
    }
  } else {
    for (const variant of obj.variants || []) {
      // Check each price object for missing selling_price
      for (const price of variant.prices || []) {
        console.log(
          "Selling price:",
          price.selling_price,
          "Type:",
          typeof price.selling_price
        );
        if (
          price.app_name &&
          (!price.selling_price || String(price.selling_price).trim() === "")
        ) {
          toast.error(
            `Selling price is missing for ${
              price.app_name
            } in variant: ${JSON.stringify(variant.attributes)}`
          );
          return false;
        }
      }

      // Check if at least one price object has a valid selling price
      const hasSellingPrice = variant.prices?.some(
        (price) =>
          price.selling_price && String(price.selling_price).trim() !== ""
      );

      if (!hasSellingPrice) {
        toast.error(
          `At least one selling price is required for variant: ${JSON.stringify(
            variant.attributes
          )}`
        );
        return false;
      }

      // Check for UPC
      if (!variant.barcode || variant.barcode.trim() === "") {
        toast.error(
          `Please enter UPC for variant: ${JSON.stringify(variant.attributes)}`
        );
        return false;
      }

      // Validate UPC length (11-14 characters)
      if (variant.barcode.length < 11 || variant.barcode.length > 14) {
        toast.error(
          `Please enter a valid UPC for variant: ${JSON.stringify(
            variant.attributes
          )}`
        );
        return false;
      }

      // Check for duplicate UPCs
      if (upcSet.has(variant.barcode)) {
        toast.error(`Duplicate UPC found: ${variant.barcode}`);
        return false;
      }

      // // Check if quantity is provided
      // if (!variant.quantity || variant.quantity.trim() === "") {
      //   toast.error(
      //     `Please add quantity for variant: ${JSON.stringify(
      //       variant.attributes
      //     )}`
      //   );
      //   return false;
      // }

      // // Check if quantity is provided
      // if (
      //   !variant.quantity_reorder_point ||
      //   variant.quantity_reorder_point.trim() === ""
      // ) {
      //   toast.error(
      //     `Please add quantity for variant: ${JSON.stringify(
      //       variant.attributes
      //     )}`
      //   );
      //   return false;
      // }
      // Validate Quantity Reorder Point <= Quantity
      const quantity = parseFloat(variant.quantity);
      const reorderPoint = parseFloat(variant.quantity_reorder_point);

      if (reorderPoint > quantity) {
        toast.error(
          `Quantity Reorder Point cannot be greater than Quantity for variant: ${JSON.stringify(
            variant.attributes
          )}`
        );
        return false;
      }

      if (!variant?.image || variant.image.trim() === "") {
        toast.error(
          `Please upload an image for variant: ${JSON.stringify(
            variant.attributes
          )}`
        );
        return false; // Halt further execution for this variant
      }

      // Add UPC to the set to check for duplicates
      upcSet.add(variant.upc);
    }
  }

  return true;
}
export { formValidator };

// function formValidator(obj, activePos, activeBtob, activeBtoc) {
//   console.log(obj, "objyyyyy");
//   let hasEmptyValues = (arr) => {
//     for (let i = 0; i < arr.length; i++) {
//       const obj = arr[i];
//       for (let key in obj) {
//         if (obj.hasOwnProperty(key) && key !== "upc" && obj[key] === "") {
//           return true; // Empty value found
//         }
//       }
//     }
//     return false; // No empty values found
//   };

//   let filterB2B = obj?.prices?.filter(
//     (val) => val?.price_type == "quantity_base"
//   );
//   console.log(filterB2B, "filterB2B");
//   let resultB2B =
//     filterB2B?.length > 0 ? hasEmptyValues(filterB2B[0]?.bundle_price) : "";

//   let priceResult = obj?.prices?.length > 0 ? hasEmptyValues(obj?.prices) : "";

//   console.log(priceResult, "priceResult");

//   let optionListResponse =
//     obj?.attributes?.length > 0 ? hasEmptyValues(obj?.attributes) : "";

//   console.log(obj?.attributes, optionListResponse, "response in validator");

//   console.log(
//     obj?.attributes?.length > 0 && optionListResponse,
//     optionListResponse,
//     obj?.attributes?.length > 0,
//     obj?.attributes,
//     "response of varinat"
//   );

//   if (!obj?.image) {
//     toast.error("Please select product images");
//     return false;
//   }
//   if (!obj?.name) {
//     toast.error("Please enter product name");
//     return false;
//   }
//   if (!obj?.service_id) {
//     toast.error("Please select master category");
//     return false;
//   }
//   if (!obj?.category_id) {
//     toast.error("Please select category");
//     return false;
//   }
//   if (!obj?.sub_category_id) {
//     toast.error("Please select sub-category");
//     return false;
//   }
//   if (!obj?.brand_id) {
//     toast.error("Please select brand");
//     return false;
//   }
//   if (!obj?.description) {
//     toast.error("Please enter product description");
//     return false;
//   }
//   if (!obj?.price) {
//     toast.error("Please enter product price");
//     return false;
//   }
//   if (!obj?.type) {
//     toast.error("Please enter product type");
//     return false;
//   }
//   if (!obj?.barcode) {
//     toast.error("Please enter barcode");
//     return false;
//   }
//   if (
//     (obj?.barcode && obj?.barcode?.length < 11) ||
//     (obj?.barcode && obj?.barcode?.length > 14)
//   ) {
//     toast.error("Please enter valid barcode");
//     return false;
//   }
//   if (
//     (obj?.sku && obj?.sku?.length < 11) ||
//     (obj?.sku && obj?.sku?.length > 14)
//   ) {
//     toast.error("Please enter valid sku");
//     return false;
//   }
//   if (!obj?.delivery_options || obj?.delivery_options.length === 0) {
//     toast.error("Please select delivery options");
//     return false;
//   }

//   if (obj?.delivery_options.includes("shipping")) {
//     const { weight, length, breadth, height } = obj?.dimensions || {};

//     if (!weight) {
//       toast.error("Please enter product weight");
//       return false;
//     }

//     if (!length) {
//       toast.error("Please enter product length");
//       return false;
//     }

//     if (!breadth) {
//       toast.error("Please enter product breadth");
//       return false;
//     }

//     if (!height) {
//       toast.error("Please enter product height");
//       return false;
//     }
//   }

//   if (!obj?.quantity) {
//     toast.error("Please enter Inventory-Opening");
//     return false;
//   }
//   // if (!obj?.quantity_reorder_point) {
//   //     toast.error("Please enter Inventory-Reorder Point")
//   //     return false
//   // }
//   // if (obj?.ingredients?.length <= 0) {
//   //     toast.error("Please select attributes")
//   //     return false
//   // }
//   if (!activePos && !activeBtob && !activeBtoc) {
//     toast.error("Please select product availability");
//     return false;
//   }
//   if (obj?.prices?.length == 0 || priceResult) {
//     toast.error("Please add selling price and profit margin");
//     return false;
//   }
//   if (filterB2B?.length > 0 && resultB2B) {
//     toast.error("Please add selling quantity and selling price");
//     return false;
//   }
//   if (obj?.attributes?.length > 0 && optionListResponse) {
//     toast.error("Variant info could not be empty");
//     return false;
//   }

// if (obj?.delivery_options?.length <= 0) {
//     toast.error("Please select delivery options")
//     return false
// }
// if (obj?.delivery_options.length > 0 && obj?.delivery_options?.includes("shipping") && !obj?.dimensions?.weight) {
//     toast.error("Please enter product weight")
//     return false
// }
// if (obj?.delivery_options.length > 0 && obj?.delivery_options?.includes("shipping") && !obj?.dimensions?.length) {
//     toast.error("Please enter product length")
//     return false
// }
// if (obj?.delivery_options.length > 0 && obj?.delivery_options?.includes("shipping") && !obj?.dimensions?.breadth) {
//     toast.error("Please enter product bredth")
//     return false
// }
// if (obj?.delivery_options.length > 0 && obj?.delivery_options?.includes("shipping") && !obj?.dimensions?.height) {
//     toast.error("Please enter product height")
//     return false
// }

//   return true;
// }
// export { formValidator };
