import React, { forwardRef } from "react";
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";
import moment from "moment-timezone";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { getFormatedDate } from "../../../utilities/helpers";

const AnalyticsHeader = (props) => {

  const goToNextWeek = () => {
    const newStartDate = moment(props?.startDate || new Date())
    const newEndDate = moment(props?.endDate || new Date())
    if(newStartDate.isSame(moment())) {
        return ""
    }
    if (newStartDate.isValid() && newEndDate.isValid()) {
      const nextNewStartDate = newStartDate.clone().add(1, "week");
      const nextNewEndDate = newEndDate.clone().add(2, "week");

      if(nextNewEndDate.isBefore(nextNewStartDate)) {
        nextNewEndDate.endOf("month")
      }
      props?.setStartDate(nextNewStartDate.toDate())
      props?.setEndDate(nextNewEndDate.toDate())
    } else {
      console.log("Invalid startdate or enddate")
    }
  }

  const gotToPreviousWeek = () => {
    const newStartDate = moment(props?.startDate || new Date())
    const newEndDate = moment(props?.endDate || new Date())
    if(newStartDate.isValid() && newEndDate.isValid()) {
      const prevStartDate = newStartDate.clone().subtract(2, "week");
      const prevEndDate = newEndDate.clone().subtract(1, "week");
      
      if(prevEndDate.isBefore(prevStartDate)) {
        prevEndDate.endOf('month')
      }
      props?.setStartDate(prevStartDate.toDate())
      props?.setEndDate(prevEndDate.toDate())
    } else {
      console.log("Invalid startdate and enddate")
    }
  }
 
  const DatePickerCustomComponent = forwardRef(({ value, onClick }, ref) => (
    <div className="date-picker-container" onClick={gotToPreviousWeek}>
      <img className="calendar-icon" src={Images.leftArrow} alt="Calendar"  />
      <p className="date-text" onClick={onClick}>
        {/* {moment.utc(calendarDate).format("MMMM YYYY")} */}
        {value}
      </p>
      <img
        className="arrow-icon"
        src={Images.newRightArrow}
        alt="Dropdown Arrow"
        onClick={goToNextWeek}
      />
    </div>
  ));

  const selectOption = [
    { value: "all", label: "All Channels"},
    { value: "pos", label: "Checkout"},
    { value: "b2c", label: "Market"},
    { value: "b2b", label: "Trade"},
  ];
  
  const handleTimePeriodFilter = (value) => {
    props?.setFilterValue(value);
    if (props.handleFilter) {
      props.handleFilter(value);
    }
  }
  let date_format = "YYYY-MM-DD";

  const onChannelChange = (e) => {
    props?.setChannelFilter(e?.value || "All")
  }
  
  return (
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-md-2">
          <div>
            <h3 className="fontSize18 headerTxtColor mb-0 pb-1">Key Metrics</h3>
            <h6 className="homeHeader_Subheading mb-0">{props?.subhead}</h6>
          </div>
        </div>
        <div className="col-md-10 d-flex justify-content-end align-items-start">
          <div className="d-flex align-items-center gap-2">
            <div className="bookingLeftTwo">
              <ReactDatePicker
                onChange={(date) => {
                  const [start, end] = date;
                  props?.setStartDate(start);
                  props?.setEndDate(end);
                  if (props?.handleDate) {
                    if (start && end) props.handleDate({ startDate: getFormatedDate(start, date_format), endDate: getFormatedDate(end, date_format) });

                  }
                }}
                startDate={props?.startDate}
                endDate={props?.endDate}
                selectsRange
                maxDate={new Date()}
                customInput={<DatePickerCustomComponent />}
              />
            </div>
            <div className="reactSelectParent">
              <ReactSelect
                options={selectOption}
                // defaultValue={channelSelected}
                value={props?.channelFilter}
                placeholder="All Channels"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682" // Background color for selected option
                      : state.isFocused
                        ? "#f5f6fc" // Background color for hovered option
                        : "#fff", // Default background color
                    // color: state.isSelected || state.isFocused ? '#fff' : '#000', // Text color
                    color: state.isSelected
                      ? "#fff" // Background color for selected option
                      : "#000", // Text color
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#263682", // Customize placeholder color if needed
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                onChange={onChannelChange}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>
            <ul className="weekNav">
              <li onClick={() => handleTimePeriodFilter("today")}>
                <Link
                  className={`weekMenu ${props?.filterValue == "today" ? "active" : ""
                    }`}
                  to="#"
                >
                  Today
                </Link>
              </li>
              <li onClick={() => handleTimePeriodFilter("week")}>
                <Link
                  className={`weekMenu ${props?.filterValue == "week" ? "active" : ""
                    }`}
                  to="#"
                >
                  Weekly
                </Link>
              </li>
              <li onClick={() => handleTimePeriodFilter("month")}>
                <Link
                  className={`weekMenu ${props?.filterValue == "month" ? "active" : ""
                    }`}
                  to="#"
                >
                  Monthly
                </Link>
              </li>
              <li onClick={() => handleTimePeriodFilter("year")}>
                <Link
                  className={`weekMenu ${props?.filterValue == "year" ? "active" : ""
                    }`}
                  to="#"
                >
                  Yearly
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsHeader;
