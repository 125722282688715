import React from 'react'
import { OrderDown,
    cancleorder,
    incoiceuser_,
    modalCancel,
    pickuporder,
    profileImg1,
    returnlogo,
    serviceuser_,
    unselectedOrder,
    drivefast_,
    upArrow,
    locateOrder,
    clockBlue,
    moneyImg,
    mapimg,
    deliveryMap,warningicon,
    fillradio_,
    verifyorder,
    orderDriver,done,cancelledstep,
    readystep,
    acceptstep,
    openTag,
    closeTag,
    Logomvoice,
    barCodeScanImg} from '../../../../utilities/images';
import moment from 'moment-timezone';
import {Link} from "react-router-dom"
import { getFormatedDate } from '../../../../utilities/helpers';
const OrderInvoiceDetails = ({setopenOrderDetails,orderDetails}) => {
  console.log(orderDetails,"Check order details");
  return<>
   {/* <div className="invoice_board mt-3 pb-3">
            <div className="select_profile">
              <img
                onClick={() => {
                    setopenOrderDetails(false);
                }}
                src={openTag}
                alt="downSelect"
                className="cursor-pointer"
              />
            </div>
                <div className="invoice_board_header d-flex flex-column align-items-center p-4 border-bottom border-dotted">
                  <h3 className="ib_title">{
                              orderDetails?.user_details?.firstname+" "+
                              orderDetails?.user_details?.lastname
                            }
                            </h3>
                  <p>

                  {
                                orderDetails?.user_details?.current_address?.street_address
                              }
                  </p>
                  <span>{
                    orderDetails?.user_details?.phone_number
                    }</span>
                </div>
            
                <div className="order_table p-2">
                 
                  {
                  orderDetails?.order_details?.length > 0
                    ? orderDetails?.order_details?.map((val, i) => {
                      return (
                        <div key={i} className="serviceDeatils_">
                          <div className="serviceinfo_">
                            <figure className="proservice_ me-3">
                              <img
                                className="img-fluid"
                                src={val?.product_image}
                              />
                            </figure>
                            <article className="duration_">
                              <h3 className="darkblack">
                               {
                                val?.product_name
                               }
                              </h3>
                            </article>
                          </div>
                          <div className="servicechanges_">
                            <span className="serciceprice_">
                              {Number(val?.actual_price)?.toFixed(2)}
                            </span>
                            <span className="serciceprice_">{val?.qty}</span>
                            <span className="serciceprice_">
                                    <span className="">{`$${(Number(val?.actual_price||0) * Number(val?.qty|| 0))?.toFixed(2)}`}
                                    </span>

                            </span>
                          </div>
                        </div>
                      )
                    })
                    : orderDetails?.return_detail?.return_details?.map(
                      (val, i) => {
                        return (
                          <div key={i} className="serviceDeatils_">
                            <div className="serviceinfo_">
                              <img
                                style={{ width: "50px" }}
                                className="img-fluid me-3"
                                src={val?.order_details?.product_image}
                              />
                              <article className="duration_">
                                <h3 className="lightgreyHeading_">
                                  {val?.order_details?.product_name}
                                </h3>
                              </article>
                            </div>
                            <div className="servicechanges_">
                              <span className="serciceprice_">
                                $
                                {Number(val?.order_details?.price)?.toFixed(2)}
                              </span>
                              <span className="serciceprice_">
                                {val?.order_details?.qty}
                              </span>
                              <span className="serciceprice_">
                                $
                                {Number(
                                  val?.order_details?.qty *
                                  val?.order_details?.price
                                )?.toFixed(2)}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
            
                <div className="payment_details row p-2">
                  {[
                    { title: "Payment Option", value: `${orderDetails?.mode_of_payment}` },
                    { title: "Date", value: `${getFormatedDate(orderDetails?.date,"MM-DD-YYYY")}` },
                    { title: "Mode", value: "Walk in" },
                    { title: "Invoice", value: `#${orderDetails?.invoices?.id}` },
                    { title: "Pos No", value: `#${orderDetails?.pos_user_id?.substr(orderDetails?.pos_user_id?.length-3,orderDetails?.pos_user_id?.length)}` },
                    { title: "User UID", value: `#${orderDetails?.user_details?.uid?.substr(orderDetails?.user_details?.uid?.length-3,orderDetails?.user_details?.uid?.length)}` },

                  ].map((data, index) => (
                    <div key={index} className="col-md-4">
                      <p>{data.title}</p>
                      <span>{data.value}</span>
                    </div>
                  ))}
                </div>
            
                <div className=" p-2">
                  {[
  {
    label: "Subtotal",
    amount: `$${orderDetails?.order_details?.length > 0 
      ? orderDetails?.order_details?.reduce((acc, curr) => {
          return acc + (parseFloat(curr?.actual_price) || 0) * (curr?.qty || 0);
        }, 0).toFixed(2)
      : 0.00
    }`
  },
  {
    label: "Discount",
    amount: `${(Number(orderDetails?.discount)||0)?.toFixed(2)}`
  },
  {
    label: "Shipping",
    amount: `${(Number(orderDetails?.shipping_charge)|| 0)?.toFixed(2)}`
  },
  {
    label: "Other Fees",
    amount: `${((Number(orderDetails?.transaction_fee) || 0) + (Number(orderDetails?.delivery_charge) || 0))?.toFixed(2)}`
  },
  {
    label: "Taxes",
    amount: `$${(Number(orderDetails?.tax) || 0)?.toFixed(2)}`
  }
]
.map((item, index) => (
                    <div key={index} className="d-flex justify-content-between align-items-center">
                      <p>{item.label}</p>
                      <span>{item.amount}</span>
                    </div>
                  ))}
                </div>
            
                <div className="d-flex justify-content-between p-3">
                  <h4>Total</h4>
                  <span className="rounded bg-light p-2">${orderDetails?.payable_amount}</span>
                </div>
            
                <div className="title_and_qr mb-2 ">
                  <h5>Jobr</h5>
                  <div className="d-flex align-items-center justify-content-center">
                  <img style={
                    {width:"100px"}
                  } src={orderDetails?.invoices?.barcode} alt="QR Code" />
                  </div>
                </div>
              </div> */}
 


              {/* ------------------------------------ */}
              <div className="orderTrack" style={{minWidth:"400px"}}>
        
          <div className="consumerOrder">
            
                <div className="col-lg-12">
                  <div className="invoiceMain_">
                  <div className="select_profile pb-3">
            {
              setopenOrderDetails?
              <img
              onClick={() => {
                setopenOrderDetails(false);
              }}
              style={{transform:'rotate(-94deg)'}}
              src={closeTag}
              alt="downSelect"
              className="cursor-pointer"
              />:<Link to="/allOrder">
                           <img
              
              style={{transform:'rotate(-94deg)'}}
              src={closeTag}
              alt="downSelect"
              className="cursor-pointer"
              />
             </Link>
            }  
            </div>
                    <div className="invoiceidHead_">
                      <h4 className="blueHeading_"># {orderDetails?.invoices?.id}</h4>
                      <h6 className="bluesmall_">Ready to Pickup</h6>
                    </div>
                    <div className="mt-3 me-0 mapleLeft ">
                      <div className="confirmRightSub confirmAddress">
                        <h2 className="mapleHeading text-center">
                        {
                              orderDetails?.seller_details?.
                              organization_name
                            }

                        </h2>
                        <h4 className="mapleAddress text-center">
                        {
                                orderDetails?.seller_details?.current_address?.format_address
                              }
                        </h4>
                        <h4 className="mapleAddress text-center p-0">
                        {
                    orderDetails?.seller_details?.phone_number

                    }
                        </h4>
                      </div>
                      <div className="mainscrollinvoice_">
                        <div className="mapleProductDetails confirmRightSub">
                          {
                             orderDetails?.order_details?.length > 0
                             ? orderDetails?.order_details?.map((val, i) =>(

                          <div className="flexBox mapleProductDetailsBox">
                            <div className="flexbase">
                              <p className="mapleProductcount">{val?.qty}</p>
                              <article className="ms-2">
                                <p className="mapleProductHeading">
                                {
                                val?.product_name
                               }
                                  {/* <span>Yellow / M</span> */}
                                </p>
                              </article>
                            </div>
                            <article>
                              <p className="mapleProductPrice">${`${(Number(val?.actual_price||0) * Number(val?.qty|| 0))?.toFixed(2)}`}</p>
                            </article>
                          </div>
                             )):orderDetails?.return_detail?.return_details?.map((val,i)=>(
                              <div className="flexBox mapleProductDetailsBox">
                            <div className="flexbase">
                              <p className="mapleProductcount">{val?.order_details?.qty}</p>
                              <article className="ms-2">
                                <p className="mapleProductHeading">
                                {val?.order_details?.product_name}
                                  {/* <span>Yellow / M</span> */}
                                </p>
                              </article>
                            </div>
                            <article>
                              <p className="mapleProductPrice">${Number(
                                  val?.order_details?.qty *
                                  val?.order_details?.price
                                )?.toFixed(2)}</p>
                            </article>
                          </div>
                             ))
                          }
                        </div>
                      </div>
                      <div className="commonInvoice_ mt-auto">
                        <div className="flexBox mapleInvoiceBox confirmRightSub">
                          <article>
                            <p className="mapleProductPrice">Payment Option</p>
                            <p className="mapleProductHeading">{orderDetails?.mode_of_payment}</p>
                            
                          </article>
                          <article>
                          <p className="mapleProductPrice">Invoice</p>
                            <p className="mapleProductHeading"># {orderDetails?.invoices?.id}</p>
                            {/* <p className="mapleProductPrice">POS No.</p>
                            <p className="mapleProductHeading">#{orderDetails?.pos_user_id?.substr(orderDetails?.pos_user_id?.length-3,orderDetails?.pos_user_id?.length)}</p> */}
                          </article>
                          <article>
                          <p className="mapleProductPrice">Date</p>
                            <p className="mapleProductHeading">{getFormatedDate(orderDetails?.date,"MM-DD-YYYY")}</p>
                            {/* <p className="mapleProductPrice">Mode</p>
                            <p className="mapleProductHeading">Walk-In</p>
                            <p className="mapleProductPrice">User UD</p>
                            <p className="mapleProductHeading">****{orderDetails?.user_details?.uid?.substr(orderDetails?.user_details?.uid?.length-3,orderDetails?.user_details?.uid?.length)}</p> */}
                          </article>
                        </div>
                        <div className="flexBox maplePriceBox">
                          <article>
                            <p className="productName">Subtotal</p>
                            <p className="productName">Discount</p>
                            <p className="productName">Shipping</p>
                            <p className="productName">Other Fees</p>
                            <p className="productName">Taxes</p>

                            <p className="productName fw-bold">Total</p>
                          </article>
                          <article>
                            <p className="productName">${orderDetails?.order_details?.length > 0 
      ? orderDetails?.order_details?.reduce((acc, curr) => {
          return acc + (parseFloat(curr?.actual_price) || 0) * (curr?.qty || 0);
        }, 0).toFixed(2)
      : 0.00
    }</p>
                            <p className="productName">{(Number(orderDetails?.discount)||0)?.toFixed(2)}</p>
                            <p className="productName">${(Number(orderDetails?.shipping_charge)|| 0)?.toFixed(2)}</p>
                            <p className="productName">${((Number(orderDetails?.delivery_charge) || 0))?.toFixed(2)}</p>
                            {/* <p className="productName">${((Number(orderDetails?.transaction_fee) || 0) + (Number(orderDetails?.delivery_charge) || 0))?.toFixed(2)}</p> */}
                            <p className="productName">${(Number(orderDetails?.tax) || 0)?.toFixed(2)}</p>

                            <p className="totalBtn">${orderDetails?.payable_amount}</p>
                          </article>
                        </div>
                        <div className="confirmFooter">
                          <img
                            src={Logomvoice}
                            alt="logo"
                            className="img-fluid logo"
                          />
                          <img
                            src={orderDetails?.invoices?.barcode||barCodeScanImg}
                            alt="barCodeScanImg"
                            className="img-fluid barCodeScanImg"
                            style={{maxWidth:"195px"}}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
             
          </div>
      </div>
  </>
}

export default OrderInvoiceDetails