import React, { useEffect } from 'react'
import * as Images from "../../../../utilities/images";  // Assuming Images is being used somewhere else

const CarriersfedxModal = ({ close }) => {
  useEffect(()=>{
    setTimeout(() => {
      close();
    }, 3000);
  },[])
  return (
    <div className='carriersfedxModal'>
        <h3 className='headingblue24_'>Redirecting to FedEx</h3>
        <p className='smalldiscription_'>Please wait while we are connecting to FedEx</p>
        <div className="d-flex justify-content-center mt-2" style={{height:"59px"}}>
            <div 
                className="spinner-border" 
                role="status" 
                style={{ width: '50px', height: '50px' }}
            >
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
  )
}

export default CarriersfedxModal;
