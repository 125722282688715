export const AllOrders=[
    { value: "", label: "All Orders" },
        { value: "1", label: "Delivery" },
        { value: "2", label: "In-Store" },
        { value: "3", label: "Pickup Delivery" },
        { value: "4", label: "Shipping" },
]
export const AllPayments=[
    {value:"all",label:"All Payments"},
    {value:"cash",label:"Cash"},
    {value:"card",label:"Cards"},
    {value:"gbr",label:"Gift Cards"},
]
export const AllStatus=[
    { value: "", label: "All Status" },
    {value:"0",label:"Order to Review"},
    {value:"1",label:"Accepted"},
    {value:"2",label:"Order Prepairing"},
    {value:"3",label:"Ready for Pickup"},
    {value:"4",label:"Pickup"},
    {value:"5",label:"Delieverd"},
    {value:"6",label:"Pickup by Customer"},
    {value:"7",label:"Cancelled"},
    {value:"8",label:"Rejected"},
    {value:"9",label:"Returned"},
]

export const DeliveryOptionsDetails=[
    {
        title:"Delivery",
        img:"driverSolid"
    },
    {
        title:"In-Store",
        img:"storeImg"
    },
    {
        title:"Pickup",
        img:"pickupCart"
    },
    {
        title:"Shipping",
        img:"shippingstatus"
    },
]

export const DeliveryStatusOptions=[
    {
        className:"revieworder",
        label:"Review Order"
    },
    {
        className:"accepted",
        label:"Accepted"
    },
    {
        className:"prepariging",
        label:"Preparing"
    },
    {
        className:"driverAssigned",
        label:"Assign Driver"
    },
    {
        className:"pickup",
        label:"Pick Up"
    },
    {
        className:"completed",
        label:"Completed"
    },
    {
        className:"completed",
        label:"Pickup by Customer"
    },
    {

        className:"cancelled",
        label:"Cancelled"
    },
    {
        className:"rejected",
        label:"Rejected"
    },
    {
        className:"returned",
        label:"Returned"
    }
    
]
// order columns field
export const OrderColumns = [
    {
        isVisible: true,
        title: "Customer"
    },
    {
        isVisible: true,
        title: "Time"
    },
    {
        isVisible: true,
        title: "Invoice Track"
    },
    {
        isVisible: true,
        title: "Delivery"
    },
    {
        isVisible: true,
        title: "Payment Method"
    },
    {
        isVisible: true,
        title: "Total Amount"
    },
    {
        isVisible: true,
        title: "Status"
    },
    {
        isVisible: false,
        title: "Location"
    },
    {
        isVisible: false,
        title: "Tips"
    },
    {
        isVisible: false,
        title: "Taxes"
    },
]

export const deliveryStatus={
    "1":["Accept","Prepairing","Ready to PcikUp","Assigning Driver","Picked up","Completed","Pick up by customer","Cancelled","Rejected","Returned"],
    "2":["Servie to review","Accepted","In Progress","In Progress","In Progress","Completed"],
    "3":["Accpted","Prepairing","Ready to Pickup","Completed","","","","Cancelled","Rejected","Returned"],
    "4":["Accepted","","Print Label","Shipped","Completed","Cancelled","Rejected","Returned"],
}
export const filterOptions = [
    {
        title: "All Orders",
        data: AllOrders
    },
    {
        title: "All Status",
        data: AllStatus
    },
    {
        title: "All Payment",
        data: AllPayments
    },
]