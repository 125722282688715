import React from "react";
import * as Images from "../../../utilities/images";

const DeleteProductModal = (props) => {
  console.log(props)
  
  const handleProductDeleteModal = () =>{
    props?.handleModal("deleteModal")
  }
  return (
    <div className="text-center">
      <img src={Images.crossCircle} alt="cancleLogo" />
      <div className="stopOfferModalContainer">
        <h3 className="stopOfferTxt mb-0">Are you sure you want to delete?</h3>
      </div>
      <ul className="deleteProductModalList">
        <li>This will remove the product from all listings.</li>
        <li>Inventory records will be retained.</li>
        <li>This action cannot be undone.</li>
      </ul>

      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
        >
          Cancel
        </button>
        <button className="modalNextBtn modalDiscardBtn modalStopBtn w-100" onClick={()=>handleProductDeleteModal()}>
          <img src={Images.newDeleteIcon} alt="stopIcon" className="me-2" />
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteProductModal;
