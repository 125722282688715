import React, { useEffect, useState } from "react";
import ProductSidebar from "../../components/shared/productSidebar";
import { useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  addProductsToInventoryAsync,
  getAddedToInventoryProductsAsync,
} from "../dashboard/dashboardSlice";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import CustomModal from "../../components/shared/CustomModal";
import MyProductDetials from "../myProducts/myProductDetails";
import DBImportProductModal from "../myProducts/DBImportProductModal";
import SuccessfulModal from "../auth/successfulModal";
import DataTable from "react-data-table-component";
import UploadproductModal from "../scalePlan/ uploadproductModal";
import {
  blueBackArrow,
  SearchIcon,
  webPosArrow,
  filterIcon,
  productWatchImg,
  plusCircleOutline,
} from "../../../utilities/images";
import { toast } from "react-toastify";

const Product = () => {
  const history = useHistory();
  const [selectedProduct, setSelectedProduct] = useState({});
  const dispatch = useDispatch();
  const backRoute = localStorage.getItem("backButtonRoute");
  const [productData, setProductData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [brandIds, setBrandIds] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const [checkedState, setCheckedState] = useState([]);
  console.log(checkedState, "checkedState");
  const [checkedValue, setCheckedValue] = useState([]);
  console.log(checkedValue, "checkedValue");
  const [selectedProductData, setSelectedProductData] = useState([]);

  console.log(selectedProductData, "checkedValueDatach");
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const { categoryId, subCategoryId, brandId, name } = useParams();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [allAddress, setAllAddress] = useState([]);
  const [allAddressIds, setAllAddressIds] = useState([]);
  console.log(allAddressIds, "allAddresssssss");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setCheckedValue([]);
    setSelectedProductData([]);
    setAllAddressIds([]);
    setSelectedAddress([]);
    setCheckedState(new Array(productData?.length)?.fill(false));
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  // const data = [1,2,3,4]

  const columns1 = [
    {
      selector: (row, index) => {
        return (
          <>
            <div className=" productViewCard mb-4">
              <div
                onClick={() => handleData(index)}
                className={`smokeContainer ${
                  row?.id === checkedValue?.find((value) => value === row?.id)
                    ? "productActive"
                    : ""
                }`}
              >
                {/* <div className='smokeCheck'>
                  <input
                    type="checkbox"
                    id={`custom-checkbox1-${row.id}`}
                    className='checkBox'
                    name={row?.id}
                    value={row?.id}
                    checked={row?.id === (checkedValue?.find(value => value === row?.id))}
                    onChange={() => handleData(index)}
                  />
                  <label htmlFor={`custom-checkbox1-${row.id}`} className='checkBoxTxt'></label>
                </div> */}
                <div className="smokeDetails">
                  <img
                    src={row?.image}
                    alt="SmokeImg"
                    style={{ width: "100px" }}
                    className="resultProductImg me-3"
                  />
                  <div className="">
                    <h4 className="subtext_">
                      {(row?.name).slice(0, 30)}
                      {row?.name?.length > 30 ? "..." : <></>}
                    </h4>
                    <p className="tableHeadsmall">#{row?.id}</p>
                  </div>
                </div>
                <button
                  className="moveDetails"
                  onClick={() => {
                    setSelectedProduct(row);
                    setModalDetail({ show: true, flag: "MyProductDetials" });
                    setKey(Math.random());
                  }}
                >
                  <i className="las la-angle-double-right"></i>
                </button>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPerPage(currentRowsPerPage);
    setCurrentPage(1);
  };

  const handleSelectAll = (status) => {
    setSelectAll((prev) => !prev);
    if (selectAll === false) {
      let checkedData = productData?.map((v, i) => {
        return v?.id;
      });
      let selectedData = productData?.map((v, i) => {
        return v;
      });
      setSelectedProductData(selectedData);
      setCheckedValue(checkedData);
      setCheckedState(new Array(productData?.length)?.fill(true));
    } else {
      setSelectedProductData([]);
      setCheckedValue([]);
      setCheckedState(new Array(productData?.length)?.fill(false));
    }
  };

  const handleData = (position) => {
    const updatedCheckedState = checkedState?.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    let data = updatedCheckedState?.map((val, index) => {
      return val === true ? productData[index]?.id : false;
    });
    let databaseProductModal = updatedCheckedState?.map((val, index) => {
      return val === true ? productData[index] : false;
    });
    let result = data?.filter((val) => val !== false);
    setCheckedValue(result);
    let databaseProductModalResult = databaseProductModal?.filter(
      (val) => val !== false
    );
    setSelectedProductData(databaseProductModalResult);
  };

  const handleModalDetails = (data) => {
    if (data === "productAdded") {
      setModalDetail({ show: false, flag: "" });
      setKey(Math.random());
    }
  };
  let productParams = {
    tokenData: auth?.payload?.token,
    postData: {
      type: "physical",
    },
  };
  const ADMIN = () => {
    const admin = auth?.payload?.user?.user_roles?.filter(
      (item) => item?.role?.slug == "admin"
    );
    return admin;
  };

  // console.log(allAddressIds?.length == 0, allAddressIds, allAddress?.length > 1, allAddress, "all address");
  const handleAddProductssToShop = () => {
    if (
      allAddressIds?.length == 0 &&
      allAddress?.length > 1 &&
      !(ADMIN()?.length > 0)
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select atleast one address");
      }
      return false;
    }
    setModalDetail({ show: true, flag: "DBImportProductModal" });
    setKey(Math.random());

    // let data = {
    //   postData: {
    //     product_ids: checkedValue,
    //   },
    //   tokenData: auth?.payload?.token,
    // };

    // if (allAddress?.length > 1 && allAddressIds?.length == 0) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error("Please select atleast one address");
    //   }
    //   return false;
    // }

    // if (allAddressIds?.length > 0) {
    //   data = {
    //     postData: {
    //       ...data.postData,
    //       seller_address_ids: allAddressIds,
    //     },
    //     tokenData: auth?.payload?.token,
    //   };
    // }

    // setButtonLoading(true);
    // dispatch(addProductsToInventoryAsync(data))
    //   .then(unwrapResult)
    //   .then((obj) => {
    //     setProductData(obj?.payload?.data);
    //     setButtonLoading(false);
    //     setModalDetail({ show: true, flag: "successfullyAdded" });
    //     setKey(Math.random());
    //     setCheckedValue([]);
    //     dispatch(getAddedToInventoryProductsAsync(productParams))
    //       .then(unwrapResult)
    //       .then((obj) => {
    //         history.push(`/productList`);
    //         setProductData(obj?.payload?.data);
    //         setTotalRows(obj?.payload?.total);
    //         setCheckedState(new Array(obj?.payload?.data?.length)?.fill(false));
    //       })
    //       .catch((obj) => {});
    //   })
    //   .catch((obj) => {
    //     setButtonLoading(false);
    //   });
  };

  const handleProductsData = async () => {
    let productData = {};
    if (filteredData?.length >= 3) {
      productData = {
        tokenData: auth?.payload?.token,
        postData: {
          type: "physical",
          search: filteredData,
          page: currentPage,
          limit: perPage,
        },
      };
    } else {
      productData = {
        tokenData: auth?.payload?.token,
        postData: {
          type: "physical",
          page: currentPage,
          limit: perPage,
        },
      };
    }
    setLoading(true);
    await dispatch(getAddedToInventoryProductsAsync(productData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        console.log({ productResp: obj?.payload?.data})
        setProductData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setCheckedState(new Array(obj?.payload?.data?.length)?.fill(false));
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getSubCategoryId = (subCategoryId) => {
    setSubCategoryIds(subCategoryId);

    console.log(subCategoryId, "sub category idsss functaion call");
    let productData = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "physical",
        sub_category_ids: subCategoryId.toString(),
      },
    };
    setLoading(true);
    dispatch(getAddedToInventoryProductsAsync(productData))
      .then(unwrapResult)
      .then((obj) => {
        setProductData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setCheckedState(new Array(obj?.payload?.data?.length)?.fill(false));
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const getBrandId = (brandIds) => {
    setBrandIds(brandIds);
    let productData = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "physical",
        brand_id: brandIds.toString(),
      },
    };
    setLoading(true);
    dispatch(getAddedToInventoryProductsAsync(productData))
      .then(unwrapResult)
      .then((obj) => {
        setProductData(obj?.payload?.data);
        setTotalRows(obj?.payload?.total);
        setCheckedState(new Array(obj?.payload?.data?.length)?.fill(false));
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleSelect = (selectedAddress, selectedData) => {
    setSelectedAddress(selectedAddress);
    let allIds = selectedAddress?.map((v) => v?.id);
    setAllAddressIds(allIds);
  };

  console.log(allAddress, "allllllllllllllllllllllllllllllllll");

  useEffect(() => {
    if (filteredData?.length >= 3 || filteredData?.length === 0) {
      handleProductsData();
    }
  }, [perPage, currentPage, filteredData]);

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        setAllAddress(obj?.payload);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };

  console.log(allAddress, "alllllll addresssss");

  useEffect(() => {
    // Automatically select if only one address is present
    if (allAddress?.length === 1) {
      setSelectedAddress(allAddress);
      let allIds = allAddress?.map((v) => v?.id);
      setAllAddressIds(allIds);
    }
  }, [allAddress]);

  useEffect(() => {
    getAllAddress();
  }, []);

  return (
    <main>
      <div className="wrapper">
        {/* Start FixedTopHeader */}
        {/* <div className='topHeader_ productsHeader'>
          <div className="productTopStickyHeader_ mb-3">
            <div className="row align-items-center">
              <div className="col-md-6">
                <h2 className="innerSubheadNormal">
                  <span onClick={() => history.push(backRoute)}>
                    <i className="las la-angle-left me-3 directionArrow"></i>
                  </span>
                  <span>Master category: {name}</span>
                </h2>
              </div>
              <div className='col-md-6 text-end'>
                <button className='staticOtherkBtn me-3' onClick={() => {
                  setModalDetail({ show: true, flag: "UploadproductModal" });
                  setKey(Math.random());
                }}>
                  <span className='me-2'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.00008 3.33594L7.64653 2.98238L8.00008 2.62883L8.35363 2.98238L8.00008 3.33594ZM8.50008 9.33594C8.50008 9.61208 8.27622 9.83594 8.00008 9.83594C7.72394 9.83594 7.50008 9.61208 7.50008 9.33594L8.50008 9.33594ZM4.31319 6.31572L7.64653 2.98238L8.35363 3.68949L5.0203 7.02282L4.31319 6.31572ZM8.35363 2.98238L11.687 6.31572L10.9799 7.02282L7.64653 3.68949L8.35363 2.98238ZM8.50008 3.33594L8.50008 9.33594L7.50008 9.33594L7.50008 3.33594L8.50008 3.33594Z" fill="#3C444D" />
                      <path d="M3.33325 10.6641L3.33325 11.3307C3.33325 12.0671 3.93021 12.6641 4.66659 12.6641L11.3333 12.6641C12.0696 12.6641 12.6666 12.0671 12.6666 11.3307V10.6641" stroke="#3C444D" />
                    </svg>
                  </span>
                  Import
                </button>
                <button className='primaryBtnOuter' onClick={() => { history.push("/add-product/myProducts") }}>
                  <span className='me-2'>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 8C3 7.06128 3.00027 6.38227 3.0369 5.84541C3.07322 5.31304 3.14362 4.95707 3.26642 4.66061C3.62165 3.80301 4.30301 3.12165 5.16061 2.76642C5.45707 2.64362 5.81304 2.57322 6.34541 2.5369C6.88227 2.50027 7.56128 2.5 8.5 2.5C9.43872 2.5 10.1177 2.50027 10.6546 2.5369C11.187 2.57322 11.5429 2.64362 11.8394 2.76642C12.697 3.12165 13.3783 3.80301 13.7336 4.66061C13.8564 4.95707 13.9268 5.31304 13.9631 5.84541C13.9997 6.38227 14 7.06128 14 8C14 8.93872 13.9997 9.61773 13.9631 10.1546C13.9268 10.687 13.8564 11.0429 13.7336 11.3394C13.3783 12.197 12.697 12.8783 11.8394 13.2336C11.5429 13.3564 11.187 13.4268 10.6546 13.4631C10.1177 13.4997 9.43872 13.5 8.5 13.5C7.56128 13.5 6.88227 13.4997 6.34541 13.4631C5.81304 13.4268 5.45707 13.3564 5.16061 13.2336C4.30301 12.8783 3.62165 12.197 3.26642 11.3394C3.14362 11.0429 3.07322 10.687 3.0369 10.1546C3.00027 9.61773 3 8.93872 3 8Z" stroke="#275AFF" />
                      <path d="M8.5 5.33594L8.5 10.6693" stroke="#275AFF" strokeLinejoin="round" />
                      <path d="M11.1667 8L5.83341 8" stroke="#275AFF" strokeLinejoin="round" />
                    </svg>
                  </span>
                  Add Manually
                </button>
              </div>
            </div>
          </div>
        </div> */}
        {/* End FixedTopHeader */}
        <div className="allProductHeader">
          <button className="headerBackBtn d-flex" onClick={()=>history.goBack()}>
            <img
              src={blueBackArrow}
              alt="lightLeftArrowLogo"
              className="backbtnArrow me-1"
            />
            Back
          </button>
        </div>
        <ProductSidebar
          subCategoryId={(e) => getSubCategoryId(e)}
          brandId={setBrandIds}
        />

        <div className="productRightWrapperParent">
          <div className="rightWrapper productRightWrapper mt-0 ProductsBox_">
            <div className="content_areas mt-0">
              <div className="container-fluid p-0">
                {/* <ProductNavbar /> */}
                <div className="myProductHeader">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <h5 className="fontSize14 fw500 txtDarkblue mb-0">
                        All Products
                        <span className="allproductCount fontSize12 ps-1">
                          (+{productData?.length})
                        </span>
                      </h5>
                    </div>
                    <div className="col-md-9">
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <div className="search_group allProduct_SearchInput">
                          <img
                            src={SearchIcon}
                            alt=""
                            className="img-fluid searchIcon"
                          />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Name..."
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                        </div>

                        <div className="productAvailabilityChild align-items-baseline">
                          <div
                            className="form-group"
                            checked={selectAll}
                            onChange={() => handleSelectAll(selectAll)}
                          >
                            <input type="checkbox" id="checkid1" />
                            <label htmlFor="checkid1" className="me-0"></label>
                          </div>
                          <h3 className="fontSize14 txtDarkblue mb-0">
                            Select All
                          </h3>
                        </div>

                        <button
                          className="allProductBtn"
                          onClick={() =>
                            history.push(`/add-product/myProducts`)
                          }
                        >
                          Add Products <span>(0)</span>
                          <img src={webPosArrow} alt="webPosArrow" />
                        </button>

                        <div
                          className="productFilter"
                          onClick={() => history.push("/all-product")}
                        >
                          Filters
                          <img src={filterIcon} alt="filterIcon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="productShop_ ">
                  <div className="col-lg-2">
                    <div className="smokeCheck me-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={selectAll}
                        onChange={() => handleSelectAll(selectAll)}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll"
                        className="checkBoxTxt masterSelect"
                      >
                        Select All
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end col-lg-10">
                    <div className="productSelect">
                      <Multiselect
                        className="brandFilter customform-control me-3 productSearch"
                        displayValue="format_address"
                        options={allAddress}
                        hidePlaceholder={false}
                        placeholder="Select Address"
                        onSelect={handleSelect}
                        onRemove={handleSelect}
                        selectedValues={
                          allAddress?.length === 1
                            ? allAddress
                            : selectedAddress
                        }
                        showCheckbox
                        // onKeyDown={(e) => {handleKeyDown(e)}}
                      />
                    </div>
                   
                    <button
                      onClick={() => history.push(`/productUploadFile`)}
                      className="importBtnProduct"
                    >
                      <img
                        src={importFile}
                        alt="fileIcon"
                        className="img-fluid"
                      />
                      Import
                    </button>
                    <button
                      onClick={() => history.push(`/add-product/myProducts`)}
                      className="importBtnProduct"
                    >
                      <i className="fa-solid fa-plus"></i>
                      Add Manually
                    </button>
                    <button
                      onClick={() => handleAddProductssToShop()}
                      disabled={buttonLoading || checkedValue?.length === 0}
                      className={`loginBtnSign ${
                        checkedValue?.length > 0 ? "buttonActive" : ""
                      }`}
                    >
                      {buttonLoading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Add</span>
                    </button>

                   
                  </div>
                 
                </div> */}
                {/* <h2 className="modalHeading_ pb-0">Showing Results: All</h2> */}

                {/* Static Design For All Product  */}
                {/* <div className="row">
                  <div className="col-md-2">
                    <div className="productBoxContainer">
                      <figure className="productImageBox m-0">
                        <img
                          src={productWatchImg}
                          alt="image"
                          className="img-fluid"
                        />
                      </figure>
                      <div className="productDetailsWrapperBox">
                        <h3 className="fontSize12 fw500 txtDarkblue mb-0">
                          Cozy Premium Cotton Henly T-shirt
                        </h3>

                        <div className="productInnerDetails">
                          <h5 className="fontSize12 fw600 txtDarkblue mb-0">
                            5 Variations
                          </h5>
                          <img src={plusCircleOutline} alt="image" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row">
                  {loading ? (
                    <span className="spinner-border inner-spin spinner-border-sm"></span>
                  ) : productData?.length > 0 ? (
                    productData?.map((items, index) => {
                      console.log(items, "items");
                      return (
                        <div className="col-md-2 mt-3" key={index}>
                          <div className="productBoxContainer">
                            <figure className="productImageBox m-0">
                              <img
                                src={items.image}
                                alt="image-product"
                                className="img-fluid"
                              />
                            </figure>
                            <div className="productDetailsWrapperBox">
                              <h3 className="productNameTxt mb-0">
                                {items.name}
                              </h3>
                              <h3 className="productNameTxt mb-0">
                                {/* {items?.sub_category} */}
                              </h3>

                              <div className="productInnerDetails">
                                <h5 className="fontSize12 fw600 txtDarkblue mb-0">
                                  5 Variations
                                </h5>
                                <img
                                  src={plusCircleOutline}
                                  alt="add-image"
                                  className="cursorPointer"
                                  onClick={() => {
                                    history.push(`/product-details?productID=${items?.id}`);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="pro productitemas__">
                  <div className="row">
                    <DataTable
                      className="columsGrid"
                      columns={columns1}
                      //  data={props.ProductList}
                      data={productData}
                      paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                      progressPending={loading}
                      // pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationPerPage={perPage}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer {...props}/> */}
        <CustomModal
          key={key}
          show={modalDetail.show}
          // backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          ids={
            modalDetail.flag === "MyProductDetials"
              ? "myProductDetails"
              : modalDetail.flag === "successfullyAdded"
              ? "successful"
              : modalDetail.flag === "DBImportProductModal"
              ? "DBImportProductModal"
              : ""
          }
          size={
            modalDetail.flag === "MyProductDetials"
              ? "xl"
              : modalDetail.flag === "DBImportProductModal"
              ? "xl"
              : "md"
          }
          child={
            modalDetail.flag === "MyProductDetials" ? (
              <MyProductDetials
                close={() => handleOnCloseModal()}
                type="product"
                selectedProduct={selectedProduct}
              />
            ) : modalDetail.flag === "successfullyAdded" ? (
              <SuccessfulModal
                flag={"productsAdded"}
                details={(e) => handleModalDetails(e)}
              />
            ) : modalDetail.flag === "UploadproductModal" ? (
              <UploadproductModal close={() => handleOnCloseModal()} />
            ) : modalDetail.flag === "DBImportProductModal" ? (
              <DBImportProductModal
                close={() => handleOnCloseModal()}
                selectedProductData={selectedProductData}
                allAddressIds={allAddressIds}
                loading={loading}
                allAddress={allAddress}
                // flag={"productsAdded"}
                // details={(e) => handleModalDetails(e)}
              />
            ) : (
              ""
            )
          }
          header={
            <>
              <div className="modalHeader_">
                <div className="common_ justify-content-end">
                  {modalDetail.flag === "DBImportProductModal" ? (
                    <div className="modalHeader_">
                      <div className="common_">
                        <h3 className="modalHeading_">Add Product</h3>

                        <span
                          className="crossIcon"
                          onClick={() => handleOnCloseModal()}
                        >
                          <i className="las la-times" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  ) : (
                    // <button className="closeButton" type="button">
                    //   <i
                    //     className="las la-times"
                    //     onClick={() => handleOnCloseModal()}
                    //   ></i>
                    // </button>
                    ""
                  )}
                </div>
              </div>
            </>
          }
          // onCloseModal={() => handleOnCloseModal()}
        />
        {/* <Footer {...props}/> */}
      </div>
    </main>
  );
};

export default Product;
