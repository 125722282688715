export const HeaderStatisticsArr=[
    {
        heading:"Total Appointments",
        key:"total_booking",
        img:"productBoxImg3",
        className:"totalProductBox"

    },
    {
        heading:"Fulfilled",
        key:"fulfillment",
        img:"grossSales",
        className:"totalBrandBox "

    },
    {
        heading:"Rescheduled",
        key:"rescheduled",
        img:"productBoxImg1",
        className:"transectionsBox"

    },
    {
        heading:"Cancelled",
        key:"cancelled",
        img:"productBoxImg2",
        className:"totalCategoryBox"

    },
    
]
export   const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        align: "start",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          pointStyle: "circle",
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };