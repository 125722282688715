import React, { useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import ReactSelect from "react-select";
import { createCashAsync } from "./cashDrawSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

const AddCashDrawModal = ({ allAddress, close, getAllCash }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    cashDrawerName: "",
    sellerAddress: null,
  });

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.trimStart() });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, sellerAddress: selectedOption || null });
  };

  // Prepare options for ReactSelect
  const addressOptions = allAddress.map((address) => ({
    value: address.id, // Use `id` as the value
    label: `${address.format_address} (${address.city}, ${address.state})`, // Customize the display
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.cashDrawerName.trim()) {
      showToast("please enter cash drawer name");
      return;
    }
    if (!formData.sellerAddress) {
      showToast("please select Address");
      return;
    }
    setLoading(true);
    const data = {
      title: formData?.cashDrawerName.trim(),
      seller_address_id: formData.sellerAddress?.value,
    };
    let params = {
      postData: data,
      tokenData: auth?.payload?.token,
    };
    dispatch(createCashAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        close();
        getAllCash();
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  return (
    <>
      <form className="addcashDrawModal" onSubmit={handleSubmit}>
        <div className="innerDrawerModal">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Cash Drawer Name
                </label>
                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Name"
                    name="cashDrawerName"
                    type="text"
                    value={formData.cashDrawerName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <div className="reactSelectParent commonSelectParent w-100">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Address
                </label>
                <ReactSelect
                  value={formData.sellerAddress}
                  options={addressOptions}
                  onChange={handleSelectChange}
                  isSearchable={true}
                  placeholder="Select one"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100% !important",
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: "unset !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.selectImg}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <button
            className="modalDiscardBtn"
            type="button"
            onClick={() => {
              close();
            }}
          >
            Discard
          </button>
          <button
            className="modalNextBtn modalDiscardBtn active"
            type="submit"
            disabled={loading}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm spinnerLight"></span>
            )}{" "}
            Save
          </button>
        </div>
      </form>
    </>
  );
};

export default AddCashDrawModal;
