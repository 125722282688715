import React, { useState } from 'react'
import Loader from '../../../components/UI/Loader/Loader'
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { getUserSettingsApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';

const StaffManagement = () => {
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
   const [userSettings, setUserSettings] = useState(null);
    const [loading, setLoading] = useState(false);
    const getUserSettings=async()=>{
        let prams={
            postData: {
                app_name:"merchant",
                seller_id:"0abd886e-d6c4-4fe0-8be2-c928f41530ca",
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(getUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setUserSettings(res?.payload);
        setLoading(false);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    useEffect(()=>{
        getUserSettings();
    },[])
    const updateUserSettings=async(data)=>{
        let prams={
            postData: {
                ...data
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(updateUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        // setUserSettings(res.data);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    const handleUserSetting=(key,value)=>{
        setUserSettings(prev=>({
            ...prev,[key]:value
        }));
        updateUserSettings({[key]:value});
    }
  return <>
  {loading&&
                <Loader/>
               }
  <div className='generalSetting_'>
            <div className="processignOrder featureseting">
                <div className="ordrsettingHead_">
                    <h3 className="normalHeading_ mb-1">Staff Management </h3>
                    <p className="textInnerHead2">Use these settings to define plugin general settings and default settings for your services and appointments</p>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" onChange={(event)=>{
                                handleUserSetting("employee_selection",event.target.checked)
                                }} checked={userSettings?.employee_selection} />
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">Enable staff selection by customer</h3>
                                <p class="textInnerHead2">Allow customers to select their preferred staff member during booking</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" onChange={(event)=>{handleUserSetting("change_staff_selection_by_custmr",event.target.checked)}} checked={userSettings?.change_staff_selection_by_custmr}/>
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">Enable change staff by customer</h3>
                                <p class="textInnerHead2">Allow customers to change/edit their preferred staff member during edit booking</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox"  onChange={(event)=>{handleUserSetting("change_staff_selection_by_admin",event.target.checked)}} checked={userSettings?.change_staff_selection_by_admin}/>
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">Enable change staff by admin</h3>
                                <p class="textInnerHead2">Allow admin to change/edit their preferred staff member </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  </>
}

export default StaffManagement