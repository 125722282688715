import { toast } from 'react-toastify';

function formValidator(obj, activePos, activeBtob, activeBtoc,dimensions) {
    let hasEmptyValues = (arr) => {
        for (let i = 0; i < arr.length; i++) {
            const obj = arr[i];
            for (let key in obj) {
                if (obj.hasOwnProperty(key) && obj[key] === '') {
                    return true; // Empty value found
                }
            }
        }
        return false; // No empty values found
    }

    let filterB2B = obj?.prices?.filter((val) => (val?.price_type == "quantity_base"))
    let resultB2B = filterB2B?.length > 0 ? hasEmptyValues(filterB2B[0]?.bundle_price) : ""

    let priceResult = obj?.prices?.length > 0 ? hasEmptyValues(obj?.prices) : ""

    let optionListResponse = obj?.attributes?.length > 0 ? hasEmptyValues(obj?.attributes) : ""

    if (!obj?.image) {
        toast.error("Please select product images")
        return false
    }
    if (!obj?.name) {
        toast.error("Please enter product name")
        return false
    }
    if (!obj?.service_id) {
        toast.error("Please select service")
        return false
    }
    if (!obj?.category_id) {
        toast.error("Please select catgeory")
        return false
    }
    if (!obj?.sub_category_id) {
        toast.error("Please select sub-category")
        return false
    }
    if (!obj?.brand_id) {
        toast.error("Please select brand")
        return false
    }
    if (!obj?.description) {
        toast.error("Please enter product description")
        return false
    }
    if (!obj?.price) {
        toast.error("Please enter product price")
        return false
    }
    if (!obj?.type) {
        toast.error("Please enter product type")
        return false
    }
    if (!obj?.barcode) {
        toast.error("Please enter barcode")
        return false
    }
    if (!obj?.sku) {
        toast.error("Please enter sku")
        return false
    }
    if (obj?.delivery_options?.length <= 0) {
        toast.error("Please select delivery options")
        return false
    }
    if (!dimensions?.weight) {
        toast.error("Please enter product weight")
        return false
    }
    if (!dimensions?.length) {
        toast.error("Please enter product length")
        return false
    }
    if (!dimensions?.breadth) {
        toast.error("Please enter product bredth")
        return false
    }
    if (!dimensions?.height) {
        toast.error("Please enter product height")
        return false
    }
    if (!obj?.quantity) {
        toast.error("Please enter Inventory-Opening")
        return false
    }
    if (!obj?.quantity_reorder_point) {
        toast.error("Please enter Inventory-Reorder Point")
        return false
    }
    // if (obj?.ingredients?.length <= 0) {
    //     toast.error("Please select attributes")
    //     return false
    // }
    // if (!activePos && !activeBtob && !activeBtoc) {
    //     toast.error("Please select product availability")
    //     return false
    // }
    if (obj?.prices?.length == 0 || priceResult) {
        toast.error("Please add selling price and profit margin")
        return false
    }
    if (filterB2B?.length > 0 && resultB2B) {
        toast.error("Please add selling quantity and selling price")
        return false
    }
    if (obj?.attributes?.length > 0 && optionListResponse) {
        toast.error("Variant info could not be empty")
        return false
    }

    return true
}
export { formValidator };