import React from "react";
import * as Images from "../../../utilities/images";

const PauseOfferModal = (props) => {
  return (
    <div className="text-center">
      <img src={Images.pauseIcon} alt="cancleLogo" className="modalPauseImg" />
      <div className="stopOfferModalContainer">
        <h3 className="stopOfferTxt mb-0">Are you sure you want to Pause?</h3>
        <p className="salesIncTxt mb-0">Sales Increased by 19% in 7 days </p>
      </div>
      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
        >
          Cancel
        </button>
        <button className="activeProductBtn pauseOfferModalBtn w-100">
          <img src={Images.pauseIcon} alt="stopIcon" className="me-2" />
          Pause
        </button>
      </div>
    </div>
  );
};

export default PauseOfferModal;
