import React, { useEffect, useState } from "react";
import { selectLoginAuth } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getOrderDetailsAsync } from "../../dashboard/dashboardSlice";
import GoogleMapView from "../../../components/UI/Orders/GoogleMapView";
import { Card, Col, Row } from "react-bootstrap";
import { unwrapResult } from "@reduxjs/toolkit";
import OrderTrackingDetails from "./OrderTrackingDetails";
import OrderInvoiceDetails from "../orders/OrderInvoiceDetails";
import OrderList from "./OrderList";
import {
  OrderDown,
  cancleorder,
  incoiceuser_,
  modalCancel,
  pickuporder,
  profileImg1,
  returnlogo,
  serviceuser_,
  unselectedOrder,
  drivefast_,
  upArrow,
  locateOrder,
  clockBlue,
  moneyImg,
  mapimg,
  deliveryMap,
  warningicon,
  fillradio_,
  verifyorder,
  orderDriver,
  done,
  cancelledstep,
  readystep,
  acceptstep,
  openTag,
  closeTag,
  NotificationIcon,
  SearchIcon,
} from "../../../../utilities/images";
import OrderTrackStatusCard from "../orders/OrderTrackStatusCard";
import Loader from "../../../components/UI/Loader/Loader";
const OrderTracking = () => {
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState("activeData");

  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [orderIndex, setorderIndex] = useState(null);
  const [openOrderDetails, setopenOrderDetails] = useState(false);
  const [showOrderInvoice, setshowOrderInvoice] = useState(true);
  //get order details
  const getAllOrderDetails = async () => {
    setLoading(true);
    let params = {
      postData: {
        seller_id: "29d0e7bc-af42-4505-bcea-3e54817fcb2d",
        status: "1,2,3,4",
      },
      tokenData: auth?.payload?.token,
    };
    dispatch(getOrderDetailsAsync(params))
      .then(unwrapResult)
      .then((res) => {
        //convert payload according to our need
        setOrderDetails(res?.payload?.data);
        console.log(res?.payload?.data);
        if (res?.payload?.data) {
          setorderIndex(0);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllOrderDetails();
  }, []);
  const handleToggleData = (status) => {
    setTableData(status);
  };

  return (
    <>
      <div className="d-flex position-relative">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="orderTrackSection position-absolute w-100 h-100">
              <div className="container-fluid">
                <Row>
                  <Col xs={!showOrderInvoice ? 6 : 4}>
                    {" "}
                    {!openOrderDetails || !showOrderInvoice ? (
                      <div className="orderTrackLeft">
                        <div className="orderTrackHead">
                          <div className="d-flex align-items-center justify-content-between">
                            <h3 className="fontSize24 fw500 txtDarkblue">
                              Order Tracking{" "}
                              <span className="fontSize16 fw400 allproductCount">
                                (10)
                              </span>
                            </h3>
                            <div className="d-flex align-items-center gap-3">
                              <img src={NotificationIcon} alt="img" />
                              <img src={SearchIcon} alt="img" />
                            </div>
                          </div>

                          <div className="orderTrackHeaderToggle d-flex align-items-center gap-2">
                            <button
                              className={`activeProductBtn ${
                                tableData == "activeData" ? "active" : ""
                              }`}
                              onClick={() => handleToggleData("activeData")}
                            >
                              Active{" "}
                              <span className="activeProductofferItem">
                                (3)
                              </span>
                            </button>
                            <button
                              className={`activeProductBtn ${
                                tableData == "completeData" ? "active" : ""
                              }`}
                              onClick={() => handleToggleData("completeData")}
                            >
                              Returning
                              <span className="activeProductofferItem ps-1">
                                (3)
                              </span>
                            </button>
                          </div>
                        </div>
                        <OrderList
                          showOrderInvoice={showOrderInvoice}
                          orderDetails={orderDetails}
                          setorderIndex={(value) => setorderIndex(value)}
                          setshowOrderInvoice={(value) =>
                            setshowOrderInvoice(value)
                          }
                          orderIndex={orderIndex}
                          setopenOrderDetails={(value) =>
                            setopenOrderDetails(value)
                          }
                        />
                      </div>
                    ) : (
                      orderDetails && (
                        <OrderInvoiceDetails
                          orderDetails={orderDetails?.[orderIndex]}
                          setopenOrderDetails={(value) =>
                            setopenOrderDetails(value)
                          }
                        />
                      )
                    )}
                  </Col>
                  <Col xs={!showOrderInvoice ? 6 : 8}>
                    {orderIndex !== null && (
                      <div
                        className={`${
                          !showOrderInvoice
                            ? "bg-white p-2"
                            : "w-100 d-flex justify-content-end align-items-end h-100"
                        }`}
                        style={{ height: "100%" }}
                      >
                        {!showOrderInvoice ? (
                          <OrderTrackingDetails
                            orderDetails={orderDetails?.[orderIndex]}
                            setshowOrderInvoice={(value) => {
                              setshowOrderInvoice(value);
                            }}
                          />
                        ) : (
                          <>
                            {orderIndex !== null && (
                              <GoogleMapView
                                data={orderDetails[orderIndex]?.order_delivery}
                              />
                            )}
                            <OrderTrackStatusCard
                              orderData={orderDetails?.[orderIndex]}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrderTracking;
