import React, { useEffect, useState } from "react";
import * as Images from "../../../../utilities/images";
import { Link, useHistory } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";

import { toast } from "react-toastify";
import CustomModal from "../../../components/shared/CustomModal";
import CropImageModal from "../../product/CropImageModal";
const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const UploadFileModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const [imageUrl, setImageUrl] = useState(props?.images);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleChange = (file) => {
    props?.uploadImageHandle(file);
  };

  function checkImageValidity(imageUrl) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = function () {
        const isSquare = this.width === this.height;
        resolve(isSquare);
      };

      img.onerror = function () {
        resolve(false);
      };
      img.src = imageUrl;
    });
  }

  const deleteImage = (item, idx) => {
    const filterImage = imageUrl?.filter((image) => image.filePath != item);
    props?.setImageUrl(filterImage);
    let filteredBlob = props?.fileBlob?.filter((value, index) => index !== idx);
    props?.setFileBlob(filteredBlob);
  };

  const sendPhoto = (images) => {
    const containsInvalid = imageUrl.some((image) => image.valid === false);
    if (containsInvalid) {
      toast.error("Please upload square image only");
    } else {
      props?.setMainImage(images[0]?.filePath);
      let imageData = {
        image: images[0]?.filePath,
        images: images?.map((val) => val.filePath),
      };
      props.imageData(imageData);
      props?.setDifferentAngelsImage("");
      props?.setCloseUpImage("");
      props?.setInUseImage("");
      props?.setStyleImage("");
      props.close();
    }
  };
  const cropImageHandle = (e) => {
    e.preventDefault();
    setModalDetail({ show: true, flag: "cropImage" });
    setKey(Math.random());
  };

  useEffect(() => {
    setLoading(true);

    async function getImageValidityArray() {
      const updatedImageArray = await Promise.all(
        props?.images.map(async (imageObj) => {
          const isValid = await checkImageValidity(imageObj.filePath);
          return { ...imageObj, valid: isValid };
        })
      );
      return updatedImageArray;
    }

    // Call the async function to get the promise array response
    getImageValidityArray()
      .then((result) => {
        setImageUrl(result);
        setLoading(false); // This will log the array response with image validity
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Log any errors encountered during the process
      });
  }, [props?.images]);

  return (
    <>
      <div className="browseUploadSection modalBodyBg p-0">
        <form className="emplyoeeDetails horizontalForms">
          <div className="row">
            <div className="form-group col-md-12 text-start">
              <div className="mainCatalog p-0">
                <div id="dropzone">
                  <FileUploader
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    children={
                      <div>
                        {/* <input type="file" id="file" accept="image/*" multiple={true} className="form-control d-none" onChange={(e) => { handleChange(e) }} /> */}
                        <label htmlFor="file">
                          <span className="file-button uploadImages_">
                            <img
                              src={Images.gallerySendImg}
                              className="img-fluid editProfileImg"
                              alt="user img"
                            />
                            <div className="dropText ms-2">
                              <span className="dragAndDrop_Txt">
                                Click to browse or <br /> drag and drop your
                                files
                              </span>
                            </div>
                          </span>
                        </label>
                      </div>
                    }
                  />
                </div>

                <p className="fontSize18 fw500 txtDarkblue text-center mt-3">
                  Drag file or upload image listing file (file format: .jpg or
                  .png )
                </p>

                {props?.loading || loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm spinnerDark"></span>
                  </>
                ) : (
                  <>
                    {imageUrl && imageUrl?.length > 0 ? (
                      <div className="row">
                        {imageUrl?.map((item, index) => {
                          return (
                            <div className="col-lg-3 col-12 mt-3" key={index}>
                              <div className="galleryView">
                                <img
                                  className="image-gallery"
                                  src={item.filePath}
                                  alt=""
                                />
                              </div>
                              <div className="gallery-icons">
                                <img
                                  src={Images.deleteIcon}
                                  onClick={(e) =>
                                    deleteImage(item.filePath, index)
                                  }
                                  alt=""
                                />
                                {item?.valid ? (
                                  <>
                                    <img
                                      src={Images.checksolid}
                                      className="validImg"
                                      alt="user img"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <>
                                      <img
                                        src={Images.cropsolid}
                                        className="validImg"
                                        alt="user img"
                                        onClick={(e) => {
                                          cropImageHandle(e);
                                          setSelectedImg(item);
                                          props?.indexVal(index);
                                        }}
                                      />
                                    </>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                        <div className="row">
                          <div className="col-md-12">
                            <button
                              type="button"
                              className="modalNextBtn modalDiscardBtn active w-100 ms-0"
                              onClick={() => sendPhoto(imageUrl)}
                            >
                              Send
                              <img className="sendFill" src={Images.sendIcon} alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          
        </form>
        <button className="commonBlueBtn active m-auto">Upload <img src={Images.closeTag} alt="img"/></button>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "cropImage" ? "CropImage" : ""}
        size={modalDetail.flag === "cropImage" ? "md" : "md"}
        child={
          modalDetail.flag === "cropImage" ? (
            <CropImageModal
              close={() => handleOnCloseModal()}
              handleData={(file) => handleChange(file)}
              selectedImg={selectedImg}
              fileBlob={props.fileBlob}
              indexId={props?.indexId}
              setCroppedImage={props.setCroppedImage}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "cropImage" ? (
            <>
              <h4 className="modalHeading_">Crop Image</h4>
              <p className="modal_cancel">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={handleOnCloseModal}
                  src={Images.modalCancel}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default UploadFileModal;
