import React, { useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import * as Images from "../../../utilities/images";
import ReactSelect from "react-select";

const ServiceSetting = () => {
  const [toggleState, setToggleState] = useState({
    toggle1: false,
    toggle2: false,
    toggle3: false,
    toggle4: false,
    toggle5: false,
    toggle6: false,
    toggle7: false,
    toggle8: false,
  });

  const handleToggleState = (name) => {
    setToggleState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const resultSelectOption = [
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "150", label: "150" },
    { value: "200", label: "200" },
  ];

  return (
    <>
      <DashboardNavbar title="Service Settings" backShow={false} />
      <div className="serviceSettingWrapper">
        <h3 className="fontSize18 txtBlue">General</h3>
        <p className="homeHeader_Subheading mb-0">
          Use these settings to define plugin general settings and default
          settings for your services and appointments
        </p>
        <div className="commonPadding">
          <div className="row">
            <div className="col-md-6">
              <div className="reactSelectParent commonSelectParent w-100">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Default time slot step
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <ReactSelect
                  options={resultSelectOption}
                  placeholder="30"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100% !important",
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: "unset !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.selectImg}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="reactSelectParent commonSelectParent w-100">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Minimum time required before booking
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <ReactSelect
                  options={resultSelectOption}
                  placeholder="Disable"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100% !important",
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: "unset !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.selectImg}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="reactSelectParent commonSelectParent w-100 mt-4">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Daily appointment limit
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <ReactSelect
                  options={resultSelectOption}
                  placeholder="10"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100% !important",
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: "unset !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.selectImg}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="reactSelectParent commonSelectParent w-100 mt-4">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Minimum time required before cancelling
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <ReactSelect
                  options={resultSelectOption}
                  placeholder="Disable"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100% !important",
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: "unset !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.selectImg}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="reactSelectParent commonSelectParent w-100 mt-4">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Period available for booking in advance{" "}
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <ReactSelect
                  options={resultSelectOption}
                  placeholder="7 days"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100% !important",
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: "unset !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.selectImg}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="reactSelectParent commonSelectParent w-100 mt-4">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Minimum time required before rescheduling
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <ReactSelect
                  options={resultSelectOption}
                  placeholder="Disable"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100% !important",
                      width: "100%",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: "unset !important",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#263682",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.selectImg}
                        width={24}
                        height={24}
                        alt="drop_icon"
                        className="mt-1"
                      />
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              className={`serviceSettingBoxes d-flex align-items-start  gap-3 ${
                toggleState.toggle1 ? "active" : ""
              }`}
            >
              <input
                type="checkbox"
                checked={toggleState.toggle1}
                onChange={() => {
                  handleToggleState("toggle1");
                }}
              />
              <div>
                <h3 className="fontSize14 txtDarkblue">
                  Use service duration for booking a time slot
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  When a customer books a service, the system will automatically
                  block off the right amount of time on your calendar.
                </h3>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div
              className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-4 ${
                toggleState.toggle2 ? "active" : ""
              }`}
            >
              <input
                type="checkbox"
                checked={toggleState.toggle2}
                onChange={() => {
                  handleToggleState("toggle2");
                }}
              />
              <div>
                <h3 className="fontSize14 txtDarkblue">
                  Include service buffer time in time slots
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Turn this ON for extra time between appointments.
                </h3>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div
              className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-4 ${
                toggleState.toggle3 ? "active" : ""
              }`}
            >
              <input
                type="checkbox"
                checked={toggleState.toggle3}
                onChange={() => {
                  handleToggleState("toggle3");
                }}
              />
              <div>
                <h3 className="fontSize14 txtDarkblue">
                  Allow booking above maximum capacity
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Turn this ON only if you're okay with potentially being
                  overbooked. Be careful with this one!
                </h3>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div
              className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-4 ${
                toggleState.toggle4 ? "active" : ""
              }`}
            >
              <input
                type="checkbox"
                checked={toggleState.toggle4}
                onChange={() => {
                  handleToggleState("toggle4");
                }}
              />
              <div>
                <h3 className="fontSize14 txtDarkblue">
                  Allow booking below minimum capacity
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Turn this ON if you're willing to run services even if you
                  don't have a full group.
                </h3>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div
              className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-4 ${
                toggleState.toggle5 ? "active" : ""
              }`}
            >
              <input
                type="checkbox"
                checked={toggleState.toggle5}
                onChange={() => {
                  handleToggleState("toggle5");
                }}
              />
              <div>
                <h3 className="fontSize14 txtDarkblue">
                  Allow booking below minimum capacity
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Turn this ON if you're willing to run services even if you
                  don't have a full group.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="serviceSettingWrapper mt-4">
        <h3 className="fontSize18 txtBlue">Advance</h3>
        <p className="homeHeader_Subheading mb-0">
          Use these settings to define plugin general settings and default
          settings for your services and appointments
        </p>
        <div className="commonPadding pb-0">
          <div className="row">
            <div className="col-12">
              <div
                className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-3 ${
                  toggleState.toggle6 ? "active" : ""
                }`}
              >
                <input
                  type="checkbox"
                  checked={toggleState.toggle6}
                  onChange={() => {
                    handleToggleState("toggle6");
                  }}
                />
                <div>
                  <h3 className="fontSize14 txtDarkblue">
                    Clients may reschedule or cancel their own appointments
                    before the cut-off time
                  </h3>
                  <h3 className="fontSize12 txtLightBlue mb-0">
                    Allow clients to reschedule or cancel on your online booking
                    site or with Square Assistant after an appointment is
                    accepted. Clients cannot cancel appointments that are
                    prepaid or charged a deposit.
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div
                className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-3 ${
                  toggleState.toggle7 ? "active" : ""
                }`}
              >
                <input
                  type="checkbox"
                  checked={toggleState.toggle7}
                  onChange={() => {
                    handleToggleState("toggle7");
                  }}
                />
                <div>
                  <h3 className="fontSize14 txtDarkblue">
                    Enable waitlist on your online booking site
                  </h3>
                  <h3 className="fontSize12 txtLightBlue mb-0">
                    Turn this ON for extra time between appointments.
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div
                className={`serviceSettingBoxes d-flex align-items-start  gap-3 mt-3 ${
                  toggleState.toggle8 ? "active" : ""
                }`}
              >
                <input
                  type="checkbox"
                  checked={toggleState.toggle8}
                  onChange={() => {
                    handleToggleState("toggle8");
                  }}
                />
                <div>
                  <h3 className="fontSize14 txtDarkblue">
                    Remove staff from the online booking site
                  </h3>
                  <h3 className="fontSize12 txtLightBlue mb-0">
                    Customers booking with you online will no longer see any
                    mention of ‘staff‘. Services will still be assigned to
                    ‘Staff‘ automatically so you as a merchant still see them,
                    but the customer does not. This is recommended if allowing
                    customers to see a staff member on an appointment is not
                    desired.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceSetting;
