import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";

const CreateServiceOfferDetails = () => {
  const [startDate, setStartDate] = useState(new Date());
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <img src={Images.calenderLogo} alt="logo" className="me-2" />
        {value}
      </button>
    )
  );

  const selectOption = [
    { value: "all", label: "All" },
    { value: "pos", label: "Location 1" },
    { value: "b2c", label: "Location 2" },
    { value: "b2b", label: "Location 3" },
  ];
  return (
    <section className="createOfferPublish_Section">
      <h2 className="fontSize24 fw500 txtBlue mb-3">Offer Details</h2>
      <div className="input-group search_group searchOfferInput">
        <img src={Images.SearchIcon} alt="" className="img-fluid searchIcon" />
        <input
          type="text"
          className="form-control"
          placeholder="Search Product here by Barcode, SKU, Name..."
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
        />
      </div>
      <div className="opacityBox pb-4">
        <div className="offerPeriodBox mt-4">
          <h5 className="fontSize18 fw500 txtDarkblue mb-0">Period</h5>

          <div className="row">
            <div className="col-md-6">
              <div className="datePicker_Parent pt-0 mt-4">
                <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
                  Start from
                </label>
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                />
                <p className="pressEnterTxt pt-2 ps-4 mb-0">
                  Starts at 00:00 AM
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="datePicker_Parent pt-0 mt-4">
                <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
                  Start from
                </label>
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  customInput={
                    <ExampleCustomInput className="example-custom-input" />
                  }
                />
                <p className="pressEnterTxt pt-2 ps-4 mb-0">Ends at 11:59 PM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-3">
          <h5 className="fontSize18 fw500 txtDarkblue mb-0">Offer Pricing</h5>
          <div className="editVariant_SecondBox mt-4">
            <div className="upcBoxParent">
              <div className="row">
                <div className="col-4">
                  <div className="upcBox">
                    <h3 className="fontSize18 mb-0">Offer price option</h3>
                  </div>
                </div>
                <div className="col-8">
                  <div className="upcBox upcRightBox">
                    <div className="reactSelectParent offerModalSelect">
                      <ReactSelect
                        options={selectOption}
                        // value={channelSelected}
                        placeholder="Select one"
                        classNamePrefix="react-select"
                        className="react-select-container anlyticSelect"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            border: "1px solid #D7DEFF",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#263682" // Background color for selected option
                              : state.isFocused
                              ? "#f5f6fc" // Background color for hovered option
                              : "#fff", // Default background color
                            // color: state.isSelected || state.isFocused ? '#fff' : '#000', // Text color
                            color: state.isSelected
                              ? "#fff" // Background color for selected option
                              : "#263682", // Text color
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#636E9F",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0px",
                          }),
                        }}
                        // onChange={onChannelChange}
                        components={{
                          DropdownIndicator: () => (
                            <img src={Images.blueDownArrow} alt="drop_icon" />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="upcBoxParent">
              <div className="row">
                <div className="col-4">
                  <div className="upcBox">
                    <h3 className="fontSize18 mb-0">Offer Type</h3>
                  </div>
                </div>
                <div className="col-8">
                  <div className="upcBox upcRightBox">
                    <div className="reactSelectParent offerModalSelect">
                      <ReactSelect
                        options={selectOption}
                        placeholder="Select one"
                        classNamePrefix="react-select"
                        className="react-select-container anlyticSelect"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            border: "1px solid #D7DEFF",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#263682"
                              : state.isFocused
                              ? "#f5f6fc"
                              : "#fff",
                            color: state.isSelected ? "#fff" : "#263682",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#636e9f",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "0px",
                          }),
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <img src={Images.blueDownArrow} alt="drop_icon" />
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="upcBoxParent">
              <div className="row">
                <div className="col-4">
                  <div className="upcBox">
                    <h3 className="fontSize18 mb-0">Offer Price</h3>
                  </div>
                </div>
                <div className="col-8">
                  <div className="upcBox upcRightBox inputUpcBox">
                    <div className="inputGroup">
                      <input
                        className="productVariant_Input"
                        name="upc"
                        type="number"
                        maxLength={14}
                      />
                      <span className="inputDollarSign">$</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateServiceOfferDetails;
