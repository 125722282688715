import React from "react";
import { lossArrow, shareMap } from "../../../../utilities/images";

const HeaderStatistics = (props) => {
  return (
    <div className="col-sm-6 col-md-3 col-lg-3" onClick={props?.push}>
      <div className= {`percentageContainer ${props?.className}`}>
        {/* <div
          className={`percentageRating ${
            props?.positive ? "" : "percentageBg"
          }`}
        >
          <img
            src={props?.positive ? shareMap : lossArrow}
            className="sharMapImg"
            alt="ShareMap"
          />
          <p
            className={
              props?.positive ? "percentageRate" : "minusPercentageRate"
            }
          >
            {Number(props?.percentage)?.toFixed(2)}%
          </p>
        </div> */}
        <img src={props?.img} alt="img" />
        <div className="percentageValues homeBoxesValues">
          <p className="textInner">{props?.heading}</p>
          <h3 className="heading_ ">{props?.value}</h3>
        </div>
      </div>
    </div>
  );
};

export default HeaderStatistics;
