import React, { useEffect, useState } from "react";
import HomePageInnerHeader from "../dashboard/HomePageInnerHeader";
import * as Images from "../../../utilities/images";
import HeaderStatistics from "../dashboard/wallet/HeaderStatistics";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import { getServiceCountDataApiAsync } from "../myProducts/productSlice";
import { selectLoginAuth } from "../auth/authSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { unwrapResult } from "@reduxjs/toolkit";

const MyService = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [countData, setCountData] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);

  const serviceCountDataHandle = () => {
    let countParams = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // "filter": "year",
        service_type: "service",
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getServiceCountDataApiAsync(countParams))
      .then(unwrapResult)
      .then((obj) => {
        console.log(obj.payload, "payloaddd");
        setCountData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    serviceCountDataHandle();
  }, []);

  return (
    <>
      <DashboardNavbar title="Services Overview" backShow={false} />
      <div className="myProductPage homePage">
        <HomePageInnerHeader subhead="vs. Same day last week" />
        <div className="container-fluid p-0">
          {loading ? (
            <span className="spinner-border inner-spin spinner-border-sm"></span>
          ) : (
            <div className="row mt-3">
              {countData?.result?.length > 0
                ? countData?.result
                    .filter((item) => item?.title !== "Brand")
                    .map((items, index) => {
                      return (
                        <>
                          <HeaderStatistics
                            className={
                              items?.title == "Product"
                                ? "totalProductBox"
                                : items?.title == "Category"
                                ? "totalCategoryBox"
                                : items?.title == "Sub Category"
                                ? "totalSubCategoryBox"
                                : ""
                            }
                            img={
                              items?.title == "Product"
                                ? Images.productBoxImg1
                                : items?.title == "Category"
                                ? Images.productBoxImg2
                                : items?.title == "Sub Category"
                                ? Images.productBoxImg3
                                : ""
                            }
                            value={items.count}
                            heading={`Total ${
                              items.title == "Product" ? "Service" : items.title
                            }`}
                          />
                        </>
                      );
                    })
                : ""}

              <HeaderStatistics
                className="totalBrandBox"
                img={Images.grossSales}
                value="88 h"
                heading="Total Service Hours"
              />
            </div>
          )}

          <div className="row mt-3">
            <div className="col-md-6">
              <div className="sellingProductContainer">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="fontSize20 txtBlue fw500 mb-0">
                    Top Selling Services
                  </h2>
                  <button className="viewAllBtn">View All</button>
                </div>

                <div className="commonTable sellingProductTable table-responsive mt-3">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sellingProductContainer">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="fontSize20 txtBlue fw500 mb-0">
                    Least Selling Services
                  </h2>
                  <button className="viewAllBtn">View All</button>
                </div>

                <div className="commonTable sellingProductTable table-responsive mt-3">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="sellingProductContainer p-0 offerProductContainer">
                <div className="d-flex align-items-center justify-content-between offerProduct_Head">
                  <div className="d-flex align-items-center gap-2">
                    <img src={Images.discountLogo} alt="logo" />
                    <h2 className="fontSize20 txtBlue fw500 mb-0">
                      Offer On-going
                    </h2>
                  </div>
                  <button className="viewAllBtn">View All</button>
                </div>

                <div className="commonTable sellingProductTable table-responsive">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-start gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="topSellProductHead discountPrice fw500 mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                              <div className="d-flex align-items-center gap-3 pt-2">
                                <h6 className="marketPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                                <h6 className="discountPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-start gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead discountPrice fw500 mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                              <div className="d-flex align-items-center gap-3 pt-2">
                                <h6 className="marketPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                                <h6 className="discountPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-start gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead discountPrice fw500 mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                              <div className="d-flex align-items-center gap-3 pt-2">
                                <h6 className="marketPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                                <h6 className="discountPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-start gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead discountPrice fw500 mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                              <div className="d-flex align-items-center gap-3 pt-2">
                                <h6 className="marketPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                                <h6 className="discountPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sellingProductContainer">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="fontSize20 txtBlue fw500 mb-0">Top Staffs</h2>
                  <button className="viewAllBtn">View All</button>
                </div>

                <div className="commonTable sellingProductTable table-responsive mt-3">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.teamMember2}
                              alt="img"
                              className="staffImg"
                            />
                            <div>
                              <h3 className="fontSize16 txtDarkblue fw500 mb-0 pb-1">
                                Alessa Penbroke
                              </h3>
                              <h6 className="staffPositionTxt fontSize14 fw500 mb-0">
                                Senior POS Staff
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack pb-1">
                            30.50
                          </h5>
                          <h6 className="fontSize10 fw500 txtLightBlue mb-0">
                            Hours
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.teamMember2}
                              alt="img"
                              className="staffImg"
                            />
                            <div>
                              <h3 className="fontSize16 txtDarkblue fw500 mb-0 pb-1">
                                Alessa Penbroke
                              </h3>
                              <h6 className="staffPositionTxt fontSize14 fw500 mb-0">
                                Senior POS Staff
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack pb-1">
                            30.50
                          </h5>
                          <h6 className="fontSize10 fw500 txtLightBlue mb-0">
                            Hours
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.teamMember2}
                              alt="img"
                              className="staffImg"
                            />
                            <div>
                              <h3 className="fontSize16 txtDarkblue fw500 mb-0 pb-1">
                                Alessa Penbroke
                              </h3>
                              <h6 className="staffPositionTxt fontSize14 fw500 mb-0">
                                Senior POS Staff
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack pb-1">
                            30.50
                          </h5>
                          <h6 className="fontSize10 fw500 txtLightBlue mb-0">
                            Hours
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.teamMember2}
                              alt="img"
                              className="staffImg"
                            />
                            <div>
                              <h3 className="fontSize16 txtDarkblue fw500 mb-0 pb-1">
                                Alessa Penbroke
                              </h3>
                              <h6 className="staffPositionTxt fontSize14 fw500 mb-0">
                                Senior POS Staff
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack pb-1">
                            30.50
                          </h5>
                          <h6 className="fontSize10 fw500 txtLightBlue mb-0">
                            Hours
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.teamMember2}
                              alt="img"
                              className="staffImg"
                            />
                            <div>
                              <h3 className="fontSize16 txtDarkblue fw500 mb-0 pb-1">
                                Alessa Penbroke
                              </h3>
                              <h6 className="staffPositionTxt fontSize14 fw500 mb-0">
                                Senior POS Staff
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack pb-1">
                            30.50
                          </h5>
                          <h6 className="fontSize10 fw500 txtLightBlue mb-0">
                            Hours
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyService;
