import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectLoginAuth } from '../../auth/authSlice';
import { getAdditionalSettingApiAsync, updateAdditionalSettingApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';

const AdditionalSetting = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(selectLoginAuth);
    const [additionalSetting, setadditionalSetting] = useState({
        order_numbering:{
            automatic:false,
            custom_format:true,
        },
        tipping:{
            tip_options:true,
        },
        collect_buyer:{
            delivery:true,
            pickup:true,
            shipping:false
        }
    })
    const resultSelectOption = [
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "150", label: "150" },
        { value: "200", label: "200" },
    ];
    const [loading, setLoading] = useState(false)
   const handleAdditionalSetting=(parent,key,value)=>{
    setadditionalSetting({...additionalSetting,[parent]:{...additionalSetting[parent],[key]:value}});
   }    

    const getOrderProcessingData=async()=>{
        let prams={
            postData: {
                // seller_id: auth?.payload?.uniqe_id
                //     ? auth?.payload?.uniqe_id
                //     : auth?.payload?.user?.unique_uuid,
                seller_id:"2db22768-195d-4b64-b0bf-060d980bba18",
                filter:"filterValue"
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(getAdditionalSettingApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        setadditionalSetting(res.data);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    const updateOrderProcessingData=async()=>{
        let prams={
            postData: {
                // seller_id: auth?.payload?.uniqe_id
                //     ? auth?.payload?.uniqe_id
                //     : auth?.payload?.user?.unique_uuid,
                seller_id:"2db22768-195d-4b64-b0bf-060d980bba18",
                filter:"filterValue"
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(updateAdditionalSettingApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        setadditionalSetting(res.data);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    useEffect(()=>{
        console.log(additionalSetting,"check here")
    },[additionalSetting])
    return (
        <div className='additionalSetting_ customerNotification_'>
            <div className='processignOrder p-0'>
                <div className='ordrsettingHead_'>
                    <h3 className='normalHeading_ mb-1'>Additional Settings</h3>
                    <p className='textInnerHead2'>Use these settings to define plugin general settings and default settings for your services and appointments</p>
                </div>

                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Order Numbering</h3>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox"
                                checked={
                                    additionalSetting?.order_numbering?.automatic
                                }
                                onChange={(event)=>{
                                    handleAdditionalSetting('order_numbering','automatic',event.target.checked);
                                }}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue">
                                        Automatic (default)
                                    </h3>
                                    <p className='textInnerHead2'>Set up carrier accounts to purchase shipping labels through JOBR</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox" 
                                checked={
                                    additionalSetting?.order_numbering?.custom_format
                                }
                                onChange={(event)=>{
                                    handleAdditionalSetting('order_numbering','custom_format',event.target.checked);
                                }}
                                
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue">
                                        Custom format
                                    </h3>
                                    <p className='textInnerHead2'>This option gives you the flexibility to create a unique format for your order numbers using specific characters and patterns.
                                        For example, ORD-2309-0001</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Tipping</h3>
                        <p className='textInnerHead2'>Configure tipping for pickup, delivery, and QR code orders</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox"
                                checked={
                                    additionalSetting?.tipping?.tip_options
                                }
                                onChange={(event)=>{
                                    handleAdditionalSetting('tipping','tip_options',event.target.checked);
                                }}
                                 />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue">
                                        Tip options
                                    </h3>
                                    <p className='textInnerHead2'>This option controls whether or not tips are displayed within your POS checkout & Marketplace checkout.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Collect buyer address for</h3>
                        <p className='textInnerHead2'>Recipient address is automatically collected where necessary for tax, and for all shipping and delivery orders.</p>
                    </div>
                    <div className='deliveryCheckbox'>
                        <div className="row mt-2">
                            {
                                Object.entries(additionalSetting?.collect_buyer)?.map((option,index)=>(
                                    <div className="col-12">
                                    <div className="graphChecked_ mt-3 mb-1 ">
                                        <div className="graphBox_ me-3">
                                            <input
                                                type="checkbox"
                                                id="custom-checkbox-selectAll2"
                                                className="checkBox "
                                                name="custom-checkbox-selectAll"
                                                checked={option[1]}
                                                onChange={(event)=>{
                                                    handleAdditionalSetting('collect_buyer',option[0],event.target.checked);
                                                }}
                                               
    
                                            />
                                            <label
                                                htmlFor="custom-checkbox-selectAll2"
                                                className="checkBoxTxt masterChecked text-capitalize"
                                            >
                                                {option[0]}
                                            </label>
                                        </div>
                                    </div>
                                </div> 
                                ))
                            }
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdditionalSetting