export const options1 = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            align: "start",
            labels: {
                boxWidth: 10,
                boxHeight: 10,
                pointStyle: "circle",
                usePointStyle: true,
            },
        },
    },
    scales: {
        y: {
            beginAtZero: true,
        },
    },
};
export const HeaderStatisticsArr=[
    {
        heading:"Total Products",
        key:"totalCount",
        img:"productBoxImg1",
        className:"totalProductBox"

    },
    {
        heading:"Total Completed",
        key:"completedCount",
        img:"grossSales",
        className:"totalBrandBox "

    },
    {
        heading:"Total Pending",
        key:"pendingCount",
        img:"transactionsLogo",
        className:"transectionsBox"

    },
    {
        heading:"Total Cancel",
        key:"cancelCount",
        img:"productBoxImg2",
        className:"totalCategoryBox"

    },
    
]