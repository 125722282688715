import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import { selectLoginAuth } from "../auth/authSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import {
  getBrandOfCategoryAsync,
  getCategoriesAsync,
} from "../dashboard/dashboardSlice";
import DeleteModal from "../myProducts/DeleteModal";
import DeleteProductModal from "../myProducts/DeleteProductModal";
import EditServiceLocationModal from "./EditServiceLocationModal";
import EditServiceSalePriceModal from "./EditServiceSalePriceModal";

const ServiceList = (props) => {
  const { seller_id } = { ...props };
  const [searchValue, setSearchValue] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [newMessagekey, setNewMessagekey] = useState(Math.random());
  const [subCategoryIds, setSubCategoryIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchBrandValue, setSearchBrandValue] = useState();
  const [brand, setBrand] = useState([]);
  const [dropdownState, setDropdownState] = useState({
    isOpen: false,
    isBrandOpen: false,
    isSubCategory: false,
  });
  const [selectedValue, setSelectedValue] = useState(false);
  const [brandIds, setBrandIds] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const containerRef = useRef(null);
  const brandRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());
  };

  // End Modal

  const resultSelectOption = [
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "150", label: "150" },
    { value: "200", label: "200" },
  ];

  const handleSubcategoryChange = (subCategoryId) => {
    let filterData = [...subCategoryIds];
    if (filterData?.includes(subCategoryId)) {
      filterData = filterData?.filter((val) => val != subCategoryId);
      setSubCategoryIds(filterData);
    } else {
      filterData = [...subCategoryIds, subCategoryId];
      setSubCategoryIds(filterData);
    }
  };

  const fetchAllCategories = (searchKeyword) => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        main_category: true,
        with_subcategories: true,
        service_type: "product",
        seller_id: seller_id
          ? seller_id
          : auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
      },
    };
    if (searchKeyword) {
      data = {
        tokenData: auth?.payload?.token,
        postData: {
          ...data.postData,
          search: searchKeyword,
          check_product_existance: false,
        },
      };
    }
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setCategoryData(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const fetchAllBrand = (searchKeyword = null) => {
    let brandData;
    if (searchKeyword && !subCategoryIds?.length > 0) {
      brandData = {
        tokenData: auth?.payload?.token,
        postData: {
          search: searchKeyword,
          check_product_existance: false,
        },
      };
    } else if (subCategoryIds?.length > 0 && searchKeyword) {
      brandData = {
        tokenData: auth?.payload?.token,
        postData: {
          search: searchKeyword,
          category_id: subCategoryIds.toString(),
          check_product_existance: false,
        },
      };
    } else if (subCategoryIds?.length > 0 && !searchKeyword) {
      brandData = {
        tokenData: auth?.payload?.token,
        postData: {
          category_id: subCategoryIds.toString(),
          check_product_existance: false,
        },
      };
    } else {
      brandData = {
        tokenData: auth?.payload?.token,
        check_product_existance: false,
      };
    }
    dispatch(getBrandOfCategoryAsync(brandData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        // let filterResult = obj?.payload?.data?.map((item) => {
        //     return {
        //         value: item?.id,
        //         label: item?.name
        //     };
        // });
        setBrand(obj?.payload?.data);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllCategories();
    fetchAllBrand();
  }, []);

  const handleToggle = (type) => {
    setTimeout(() => {
      setNewMessagekey(Math.random());
    }, 100);

    setDropdownState((prevState) => {
      const newstate = { ...prevState };
      if (type == "isOpen") {
        newstate.isOpen = !prevState.isOpen;
        newstate.isBrandOpen = false;
        newstate.isSubCategory = false;
      } else if (type == "isBrandOpen") {
        newstate.isOpen = false;
        newstate.isBrandOpen = !prevState.isBrandOpen;
        newstate.isSubCategory = false;
      } else if (type == "isSubCategory") {
        newstate.isOpen = false;
        newstate.isBrandOpen = false;
        newstate.isSubCategory = !prevState.isSubCategory;
      }
      return newstate;
    });
  };

  return (
    <>
      <DashboardNavbar title="Services" backShow={false} />
      <div className="walletStats h-100">
        <div className="productOfferHead_Container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="row">
                <div className="col-6 ps-0">
                  <div className="productMainCategory">
                    <div className="productMainbox_">
                      <button
                        className="filterTab_"
                        onClick={() => handleToggle("isOpen")}
                      >
                        Category
                        <span>
                          <i
                            className="fal fa-chevron-down dropDownArrow"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </button>
                      {dropdownState.isOpen ? (
                        <div className="filterDropdown_" ref={containerRef}>
                          <div className="d-flex align-items-center justify-content-between pt-2 pb-4">
                            <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                              Category: 5
                            </h3>
                            <h3 className="fontSize16 fw500 txtLightBlue mb-0 categoryDropClearTxt">
                              Clear
                            </h3>
                          </div>
                          <div className="search_group_parent">
                            <div className="input-group search_group mb-2">
                              <img
                                src={Images.SearchIcon}
                                alt=""
                                className="img-fluid searchIcon"
                              />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="listdropfilterList_">
                            {loading ? (
                              <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                            ) : (
                              <>
                                {categoryData?.length > 0 ? (
                                  categoryData?.map((val, index) => {
                                    return (
                                      <div
                                        className="listProductbody_ mt-3"
                                        key={index}
                                      >
                                        {/* <h3 className="mainboldHead">
                                          {val?.name}
                                        </h3> */}
                                        <ul className="listItemsprodct_">
                                          {val?.sub_catgories?.map(
                                            (data, index) => {
                                              return (
                                                <li
                                                  className={`textParagh ${
                                                    subCategoryIds?.includes(
                                                      data?.id
                                                    )
                                                      ? "selectProduct"
                                                      : ""
                                                  } `}
                                                  key={index}
                                                  onClick={() => {
                                                    handleSubcategoryChange(
                                                      data?.id
                                                    );
                                                    setSelectedValue(true);
                                                  }}
                                                >
                                                  <div className="productAvailabilityChild align-items-center">
                                                    <div className="form-group">
                                                      <input
                                                        type="checkbox"
                                                        id={index}
                                                      />
                                                      <label
                                                        htmlFor={index}
                                                        className="me-0"
                                                      ></label>
                                                    </div>
                                                  </div>
                                                  {data?.name?.slice(0, 30)}
                                                  {data?.name?.length >= 30
                                                    ? "..."
                                                    : ""}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="text-center txtDarkblue pt-3">
                                    No Categories Found
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-6 ps-0">
                  <div className="productMainCategory">
                    <div className="productMainbox_">
                      <button
                        className="filterTab_"
                        onClick={() => handleToggle("isSubCategory")}
                      >
                        Sub-Category
                        <span>
                          <i
                            className="fal fa-chevron-down dropDownArrow"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </button>
                      {dropdownState.isSubCategory ? (
                        <div className="filterDropdown_" ref={containerRef}>
                          <div className="search_group_parent">
                            <div className="input-group search_group mb-2">
                              <img
                                src={Images.SearchIcon}
                                alt=""
                                className="img-fluid searchIcon"
                              />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="listdropfilterList_">
                            {loading ? (
                              <span className="spinner-border inner-spin-sidebar spinner-border-sm serviceList mt-4"></span>
                            ) : (
                              <>
                                <div className="listProductbody_ mt-3"></div>
                              </>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="d-flex align-items-center justify-content-end gap-3">
                <div className="d-flex align-items-center gap-2">
                  <h3 className="fontSize14 txtDarkblue mb-0">
                    Showing Results
                  </h3>
                  <div className="reactSelectParent offerHeaderSelect">
                    <ReactSelect
                      options={resultSelectOption}
                      placeholder="50"
                      classNamePrefix="react-select"
                      className="react-select-container anlyticSelect"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minWidth: "75px",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          border: "1px solid #D7DEFF",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#263682"
                            : state.isFocused
                            ? "#f5f6fc"
                            : "#fff",

                          color: state.isSelected ? "#fff" : "#636E9F",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "#636E9F",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                      }}
                      components={{
                        DropdownIndicator: () => (
                          <img
                            src={Images.blueDownArrow}
                            width={24}
                            height={24}
                            alt="drop_icon"
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="paginationParent">
                  <div className="d-flex align-items-center gap-1">
                    <div className="backPageBox ">
                      <img
                        src={Images.arrowDoubleLeft}
                        alt="arrowDoubleRight"
                      />
                    </div>
                    <div className="backPageBox backPageBox2">
                      <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                    </div>
                  </div>
                  <h6 className="fontSize14 txtDarkblue mb-0">1-20 of 1550</h6>
                  <div className="d-flex align-items-center gap-1">
                    <div className="nextPageBox nextPageBox2">
                      <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                    </div>
                    <div className="nextPageBox ">
                      <img
                        src={Images.arrowDoubleRight}
                        alt="arrowDoubleRight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="commonTable productTable table-responsive mt-3">
          <table className="w-100">
            <thead>
              <tr>
                <th>
                  <div className="productAvailabilityChild align-items-center">
                    <div className="form-group">
                      <input type="checkbox" id="id1" />
                      <label htmlFor="id1" className="me-0"></label>
                    </div>
                    <h3 className="fontSize12 mb-0">Service</h3>
                  </div>
                </th>
                <th>Category</th>
                <th>Sub-Category</th>
                <th>POS Price</th>
                <th>B2C Price</th>
                <th>Staff</th>
                <th>
                  <div className="productTableLastChild d-flex align-items-center gap-1 justify-content-end">
                    <img
                      src={Images.downloadLogo}
                      alt="logo"
                      onClick={(e) => e.stopPropagation()}
                      height={17}
                      width={17}
                    />

                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="variantsTable_Dropdown"
                        >
                          <img src={Images.moreHorizontalLogo} alt="logo" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                          <Dropdown.Item href="#/action-1">
                            <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                              Bulk Editing
                            </h3>
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-1"
                            onClick={() => handleOpenModal("editLocationModal")}
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <img src={Images.locationLogo} alt="logo" />
                              <h3 className="fontSize14 mb-0">Edit Location</h3>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-2"
                            onClick={() =>
                              handleOpenModal("editSalePriceModal")
                            }
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <img src={Images.moneyPaperIcon} alt="logo" />
                              <h3 className="fontSize14 mb-0">
                                Edit Sales Pricing
                              </h3>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-3"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "deleteProductModal",
                              });
                              setKey(Math.random());
                            }}
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <img src={Images.newDeleteIcon} alt="logo" />
                              <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                                Delete All
                              </h3>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="productAvailabilityChild align-items-center">
                    <div className="form-group">
                      <input type="checkbox" id="id3" />
                      <label htmlFor="id3" className="me-0"></label>
                    </div>

                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={Images.productItemImg}
                        alt="img"
                        className="productItemImg"
                      />
                      <div>
                        <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                          Lightweight Stylish Casual Daypack
                        </h3>

                        <div className="d-flex align-items-center gap-2">
                          <span className="skyblueDot"></span>
                          <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                            SKU 0199 - 3221
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <td className="fontSize12 txtLightBlue">Grocery</td>
                <td className="fontSize12 txtLightBlue">Breads</td>
                <td className="fontSize12 txtLightBlue">$90.00</td>
                <td className="fontSize12 txtLightBlue">$90.00</td>
                <td className="fontSize12 txtLightBlue">1</td>

                <td className="text-end">
                  <div className="d-flex align-items-center justify-content-end gap-3">
                    <input type="checkbox" checked={true} />
                    <Dropdown className="d-flex">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                      >
                        <img src={Images.moreVerticalLogo} alt="logo" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                        <Dropdown.Item href="#/action-1">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.darkBlueEyeIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">View</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.editIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">Edit Variants</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-3"
                          onClick={() => {
                            setModalDetail({
                              show: true,
                              flag: "deleteProductModal",
                            });
                            setKey(Math.random());
                          }}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.newDeleteIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                              Delete
                            </h3>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="productAvailabilityChild align-items-center">
                    <div className="form-group">
                      <input type="checkbox" id="id3" />
                      <label htmlFor="id3" className="me-0"></label>
                    </div>

                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={Images.productItemImg}
                        alt="img"
                        className="productItemImg"
                      />
                      <div>
                        <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                          Lightweight Stylish Casual Daypack
                        </h3>

                        <div className="d-flex align-items-center gap-2">
                          <span className="skyblueDot"></span>
                          <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                            SKU 0199 - 3221
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <td className="fontSize12 txtLightBlue">Grocery</td>
                <td className="fontSize12 txtLightBlue">Breads</td>
                <td className="fontSize12 txtLightBlue">$90.00</td>
                <td className="fontSize12 txtLightBlue">$90.00</td>
                <td className="fontSize12 txtLightBlue">1</td>

                <td className="text-end">
                  <div className="d-flex align-items-center justify-content-end gap-3">
                    <input type="checkbox" checked={true} />
                    <Dropdown className="d-flex">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                      >
                        <img src={Images.moreVerticalLogo} alt="logo" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                        <Dropdown.Item href="#/action-1">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.darkBlueEyeIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">View</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.editIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">Edit Variants</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-3"
                          onClick={() => {
                            setModalDetail({
                              show: true,
                              flag: "deleteProductModal",
                            });
                            setKey(Math.random());
                          }}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.newDeleteIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                              Delete
                            </h3>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="productAvailabilityChild align-items-center">
                    <div className="form-group">
                      <input type="checkbox" id="id3" />
                      <label htmlFor="id3" className="me-0"></label>
                    </div>

                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={Images.productItemImg}
                        alt="img"
                        className="productItemImg"
                      />
                      <div>
                        <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                          Lightweight Stylish Casual Daypack
                        </h3>

                        <div className="d-flex align-items-center gap-2">
                          <span className="skyblueDot"></span>
                          <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                            SKU 0199 - 3221
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <td className="fontSize12 txtLightBlue">Grocery</td>
                <td className="fontSize12 txtLightBlue">Breads</td>
                <td className="fontSize12 txtLightBlue">$90.00</td>
                <td className="fontSize12 txtLightBlue">$90.00</td>
                <td className="fontSize12 txtLightBlue">1</td>

                <td className="text-end">
                  <div className="d-flex align-items-center justify-content-end gap-3">
                    <input type="checkbox" checked={true} />
                    <Dropdown className="d-flex">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                      >
                        <img src={Images.moreVerticalLogo} alt="logo" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                        <Dropdown.Item href="#/action-1">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.darkBlueEyeIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">View</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.editIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">Edit Variants</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-3"
                          onClick={() => {
                            setModalDetail({
                              show: true,
                              flag: "deleteProductModal",
                            });
                            setKey(Math.random());
                          }}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.newDeleteIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                              Delete
                            </h3>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={false}
          mediumWidth={false}
          ids={
            modalDetail.flag === "deleteProductModal"
              ? "stopOfferModal"
              : modalDetail.flag === "deleteModal"
              ? "deleteModal"
              : modalDetail.flag === "editLocationModal"
              ? "editLocationModal"
              : modalDetail.flag === "editSalePriceModal"
              ? "editLocationModal"
              : ""
          }
          child={
            modalDetail.flag === "deleteProductModal" ? (
              <DeleteProductModal
                close={() => handleOnCloseModal()}
                handleModal={handleOpenModal}
              />
            ) : modalDetail.flag === "deleteModal" ? (
              <DeleteModal close={() => handleOnCloseModal()} />
            ) : modalDetail.flag === "editLocationModal" ? (
              <EditServiceLocationModal close={() => handleOnCloseModal()} />
            ) : modalDetail.flag === "editSalePriceModal" ? (
              <EditServiceSalePriceModal close={() => handleOnCloseModal()} />
            ) : (
              <></>
            )
          }
          header={
            <>
              {modalDetail.flag === "editLocationModal" ? (
                <>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div>
                      <h6 className="fontSize12 txtLightBlue">
                        2 items selected
                      </h6>
                      <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                        Update visibility
                      </h3>
                    </div>
                    <img
                      src={Images.modalCross}
                      alt="logo"
                      className="cursorPointer"
                      onClick={() => handleOnCloseModal()}
                    />
                  </div>
                </>
              ) : modalDetail.flag === "editSalePriceModal" ? (
                <>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div>
                      <h6 className="fontSize12 txtLightBlue">
                        2 items selected
                      </h6>
                      <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                        Update sale price
                      </h3>
                    </div>
                    <img
                      src={Images.modalCross}
                      alt="logo"
                      className="cursorPointer"
                      onClick={() => handleOnCloseModal()}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      </div>
    </>
  );
};

export default ServiceList;
