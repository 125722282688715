import React, { useEffect, useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { square, wordpress, wooImg } from "../../../utilities/images";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getPluginsListAsync } from "../dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import * as Images from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import LoaderModal from "./LoaderModal";

const ProductPlugin = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [pluginstData, setPluginsData] = useState();
  const dispatch = useDispatch();

  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());

    if (flag == "loaderModal") {
      setTimeout(() => {
        handleOnCloseModal();
      }, 5000);
    }
  };

  // End Modal

  const handleDownload = (flag, url) => {
    if (flag == "Wordpress") {
      const fileUrl = url;

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "jobr-wordpress.zip"; // Specify the downloaded file name

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getPluginsListAsync())
      .then(unwrapResult)
      .then((obj) => {
        setPluginsData(obj?.payload);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="productPluginSection rightWrapper headerWrap productAddingOption_Section d-flex align-items-center justify-content-center">
        <div className="text-end">
          <button
            className="createOfferCloseBtn backCloseBtn previousPageBtn"
              onClick={() => history.goBack()}
          >
            Close
            <img
              src={Images.lightCrossIcon}
              alt="logo"
              className="closeCrossIcon ms-1"
            />
          </button>
        </div>

        <div className="productPluginsSection">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="fontSize36 fw400 txtBlue text-center">
                  Discover the Best Plugins for your <br /> business to add
                  products easily!
                </h2>
                <p className="fontSize20 fw400 txtBlue text-center pt-2">
                  JOBR Developed apps for your every needs
                </p>
              </div>
            </div>

            <div className="row">
              {loading ? (
                <span className="spinner-border inner-spin spinner-border-sm"></span>
              ) : (
                <>
                  {pluginstData?.length > 0 ? (
                    <>
                      {pluginstData?.map((val, index) => {
                        return (
                          <div
                            className="col-lg-6 plugSubSecParent"
                            key={index}
                          >
                            <div
                              className="plugSubSection"
                              onClick={() => {
                                handleOpenModal("loaderModal");
                                handleDownload(val?.title, val?.url);
                              }}
                            >
                              <img
                                src={val?.logo}
                                alt="wordpress image"
                                className="img-fluid pluginImg"
                              />
                              <div className="d-flex align-items-start gap-2">
                                <div className="plugHeadSection">
                                  <h4 className="fontSize24 fw500 txtDarkblue mb-0">
                                    {val?.title}
                                  </h4>
                                  <div className="pluginSubscriptionDiv">
                                    <span className="pinkDot"></span>
                                    <h4 className="freeText mb-0">
                                      {val?.subscription}
                                    </h4>
                                  </div>
                                  <p className="fontSize16 fw500 txtDarkblue mb-0">
                                    {val?.discription}
                                  </p>
                                </div>
                                <img src={Images.blueArrowTopRight} alt="img" />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div>No Plugins Found</div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "loaderModal" ? "loaderModal" : ""}
        child={
          modalDetail.flag === "loaderModal" ? (
            <LoaderModal close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ProductPlugin;
