import React, { useEffect, useState } from "react";
import {
  calendar,
} from "../../../utilities/images";
import ChartArea from "../dashboard/wallet/ChartArea";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import {
  getAnalyticOrdersApiAsync,
  getAnalyticStatDataApiAsync,
  totalAnalyticsInventoryApiAsync,
  totalAnalyticsOrderApiAsync,
  totalAnalyticsProductSoldApiAsync,
} from "../dashboard/dashboardSlice";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment-timezone";
import AnalyticsHeader from "./AnalyticsHeader";


const Analytics = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [filterValue, setFilterValue] = useState("week");
  const [channelFilter, setChannelFilter] = useState("all");
  const [analyticsProfitData, setAnalyticsProfitsData] = useState("");
  const [analyticsOrderData, setAnalyticsOrderData] = useState("");
  const [totalOrderAnalyticsData, setTotalOrderAnalyticsData] = useState("");
  const [totalInventoryAnalyticsData, setTotalInventoryAnalyticsData] = useState("");
  const [totalProductSoldAnalyticsData, setTotalProductSoldAnalyticsData] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // POS DATASET change

  const [POSdataSets, setPOSDataSets] = useState([]);
  const [POSchecked, setPOSChecked] = useState({
    Delivered: true,
    Returned: true,
    Cancelled: true,
  });
  const handelPOSDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setPOSDataSets([
        ...POSdataSets,
        {
          fill: true,
          label: value,
          data: analyticsOrderData?.pos_graph?.graph_data?.datasets[num]?.data,
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setPOSDataSets(POSdataSets.filter((item) => item.label !== value));
    }
  };
  const PosOrderAnalytics = {
    labels: analyticsOrderData?.pos_graph?.graph_data?.labels,
    datasets: POSdataSets,
  };

  const GrossProfitAnalytics = {
    labels: analyticsProfitData?.profit?.graph_data?.labels,
    datasets: [
      {
        fill: true,
        label: "Profit",
        data: analyticsProfitData?.profit?.graph_data?.datasets[0]?.data,
        borderColor: "#275AFF",
        backgroundColor: "#FFFFFF00",
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  const RevenueProfitAnalytics = {
    labels: analyticsProfitData?.revenue?.graph_data?.labels,
    datasets: [
      {
        fill: true,
        label: "Profit",
        data: analyticsProfitData?.revenue?.graph_data?.datasets[0]?.data,
        borderColor: "#275AFF",
        backgroundColor: "#FFFFFF00",
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  const CostProfitAnalytics = {
    labels: analyticsProfitData?.cost?.graph_data?.labels,
    datasets: [
      {
        fill: true,
        label: "Profit",
        data: analyticsProfitData?.cost?.graph_data?.datasets[0]?.data,
        borderColor: "#275AFF",
        backgroundColor: "#FFFFFF00",
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  const TotalProductSoldAnalytics = {
    labels: totalProductSoldAnalyticsData?.graphData?.labels,
    datasets: [
      {
        fill: true,
        label: "Product Sold",
        data: totalProductSoldAnalyticsData?.graphData?.datasets[0]?.data,
        borderColor: "#275AFF",
        backgroundColor: "#FFFFFF00",
        cubicInterpolationMode: "monotone",
      },
    ],
  };
  const TotalInventoryAnalytics = {
    labels: [],
    datasets: [
      {
        fill: true,
        label: "Inventory",
        data: [],
        borderColor: "#275AFF",
        backgroundColor: "#FFFFFF00",
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  //delivery DataSet
  const [DeliveryChecked, setDeliveryChecked] = useState({
    Delivered: true,
    Returned: true,
    Cancelled: true,
  });

  const [deliveryDatasets, setDeliveryDatasets] = useState([]);
  const DeliveryOrderAnalytics = {
    labels: analyticsOrderData?.delivery_graph?.graph_data?.labels,
    datasets: deliveryDatasets,
  };
  const handelDeliveryDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDeliveryDatasets([
        ...deliveryDatasets,
        {
          fill: true,
          label: value,
          data: analyticsOrderData?.delivery_graph?.graph_data?.datasets[num]?.data,
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setDeliveryDatasets(
        deliveryDatasets.filter((item) => item.label !== value)
      );
    }
  };

  //shipping Data Sets
  const [shippingChecked, setShippingChecked] = useState({
    Delivered: true,
    Returned: true,
    Cancelled: true,
  });
  const [shippingDatasets, setShippingDatasets] = useState([]);
  const handelShippingDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setShippingDatasets([
        ...shippingDatasets,
        {
          fill: true,
          label: value,
          data: analyticsOrderData?.pos_graph?.graph_data?.datasets[num]?.data,
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setShippingDatasets(
        shippingDatasets.filter((item) => item.label !== value)
      );
    }
  };

  const ShippingOrderAnalytics = {
    labels: analyticsOrderData?.shipping_graph?.graph_data?.labels,
    datasets: shippingDatasets,
  };
  // Total Orders DataSet
  const [totalChecked, setTotalChecked] = useState({
    POS: true,
    Online: true,
  });
  const [totalDatasets, setTotalDatasets] = useState([]);
  const handelToatalDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setTotalDatasets([
        ...totalDatasets,
        {
          fill: true,
          label: value,
          data: analyticsOrderData?.shipping_graph?.graph_data?.datasets[num]?.data,
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setTotalDatasets(
        totalDatasets.filter((item) => item.label !== value)
      );
    }
  };
  const TotalOrderAnalytics = {
    labels: totalOrderAnalyticsData?.graphData?.labels,
    datasets: totalDatasets,
  };

  const newUserDataHandle = () => {
    let params = {
      postData: {
        is_admin: true,
        filter: filterValue,
        channel: channelFilter,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852",
      },
      tokenData: auth?.payload?.token,
    };
    if (startDate && endDate) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    setLoading(true);
    dispatch(getAnalyticStatDataApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAnalyticsProfitsData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const orderAnalyticsHandle = () => {
    let params = {
      postData: {
        filter: filterValue,
        channel: channelFilter,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852",
      },
      tokenData: auth?.payload?.token,
    };
    if (startDate && endDate) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    setLoading(true);
    dispatch(getAnalyticOrdersApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAnalyticsOrderData(obj?.payload);
        setPOSDataSets([
          {
            fill: true,
            label: "Delivered",
            data: obj?.payload?.pos_graph?.graph_data?.datasets[0]?.data,
            borderColor: "#275AFF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Returned",
            data: obj?.payload?.pos_graph?.graph_data?.datasets[1]?.data,
            borderColor: "#FCBA30",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Cancelled",
            data: obj?.payload?.pos_graph?.graph_data?.datasets[2]?.data,
            borderColor: "#FB466C",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
        setPOSChecked({
          Delivered: true,
          Returned: true,
          Cancelled: true,
        });
        // Delivery Order
        setDeliveryDatasets([
          {
            fill: true,
            label: "Delivered",
            data: obj?.payload?.delivery_graph?.graph_data?.datasets[0]?.data,
            borderColor: "#275AFF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Returned",
            data: obj?.payload?.delivery_graph?.graph_data?.datasets[1]?.data,
            borderColor: "#FCBA30",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Cancelled",
            data: obj?.payload?.delivery_graph?.graph_data?.datasets[2]?.data,
            borderColor: "#FB466C",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
        setDeliveryChecked({
          Delivered: true,
          Returned: true,
          Cancelled: true,
        });
        // shipping DataSets
        setShippingDatasets([
          {
            fill: true,
            label: "Delivered",
            data: obj?.payload?.shipping_graph?.graph_data?.datasets[0]?.data,
            borderColor: "#275AFF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Returned",
            data: obj?.payload?.shipping_graph?.graph_data?.datasets[1]?.data,
            borderColor: "#FCBA30",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Cancelled",
            data: obj?.payload?.shipping_graph?.graph_data?.datasets[2]?.data,
            borderColor: "#FB466C",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
        
        setShippingChecked({
          Delivered: true,
          Returned: true,
          Cancelled: true,
        });

        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const totalOrderAnalyticsHandle = () => {
    let params = {
      postData: {
        filter: filterValue,
        channel: channelFilter,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852",
      },
      tokenData: auth?.payload?.token,
    };
    if (startDate && endDate) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    setLoading(true);
    dispatch(totalAnalyticsOrderApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setTotalOrderAnalyticsData(obj?.payload);
        // Total Order DataSets
        setTotalDatasets([
          {
            fill: true,
            label: "POS",
            data: obj?.payload?.graphData?.datasets[0]?.data,
            borderColor: "#275AFF",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
          {
            fill: true,
            label: "Online",
            data: obj?.payload?.graphData?.datasets[1]?.data,
            borderColor: "#FCBA30",
            backgroundColor: "#FFFFFF00",
            cubicInterpolationMode: "monotone",
          },
        ]);
        setTotalChecked({
          POS: true,
          Online: true
        })
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const totalProductSoldAnalyticsHandle = () => {
    let params = {
      postData: {
        filter: filterValue,
        channel: channelFilter,
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852",
      },
      tokenData: auth?.payload?.token,
    };
    if (startDate && endDate) {
      let newData = {
        ...params.postData,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      params = {
        tokenData: auth?.payload?.token,
        postData: newData,
      };
    }
    setLoading(true);
    dispatch(totalAnalyticsProductSoldApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setTotalProductSoldAnalyticsData(obj?.payload);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const totalInventoryAnalyticsHandle = () => {
    let params = {
      "postData": {
        filter: filterValue,
        channel: channelFilter,
        seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid
      },
      "tokenData": auth?.payload?.token
    }
    setLoading(true)
    dispatch(totalAnalyticsInventoryApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setTotalInventoryAnalyticsData(obj?.payload)
      })
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    newUserDataHandle();
    orderAnalyticsHandle();
    totalInventoryAnalyticsHandle();
    totalOrderAnalyticsHandle();
    totalProductSoldAnalyticsHandle();
  }, [filterValue, channelFilter, endDate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Analytics";
  }, []);

  return (
    <>
      <DashboardNavbar title="Analytics" show={false} />
      <div className="analyticsMain chartsOuter">
        <>
          <div className="productDatatwallet mt-2 mb-2">
            <AnalyticsHeader 
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setFilterValue={setFilterValue}
            filterValue={filterValue}
            setChannelFilter={setChannelFilter}
            channelFilter={channelFilter}
            subhead="vs. Same day last week"
            />
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mt-4 h-100">
                <div
                  className="chartsOuter h-100"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/Gross-Profits")}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="expectedHeading ">Gross Profits</h4>
                    <h4 className="successMain">
                      {" "}
                      $
                      {addThousandSeparator(
                        analyticsProfitData?.profit?.total_count
                      )}
                    </h4>
                  </div>
                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options}
                    data={GrossProfitAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-4 h-100">
                <div
                  className="chartsOuter h-100"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/Total-Revenue")}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="expectedHeading ">Total Revenue</h4>
                    <h4 className="successMain">
                      {" "}
                      $
                      {addThousandSeparator(
                        analyticsProfitData?.revenue?.total_count
                      )}
                    </h4>
                  </div>
                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options}
                    data={RevenueProfitAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-4 h-100">
                <div
                  className="chartsOuter h-100"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/Total-Costs")}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="expectedHeading ">Total Costs</h4>
                    <h4 className="successMain">
                      {" "}
                      $
                      {addThousandSeparator(
                        analyticsProfitData?.cost?.total_count
                      )}
                    </h4>
                  </div>
                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options}
                    data={CostProfitAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-4 h-100">
                <div className="chartsOuter h-100">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="expectedHeading ">Total POS Orders</h4>
                    <h4 className="successMain">
                      {" "}
                      {addThousandSeparator(
                        analyticsOrderData?.pos_graph?.total_count
                      )}
                    </h4>
                  </div>
                  <div className="graphChecked_  mb-2 ">
                    <div className="graphBox_ me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll2"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelPOSDataSetChange(e, "Delivered", 0, "#275AFF");
                          setPOSChecked({
                            ...POSchecked,
                            Delivered:
                              POSchecked.Delivered === true ? false : true,
                          });
                        }}
                        checked={POSchecked.Delivered}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll2"
                        className="checkBoxTxt "
                      >
                        Delivered
                      </label>
                    </div>
                    <div className="checkYellow me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll3"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={POSchecked.Returned}
                        onChange={(e) => {
                          handelPOSDataSetChange(e, "Returned", 1, "#FCBA30");
                          setPOSChecked({
                            ...POSchecked,
                            Returned:
                              POSchecked.Returned === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll3"
                        className="checkBoxTxt "
                      >
                        Returned
                      </label>
                    </div>
                    <div className="checkRed me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll4"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={POSchecked.Cancelled}
                        onChange={(e) => {
                          handelPOSDataSetChange(e, "Cancelled", 2, "#FB466C");
                          setPOSChecked({
                            ...POSchecked,
                            Cancelled:
                              POSchecked.Cancelled === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll4"
                        className="checkBoxTxt "
                      >
                        Cancelled
                      </label>
                    </div>
                  </div>

                  <ChartArea
                    style={{ cursor: "pointer" }}
                    push={() => history.push("/Total-Pos-Orders")}
                    className="col-md-12"
                    header=""
                    options={options1}
                    data={PosOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-4 h-100">
                <div className="chartsOuter h-100">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="expectedHeading ">Total Delivery Orders</h4>
                    <h4 className="successMain">
                      {" "}
                      {addThousandSeparator(
                        analyticsOrderData?.delivery_graph?.total_count
                      )}
                    </h4>
                  </div>
                  <div className="graphChecked_  mb-2 ">
                    <div className="graphBox_ me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll23"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelDeliveryDataSetChange(
                            e,
                            "Delivered",
                            0,
                            "#275AFF"
                          );
                          setDeliveryChecked({
                            ...DeliveryChecked,
                            Delivered:
                              DeliveryChecked.Delivered === true ? false : true,
                          });
                        }}
                        checked={DeliveryChecked.Delivered}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll23"
                        className="checkBoxTxt "
                      >
                        Delivered
                      </label>
                    </div>
                    <div className="checkYellow me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll34"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={DeliveryChecked.Returned}
                        onChange={(e) => {
                          handelDeliveryDataSetChange(
                            e,
                            "Returned",
                            1,
                            "#FCBA30"
                          );
                          setDeliveryChecked({
                            ...DeliveryChecked,
                            Returned:
                              DeliveryChecked.Returned === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll34"
                        className="checkBoxTxt "
                      >
                        {/* Total DElivery oRDERS */}
                        Returned
                      </label>
                    </div>
                    <div className="checkRed me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll43"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={DeliveryChecked.Cancelled}
                        onChange={(e) => {
                          handelDeliveryDataSetChange(
                            e,
                            "Cancelled",
                            2,
                            "#FB466C"
                          );
                          setDeliveryChecked({
                            ...DeliveryChecked,
                            Cancelled:
                              DeliveryChecked.Cancelled === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll43"
                        className="checkBoxTxt "
                      >
                        Cancelled
                      </label>
                    </div>
                  </div>

                  <ChartArea
                    style={{ cursor: "pointer" }}
                    push={() => history.push("/Total-Delivery-Orders")}
                    className="col-md-12"
                    header=""
                    options={options1}
                    data={DeliveryOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-4 h-100">
                <div className="chartsOuter h-100">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="expectedHeading ">Total Shipping Orders</h4>
                    <h4 className="successMain">
                      {" "}
                      {addThousandSeparator(
                        analyticsOrderData?.shipping_graph?.total_count
                      )}
                    </h4>
                  </div>

                  <div className="graphChecked_  mb-2 ">
                    <div className="graphBox_ me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll231"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelShippingDataSetChange(
                            e,
                            "Delivered",
                            0,
                            "#275AFF"
                          );
                          setShippingChecked({
                            ...shippingChecked,
                            Delivered:
                              shippingChecked.Delivered === true ? false : true,
                          });
                        }}
                        checked={shippingChecked.Delivered}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll231"
                        className="checkBoxTxt "
                      >
                        Delivered
                      </label>
                    </div>
                    <div className="checkYellow me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll341"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={shippingChecked.Returned}
                        onChange={(e) => {
                          handelShippingDataSetChange(
                            e,
                            "Returned",
                            1,
                            "#FCBA30"
                          );
                          setShippingChecked({
                            ...shippingChecked,
                            Returned:
                              shippingChecked.Returned === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll341"
                        className="checkBoxTxt "
                      >
                        {/* Total DElivery oRDERS */}
                        Returned
                      </label>
                    </div>
                    <div className="checkRed me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll431"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={shippingChecked.Cancelled}
                        onChange={(e) => {
                          handelShippingDataSetChange(
                            e,
                            "Cancelled",
                            2,
                            "#FB466C"
                          );
                          setShippingChecked({
                            ...shippingChecked,
                            Cancelled:
                              shippingChecked.Cancelled === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll431"
                        className="checkBoxTxt "
                      >
                        Cancelled
                      </label>
                    </div>
                  </div>

                  <ChartArea
                    style={{ cursor: "pointer" }}
                    push={() => history.push("/Total-Shipping-Orders")}
                    className="col-md-12"
                    header=""
                    options={options1}
                    data={ShippingOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-4 h-100">
                <div className="chartsOuter h-100">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="expectedHeading ">Total Orders</h4>
                    <h4 className="successMain">
                      {" "}
                      {addThousandSeparator(
                        totalOrderAnalyticsData?.total_orders
                      )}
                    </h4>
                  </div>

                  <div className="graphChecked_  mb-2 ">
                    <div className="graphBox_ blueBox_ me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll2312"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        onChange={(e) => {
                          handelToatalDataSetChange(
                            e,
                            "POS",
                            0,
                            "#275AFF"
                          );
                          setTotalChecked({
                            ...totalChecked,
                            POS:
                              totalChecked.POS === true ? false : true,
                          });
                        }}
                        checked={totalChecked.POS}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll2312"
                        className="checkBoxTxt "
                      >
                        POS Orders
                      </label>
                    </div>
                    <div className="skyblueBox_ me-3 mt-3">
                      <input
                        type="checkbox"
                        id="custom-checkbox-selectAll3412"
                        className="checkBox "
                        name="custom-checkbox-selectAll"
                        value="custom-checkbox-selectAll"
                        checked={totalChecked.Online}
                        onChange={(e) => {
                          handelToatalDataSetChange(
                            e,
                            "Online",
                            1,
                            "#FCBA30"
                          );
                          setTotalChecked({
                            ...totalChecked,
                            Online:
                              totalChecked.Online === true ? false : true,
                          });
                        }}
                      />
                      <label
                        htmlFor="custom-checkbox-selectAll3412"
                        className="checkBoxTxt "
                      >
                        {/* Total DElivery oRDERS */}
                        Online
                      </label>
                    </div>
                  </div>

                  <ChartArea
                    style={{ cursor: "pointer" }}
                    push={() => history.push("/Total-Orders")}
                    className="col-md-12"
                    header=""
                    options={options1}
                    data={TotalOrderAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-4 ">
                <div
                  className="chartsOuter h-100"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/Total-Inventory")}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="expectedHeading ">Total Inventory</h4>
                    <h4 className="successMain">
                      {" "}
                      {addThousandSeparator(
                        totalInventoryAnalyticsData?.inventory_overview?.total_inventory
                      )}
                    </h4>
                  </div>
                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options}
                    data={TotalInventoryAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-4 ">
                <div
                  className="chartsOuter h-100"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/Total-Product-Sold")}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="expectedHeading ">Total Product Sold</h4>
                    <h4 className="successMain">
                      {" "}
                      {addThousandSeparator(
                        totalProductSoldAnalyticsData?.totalProductSoldList?.total
                      )}
                    </h4>
                  </div>
                  <ChartArea
                    className="col-md-12"
                    header=""
                    options={options}
                    data={TotalProductSoldAnalytics}
                    chartType="Line"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default Analytics;
