import React from "react";
import * as Images from "../../../utilities/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ProductAddingOption = () => {
  const history = useHistory();
  return (
    <div className="productPluginSection productAddingOption_Section d-flex align-items-center justify-content-center">
      <button
        className="createOfferCloseBtn previousPageBtn backCloseBtn  d-flex gap-2"
        onClick={() => history.goBack()}
      >
        Close
        <img src={Images.modalCross} alt="logo" className="closeCrossIcon" />
      </button>
      <div className="productPluginsSection text-center w-100">
        <div className="container">
          <div className="row pb-3">
            <div className="col-12">
              <h1 className="fontSize36 fw400 txtBlue">Add New Product</h1>
              <p className="fontSize20 fw400 txtBlue">
                To get the best experience, we recommend setting up following
                steps. This is necessary!
              </p>
            </div>
          </div>
          <div className="addingOptionBox_Container">
            <div className="addingOptionBox">
              <figure className="bigTickSection">
                <img
                  src={Images.addProductLogo}
                  alt="databaseImage"
                  className="img-fluid"
                />
              </figure>
              <h4 className="fontSize24 fw500 txtBlue">Physical Product</h4>
              <p className="fontSize14 fw400 txtBlue pt-1">
                e.g. T-shirt, shoes, food items
              </p>
              <button
                className="commonBlueBtn active gap-2 mt-3 w-100"
                type="button"
                onClick={() => history.push(`/add-product/myProducts`)}
              >
                <img src={Images.plusIcon} alt="plusIcon" />
                Add Physical Product
              </button>
            </div>
            <div className="addingOptionBox">
              <figure className="bigTickSection">
                <img
                  src={Images.addServiceLogo}
                  alt="databaseImage"
                  className="img-fluid"
                />
              </figure>
              <h4 className="fontSize24 fw500 txtBlue">Service</h4>
              <p className="fontSize14 fw400 txtBlue pt-1">
                e.g. Haircut, Makeup, Treatment
              </p>

              <button
                className="commonBlueBtn active gap-2 mt-3 w-100"
                type="button"
                onClick={() => history.push("/add-service")}
              >
                <img src={Images.plusIcon} alt="plusIcon" />
                Add Service
              </button>
            </div>

            <div className="addingOptionBox">
              <figure className="bigTickSection">
                <img
                  src={Images.addDigitalProductLogo}
                  alt="databaseImage"
                  className="img-fluid"
                />
              </figure>
              <h4 className="fontSize24 fw500 txtBlue">Digital Product</h4>
              <p className="fontSize14 fw400 txtBlue pt-1">
                e.g. Online Class, audio, eBook, printable
              </p>

              <button
                className="commonBlueBtn active gap-2 mt-3 w-100"
                type="button"
                onClick={() => history.push("/digital-product")}
              >
                <img src={Images.plusIcon} alt="plusIcon" />
                Add Service
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAddingOption;
