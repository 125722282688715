import React, { useState } from 'react'
import moment from 'moment-timezone';
import { locationBlue, profileImg1, clockimg_ } from '../../../utilities/images';
import CustomModal from '../../components/shared/CustomModal';
import CheckedInModal from './CheckedInModal';
import * as Images from "../../../utilities/images";
const CheckinModal = (props) => {
    let data = props?.data;
const [randomKey, setrandomKey] = useState(Math.random())
    const statusChange = (id, status) => {
        props.changeStatusOfAppointment(id, status)
        if (!props.loading2) {
            props.close()
        }
    }
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
      });
      const handleOnCloseModal = () => {
        setModalDetail({
          show: false,
          title: "",
          flag: "",
        });
        setrandomKey(Math.random());
      };
    return (
        <div className="checkInModalSection scheduleModal">
            <div className="checkModalDetails">
                <div className="Innerbooking mb-4">
                    <div className="customerBookProfile mb-4">
                        <h5 className='smallHeading mb-2'>Customer:</h5>
                        {
                            data?.user_details ?
                                <div className='d-flex align-items-center'>
                                    <img className='userInnerImg' src={data?.user_details?.profile_photo ? data?.user_details?.profile_photo : profileImg1} />
                                    <div className='ms-2'>
                                        <span className='haedingsemiBold_ '>{data?.user_details?.firstname + " " + data?.user_details?.lastname}</span>
                                        {data?.user_details?.current_address &&
                                            <p className='mb-0'>
                                                <img src={locationBlue} className='img-fluid me-2 custImg' />
                                                <span className='contentSmallTxt'>{data?.user_details?.current_address?.city},{data?.user_details?.current_address?.country}</span>
                                            </p>}
                                    </div>
                                </div> : <>Not Available</>}
                    </div>
                </div>
                <div className="InnercheckIn mb-4">
                    <div className="contentappont">
                        <h5 className='smallHeading mb-2'>Assigned:</h5>
                        <div className='d-flex align-items-center'>
                            <img className='userInnerImg' src={data?.pos_user_details?.user?.user_profiles?.profile_photo ? data?.pos_user_details?.user?.user_profiles?.profile_photo : profileImg1} />
                            <div className='ms-2'>
                                <span className='haedingsemiBold_'>{data?.pos_user_details?.user?.user_profiles?.firstname + " " + data?.pos_user_details?.user?.user_profiles?.lastname}</span>
                                <p className='mb-0'>
                                    <span className='contentSmallTxt'>{data?.pos_user_details?.is_staff_member === true ? "Staff" : "Non-staff"}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="appointInner mb-4">
                    <div className="contentappont">
                        <h5 className='smallHeading mb-2'>Service Requested:</h5>
                        <span>{data?.product_name}</span>
                    </div>
                </div>
                <div className="appointInner upcommingAnother mb-4">
                    <div className="contentappont">
                        <h5 className='smallHeading mb-2'>Service Time:</h5>
                        <div className='serviceTime_'>
                            <span><img className='img-fluid clockimg_ me-2' alt="clock" src={clockimg_} />{moment.utc(data?.date).format("dddd ")}  </span> | <span> {moment.utc(data?.date).format("MMMM DD, YYYY")} </span> | <span>{data?.start_time + "-" + data?.end_time}</span></div>
                    </div>
                </div>
                <div className="appointInner mb-2">
                    <div className="contentappont">
                        <span className='smallHeading'>Duration : </span>
                        <span className='timeText'>{data?.approx_service_time + " min"}</span>
                    </div>
                </div>
                <div className="serviceAmount mt-4 mb-2">
              <h6 className="textSmall fw-700">Total</h6>
              <h6 className="textSmall fw-700">{`$ ${data?.actual_price}`}</h6>
            </div>
            </div>
            <div className="CustomerBtn mt-4">
          <button
            className="declineBtn"
            type="submit"
            onClick={() => {
                props.changeStatusOfAppointment(data?.id, 4)
            }
                }
          >
            Decline
          </button>
          <button
            className="confirmbtn"
            type="submit"
            onClick={() => {
                // props.changeStatusOfAppointment(data?.id, 2)
                setModalDetail({ show: true, flag: "checkedIn" });

            }
                }
          >
            Confirm
            <img
              src={Images.ArrowRight}
              alt="greenRight"
              className="img-fluid "
            />
          </button>
        </div>
            <CustomModal
        key={randomKey}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "checkedIn" ? "customContent" : ""}
        ids={modalDetail.flag === "checkedIn" ? "activePos" : ""}
        size={modalDetail.flag === "checkedIn" ? "md" : "md"}
        child={
          modalDetail.flag === "checkedIn" ? (
            <CheckedInModal close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        header={
        modalDetail.flag==="checkedIn"?(<></>):<></>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
        </div>
    )
}

export default CheckinModal;