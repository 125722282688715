import React from 'react'
import * as Images from "../../../../utilities/images";

const ChooseitemModal = () => {
    return (
        <div className='chooseitemModal_'>
            <div className='chooseitemModal__content'>
                <div className='searchBox_ship'>
                    <img src={Images.SearchIcon} className='searchIcon_' />
                    <input className='searchinput_' type='search' placeholder='Search team member' />
                </div>
                <div className='listofchooseItem_'>
                    <div className="commonTable productTable table-responsive mt-3">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="productAvailabilityChild align-items-center">
                                            <div className="form-group">
                                                <span className='hashitem_'>#</span>

                                            </div>
                                            <h3 className="fontSize12 mb-0">Items</h3>
                                        </div>
                                    </th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="productAvailabilityChild align-items-center">
                                            <div className="form-group">
                                                <input type="checkbox" id="item1" />
                                                <label htmlFor="item1" className="me-0"></label>
                                            </div>

                                            <div className="d-flex align-items-center gap-3">
                                                <img
                                                    src={Images.productItemImg}
                                                    alt="img"
                                                    className="productItemImg"
                                                />
                                                <div>
                                                    <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                                                        Lightweight Stylish Casual Daypack
                                                    </h3>

                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className="skyblueDot"></span>
                                                        <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                                            SKU 0199 - 3221
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="fontSize12 txtLightBlue">$90.00</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="productAvailabilityChild align-items-center">
                                            <div className="form-group">
                                                <input type="checkbox" id="item2" />
                                                <label htmlFor="item2" className="me-0"></label>
                                            </div>

                                            <div className="d-flex align-items-center gap-3">
                                                <img
                                                    src={Images.productItemImg}
                                                    alt="img"
                                                    className="productItemImg"
                                                />
                                                <div>
                                                    <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                                                        Lightweight Stylish Casual Daypack
                                                    </h3>

                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className="skyblueDot"></span>
                                                        <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                                            SKU 0199 - 3221
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="fontSize12 txtLightBlue">$90.00</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="productAvailabilityChild align-items-center">
                                            <div className="form-group">
                                                <input type="checkbox" id="item3" />
                                                <label htmlFor="item3" className="me-0"></label>
                                            </div>

                                            <div className="d-flex align-items-center gap-3">
                                                <img
                                                    src={Images.productItemImg}
                                                    alt="img"
                                                    className="productItemImg"
                                                />
                                                <div>
                                                    <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                                                        Lightweight Stylish Casual Daypack
                                                    </h3>

                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className="skyblueDot"></span>
                                                        <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                                            SKU 0199 - 3221
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="fontSize12 txtLightBlue">$90.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='footerBtn_ d-flex align-items-center mt-4'>
                    <button className='greyBtn_'>Cancel</button>
                    <button className='blueButton_'>Add items</button>
                </div>
        </div>
    )
}

export default ChooseitemModal