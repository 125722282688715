import { GoogleMap, DirectionsService, DirectionsRenderer, Marker } from '@react-google-maps/api';
import { useState, useEffect } from 'react';

const GoogleMapView = ({ data }) => {
  // Set default data in case 'data' is not provided
  const orderDelivery = {
    order_pickup_latitude: data?.order_pickup_latitude || 30.6575589,
    order_pickup_longitude: data?.order_pickup_longitude || 76.7271485,
    order_delivery_latitude: data?.order_delivery_latitude || 30.6770008,
    order_delivery_longitude: data?.order_delivery_longitude || 76.72408759999999,
    // other fields...
  };
console.log(orderDelivery)
  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [directionsOptions, setDirectionsOptions] = useState({
    origin: {
      lat: orderDelivery.order_pickup_latitude,
      lng: orderDelivery.order_pickup_longitude,
    },
    destination: {
      lat: orderDelivery.order_delivery_latitude,
      lng: orderDelivery.order_delivery_longitude,
    },
    travelMode: "DRIVING",
  });

  const directionsCallback = (response, status) => {
    if (status === "OK" && !directionsResponse) {
      setDirectionsResponse(response);
    } else if (status !== "OK") {
      console.error("Directions request failed due to " + status);
    }
  };

  // Effect hook to ensure the DirectionsService only runs once
  useEffect(() => {
    console.log("check")
    if (!directionsResponse) {
      setDirectionsResponse(null); // Clear directions if needed
    }
  }, [directionsResponse,data]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMap
        zoom={15}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        center={{
          lat: (orderDelivery.order_pickup_latitude + orderDelivery.order_delivery_latitude) / 2,
          lng: (orderDelivery.order_pickup_longitude + orderDelivery.order_delivery_longitude) / 2,
        }} // Center the map between the pickup and delivery locations
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        onLoad={(map) => setMap(map)}
      >
          {/* Remove the manual markers, because DirectionsRenderer will add them automatically */}

        {/* Only call DirectionsService if directionsResponse is not set */}
        {!directionsResponse && (
          <DirectionsService
            options={directionsOptions}
            callback={directionsCallback}
          />
        )}

        {/* Render the directions once we have the response */}
        {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapView;
