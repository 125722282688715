import React, { useState } from "react";
import * as Images from "../../../utilities/images";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ReactSelect from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomModal from "../../components/shared/CustomModal";
import EditOfferModal from "./EditOfferModal";
import StopOfferModal from "./StopOfferModal";
import PauseOfferModal from "./PauseOfferModal";
import DeleteProductModal from "./DeleteProductModal";
import DeleteModal from "./DeleteModal";
import EditLocationModal from "./EditLocationModal";
import EditSalePriceModal from "./EditSalePriceModal";

const ProductOffer = () => {
  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());
  };

  // End Modal
  const history = useHistory();
  const selectOption = [
    { value: "Offer type", label: "Offer type" },
    { value: "Offer", label: "Offer" },
    { value: "b2c", label: "Location 2" },
    { value: "b2b", label: "Location 3" },
  ];
  const resultSelectOption = [
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "150", label: "150" },
    { value: "200", label: "200" },
  ];
  return (
    <div className="walletStats h-100">
      <div className="productOfferHead_Container">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="fontSize24 txtDarkblue fw500 mb-0">Product Offers</h3>
          <div className="d-flex align-items-center gap-4">
            <img src={Images.NotificationIcon} alt="NotificationIcon" />
            <img src={Images.SearchIcon} alt="SearchIcon" />
            <Link
              to="/createOffer"
              className="addNew_ dropdown-toggle headerSaveBtn"
            >
              <span className="addNewTxt">Create Offer</span>
              <img src={Images.addButtonImg} alt="addNew_Image" />
            </Link>
          </div>
        </div>

        <div className="row align-items-center mt-4">
          <div className="col-md-4">
            <div className="d-flex align-items-center gap-2">
              <button className="activeProductBtn active">
                Active <span className="activeProductofferItem">(3)</span>
              </button>
              <button className="activeProductBtn">
                Completed <span className="activeProductofferItem">(3)</span>
              </button>
            </div>
          </div>

          <div className="col-md-8">
            <div className="d-flex align-items-center justify-content-end gap-3">
              <div className="reactSelectParent offerHeaderSelect">
                <ReactSelect
                  options={selectOption}
                  placeholder="Offer type"
                  classNamePrefix="react-select"
                  className="react-select-container anlyticSelect"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minWidth: "120px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: "1px solid #D7DEFF",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#263682"
                        : state.isFocused
                        ? "#f5f6fc"
                        : "#fff",

                      color: state.isSelected ? "#fff" : "#636E9F",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#636E9F",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => (
                      <img
                        src={Images.blueDownArrow}
                        width={24}
                        height={24}
                        alt="drop_icon"
                      />
                    ),
                  }}
                />
              </div>
              <div className="d-flex align-items-center gap-2">
                <h3 className="fontSize14 txtDarkblue mb-0">Showing Results</h3>
                <div className="reactSelectParent offerHeaderSelect">
                  <ReactSelect
                    options={resultSelectOption}
                    placeholder="50"
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: "75px",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#636E9F",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.blueDownArrow}
                          width={24}
                          height={24}
                          alt="drop_icon"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="paginationParent">
                <div className="d-flex align-items-center gap-1">
                  <div className="backPageBox ">
                    <img src={Images.arrowDoubleLeft} alt="arrowDoubleRight" />
                  </div>
                  <div className="backPageBox backPageBox2">
                    <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                  </div>
                </div>
                <h6 className="fontSize14 txtDarkblue mb-0">1-20 of 1550</h6>
                <div className="d-flex align-items-center gap-1">
                  <div className="nextPageBox nextPageBox2">
                    <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                  </div>
                  <div className="nextPageBox ">
                    <img src={Images.arrowDoubleRight} alt="arrowDoubleRight" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="commonTable productOfferTable table-responsive">
        <table className="w-100">
          <thead>
            <tr>
              <th>
                <div className="productAvailabilityChild align-items-center">
                  <div className="form-group">
                    <input type="checkbox" id="id1" />
                    <label htmlFor="id1" className="me-0"></label>
                  </div>
                  <h3 className="fontSize12 mb-0">Product</h3>
                </div>
              </th>
              <th>Validity</th>
              <th>Offer Type</th>
              <th>POS Price</th>
              <th>B2C Price</th>
              <th>B2B Price</th>
              <th>
                <div className="d-flex align-items-center gap-1 justify-content-end">
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                      >
                        <img src={Images.plusIconWithBg} alt="icon" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                        <Dropdown.Item href="#/action-1">
                          <div className="productAvailabilityChild align-items-center">
                            <div className="form-group">
                              <input type="checkbox" id="id2" />
                              <label htmlFor="id2" className="me-0"></label>
                            </div>
                            <h3 className="fontSize14 mb-0 mt-1">Category</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          <div className="productAvailabilityChild align-items-center">
                            <div className="form-group">
                              <input type="checkbox" id="check2" />
                              <label htmlFor="check3" className="me-0"></label>
                            </div>
                            <h3 className="fontSize14 mb-0 mt-1">
                              Sub-category
                            </h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          <div className="productAvailabilityChild align-items-center">
                            <div className="form-group">
                              <input type="checkbox" id="check2" />
                              <label htmlFor="check3" className="me-0"></label>
                            </div>
                            <h3 className="fontSize14 mb-0 mt-1">Cost Price</h3>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="variantsTable_Dropdown"
                      >
                        <img src={Images.moreHorizontalLogo} alt="logo" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                        <Dropdown.Item href="#/action-1">
                          <h3 className="fontSize16 fw500 txtDarkblue mb-0">
                            Bulk Editing
                          </h3>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-1"
                          onClick={() => handleOpenModal("editLocationModal")}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.locationLogo} alt="logo" />
                            <h3 className="fontSize14 mb-0">Edit Location</h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-2"
                          onClick={() => handleOpenModal("editSalePriceModal")}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.moneyPaperIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0">
                              Edit Sales Pricing
                            </h3>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-3"
                          onClick={() => {
                            setModalDetail({
                              show: true,
                              flag: "deleteProductModal",
                            });
                            setKey(Math.random());
                          }}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <img src={Images.newDeleteIcon} alt="logo" />
                            <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                              Delete All
                            </h3>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="productAvailabilityChild align-items-center">
                  <div className="form-group">
                    <input type="checkbox" id="id3" />
                    <label htmlFor="id3" className="me-0"></label>
                  </div>

                  <div className="d-flex align-items-center gap-3">
                    <img
                      src={Images.productItemImg}
                      alt="img"
                      className="productItemImg"
                    />
                    <div>
                      <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1">
                        Lightweight Stylish Casual Daypack
                      </h3>

                      <div className="d-flex align-items-center gap-2">
                        <span className="skyblueDot"></span>
                        <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                          SKU 0199 - 3221
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="fontSize12 txtLightBlue">1 Jul - 30 Jul, 2024</td>
              <td className="fontSize12 txtLightBlue">Amount</td>
              <td className="fontSize12 txtLightBlue">$64.00</td>
              <td>
                <h6 className="marketPrice fontSize12 ">$90.00</h6>
                <h6 className="discountPrice fontSize12 mb-0">$90.00</h6>
              </td>
              <td>
                <h6 className="marketPrice fontSize12 ">$90.00</h6>
                <h6 className="discountPrice fontSize12 mb-0">$90.00</h6>
              </td>
              <td className="text-end">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="variantsTable_Dropdown"
                  >
                    <img src={Images.moreVerticalLogo} alt="logo" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                    <Dropdown.Item
                      href="#/action-1"
                      onClick={() => history.push("/productOfferView")}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.darkBlueEyeIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">View</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#/action-2"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "editOfferModal",
                        });
                        setKey(Math.random());
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.darkblueEditIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">Edit</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#/action-3"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "pauseOfferModal",
                        });
                        setKey(Math.random());
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.pauseIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">Pause</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#/action-3"
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          flag: "stopOfferModal",
                        });
                        setKey(Math.random());
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.stopIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0 productDropDeleteTxt">
                          Stop
                        </h3>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>
                <div className="productAvailabilityChild align-items-center">
                  <div className="form-group">
                    <input type="checkbox" id="check1" />
                    <label htmlFor="check2" className="me-0"></label>
                  </div>

                  <div className="d-flex align-items-center gap-3">
                    <img
                      src={Images.productItemImg}
                      alt="img"
                      className="productItemImg"
                    />
                    <div>
                      <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1">
                        Lightweight Stylish Casual Daypack
                      </h3>

                      <div className="d-flex align-items-center gap-2">
                        <span className="skyblueDot"></span>
                        <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                          SKU 0199 - 3221
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="fontSize12 txtLightBlue">1 Jul - 30 Jul, 2024</td>
              <td className="fontSize12 txtLightBlue">Amount</td>
              <td className="fontSize12 txtLightBlue">
                <h6 className="marketPrice fontSize12 ">$90.00</h6>
                <h6 className="discountPrice fontSize12 mb-0">$90.00</h6>
              </td>
              <td>
                <h6 className="marketPrice fontSize12 ">$90.00</h6>
                <h6 className="discountPrice fontSize12 mb-0">$90.00</h6>
              </td>
              <td>
                <h6 className="marketPrice fontSize12 ">$90.00</h6>
                <h6 className="discountPrice fontSize12 mb-0">$90.00</h6>
              </td>
              <td className="text-end">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="variantsTable_Dropdown"
                  >
                    <img src={Images.moreVerticalLogo} alt="logo" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                    <Dropdown.Item href="#/action-1">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.darkBlueEyeIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">View</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.darkblueEditIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">Edit</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.pauseIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">Pause</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.stopIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">Stop</h3>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td>
                <div className="productAvailabilityChild align-items-center">
                  <div className="form-group">
                    <input type="checkbox" id="check1" />
                    <label htmlFor="check2" className="me-0"></label>
                  </div>

                  <div className="d-flex align-items-center gap-3">
                    <img
                      src={Images.productItemImg}
                      alt="img"
                      className="productItemImg"
                    />
                    <div>
                      <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1">
                        Lightweight Stylish Casual Daypack
                      </h3>

                      <div className="d-flex align-items-center gap-2">
                        <span className="skyblueDot"></span>
                        <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                          SKU 0199 - 3221
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="fontSize12 txtLightBlue">1 Jul - 30 Jul, 2024</td>
              <td className="fontSize12 txtLightBlue">Amount</td>
              <td className="fontSize12 txtLightBlue">$64.00</td>
              <td>
                <h6 className="marketPrice fontSize12 ">$90.00</h6>
                <h6 className="discountPrice fontSize12 mb-0">$90.00</h6>
              </td>
              <td>
                <h6 className="marketPrice fontSize12 ">$90.00</h6>
                <h6 className="discountPrice fontSize12 mb-0">$90.00</h6>
              </td>
              <td className="text-end">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="variantsTable_Dropdown"
                  >
                    <img src={Images.moreVerticalLogo} alt="logo" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                    <Dropdown.Item href="#/action-1">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.darkBlueEyeIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">View</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.darkblueEditIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">Edit</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.pauseIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">Pause</h3>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <div className="d-flex gap-2 align-items-center">
                        <img src={Images.stopIcon} alt="logo" />
                        <h3 className="fontSize14 mb-0">Stop</h3>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "editOfferModal"
            ? "editVariantModal"
            : modalDetail.flag === "stopOfferModal"
            ? "stopOfferModal"
            : modalDetail.flag === "pauseOfferModal"
            ? "stopOfferModal"
            : modalDetail.flag === "deleteProductModal"
            ? "stopOfferModal"
            : modalDetail.flag === "deleteModal"
            ? "deleteModal"
            : modalDetail.flag === "editLocationModal"
            ? "editLocationModal"
            : modalDetail.flag === "editSalePriceModal"
            ? "editLocationModal"
            : ""
        }
        child={
          modalDetail.flag === "editOfferModal" ? (
            <EditOfferModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "stopOfferModal" ? (
            <StopOfferModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "pauseOfferModal" ? (
            <PauseOfferModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "deleteProductModal" ? (
            <DeleteProductModal
              close={() => handleOnCloseModal()}
              handleModal={handleOpenModal}
            />
          ) : modalDetail.flag === "deleteModal" ? (
            <DeleteModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "editLocationModal" ? (
            <EditLocationModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "editSalePriceModal" ? (
            <EditSalePriceModal close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "editOfferModal" ? (
              <>
                <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                  {" "}
                  Edit Product Offer
                </h2>
                <img
                  src={Images.modalCross}
                  alt="logo"
                  className="cursorPointer"
                  onClick={() => handleOnCloseModal()}
                />
              </>
            ) : modalDetail.flag === "editLocationModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h6 className="fontSize12 txtLightBlue">
                      2 items selected
                    </h6>
                    <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                      Update visibility
                    </h3>
                  </div>
                  <img
                    src={Images.modalCross}
                    alt="logo"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            ) : modalDetail.flag === "editSalePriceModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h6 className="fontSize12 txtLightBlue">
                      2 items selected
                    </h6>
                    <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                      Update sale price
                    </h3>
                  </div>
                  <img
                    src={Images.modalCross}
                    alt="logo"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default ProductOffer;
