import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { brandLogo, shops, stepHalf } from "../../../../utilities/images";
import OTPInput from "react-otp-input";
import { resendOtpApiAsync, verifyPhoneOtpApiAsync } from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import * as Images from "../../../../utilities/images.js";
import OtpTimer from "otp-timer";

const VerifyOtp = () => {
  const history = useHistory();
  const pathName = window.location.pathname;
  const [key] = useState(Math.random());
  const phoneData = localStorage.getItem("phone_details")
    ? JSON.parse(localStorage.getItem("phone_details"))
    : "";
  const [otp, setOtp] = useState("");
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const [otpLoading, setOtpLoading] = useState(false);
  const [buttonActive, setButtonActive] = useState(false);
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const onComplete = (code) => {
    setOtp(code);
    if (code.length == 5) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }
  };

  const handleVerifyOtp = (e) => {
    // e.preventDefault()
    if (!otp || otp.length < 5) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your otp");
      }
      return false;
    }
    setOtpLoading(true);
    let params;
    if (phoneData?.user_id) {
      params = {
        type: "phone",
        verification_id: `${phoneData?.verification_id}`,
        otp: otp,
        user_id: `${phoneData?.user_id}`,
      };
    } else {
      params = {
        type: "phone",
        verification_id: `${phoneData?.verification_id}`,
        otp: otp,
      };
    }

    dispatch(verifyPhoneOtpApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
        history.push("/verifyEmail");
        localStorage.setItem("existing_user", JSON.stringify(obj?.payload));
        setOtpLoading(false);
      })
      .catch((obj) => {
        setOtpLoading(false);
      });
  };

  const resendOtp = () => {
    let params = {
      type: "phone",
      verification_id: `${phoneData?.verification_id}`,
    };
    dispatch(resendOtpApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
      })
      .catch((obj) => {});
  };
  console.log(otp, "otppppppppppppppppppp");
  useEffect(() => {
    if (otp && otp?.length == 5) {
      handleVerifyOtp();
    }
  }, [otp]);

  return (
    <div className="commonLogin">
      <div className="loginForm verifyOtpForm">
        <form onSubmit={(e) => handleVerifyOtp(e)}>
          <div className="authFormHead">
            <h2 className="fontSize30 txtDarkblue mb-0">
              Let’s verify your phone <br /> number.
            </h2>
            <h5 className="fontSize18 txtLightBlue mb-0 pt-3">
              Enter the code we’ve sent to +1 (438) 000-0000
            </h5>
          </div>

          <div className="verify-part">
            <div className="verify-box text-center">
              <div className="pin-box d-flex justify-content-center">
                <OTPInput
                  numInputs={5}
                  className="input_digits_"
                  value={otp}
                  data-cy="pin-field"
                  name={generateRandomName()}
                  autoComplete="new-password"
                  isInputNum={true}
                  isInputSecure={true}
                  onChange={onComplete}
                  // onComplete={(code) => onComplete(code)}
                />
              </div>
            </div>
          </div>

          <div className="d-flex gap-5 justify-content-between align-items-center">
            <button
              className="forgetPass_BackBtn w-100 d-flex align-items-center justify-content-center"
              onClick={() => {
                history.push("/verify-Phone");
              }}
            >
              <img
                src={Images.lightLeftArrowLogo}
                alt="logo"
                className="me-2"
              />
              Back
            </button>
            <p className="resendOtpBtn w-100">
              <OtpTimer
                //minutes={1}
                seconds={60}
                key={key}
                //seconds={otpTimerPeriod}
                text="Resend OTP in"
                ButtonText="Resend code"
                resend={resendOtp}
              />
            </p>
            {/* <p>Didn’t receive Code? <Link to="#" onClick={() => resendOtp()}>Resend</Link></p> */}
            {/* <button className={`loginBtnSign w-100 ${buttonActive ? 'buttonActive': ''}`} disabled={otpLoading || !buttonActive} type="submit">
                                            {otpLoading === true && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            &nbsp;&nbsp;
                                            <span>Verified</span>
                                        </button> */}
            {/* <p className="mt-4">
              Not the correct number? <Link to="/verify-Phone">Change it</Link>
            </p> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtp;
