import React, { useState } from 'react'
import SelectMenu from '../../../components/UI/Orders/SelectMenu'
import Loader from '../../../components/UI/Loader/Loader'
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { getUserSettingsApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { useEffect } from 'react';
import ReactSelect from "react-select";
import * as Images from "../../../../utilities/images";

const GeneralSetting = () => {
    const dispatch = useDispatch();
    const auth = useSelector(selectLoginAuth);
   const [userSettings, setUserSettings] = useState(null);
    const [loading, setLoading] = useState(false);
    const getUserSettings=async()=>{
        let prams={
            postData: {
                app_name:"merchant",
                seller_id:"0abd886e-d6c4-4fe0-8be2-c928f41530ca",
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(getUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setUserSettings(res?.payload);
        setLoading(false);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    useEffect(()=>{
        getUserSettings();
    },[])
    const updateUserSettings=async(data)=>{
        let prams={
            postData: {
                ...data
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(updateUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        // setUserSettings(res.data);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    const handleUserSetting=(key,value)=>{
        setUserSettings(prev=>({
            ...prev,[key]:value
        }));
        updateUserSettings({[key]:value});
    }
    return <>
    {loading&&
                <Loader/>
               }
        <div className='generalSetting_'>
            <div className="processignOrder featureseting">
                <div className="ordrsettingHead_">
                    <h3 className="normalHeading_ mb-1">Booking Types</h3>
                    <p className="textInnerHead2">Use these settings to define plugin general settings and default settings for your services and appointments</p>
                </div>
                <div className="row mt-4 ps-5">
                    <div className="col-12">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" checked={userSettings?.enable_online_booking} onChange={(event)=>handleUserSetting("enable_online_booking", event.target.checked)} />
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">Enable online booking</h3>
                                <p class="textInnerHead2">Allow customer shipping orders</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" checked={userSettings?.enable_onsite_booking} onChange={(event)=>handleUserSetting("enable_onsite_booking", event.target.checked)}/>
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">Enable on-site booking</h3>
                                <p class="textInnerHead2">Allow customer shipping orders</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="processignOrder featureseting mt-4">
                <div className="ordrsettingHead_">
                    <h3 className="normalHeading_ mb-1">Booking Acceptance</h3>
                    <p className="textInnerHead2">Use these settings to define each incoming booking</p>
                </div>
                <div className="row mt-4 ps-5">
                    <div className="col-12">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" checked={userSettings?.online_booking_acceptance} onChange={(event)=>handleUserSetting("online_booking_acceptance", event.target.checked)}/>
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">Enable Automatic for online booking </h3>
                                <p class="textInnerHead2">Bookings are automatically accepted upon submission.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" checked={userSettings?.onsite_booking_acceptance} onChange={(event)=>handleUserSetting("onsite_booking_acceptance", event.target.checked)}/>
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">Enable Automatic for on-site booking</h3>
                                <p class="textInnerHead2">Admin/staff must manually accept each incoming booking.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="processignOrder featureseting mt-4">
                <div className="ordrsettingHead_">
                    <h3 className="normalHeading_ mb-1">Calendar Display and Time Zone</h3>
                    <p className="textInnerHead2">Use these settings to define each incoming booking</p>
                </div>
                <div className="row mt-4 ps-5">
                    <div className="col-12">
                        <div className="calandermain_view ">
                            <h3 className="fontSize14 txtDarkblue m-0 mb-3">Choose a calendar default view</h3>
                            <div className='calenderViewchoose_'>
    <div className="calenderViewradio">
        <input 
            type="radio" 
            id="day" 
            name="calendarView" 
            value="day" 
            checked={userSettings?.calender_view === "day"} 
            onChange={(event) => handleUserSetting("calender_view", event.target.value)} 
        />
        <label htmlFor="day">Day View</label>
    </div>
    <div className="calenderViewradio">
        <input 
            type="radio" 
            id="week" 
            name="calendarView" 
            value="week" 
            checked={userSettings?.calender_view === "week"} 
            onChange={(event) => handleUserSetting("calender_view", event.target.value)} 
        />
        <label htmlFor="week">Week View</label>
    </div>
    <div className="calenderViewradio">
        <input 
            type="radio" 
            id="month" 
            name="calendarView" 
            value="month" 
            checked={userSettings?.calender_view === "month"} 
            onChange={(event) => handleUserSetting("calender_view", event.target.value)} 
        />
        <label htmlFor="month">Month View</label>
    </div>
</div>


                        </div>
                    </div>
                    <div className="col-12 mt-3">
                    <div className="ordrsettingHead_">
                    <p className="textInnerHead2">Choose timezone</p>
                </div>
                        <div className="serviceSettingBoxes  align-items-start  gap-3">
                            <SelectMenu
                            width="100%"
options={[{value:"pst",label:"PST"}]}
placeholder={"PST"}
onChange={(event) => {  }}
                            />
                        </div>
                        {/* 
                    <div className='col-12'>
                    <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    Category
                  </label>
                  <ReactSelect
                    // value={selectedCategory}
                    // options={category}
                    // isDisabled={!servicesId}
                    // isSearchable={true}
                    placeholder=                      
                         "Select one"
                    
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                </div>
                    </div>
                        */}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default GeneralSetting