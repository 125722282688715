import React, { useCallback, useState } from "react";
import * as Images from "../../../utilities/images";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Select from "react-select";

const AddServiceVariationModal = (props) => {
  const [activeTab, setActiveTab] = useState("details");
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [differentSale, setDifferentSale] = useState(false);
  const [fileError, setFileError] = useState("");
  const [activePos, setActivePos] = useState(false);
  const handleToggleTab = (plan) => {
    setActiveTab(plan);
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles, "acceptdedd");
    const uploadedFile = acceptedFiles?.[0];
    const validTypes = ["image/jpeg", "image/png"];
    const maxSize = 5 * 1024 * 1024;

    if (!uploadedFile) {
      setFileError("Please upload a file.");
      toast.error("No file uploaded.");
      return;
    }

    if (!validTypes.some((type) => uploadedFile.type === type)) {
      setFileError("Invalid file type.");
      toast.error("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }

    if (uploadedFile.size > maxSize) {
      setFileError("File size exceeds the 5MB limit.");
      toast.error("File size exceeds the 5MB limit.");
      return;
    }

    setFileError("");
    setFile(uploadedFile);

    const filePreview = URL.createObjectURL(uploadedFile);
    setPreview(filePreview);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
  });

  const handleSaleToggle = (type) => (event) => {
    const isChecked = event.target.checked;
    if (type === "blockTime") {
      setDifferentSale(isChecked);
    }
  };

  return (
    <>
      <ul className="planTabs_ editVariantTab serviceVariationTab m-0">
        <li
          className={`planttabslist ${activeTab === "details" ? "active" : ""}`}
          onClick={() => handleToggleTab("details")}
        >
          Details
        </li>
        <li
          className={`planttabslist ${activeTab === "booking" ? "active" : ""}`}
          onClick={() => handleToggleTab("booking")}
        >
          Online booking
        </li>
        <li
          className={`planttabslist ${
            activeTab === "location" ? "active" : ""
          }`}
          onClick={() => handleToggleTab("location")}
        >
          Locations
        </li>
        <li
          className={`planttabslist ${
            activeTab === "attributes" ? "active" : ""
          }`}
          onClick={() => handleToggleTab("attributes")}
        >
          Custom Attributes
        </li>
      </ul>

      {activeTab == "details" && (
        <>
          <div className="serviceLocationContainer">
            <div className="form_group mt-3">
              <label className="fontSize14 fw500 txtDarkblue mb-2 ps-4">
                Variation name
              </label>
              <div className="inputGroup">
                <input
                  className="customInput editVariantInput"
                  placeholder="Placeholder"
                  name="text"
                  type="text"
                />
              </div>
            </div>

            <div className="editVariant_SecondBox">
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Unit</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <select name="cars" id="cars" className="uptBoxSelect">
                        <option value="">Per Service</option>
                        <option value="saab">Per Item</option>
                        <option value="mercedes">Per Item</option>
                        <option value="audi">Per Item</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">POS Price</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input"
                          placeholder="$0"
                          name="text"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Marketplace Price</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input"
                          placeholder="$0"
                          name="text"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0 d-flex align-items-center">
                        Cancellation fee
                        <img
                          src={Images.infoIcon}
                          alt="logo"
                          className="ms-2"
                        />
                      </h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input"
                          placeholder="0"
                          name="text"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ProductCategory">
              <h2 className="fontSize18 fw500 txtDarkblue mb-0 d-flex align-items-cetner">
                Service Estimated Time
                <img src={Images.infoIcon} alt="logo" className="ms-2" />
              </h2>
              <div className="row">
                <div className="col-md-6">
                  <div className="form_group">
                    <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                      Hour
                    </label>
                    <Select placeholder="0" className="customSelect" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form_group">
                    <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                      Minute
                    </label>
                    <Select className="customSelect" placeholder="0" />
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="productSale_Parent serviceSale_Parent  mt-0 justify-content-between align-items-start">
                <div>
                  <h3 className="fontSize16 fw500 txtDarkblue mb-3">
                    Block extra time after & before appointment
                  </h3>
                  <h3 className="fontSize14 txtLightBlue fw500 mb-0">
                    Avoid rushing between appointments by blocking off time for
                    cleanup.
                  </h3>
                </div>
                <input
                  type="checkbox"
                  className="cursorPointer"
                  checked={differentSale}
                  onChange={handleSaleToggle("blockTime")}
                />
              </div>

              {differentSale && (
                <>
                  <form className="ProductCategory mt-3">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0 d-flex align-items-center">
                      Buffer Time After
                      <img src={Images.infoIcon} alt="logo" className="ms-2" />
                    </h2>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form_group">
                          <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                            Hour
                          </label>
                          <Select placeholder="0" className="customSelect" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form_group">
                          <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                            Minute
                          </label>
                          <Select placeholder="0" className="customSelect" />
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <h2 className="fontSize18 fw500 txtDarkblue mb-0 d-flex align-items-center">
                        Buffer Time Before
                        <img
                          src={Images.infoIcon}
                          alt="logo"
                          className="ms-2"
                        />
                      </h2>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                              Hour
                            </label>
                            <Select placeholder="0" className="customSelect" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                              Minute
                            </label>
                            <Select placeholder="0" className="customSelect" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>

            <div className="showToken_Img">
              <div {...getRootProps({ className: "" })}>
                <input name="image" type="file" {...getInputProps()} />
                <div className="uploadImg_Parent">
                  {file ? (
                    <div className="showImg_Parent">
                      <img
                        src={preview}
                        alt="Preview"
                        className="detailsImg img-fluid h-100"
                      />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.CameraLogo}
                        alt="Camera"
                        style={{ cursor: "pointer" }}
                      />
                      <p className="dragAndDrop_Txt ms-2">
                        Click to browse or <br /> drag and drop your files
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {fileError && <p className="error-message">{fileError}</p>}
            </div>

            <div className="d-flex align-items-center justify-content-between mt-2">
              <p className="supportImgTxt">Support formats: png, Jpg, heic </p>
              <p className="supportImgTxt">Maximum image size: 1.5 MB</p>
            </div>

            <div className="d-flex align-items-center editVariantModal_Footer">
              <button
                className="modalDiscardBtn w-100"
                onClick={() => {
                  props?.close();
                }}
              >
                Discard
              </button>
              <button className="modalNextBtn modalDiscardBtn active w-100">
                Save
              </button>
            </div>
          </div>
        </>
      )}

      {activeTab == "booking" && (
        <div className="serviceBookingContainer">
          <div className="productDetailsContainer">
            <div className="productSale_Parent mt-0 align-items-center">
              <input type="checkbox" />
              <div className="ms-3">
                <h3 className="fontSize16 txtDarkblue">
                  Bookable by customers online
                </h3>
                <h3 className="turnOnShowTxt fw500 mb-0">
                  Allow customers to book this service on JOBR Marketplace app.
                </h3>
              </div>
            </div>
          </div>

          <div className="modalOptionBox deleteVariation_ModalBox">
            <div className="position-relative">
              <input
                type="search"
                className="teamsearch"
                name="teamsearch"
                placeholder="Search team member"
              />
              <img
                src={Images.SearchIcon}
                alt="logo"
                className="teamsearch_Searchicon"
              />
            </div>
            <div className="productAvailabilityChild mt-4 allteamBox_Parent align-items-center">
              <div class="form-group">
                <input type="checkbox" id="store1" checked="store1" />
                <label htmlFor="store1"></label>
              </div>
              <p className="fontSize16 fw500 txtDarkblue mb-0">
              Assigned team members
              </p>
            </div>
            <div className="deleteVariation_innerBox">
              <div className="productAvailabilityChild align-items-center">
                <div className="form-group mb-1">
                  <input type="checkbox" id="store2" checked="store2" />
                  <label htmlFor="store1"></label>
                </div>
                <div className="assignedTeamModalBox">
                  <img
                    src={Images.teamMemberImg}
                    alt="logo"
                    className="teamMemberImg"
                  />
                  <div>
                    <h3 className="fontSize12 mb-0 pb-1">Hasan Al Mamun</h3>
                    <p className="fontSize12 txtLightBlue mb-0">
                      Haircut specialist
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="deleteVariation_innerBox">
              <div className="productAvailabilityChild align-items-center">
                <div className="form-group mb-1">
                  <input type="checkbox" id="store2" checked="store2" />
                  <label htmlFor="store1"></label>
                </div>
                <div className="assignedTeamModalBox">
                  <img
                    src={Images.teamMemberImg}
                    alt="logo"
                    className="teamMemberImg"
                  />
                  <div>
                    <h3 className="fontSize12 mb-0 pb-1">Hasan Al Mamun</h3>
                    <p className="fontSize12 txtLightBlue mb-0">
                      Haircut specialist
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="deleteVariation_innerBox">
              <div className="productAvailabilityChild align-items-center">
                <div className="form-group mb-1">
                  <input type="checkbox" id="store2" checked="store2" />
                  <label htmlFor="store1"></label>
                </div>
                <div className="assignedTeamModalBox">
                  <img
                    src={Images.teamMemberImg}
                    alt="logo"
                    className="teamMemberImg"
                  />
                  <div>
                    <h3 className="fontSize12 mb-0 pb-1">Hasan Al Mamun</h3>
                    <p className="fontSize12 txtLightBlue mb-0">
                      Haircut specialist
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="deleteVariation_innerBox">
              <div className="productAvailabilityChild align-items-center">
                <div className="form-group mb-1">
                  <input type="checkbox" id="store2" checked="store2" />
                  <label htmlFor="store1"></label>
                </div>
                <div className="assignedTeamModalBox">
                  <img
                    src={Images.teamMemberImg}
                    alt="logo"
                    className="teamMemberImg"
                  />
                  <div>
                    <h3 className="fontSize12 mb-0 pb-1">Hasan Al Mamun</h3>
                    <p className="fontSize12 txtLightBlue mb-0">
                      Haircut specialist
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="deleteVariation_innerBox">
              <div className="productAvailabilityChild align-items-center">
                <div className="form-group mb-1">
                  <input type="checkbox" id="store2" checked="store2" />
                  <label htmlFor="store1"></label>
                </div>
                <div className="assignedTeamModalBox">
                  <img
                    src={Images.teamMemberImg}
                    alt="logo"
                    className="teamMemberImg"
                  />
                  <div>
                    <h3 className="fontSize12 mb-0 pb-1">Hasan Al Mamun</h3>
                    <p className="fontSize12 txtLightBlue mb-0">
                      Haircut specialist
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center editVariantModal_Footer">
            <button
              className="modalDiscardBtn w-100"
              onClick={() => {
                props?.close();
              }}
            >
              Discard
            </button>
            <button className="modalNextBtn modalDiscardBtn active w-100">
              Save
            </button>
          </div>
        </div>
      )}

      {activeTab == "location" && (
        <div className="serviceLocationContainer">
          <div className="outerArea EditVariation_ModalBox mb-0">
            <h3 className="fontSize18 fw500 txtDarkblue mb-4">
              Product availability
            </h3>

            <div className="productAvail_Second mt-0">
              <div className="d-flex align-items-center">
                <img
                  src={Images.physicalStoreIcon}
                  alt="logo"
                  className="physicalStoreIcon me-2"
                />
                <div className="innnerParent align-items-center">
                  <div className="productSale_Parent p-0 m-0">
                    <input
                      type="checkbox"
                      onChange={() => setActivePos(!activePos)}
                    />
                  </div>
                  <div>
                    <h3 className="fontSize14 fw500 txtDarkblue">
                      Physical stores
                    </h3>
                    <h3 className="fontSize12 txtLightBlue mb-0">JOBR POS</h3>
                  </div>
                </div>
              </div>
              <ul className="productAvailabilityList">
                <li className="productAvailabilityChild mt-4 align-items-baseline">
                  <div class="form-group">
                    <input type="checkbox" id="check5" />
                    <label for="check5"></label>
                  </div>
                  <div>
                    <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                      Store 1
                    </h3>
                    <p className="fontSize12 txtLightBlue mb-0">
                      88070 Torey Gateway Colorado Springs, CO 80924
                    </p>
                  </div>
                </li>
                <li className="productAvailabilityChild align-items-baseline mt-3">
                  <div class="form-group">
                    <input type="checkbox" id="check4" />
                    <label for="check4"></label>
                  </div>
                  <div>
                    <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                      Store 2
                    </h3>
                    <p className="fontSize12 txtLightBlue mb-0">
                      88070 Torey Gateway Colorado Springs, CO 80924
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex align-items-center editVariantModal_Footer">
            <button
              className="modalDiscardBtn w-100"
              onClick={() => {
                props?.close();
              }}
            >
              Discard
            </button>
            <button className="modalNextBtn modalDiscardBtn active w-100">
              Save
            </button>
          </div>
        </div>
      )}

      {activeTab == "attributes" && (
        <>
          <div className="attributesTab_Parent">
            <div className="editVariant_SecondBox">
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Custom Attribute</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input p-0"
                          placeholder="Attribute name"
                          name="text"
                          type="text"
                          value="Use"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upcBoxParent">
                <div className="row">
                  <div className="col-4">
                    <div className="upcBox">
                      <h3 className="fontSize18 mb-0">Value</h3>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="upcBox upcRightBox">
                      <div className="inputGroup">
                        <input
                          className="upcBox_Input p-0"
                          name="text"
                          type="text"
                          placeholder="Value"
                          value="Careful"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button className="addOptionSetBtn w-100">
                <i className="fa-regular fa-plus addOptionPlusIcon me-2"></i>
                Add New Attribute
              </button>
            </div>
          </div>

          <div className="d-flex align-items-center editVariantModal_Footer">
            <button
              className="modalDiscardBtn w-100"
              onClick={() => {
                props?.close();
              }}
            >
              Discard
            </button>
            <button className="modalNextBtn modalDiscardBtn active w-100">
              Save
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AddServiceVariationModal;
