import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { modalCancel } from "../../../utilities/images";
import ProductDetails from "./ProductDetails";
import ProductImageAdd from "./productImageAdd";
import AddProductHeader from "./AddProductHeader";
import AddOptionsModal from "./addOptionsModal";
import CustomModal from "../../components/shared/CustomModal";
import AddAttributesModal from "./addAttributesModal";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageUploadAsync } from "../message/messageSlice";
import { toast } from "react-toastify";
import { formValidator } from "./formValidator";
import { createProductApiAsync } from "../dashboard/dashboardSlice";
import SaleTaxModal from "./saleTaxModal";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CreateOptionModal from "./createOptionModal";
import * as Images from "../../../utilities/images";
import CreateVariationsModal from "./createVariationsModal";
import DeleteVariationModal from "./DeleteVariationModal";
import { Dropdown } from "react-bootstrap";
import EditVariantModal from "./EditVariantModal";
import BulkVarientionModal from "./bulkVarientionModal";
import ReactSelect from "react-select";
import ChartImageUploader from "../service/chartImageUploader";

const AddProduct = (props) => {
  const history = useHistory();
  const toastId = useRef(null);
  const backRoute = localStorage.getItem("backButtonRoute");
  const [productDetails, setProductDetails] = useState({});
  const [serviceId, setServiceId] = useState("");
  const [selectedService, setSelectedService] = useState(null);
  const [costPrice, setcostPrice] = useState("");
  const { flag } = useParams();
  const [productDescription, setProductDescription] = useState("");
  const [imageDetails, setImageDetails] = useState({});
  const [activePos, setActivePos] = useState(false);
  const [activeBtoc, setActiveBtoc] = useState(false);
  const [activeBtob, setActiveBtob] = useState(false);
  const [inventoryReorderPoint, setInventoryReorderPoint] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const [allAddressIds, setAllAddressIds] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [chartImage, setChartImage] = useState("");
  const [dimensions, setDimensions] = useState({
    weight: "",
    length: "",
    breadth: "",
    height: "",
  });
  const [type] = useState("physical");
  const [unitType, setUnitType] = useState("per_unit");
  const [barCode, setBarCode] = useState("");
  const [upc, setUpc] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const dispatch = useDispatch();
  const [loading1, setLoading1] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);

  const [isTax, setIsTax] = useState(false);
  const [saleSame, setSaleSame] = useState(false);
  const [differentSale, setDifferentSale] = useState(false);
  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  console.log(
    { sellerAddressIds },
    "this is seller address id here ->>>>>>>>>>>>>>>>>"
  );
  console.log({ auth });
  const [prices, setPrices] = useState([
    {
      app_name: "pos",
      price_type: "fixed",
      selling_price: "",
      margin_percentage: "",
    },
    {
      app_name: "b2c",
      price_type: "fixed",
      selling_price: "",
      margin_percentage: "",
    },
  ]);

  const [priceJobrPos, setPriceJobrPos] = useState({
    app_name: "pos",
    price_type: "fixed",
    selling_price: "",
    margin_percentage: "",
  });
  const [priceJobrBtoc, setPriceJobrBtoc] = useState({
    app_name: "b2c",
    price_type: "fixed",
    selling_price: "",
    margin_percentage: "",
  });
  const [posPrice, setPosPrice] = useState(null);
  const [marketplacePrice, setMarketplacePrice] = useState(null);
  const [posProfit, setPosProfit] = useState(null);
  const [posMargin, setPosMargin] = useState(null);
  const [marketplaceProfit, setMarketplaceProfit] = useState(null);
  const [marketplaceMargin, setMarketplaceMargin] = useState(null);

  const [rowData, setRowData] = useState([
    {
      id: 1,
      activePlan: "list",
      displayName: "",
      productTags: [],
    },
  ]);

  const [tempRowData, setTempRowData] = useState([
    {
      id: 1,
      activePlan: "list",
      displayName: "",
      productTags: [],
    },
  ]);
  const [attributes, setAttributes] = useState([]);
  const [selectedCombinations, setSelectedCombinations] = useState([]); // State to store selected combinations
  const [newCombinations, setNewCombinations] = useState([]);
  const [attributeShow, setAttributeShow] = useState(false);
  console.log(rowData, "rowData");

  const [variants, setVariants] = useState([]);

  console.log(variants, "variants");

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleInputChange = (idx, field, value) => {
    const updatedVariants = [...variants];

    // Helper to ensure price object exists
    const ensurePriceObject = (variant, appName) => {
      if (!variant.prices) variant.prices = [];
      const existingPrice = variant.prices.find((p) => p.app_name === appName);
      if (!existingPrice) {
        variant.prices.push({
          app_name: appName,
          selling_price: "",
          margin_percentage: "",
        });
      }
    };

    // Helper to calculate margin
    const calculateMargin = (sellingPrice, costPrice) => {
      if (costPrice > 0) {
        return (((sellingPrice - costPrice) / costPrice) * 100).toFixed(2);
      }
      return "0.00";
    };

    if (field === "pos_price" || field === "marketplace_price") {
      const appName = field === "pos_price" ? "pos" : "b2c";

      // Ensure the price object exists for the given appName
      ensurePriceObject(updatedVariants[idx], appName);

      // Find and update the price object
      updatedVariants[idx].prices = updatedVariants[idx].prices.map((price) => {
        if (price.app_name === appName) {
          const costPrice = parseFloat(updatedVariants[idx].cost_price || 0);
          const sellingPrice = parseFloat(value);

          return {
            ...price,
            selling_price: value,
            margin_percentage: calculateMargin(sellingPrice, costPrice),
          };
        }
        return price;
      });
    } else {
      // Update other top-level fields
      updatedVariants[idx][field] = value;
    }

    setVariants(updatedVariants);
  };

  const handleRowsChange = (rows) => {
    // setRowData(rows);
    setTempRowData(rows);
    handleAttributesChange(rows);
  };

  const handleCombination = (rows) => {
    // setSelectedCombinations(rows);
    setNewCombinations(rows);
  };

  // Handle the attribute update from the child
  const handleAttributesChange = (rows) => {
    // Map the rows to the required attributes format
    const updatedAttributes = rows.map((row) => ({
      name: row.displayName,
      values: row.productTags,
      is_group_by: row.productTags.length > 1, // Example logic: group by if more than 1 product tag
    }));

    // Update the parent attributes state
    setAttributes(updatedAttributes);
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleUnitChange = (e) => {
    setUnitType(e.target.value);
  };

  // handle detail data
  const handleProductDetails = (e, flag) => {
    if (flag == "description") {
      setProductDescription(e);
    } else if (flag == "dimensions") {
      setDimensions(e);
    } else if (flag == "barCode") {
      setBarCode(e);
    } else if (flag == "upc") {
      setUpc(e);
    } else {
      setProductDetails(e);
    }
  };

  // toggle
  const handletaxToggle = (event) => {
    const isChecked = event.target.checked; // Get the checkbox state
    setIsTax(isChecked); // Update local state
  };

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        // Add store ID if checked
        return [...prevIds, storeId];
      } else {
        // Remove store ID if unchecked
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  const handleDeliveryOptionToggle = (option) => (event) => {
    const isChecked = event.target.checked;

    setDeliveryOptions((prevOptions) => {
      if (isChecked) {
        // Add option if checked
        return [...prevOptions, option];
      } else {
        // Remove option if unchecked
        return prevOptions.filter((opt) => opt !== option);
      }
    });
  };

  //price

  // Handle changes for POS and Marketplace Sale Prices
  const handlePriceChange = (e, platform) => {
    const newPrice = parseFloat(e.target.value) || null;
    if (newPrice === null || newPrice < 0 || newPrice === "" || !newPrice) {
      setPosProfit("");
      setPosMargin("");
      setMarketplaceProfit("");
      setMarketplaceMargin("");
      setPosPrice("");
      setMarketplacePrice("");
      setPrices([
        {
          app_name: "pos",
          price_type: "fixed",
          selling_price: "",
          margin_percentage: "",
        },
        {
          app_name: "b2c",
          price_type: "fixed",
          selling_price: "",
          margin_percentage: "",
        },
      ]);
      return;
    }
    if (platform === "pos") {
      setPosPrice(newPrice);
      setPriceJobrPos({
        ...priceJobrPos,
        selling_price: newPrice,
      });
    } else if (platform === "marketplace") {
      setMarketplacePrice(newPrice);
      setPriceJobrBtoc({
        ...priceJobrBtoc,
        selling_price: newPrice,
      });
    }

    if (costPrice > 0 && newPrice > 0) {
      if (platform === "pos") {
        calculatePosProfitAndMargin(newPrice, costPrice);
        if (saleSame) {
          setMarketplacePrice(newPrice);
          calculateMarketplaceProfitAndMargin(newPrice, costPrice);
        }
      } else if (platform === "marketplace") {
        calculateMarketplaceProfitAndMargin(newPrice, costPrice);
      }
    } else {
      if (platform === "pos") {
        setPosProfit(null);
        setPosMargin(null);
      } else if (platform === "marketplace") {
        setMarketplaceProfit(null);
        setMarketplaceMargin(null);
      }
    }

    if (platform === "pos") {
      updatePriceArray("pos", newPrice);
      if (saleSame) {
        updatePriceArray("b2c", newPrice);
      }
    } else {
      updatePriceArray("b2c", newPrice);
    }
  };

  const handleChangeCostPrice = (e) => {
    const newCostPrice = parseFloat(e.target.value); // Ensure valid number
    setcostPrice(newCostPrice);
  };

  const calculatePosProfitAndMargin = (price, costPrice) => {
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      const margin = (profit / costPrice) * 100; // Formula based on cost price
      setPosProfit(profit.toFixed(2)); // Update profit
      setPosMargin(margin.toFixed(2)); // Update margin
    } else {
      setPosProfit(null); // Reset profit if invalid
      setPosMargin(null); // Reset margin if invalid
    }
  };

  const calculateMarketplaceProfitAndMargin = (price, costPrice) => {
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      const margin = (profit / costPrice) * 100; // Formula based on cost price
      setMarketplaceProfit(profit.toFixed(2)); // Update profit
      setMarketplaceMargin(margin.toFixed(2)); // Update margin
    } else {
      setMarketplaceProfit(null); // Reset profit if invalid
      setMarketplaceMargin(null); // Reset margin if invalid
    }
  };

  const calculateMargin = (price) => {
    let margin = 0; // Default value for margin
    if (price > 0 && costPrice > 0) {
      const profit = price - costPrice;
      margin = (profit / costPrice) * 100; // Formula to calculate margin
    }
    return margin; // Return the calculated margin
  };

  const updatePriceArray = (platform, newPrice) => {
    if (newPrice == null) {
      return;
    }
    const margin = calculateMargin(newPrice);
    setPrices((prevPrices) => [
      ...prevPrices.filter((price) => price.app_name !== platform), // Remove old price for the platform
      {
        app_name: platform,
        price_type: "fixed",
        selling_price: newPrice.toString(),
        margin_percentage: margin.toFixed(2),
      },
    ]);
  };

  const handleImageDetails = (e) => {
    setImageDetails(e);
  };

  const handleAttributes = (e) => {
    setIngredients(e);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Add-Products";
  }, []);

  const handleSave = () => {
    // Remove empty keys from each variant
    const sanitizedVariants = variants.map((variant) => {
      return Object.entries(variant).reduce((acc, [key, value]) => {
        if (
          value !== null &&
          value !== undefined &&
          String(value).trim() !== ""
        ) {
          acc[key] = value;
        }
        return acc;
      }, {});
    });

    let product_attribute = {
      name: productDetails?.productName || "",
      description: productDescription || "",
      image: imageDetails?.image || "",
      images: imageDetails?.images || [],
      service_id: productDetails?.service_id || "",
      category_id: productDetails?.category_id?.toString() || "",
      sub_category_id: productDetails?.sub_category_id?.toString() || "",
      brand_id: productDetails?.brand_id
        ? productDetails.brand_id.toString()
        : "",
      is_adult: false, //static
      is_taxable: isTax,
      cost_price: costPrice || "",
      barcode: barCode.toString(),
      // upc: upc.toString(),
      is_returnable: true, //static
      // dimensions: dimensions,
      weight_unit: "lb", //static
      type: "physical",
      country_of_origin: "US",
      // attributes: attributes,
      quantity_unit: unitType,
      delivery_options: deliveryOptions,
      seller_address_ids: sellerAddressIds,
      variants: sanitizedVariants,
      size_chart_image:chartImage.toString()

    };
    // console.log(product_attribute, "product_attributeRRRR");
    if (deliveryOptions.length > 0 && deliveryOptions?.includes("shipping")) {
      product_attribute = { ...product_attribute, dimensions: dimensions };
    }
    if (attributes.length > 0) {
      product_attribute = { ...product_attribute, attributes: attributes };
    }
    if (inventoryReorderPoint) {
      product_attribute = {
        ...product_attribute,
        quantity_reorder_point: inventoryReorderPoint,
      };
    }
    if (ingredients?.length > 0) {
      product_attribute = { ...product_attribute, ingredients: ingredients };
    }
    if (optionsList?.length > 0) {
      product_attribute = { ...product_attribute, attributes: optionsList };
    }
    if (allAddressIds?.length > 0) {
      product_attribute = {
        ...product_attribute,
        seller_address_ids: allAddressIds,
      };
    }

    let result = formValidator(
      product_attribute,
      activePos,
      activeBtob,
      activeBtoc
    );
    if (result) {
      setSuccessLoader(true);
      let params = {
        postData: product_attribute,
        tokenData: auth?.payload?.token,
      };
      dispatch(createProductApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setSuccessLoader(false);
          toast.success(obj?.msg);
          history.push(`${backRoute}`);
        })
        .catch((obj) => {
          setSuccessLoader(false);
        });
    }
  };

  const handleModalChange = (flag) => {
    setModalDetail({ show: true, flag: flag });
    setKey(Math.random());
  };

  const getAllAddress = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    setLoading1(true);
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading1(false);
        const addresses = [
          ...new Set(obj?.payload?.map((entry) => entry?.format_address)),
        ];

        setAllAddress(obj?.payload);
      })
      .catch((obj) => {
        setLoading1(false);
      });
  };
  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleSaleToggle = (type) => (event) => {
    const isChecked = event.target.checked;

    if (type === "saleSame") {
      setSaleSame(isChecked);
      if (isChecked) {
        setDifferentSale(false); // Ensure only one can be selected at a time
      }
    } else if (type === "differentSale") {
      setDifferentSale(isChecked);
      if (isChecked) {
        setSaleSame(false); // Ensure only one can be selected at a time
      }
    }
    setPosPrice(null);
    setMarketplacePrice(null);
    setPosProfit(null);
    setMarketplacePrice(null);
    setPosMargin(null);
    setMarketplaceMargin(null);
    setPrices([
      {
        app_name: "pos",
        price_type: "fixed",
        selling_price: "",
        margin_percentage: "",
      },
      {
        app_name: "b2c",
        price_type: "fixed",
        selling_price: "",
        margin_percentage: "",
      },
    ]);
  };

  const handleOpenVariation = () => {
    let isValid = true;

    // Validate displayName is not empty
    if (
      tempRowData.some((row) => {
        if (!row.displayName.trim()) {
          showToast("Display name is required");
          return true; // Stop validation on first empty displayName
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    if (
      tempRowData.some((row, idx) => {
        const isDuplicate = tempRowData.some(
          (existingRow, i) =>
            existingRow.displayName === row.displayName && i !== idx
        );
        if (isDuplicate) {
          showToast("Display name must be unique");
          return true; // Stop validation on first duplicate
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    // Validate productTags are not empty
    if (
      tempRowData.some((row) => {
        if (row.productTags.length === 0) {
          showToast("Please add at least one option.");
          return true; // Stop validation on first empty productTags
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    if (!isValid) return;
    // setAttributeShow(true);

    if (modalDetail?.flag == "EditOption") {
      handleOnCloseModal();
      setRowData(tempRowData);
      setTempRowData([]);
    } else {
      setModalDetail({
        show: true,
        flag: "createVariations",
      });
      setKey(Math.random());
    }
  };

  const handleDeleteOption = (displayName) => {
    // Update rowData to exclude the row that matches the displayName
    const updatedRowData = rowData.filter(
      (item) => item.displayName !== displayName
    );
    setRowData(updatedRowData);

    // Update selectedCombinations to delete the displayName key from combinations
    const updatedCombinations = selectedCombinations.map((combination) => {
      // If the displayName exists as a key, delete it from the combination
      const updatedCombination = { ...combination };
      if (updatedCombination.hasOwnProperty(displayName)) {
        delete updatedCombination[displayName];
      }
      return updatedCombination;
    });

    // Remove duplicate combinations
    const uniqueCombinations = Array.from(
      new Set(updatedCombinations.map(JSON.stringify))
    ).map(JSON.parse);

    setSelectedCombinations(uniqueCombinations);

    // Check if rowData is empty after deletion and set a default row if it is
    if (updatedRowData.length === 0) {
      setRowData([
        {
          id: 1,
          activePlan: "list",
          displayName: "",
          productTags: [],
        },
      ]);
      setAttributeShow(false);
      setSelectedCombinations([]);
      setAttributes([]);
    }
  };

  const toggleVariantSelection = (idx) => {
    setSelectedVariants((prevSelected) => {
      if (prevSelected.includes(idx)) {
        return prevSelected.filter((id) => id !== idx); // Remove if already selected
      } else {
        return [...prevSelected, idx]; // Add if not selected
      }
    });
  };
  const handleSelectAllChange = () => {
    // If all variants are selected, unselect them, otherwise select all
    if (selectedVariants.length === variants.length) {
      setSelectedVariants([]); // Deselect all
    } else {
      const allVariantIndexes = variants.map((_, idx) => idx); // Create an array of all variant indexes
      setSelectedVariants(allVariantIndexes); // Select all variants
    }
  };

  const handleBulkUpdate = (field, values) => {
    if (selectedVariants.length <= 0) {
      showToast("please select variants");
      return;
    }

    const updatedVariants = variants.map((variant, idx) => {
      const isSelected = selectedVariants.includes(idx);

      if (isSelected) {
        const costPrice = parseFloat(variant.cost_price || 0);

        // Calculate margin percentage based on cost and selling price
        const calculateMargin = (costPrice, sellingPrice) => {
          if (!costPrice || !sellingPrice) return "";
          return (((sellingPrice - costPrice) / costPrice) * 100).toFixed(2);
        };

        // Update prices if the field is "price"
        const updatedPrices = variant.prices.map((price) => {
          if (price.app_name === "pos") {
            return {
              ...price,
              selling_price: values.posPrice || price.selling_price,
              margin_percentage: calculateMargin(
                costPrice,
                parseFloat(values.posPrice || price.selling_price)
              ),
            };
          }

          if (price.app_name === "b2c") {
            return {
              ...price,
              selling_price: values.b2cPrice || price.selling_price,
              margin_percentage: calculateMargin(
                costPrice,
                parseFloat(values.b2cPrice || price.selling_price)
              ),
            };
          }

          return price; // Return price unchanged if it's not being updated
        });

        // Return the updated variant object
        return {
          ...variant,
          ...(field === "quantity" && {
            quantity: values.quantity || variant.quantity,
          }),
          ...(field === "quantity" && {
            quantity_reorder_point:
              values.quantity_reorder_point || variant.quantity_reorder_point,
          }),
          ...(field === "weight" && {
            weight: values.weight || variant.weight,
          }),
          ...(field === "pos_price" && { prices: updatedPrices }),
          ...(field === "b2c_price" && { prices: updatedPrices }),
        };
      }

      return variant; // If not selected, return the variant unchanged
    });

    setVariants(updatedVariants);
  };

  const handleDeleteVariant = (indexToDelete) => {
    const updatedVariants = variants.filter((_, idx) => idx !== indexToDelete);
    setVariants(updatedVariants);
  };

  useEffect(() => {
    if (selectedCombinations && selectedCombinations.length > 0) {
      setVariants((prevVariants) =>
        selectedCombinations.map((combination, index) => ({
          ...(prevVariants[index] || {}),
          attributes: { ...combination },
          cost_price: costPrice || prevVariants[index]?.cost_price || "", // Preserve existing value
          prices: prevVariants[index]?.prices || [
            {
              app_name: "pos",
              price_type: "fixed",
              selling_price: "",
              margin_percentage: "",
            },
            {
              app_name: "b2c",
              price_type: "fixed",
              selling_price: "",
              margin_percentage: "",
            },
          ],
          quantity: prevVariants[index]?.quantity || "",
          quantity_reorder_point:
            prevVariants[index]?.quantity_reorder_point || "",
          weight: dimensions?.weight || prevVariants[index]?.weight || "",
          barcode: String(
            Number(barCode) + index || prevVariants?.[index]?.barcode || ""
          ),
          image: prevVariants[index]?.image || "",
          images: prevVariants[index]?.images || [],
          seller_address_ids: prevVariants[index]?.seller_address_ids || [],
        }))
      );
    } else {
      setVariants((prevVariants) => [
        {
          cost_price: costPrice || "",
          prices: prices || [
            {
              app_name: "pos",
              price_type: "fixed",
              selling_price: "",
              compare_at_price: "",
              margin_percentage: "",
            },
            {
              app_name: "b2c",
              price_type: "fixed",
              selling_price: "",
              compare_at_price: "",
              margin_percentage: "",
            },
          ],
          weight: dimensions?.weight || "",
          barcode: barCode || "",
          image: imageDetails?.image || "",
          images: imageDetails?.images || [],
        },
      ]);
    }
  }, [
    selectedCombinations,
    costPrice,
    prices,
    imageDetails,
    barCode,
    dimensions,
  ]);

  useEffect(() => {
    if (costPrice > 0) {
      // Update POS profit and margin
      if (posPrice > 0) {
        calculatePosProfitAndMargin(posPrice, costPrice);
      }

      // Update Marketplace profit and margin
      if (marketplacePrice > 0) {
        calculateMarketplaceProfitAndMargin(marketplacePrice, costPrice);
      }

      // Update margin in prices array for variants
      setPrices((prevPrices) =>
        prevPrices.map((price) => {
          const updatedMargin =
            price.selling_price > 0
              ? calculateMargin(
                  parseFloat(price.selling_price),
                  costPrice
                ).toFixed(2)
              : price.margin_percentage;

          return {
            ...price,
            margin_percentage: updatedMargin,
          };
        })
      );

      // Update variants
      setVariants((prevVariants) =>
        prevVariants.map((variant) => {
          const updatedPrices = variant.prices.map((price) => {
            const updatedMargin =
              price.selling_price > 0
                ? calculateMargin(
                    parseFloat(price.selling_price),
                    costPrice
                  ).toFixed(2)
                : price.margin_percentage;

            return {
              ...price,
              margin_percentage: updatedMargin,
            };
          });

          return {
            ...variant,
            prices: updatedPrices,
          };
        })
      );
    } else {
      // Reset profits and margins when costPrice is invalid
      setPosProfit("");
      setPosMargin("");
      setMarketplaceProfit("");
      setMarketplaceMargin("");

      // Reset margin in prices array for variants
      setPrices((prevPrices) =>
        prevPrices.map((price) => ({
          ...price,
          margin_percentage: "",
        }))
      );

      // Reset variants
      setVariants((prevVariants) =>
        prevVariants.map((variant) => ({
          ...variant,
          prices: variant.prices.map((price) => ({
            ...price,
            margin_percentage: "",
          })),
        }))
      );
    }
  }, [costPrice, posPrice, marketplacePrice]);

  return (
    <div className="addProductManually">
      {/* Start FixedTopHeader */}
      <AddProductHeader
        flag={backRoute}
        save={(e) => handleSave(e)}
        successLoader={successLoader}
      />
      {/* End FixedTopHeader */}
      <div className="productRightWrapper">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-8 p-0">
              <div className="productRightContainer">
                {/* Start Uploaded ProductImg Area */}

                <ProductImageAdd imageData={(e) => handleImageDetails(e)} />

                <ProductDetails
                  setSelectedService={setSelectedService}
                  setServiceIdforAttributes={setServiceId}
                  productDetails={(e, flag) => handleProductDetails(e, flag)}
                />

                {/* End Uploaded ProductImg Area */}

                {/* Start CostPrice Area */}
                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      Product cost price
                    </h2>
                    <div className="col-md-6">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                          Cost of Good Sold
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="Cost Price"
                            name="text"
                            type="number"
                            value={costPrice}
                            onChange={(e) => handleChangeCostPrice(e)}
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form_group"></div>
                      <div className="reactSelectParent commonSelectParent w-100">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4">
                          Unit
                        </label>
                        <ReactSelect
                          value={
                            unitType
                              ? {
                                  value: unitType,
                                  label: unitType
                                    .replace("_", " ")
                                    .replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    ),
                                }
                              : null
                          }
                          options={[
                            { value: "per_item", label: "Per Item" },
                            { value: "per_unit", label: "Per Unit" },
                            { value: "per_box", label: "Per Box" },
                            { value: "per_bag", label: "Per Bag" },
                            { value: "per_pound", label: "Per Pound" },
                            { value: "per_feet", label: "Per Feet" },
                            { value: "per_gallon", label: "Per Gallon" },
                          ]}
                          onChange={(selectedOption) =>
                            handleUnitChange({
                              target: { value: selectedOption?.value },
                            })
                          }
                          isSearchable={true}
                          placeholder="Select one"
                          classNamePrefix="react-select"
                          className="react-select-container anlyticSelect"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              maxWidth: "100% !important",
                              width: "100%",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              border: "1px solid #D7DEFF",
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              paddingLeft: "unset !important",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#263682"
                                : state.isFocused
                                ? "#f5f6fc"
                                : "#fff",

                              color: state.isSelected ? "#fff" : "#263682",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "#636E9F",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }),
                          }}
                          components={{
                            DropdownIndicator: () => (
                              <img
                                src={Images.selectImg}
                                width={24}
                                height={24}
                                alt="drop_icon"
                                className="mt-1"
                              />
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      Product sale price
                    </h2>
                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={differentSale}
                          onChange={handleSaleToggle("differentSale")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            Use different sale price
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Sale price for B2B, Marketplace & POS
                          </h3>
                        </div>
                      </div>
                      {differentSale && (
                        <>
                          <div className="productSalePriceBox">
                            <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                              POS Pricing
                            </h3>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Sale Price
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      min="1"
                                      onKeyDown={blockInvalidChar}
                                      className="customInput costPriceInput"
                                      placeholder="1,000.00"
                                      name="posPrice"
                                      type="number"
                                      value={posPrice}
                                      onChange={(e) =>
                                        handlePriceChange(e, "pos")
                                      }
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Profit
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      readOnly
                                      className="customInput costPriceInput"
                                      value={posProfit}
                                      placeholder="Profit"
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Margin
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      readOnly
                                      className="customInput"
                                      value={posMargin}
                                      placeholder="Margin"
                                    />

                                    <span className="dollrsign1_ usdTxt">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="productSalePriceBox">
                            <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                              Marketplace Pricing
                            </h3>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Sale Price
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      min="1"
                                      onKeyDown={(e) => blockInvalidChar(e)}
                                      className="customInput costPriceInput"
                                      placeholder="1,000.00"
                                      name="marketplacePrice"
                                      type="number"
                                      value={marketplacePrice}
                                      onChange={(e) =>
                                        handlePriceChange(e, "marketplace")
                                      }
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Profit
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      readOnly
                                      className="customInput costPriceInput"
                                      value={marketplaceProfit}
                                      placeholder="Profit"
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Margin
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      readOnly
                                      className="customInput"
                                      value={marketplaceMargin}
                                      placeholder="Margin"
                                    />
                                    <span className="dollrsign1_ usdTxt">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={saleSame}
                          onChange={handleSaleToggle("saleSame")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            On sale
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Sale price would be same for all platforms
                          </h3>
                        </div>
                      </div>
                    </div>
                    {saleSame && (
                      <>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              Sale Price
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="1,000.00"
                                name="posPrice"
                                type="number"
                                value={posPrice}
                                onChange={(e) => handlePriceChange(e, "pos")}
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              Profit
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                readOnly
                                className="customInput costPriceInput"
                                value={posProfit}
                                placeholder="Profit"
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                              Margin
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                readOnly
                                className="customInput"
                                value={posMargin}
                                placeholder="Margin"
                              />

                              <span className="dollrsign1_ usdTxt">%</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* {saleToggle && (
                      <>
                        <div className="col-md-6">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                              Discount
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="100"
                                name="text"
                                type="number"
                              />
                              <span className="dollrsign1_">$</span>

                              <ul className="discountTab m-0">
                                <li
                                  className={`discountTabList ${
                                    discountOption === "dollar" ? "active" : ""
                                  }`}
                                  onClick={() => handleDiscountOption("dollar")}
                                >
                                  $
                                </li>
                                <li
                                  className={`discountTabList discountTabListTwo ${
                                    discountOption === "percentage"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleDiscountOption("percentage")
                                  }
                                >
                                  %
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_group">
                            <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                              Sale price
                              <img
                                src={Images.labelImg}
                                alt="logo"
                                className="ms-2"
                              />
                            </label>
                            <div className="inputGroup">
                              <input
                                min="1"
                                onKeyDown={blockInvalidChar}
                                className="customInput costPriceInput"
                                placeholder="1,000.00"
                                name="text"
                                type="number"
                              />
                              <span className="dollrsign1_">$</span>
                              <span className="dollrsign1_ usdTxt">USD</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}
                  </form>

                  <div className="col-md-12">
                    <div className="productDetailsContainer productSale_Parent">
                      <input
                        type="checkbox"
                        checked={isTax} // Bind to state
                        onChange={handletaxToggle} // Use the updated handler
                      />
                      <div>
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue">
                            Product Tax
                          </h3>
                          <h3 className="fontSize14 txtDarkblue mb-0">
                            No Product tax setup yet!
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                        Product Stock & Locations
                      </h2>

                      <div className="productStockBox">
                        <h3 className="fontSize14 txtDarkblue mb-1">
                          Daugherty LLC 01
                        </h3>
                        <p className="fontSize12 txtLightBlue">
                          9 Indrio Blvd, Satellite Beach, Florida 32937, USA
                        </p>

                        <div className="productStockBox_Inner mt-3">
                          <div>
                            <h5 className="fontSize14 fw500 txtDarkblue">
                              Stocks
                            </h5>
                            <h5 className="fontSize18 txtLightBlue mb-0">64</h5>
                          </div>
                          <div>
                            <h5 className="fontSize14 fw500 txtDarkblue">
                              Available for sell
                            </h5>
                            <h5 className="fontSize18 txtLightBlue mb-0">64</h5>
                          </div>
                        </div>
                      </div>

                      <div className="productStockBox">
                        <h3 className="fontSize14 txtDarkblue mb-1">
                          Daugherty LLC 01
                        </h3>
                        <p className="fontSize12 txtLightBlue">
                          9 Indrio Blvd, Satellite Beach, Florida 32937, USA
                        </p>

                        <div className="productStockBox_Inner mt-3">
                          <div>
                            <h5 className="fontSize14 fw500 txtDarkblue">
                              Stocks
                            </h5>
                            <h5 className="fontSize18 txtLightBlue mb-0">30</h5>
                          </div>
                          <div>
                            <h5 className="fontSize14 fw500 txtDarkblue">
                              Available for sell
                            </h5>
                            <h5 className="fontSize18 txtLightBlue mb-0">23</h5>
                          </div>
                        </div>
                      </div>

                      <button className="commonBtn mt-4">
                        Manage Stock
                        <img src={Images.upArrow} alt="logo" className="ms-2" />
                      </button>
                    </div>
                  </div> */}

                  <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div
                        className={`productSale_Parent mt-0 w-100 ${
                          rowData[0].displayName !== "" &&
                          rowData.length > 0 &&
                          attributeShow &&
                          "active"
                        }`}
                      >
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-2">
                            Product Option
                          </h3>
                          <p className="fontSize14 fw500 txtDarkblue mb-0">
                            Does your product come in different options, like
                            size, color or material? Add them here.
                          </p>
                          {rowData &&
                            attributeShow &&
                            rowData.length > 0 &&
                            rowData[0].displayName !== "" &&
                            rowData.map((value, idx) => (
                              <div className="productOptionInner">
                                <img src={Images.barsLogo} alt="logo" />
                                <div className="productOption_InnerBox">
                                  <div className="row align-items-center">
                                    <div className="col-3">
                                      <h3 className="fontSize18 txtDarkblue mb-0 textCapital">
                                        {value?.displayName}
                                      </h3>
                                    </div>
                                    <div className="col-9">
                                      <div className="d-flex align-items-center justify-content-between w-100">
                                        <h3 className="fontSize18 txtDarkblue mb-0">
                                          {value?.productTags?.join(", ")}
                                        </h3>

                                        <div className="d-flex align-items-center ms-3">
                                          <img
                                            src={Images.newEditIcon}
                                            alt="logo"
                                            className="me-2"
                                            onClick={() => {
                                              setModalDetail({
                                                show: true,
                                                flag: "EditOption",
                                                data: value,
                                                rowIndex: idx,
                                              });
                                              setKey(Math.random());
                                            }}
                                          />
                                          <img
                                            src={Images.modalCross}
                                            alt="logo"
                                            onClick={() =>
                                              handleDeleteOption(
                                                value.displayName
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                          <button
                            className="commonBtn mt-4"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "AddOptions",
                              });
                              setKey(Math.random());
                              // setAttributeShow(false);
                              if (
                                rowData.length >= 1 &&
                                rowData.displayName !== ""
                              ) {
                                setTempRowData([]);
                              }
                            }}
                          >
                            <img
                              src={Images.btnPlusIcon}
                              alt="logo"
                              className="me-2"
                            />
                            Add Option
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {variants[0]?.attributes &&
                    attributeShow &&
                    Object.keys(variants[0]?.attributes).length > 0 && (
                      <div className="col-md-12">
                        <div className="productDetailsContainer">
                          <h2 className="fontSize18 fw500 txtDarkblue">
                            Product Variants
                          </h2>
                          <p className="fontSize14 txtDarkblue mb-0">
                            Does your product come in different options, like
                            size, color or material? View them here.
                          </p>
                          <div className="row mt-3 align-items-center">
                            <div className="col-6">
                              <div className="productAvailabilityChild variationsParent align-items-center">
                                <div class="form-group">
                                  <input
                                    type="checkbox"
                                    id="selectAllCheckbox"
                                    checked={
                                      selectedVariants.length ===
                                      variants.length
                                    }
                                    onChange={handleSelectAllChange}
                                  />
                                  <label for="selectAllCheckbox"></label>
                                </div>
                                <div>
                                  <h3 className="fontSize16 txtDarkblue fw500 mb-0 pt-1">
                                    {selectedCombinations.length} Variations
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-end">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                  className="variantsTable_Dropdown"
                                >
                                  <img
                                    src={Images.moreVerticalLogo}
                                    alt="logo"
                                    className="plusDropImg"
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="variantsDropdownMenu">
                                  <Dropdown.Item
                                    href="#/action-1"
                                    onClick={() => {
                                      setModalDetail({
                                        show: true,
                                        flag: "bulkVariation",
                                        title: "Update Quantity",
                                        field: "quantity",
                                      });
                                      setKey(Math.random());
                                    }}
                                  >
                                    Update Quantity
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href="#/action-2"
                                    onClick={() => {
                                      setModalDetail({
                                        show: true,
                                        flag: "bulkVariation",
                                        title: "Update Price",
                                        field: "pos_price",
                                      });
                                      setKey(Math.random());
                                    }}
                                  >
                                    Update Price
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    href="#/action-3"
                                    onClick={() => {
                                      setModalDetail({
                                        show: true,
                                        flag: "bulkVariation",
                                        title: "Update Weight",
                                        field: "weight",
                                      });
                                      setKey(Math.random());
                                    }}
                                  >
                                    Update Weight
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <div className="productVariantsTable table-responsive">
                            <table>
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Variation</th>
                                  <th>UPC</th>
                                  <th>POS Price</th>
                                  <th>Marketplace Price</th>
                                  <th>Weight</th>
                                  <th>Quantity</th>
                                  <th>Quantity Re-order Point</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {variants.map((variant, idx) => (
                                  <tr key={idx}>
                                    <td>
                                      <div className="productAvailabilityChild variationsParent">
                                        <div className="form-group">
                                          <input
                                            type="checkbox"
                                            id={`checkbox-${idx}`}
                                            checked={selectedVariants.includes(
                                              idx
                                            )} // Check if this variant is selected
                                            onChange={() =>
                                              toggleVariantSelection(idx)
                                            } // Toggle selection on change
                                          />
                                          <label
                                            htmlFor={`checkbox-${idx}`}
                                            className="me-0"
                                          ></label>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={
                                            variant?.image
                                              ? variant?.image
                                              : Images.uploadVariantLogo
                                          }
                                          alt="logo"
                                          className="me-3 teamMemberImg"
                                        />
                                        <div>
                                          {variant.attributes
                                            ? Object.entries(
                                                variant.attributes
                                              ).map(([key, val], index) => (
                                                <span
                                                  className="varintText textCapital"
                                                  key={index}
                                                >
                                                  {val}
                                                  {index <
                                                    Object.entries(
                                                      variant.attributes
                                                    ).length -
                                                      1 && " / "}
                                                </span>
                                              ))
                                            : "Default Variant"}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        // min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="barcode"
                                        type="number"
                                        value={variant.barcode || ""}
                                        maxLength={14}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (inputValue.length <= 14) {
                                            handleInputChange(
                                              idx,
                                              "barcode",
                                              inputValue
                                            );
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="pos_price"
                                        type="number"
                                        value={
                                          variant.prices.find(
                                            (p) => p.app_name === "pos"
                                          )?.selling_price || ""
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            idx,
                                            "pos_price",
                                            e.target.value
                                          )
                                        }
                                        disabled={
                                          !variant.prices.some(
                                            (price) => price.app_name === "pos"
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="marketplace_price"
                                        type="number"
                                        value={
                                          variant.prices.find(
                                            (p) => p.app_name === "b2c"
                                          )?.selling_price || ""
                                        }
                                        onChange={(e) =>
                                          handleInputChange(
                                            idx,
                                            "marketplace_price",
                                            e.target.value
                                          )
                                        }
                                        disabled={
                                          !variant.prices.some(
                                            (price) => price.app_name === "b2c"
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="weight"
                                        type="number"
                                        value={variant.weight || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            idx,
                                            "weight",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        // min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="quantity"
                                        type="number"
                                        value={variant.quantity || ""}
                                        maxLength={14}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (inputValue.length <= 14) {
                                            handleInputChange(
                                              idx,
                                              "quantity",
                                              inputValue
                                            );
                                          }
                                        }}
                                      />
                                    </td>{" "}
                                    <td>
                                      <input
                                        // min="1"
                                        onKeyDown={blockInvalidChar}
                                        className="productVariant_Input"
                                        name="quantity_reorder_point"
                                        type="number"
                                        value={
                                          variant.quantity_reorder_point || ""
                                        }
                                        maxLength={14}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          if (inputValue.length <= 14) {
                                            handleInputChange(
                                              idx,
                                              "quantity_reorder_point",
                                              inputValue
                                            );
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                          className="variantsTable_Dropdown"
                                        >
                                          <img
                                            src={Images.moreVerticalLogo}
                                            alt="logo"
                                          />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="variantsDropdownMenu">
                                          <Dropdown.Item
                                            href="#/action-1"
                                            onClick={() => {
                                              setModalDetail({
                                                show: true,
                                                flag: "editVariantModal",
                                                index: idx,
                                                variationname:
                                                  variant.attributes
                                                    ? Object.entries(
                                                        variant.attributes
                                                      )
                                                        .map(
                                                          (
                                                            [key, val],
                                                            index,
                                                            array
                                                          ) => {
                                                            // Concatenate values, ensuring proper formatting
                                                            const displayValue = `${val}${
                                                              index <
                                                              array.length - 1
                                                                ? " / "
                                                                : ""
                                                            }`;
                                                            return displayValue;
                                                          }
                                                        )
                                                        .join("") // Join the array of strings into a single string
                                                    : "Default Variant", // Default text when no attributes exist
                                              });
                                              setKey(Math.random());
                                            }}
                                          >
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            href="#/action-3"
                                            onClick={() =>
                                              handleDeleteVariant(idx)
                                            }
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {/* <div className="d-flex align-items-center mt-3">
                            <button className="manageStockBtn">
                              Manage Stock
                              <img
                                src={Images.upArrow}
                                alt="logo"
                                className="ms-2"
                              />
                            </button>
                            <button className="manageStockBtn ms-3">
                              Edit Re-order Point
                              <img
                                src={Images.upArrow}
                                alt="logo"
                                className="ms-2"
                              />
                            </button>
                          </div> */}
                        </div>
                      </div>
                    )}

                  {/* <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <h2 className="fontSize18 fw500 txtDarkblue">
                        Attributes
                      </h2>
                      <p className="fontSize14 txtDarkblue mb-0">
                        Does your product come in different options, like size,
                        color or material? Add them here.
                      </p>
                      <div className="productOption_InnerBox mt-4">
                        <div className="row">
                          <div className="col-4">
                            <h3 className="fontSize18 txtDarkblue mb-0">Age</h3>
                          </div>
                          <div className="col-4">
                            <h3 className="fontSize18 txtDarkblue mb-0">
                              Must be 21
                            </h3>
                          </div>
                          <div className="col-4">
                            <div className="d-flex align-items-center justify-content-end">
                              <img
                                src={Images.openEyeIcon}
                                alt="logo"
                                className="me-2 cursorPointer"
                              />
                              <div>
                                <img
                                  src={Images.lightCrossIcon}
                                  alt="logo"
                                  className="cursorPointer"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="productOption_InnerBox mt-2">
                        <div className="row">
                          <div className="col-4">
                            <h3 className="fontSize18 txtLightPurple mb-0">
                              Age
                            </h3>
                          </div>
                          <div className="col-4">
                            <h3 className="fontSize18 txtLightPurple mb-0">
                              Must be 21
                            </h3>
                          </div>
                          <div className="col-4">
                            <div className="d-flex align-items-center justify-content-end">
                              <img
                                src={Images.closeEyeIcon}
                                alt="logo"
                                className="me-2 cursorPointer"
                              />
                              <div>
                                <img
                                  src={Images.lightCrossIcon}
                                  alt="logo"
                                  className="cursorPointer"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <button className="manageStockBtn mt-4">
                        <img
                          src={Images.btnPlusIcon}
                          alt="logo"
                          className="me-2"
                        />
                        Add Attribute
                      </button>
                    </div>
                  </div> */}

                  {/* <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-0">
                            Product Stock & Locations
                          </h3>
                          <button className="commonBtn mt-4">
                            Manage Stock
                            <img
                              src={Images.upArrow}
                              alt="logo"
                              className="ms-2"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-2">
                            Attributes
                          </h3>
                          <p className="fontSize14 fw500 txtDarkblue mb-0">
                            Does your product come in different options, like
                            size, color or material? Add them here.
                          </p>
                          <button
                            className="commonBtn mt-4"
                            // onClick={() => {
                            //   setModalDetail({
                            //     show: true,
                            //     flag: "addAttributeModal",
                            //   });
                            //   setKey(Math.random());
                            // }}
                          >
                            <img
                              src={Images.btnPlusIcon}
                              alt="logo"
                              className="me-2"
                            />
                            Add Attribute
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0 justify-content-between align-items-start">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue">
                            Order after out of stock
                          </h3>
                          <h3 className="fontSize14 txtLightBlue fw500 mb-0">
                            Let customers buy this product when it’s out of
                            stock.
                          </h3>
                        </div>
                        <input type="checkbox" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* End ProductDetails Area */}
            <div className="col-md-4">
              {/* Start ProductAvailability Area */}
              <div className="productLeftContainer">
                <div className="outerArea">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    Product availability
                  </h3>

                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.physicalStoreIcon}
                        alt="logo"
                        className="physicalStoreIcon me-3"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            Physical store
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            JOBR POS
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="productAvailOption_Wrapper">
                      {allAddress.map((store) => (
                        <div
                          key={store.id}
                          className="productAvailabilityChild mt-3"
                        >
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id={`store-${store.id}`}
                              checked={sellerAddressIds.includes(store.id)}
                              onChange={handleStoreToggle(store.id)} // Correct usage
                            />
                            <label htmlFor={`store-${store.id}`}></label>
                          </div>
                          <div>
                            <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                              Store {store.id} - {store.format_address}
                            </h3>
                            <p className="fontSize12 txtLightBlue mb-0">
                              {store.street_address}, {store.city},{" "}
                              {store.state} {store.zipcode}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="productAvail_Second ">
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.jobrMarketplaceIcon}
                        alt="logo"
                        className="physicalStoreIcon me-3"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            JOBR Marketplace
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Allow customers to book this service on JOBR
                            Marketplace app.
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="productAvailOption_Wrapper">
                      <div className="productAvailabilityChild mt-3 align-items-center">
                        <div class="form-group">
                          <input
                            type="checkbox"
                            id="pickup"
                            checked={deliveryOptions.includes("pickup")}
                            onChange={handleDeliveryOptionToggle("pickup")}
                          />
                          <label for="pickup"></label>
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Pickup
                          </h3>
                        </div>
                      </div>
                      <div className="productAvailabilityChild mt-3 align-items-center">
                        <div class="form-group">
                          <input
                            type="checkbox"
                            id="delivery"
                            checked={deliveryOptions.includes("delivery")}
                            onChange={handleDeliveryOptionToggle("delivery")}
                          />
                          <label for="delivery"></label>
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Delivery
                          </h3>
                        </div>
                      </div>
                      <div className="productAvailabilityChild mt-3 align-items-center">
                        <div class="form-group">
                          <input
                            type="checkbox"
                            id="shipping"
                            checked={deliveryOptions.includes("shipping")}
                            onChange={handleDeliveryOptionToggle("shipping")}
                          />
                          <label for="shipping"></label>
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Shipping
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="outerArea">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    Marketing options
                  </h3>
                  <div className="createCoupen_container">
                    <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                      Create coupon
                    </h3>
                    <img src={Images.upArrow} alt="logo" />
                  </div>
                  <div className="createCoupen_container">
                    <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                      Promote this product
                    </h3>
                    <img src={Images.upArrow} alt="logo" />
                  </div>
                </div>
                <div className="chartUploadBox mb-2">
                  <h2 className="fontSize18 fw500 txtDarkblue ">
                    Product Size Chart
                  </h2>
                  <ChartImageUploader
                    chartImage={chartImage}
                    setChartImage={setChartImage}
                  />
                </div>
              </div>
            </div>

            {/* End ProductDetails Area */}
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "AddOptions" || modalDetail.flag === "EditOption"
            ? "addProductOptions"
            : modalDetail.flag === "createVariations"
            ? "createVariations"
            : modalDetail.flag === "AddAttributesModal"
            ? "addAttributesModal"
            : modalDetail.flag === "saleTax"
            ? "saleModalTax"
            : modalDetail.flag === "deleteVariation"
            ? "addProductOptions"
            : modalDetail.flag === "editVariantModal"
            ? "editVariantModal"
            : modalDetail.flag === "addAttributeModal"
            ? "addProductOptions"
            : modalDetail.flag === "bulkVariation"
            ? "addProductOptions"
            : ""
        }
        size={modalDetail.flag === "AddOptions" ? "xl" : "lg"}
        child={
          modalDetail.flag === "AddOptions" ||
          modalDetail.flag === "EditOption" ? (
            <AddOptionsModal
              id={serviceId}
              close={() => handleOnCloseModal()}
              onRowsChange={handleRowsChange}
              // rowData={tempRowData.length > 1 ? tempRowData : rowData}
              rowData={
                modalDetail.flag === "EditOption"
                  ? rowData
                  : tempRowData.length > 1
                  ? tempRowData
                  : rowData
              }
              optionModalDetail={modalDetail}
            />
          ) : modalDetail.flag === "createVariations" ? (
            <CreateVariationsModal
              close={() => handleOnCloseModal()}
              rowData={tempRowData}
              costPrice={costPrice}
              onCombinationChange={handleCombination}
              selectedCombinations={selectedCombinations}
            />
          ) : modalDetail.flag === "bulkVariation" ? (
            <BulkVarientionModal
              title={modalDetail.title}
              field={modalDetail.field}
              onSave={(value) => {
                handleBulkUpdate(modalDetail.field, value);
              }}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "AddAttributesModal" ? (
            <AddAttributesModal
              close={() => handleOnCloseModal()}
              attributes={(e) => handleAttributes(e)}
              value={ingredients}
            />
          ) : modalDetail.flag === "saleTax" ? (
            <SaleTaxModal
              close={() => handleOnCloseModal()}
              attributes={(e) => handleAttributes(e)}
              value={ingredients}
            />
          ) : modalDetail.flag === "createVariant" ? (
            <CreateOptionModal
              selectedService={selectedService}
              serviceId={serviceId}
              close={() => handleOnCloseModal()}
              handleModalChange={(e) => handleModalChange(e)}
            />
          ) : modalDetail.flag === "deleteVariation" ? (
            <DeleteVariationModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "editVariantModal" ? (
            <EditVariantModal
              closeModal={() => handleOnCloseModal()}
              modalDetail={modalDetail}
              allAddress={allAddress}
              variants={variants}
              setVariants={setVariants}
            />
          ) : modalDetail.flag === "addAttributeModal" ? (
            <AddAttributesModal close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "AddOptions" ||
            modalDetail.flag === "EditOption" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  {modalDetail.flag === "EditOption"
                    ? "Edit Options"
                    : "Add Options"}
                </h3>
                <div className="d-flex align-items-center">
                  <button
                    className="modalDiscardBtn"
                    onClick={() => {
                      handleOnCloseModal();
                      if (
                        rowData.length >= 1 &&
                        rowData[0].displayName !== "" &&
                        selectedCombinations.length > 0
                      ) {
                        setAttributeShow(true);
                      } else {
                        setRowData([
                          {
                            id: 1,
                            activePlan: "list", // Default active plan
                            displayName: "", // Reset display name
                            productTags: [], // Empty productTags array
                          },
                        ]);
                        setAttributes(false);
                      }
                    }}
                  >
                    Discard
                  </button>
                  <button
                    className="modalNextBtn modalDiscardBtn active"
                    onClick={handleOpenVariation}
                  >
                    {modalDetail?.flag == "EditOption" ? "Save" : "Next"}
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "createVariations" ? (
              <>
                <div className="d-flex align-items-center">
                  <button
                    className="modalBackBtn"
                    onClick={() => {
                      handleOnCloseModal();
                      // setSelectedCombinations([]);
                      setModalDetail({
                        show: true,
                        flag: "AddOptions",
                      });
                      // setAttributeShow(false);
                    }}
                  >
                    <img src={Images.blueBackArrow} alt="logo" />
                  </button>
                  <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                    Create variations
                  </h3>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    className="modalNextBtn modalDiscardBtn active createVariationBtn"
                    onClick={() => {
                      if (newCombinations.length <= 0) {
                        showToast("please select variation");
                        return;
                      }
                      handleOnCloseModal();
                      setAttributeShow(true);
                      setSelectedCombinations(newCombinations);
                      setRowData(tempRowData);
                      setTempRowData([]);
                    }}
                  >
                    Create {newCombinations.length} variations
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "saleTax" ? (
              <>
                <h4 className="modalHeading_">Sales tax</h4>
                <p onClick={handleOnCloseModal} className="modal_cancel">
                  <img
                    src={modalCancel}
                    className="ModalCancel"
                    alt="modalcancelImg"
                  />
                </p>
              </>
            ) : modalDetail.flag === "bulkVariation" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  {modalDetail.title}
                </h3>
                <button
                  className="modalNextBtn modalDiscardBtn active"
                  onClick={handleOnCloseModal}
                >
                  close
                </button>
              </>
            ) : modalDetail.flag === "deleteVariation" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  Remove option set and delete variations
                </h3>

                <div className="d-flex align-items-center">
                  <button
                    className="modalDiscardBtn w-auto"
                    onClick={() => handleOnCloseModal()}
                  >
                    Discard
                  </button>
                  <button className="deleteVariationBtn modalDiscardBtn active">
                    Delete variations
                  </button>
                </div>
              </>
            ) : modalDetail.flag === "editVariantModal" ? (
              <>
                <div></div>
                <img
                  src={Images.modalCross}
                  alt="logo"
                  className="cursorPointer"
                  onClick={() => handleOnCloseModal()}
                />
              </>
            ) : modalDetail.flag === "addAttributeModal" ? (
              <>
                <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                  Add Attributes
                </h3>

                <div className="d-flex align-items-center">
                  <button className="modalDiscardBtn">Discard</button>
                  <button
                    className="modalNextBtn modalDiscardBtn"
                    onClick={() => {
                      setModalDetail({
                        show: true,
                        flag: "createVariations",
                      });
                      setKey(Math.random());
                    }}
                  >
                    Save
                  </button>
                </div>
              </>
            ) : (
              <>
                <h4 className="modalHeading_">Add custom attribute</h4>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={handleOnCloseModal}
                  className="modal_cancel"
                >
                  <img
                    src={modalCancel}
                    className="ModalCancel"
                    alt="modalcancelImg"
                  />
                </p>
              </>
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default AddProduct;
