import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import * as Images from "../../../../utilities/images.js";
import { getServicesApiAsync, sendEmailOtpAsync } from "../authSlice";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const history = useHistory();
  const phoneData = localStorage.getItem("phone_details")
    ? JSON.parse(localStorage.getItem("phone_details"))
    : "";
  const existing_user = localStorage.getItem("existing_user")
    ? JSON.parse(localStorage.getItem("existing_user"))
    : "";
  const [email, setEmail] = useState(
    phoneData?.user_email ? phoneData?.user_email : ""
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const toastId = React.useRef(null);
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const handleSendOtp = (e) => {
    e.preventDefault();
    let checkEmail =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (!checkEmail.test(email)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Invalid email given.");
      }
      return false;
    }

    let params;
    if (phoneData?.user_id) {
      params = {
        type: "email",
        email: email,
        user_id: `${phoneData?.user_id}`,
      };
    } else {
      params = {
        type: "email",
        email: email,
      };
    }
    setLoading(true);
    dispatch(sendEmailOtpAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg);
        }
        localStorage.setItem("email_details", JSON.stringify(obj?.payload));
        localStorage.setItem("email_info", email);
        history.push("/verifyemailOtp");
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  return (
    <div className="commonLogin">
      <div className="loginForm verifyPhoneNoForm">
        <form autoComplete="nope" onSubmit={(e) => handleSendOtp(e)}>
          <div className="authFormHead">
            <h2 className="fontSize30 txtDarkblue mb-0">Verify your Email</h2>
            <h5 className="fontSize18 txtLightBlue mb-0 pt-3">
              We’ll send you OTP to your email.
            </h5>
          </div>

          <div className="phone-numbpart">
            <div className="country-plugin">
              <input
                type="text"
                autoComplete="new-password"
                className="customform-control emailInput"
                value={email}
                name={generateRandomName()}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex gap-5 mx-auto">
          <button
              className="forgetPass_BackBtn w-100 d-flex align-items-center justify-content-center"
              onClick={() => {
                history.push("/verifyOtp");
              }}
            >
              <img
                src={Images.lightLeftArrowLogo}
                alt="logo"
                className="me-2"
              />
              Back
            </button>
            <button className="loginBtnSign w-100" type="submit" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
             
              Next <img src={Images.upArrow} alt="logo" className="ms-2 mb-1" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyEmail;
