export const BookingColumns = [
    {
        isVisible: true,
        title: "Customer"
    },
    {
        isVisible: true,
        title: "Time"
    },
    {
        isVisible: true,
        title: "Invoices"
    },
    {
        isVisible: true,
        title: "Staff"
    },
    {
        isVisible: true,
        title: "Services"
    },
    {
        isVisible: true,
        title: "Payment Method"
    },
    {
        isVisible: true,
        title: "Total Amount"
    },
    
    {
        isVisible: false,
        title: "Location"
    },
    {
        isVisible: false,
        title: "Tips"
    },
    {
        isVisible: false,
        title: "Taxes"
    },
    {
        isVisible: false,
        title: "Action"
    }
]
export const AllStatus=[
    { value: "", label: "All Status" },
    {value:"0",label:"Order to Review"},
    {value:"1",label:"Accepted"},
    {value:"2",label:"Order Prepairing"},
    {value:"3",label:"Ready for Pickup"},
    {value:"4",label:"Pickup"},
    {value:"5",label:"Delieverd"},
    {value:"6",label:"Pickup by Customer"},
    {value:"7",label:"Cancelled"},
    {value:"8",label:"Rejected"},
    {value:"9",label:"Returned"},
]
export const AllServices=[
    {value:"0",label:"All Services"}
]
export const AllStuffs=[
    {value:"0",label:"All Stuffs"}
]
export const filterOptions = [
    {
        title: "All Stuffs",
        data: AllStuffs
    },
    {
        title: "All Services",
        data: AllServices
    },
    {
        title: "All Status",
        data: AllStatus
    },
]
export const APPOINTMENT_STATUS = {
    REVIEWING: 0,
    ACCEPTED_BY_SELLER: 1,
    CHECKED_IN: 2,
    COMPLETED: 3,
    REJECTED_BY_SELLER: 4,
    CANCELLED_BY_CUSTOMER: 5,
  };
  export const CALENDAR_MODES = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
  };
  
  export const CALENDAR_VIEW_MODES = {
    LIST_VIEW: "LIST_VIEW",
    CALENDAR_VIEW: "CALENDAR_VIEW",
  };
  
  export const DELIVERY_MODE = {
    1: "Delivery",
    2: "Reservation",
    3: "Walkin",
    4: "Shipping",
  };
  
  export const CALENDAR_TIME_FORMAT = {
    TWELVE_HOUR: true, // In react native big calendar package `ampm` true means it is 12 hour format
    TWENTY_FOUR_HOURS: false, // In react native big calendar package `ampm` false means it is 24 hour format
  };
  
  export const APPOINTMENT_REQUEST_MODE = {
    MANUAL: "manually",
    AUTOMATIC: "automatically",
  };
  export const EMPLOYEES_COLOR_SET_MODE = {
    DEFAULT: "default",
    MANUAL: "manual",
  };
  
  