import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import ReactSelect from "react-select";
import { Dropdown } from "react-bootstrap";
import { selectLoginAuth } from '../../auth/authSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { getPickupDeliveryDataApiAsync, getSellerAddressesApiAsync, getUserSettingsApiAsync, updatePickupDeliveryDataApiAsync, updateSellerInfoApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Loader from '../../../components/UI/Loader/Loader';
const OrderpickupDelivery = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const auth = useSelector(selectLoginAuth);
  const [sellerAddressesDetails, setsellerAddressesDetails] = useState(null);
  const [userSettingInfo, setuserSettingInfo] = useState(null);

    
    const resultSelectOption = [
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "150", label: "150" },
        { value: "200", label: "200" },
    ];
    const deliveryRadius = [
        { value: 0, label: "0 miles" },
        { value: 5, label: "5 miles" },
        { value: 10, label: "10 miles" },
        { value: 15, label: "15 miles" },
        { value: 20, label: "20 miles" },
        { value: 25, label: "20 miles" },
    ];
    const [loading, setLoading] = useState(false)
    const handleDiscountOption = (options) => {
        setDiscountOption(options);
    };
    
    const getSellerAddressDetails=async()=>{
        const prams={
            postData: {
                // seller_id: auth?.payload?.uniqe_id
                //     ? auth?.payload?.uniqe_id
                //     : auth?.payload?.user?.unique_uuid,
                seller_id:"2db22768-195d-4b64-b0bf-060d980bba18",
            },
            tokenData: auth?.payload?.token,
        };
        dispatch(getSellerAddressesApiAsync(prams))
        .then(unwrapResult)
        .then(res=>{
          if(res?.payload){
                setsellerAddressesDetails(res?.payload);
            }
        })
        .catch(err=>{
        })
    }
    const updateSellerAddressInfo=(data)=>{
        let prams={
            postData: {
               ...data
            },
            tokenData: auth?.payload?.token,
        }
        dispatch(updateSellerInfoApiAsync(prams))
        .then(unwrapResult)
        .then(res=>{

        })
        .catch(err=>{

        })
    }
    const handleLocationChange = (index,key, value) => {
        let locations = [...sellerAddressesDetails];
        locations[index][key] = value;
        setsellerAddressesDetails(locations);
        if(value===false||value===true){
           value= value===true?'1':'0';
        }
        updateSellerAddressInfo({   id:locations[index].id,[key]:value});
    };
    useEffect(()=>{
        getSellerAddressDetails();
    },[])
    const [discountOption, setDiscountOption] = useState("dollar");

    const getUserSetting=async()=>{
        let prams={
            postData: {
                app_name:"merchant",
                seller_id:"0abd886e-d6c4-4fe0-8be2-c928f41530ca",
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(getUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setuserSettingInfo(res?.payload);
        setLoading(false);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    useEffect(()=>{
        getUserSetting();
    },[])
    const updateUserSetting=async(data)=>{
        let prams={
            postData: {
                ...data
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(updateUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        // setOrderProcessingData(res.data);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    const handleUserSetting=(key,value)=>{
        setuserSettingInfo(prev=>({
            ...prev,[key]:value
        }));
        updateUserSetting({[key]:value});
    }
    return (
        <>
            {/* Start Pickup and Delivery */}
            {loading&&
                <Loader/>
               }
            <div className='deliveryPickup_'>
                <div className='processignOrder'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Pickup & Delivery</h3>
                        <p className='textInnerHead2'>Get your locations set up to accept pickup and delivery orders</p>
                    </div>
                    <div className="commonTable pickuptable_ table-responsive mt-3">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th>Location</th>
                                    <th>Address</th>
                                    <th>Pickup</th>
                                    <th>Delivery</th>
                                    {/* 
                                            Add functionality later
                                    <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sellerAddressesDetails?.map((item, index) => (
                                        <tr key={index}>
                                            <td className='fontSize14 txtLightBlue'>
                                                {item?.address_type}
                                            </td>
                                            <td className="fontSize14 txtLightBlue">{item?.format_address}</td>
                                            <td className="fontSize14 txtLightBlue"><input type="checkbox" onChange={(event)=>{
                                                let value=event.target.checked;
                                                handleLocationChange(index,"allow_local_pickup",value);
                                            }} checked={item?.allow_local_pickup} /></td>
                                            <td className="fontSize14 txtLightBlue"><input type="checkbox" onChange={(event)=>{
                                                let value=event.target.checked;
                                                handleLocationChange(index,"allow_jobr_delivery",value);
                                            }} checked={item?.allow_jobr_delivery} /></td>
                                            {/* 
                                            Add functionality later
                                            <td className="fontSize14 txtLightBlue"><button className={`${item.confirm? 'confirmbtn_':'notconfirmbtn_'}`} type='submit'>{item.confirm? 'Confirmed':'Confirm Address'}
                                            {
                                                !item.confirm&& <img className='uparrowpickup_' src={Images.redarrow_} />
                                            }
                                             </button></td> */}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className='processignOrder mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Delivery area radius </h3>
                        <p className='textInnerHead2'>Define the geographical area around your business location where you're willing to deliver orders directly to customers. Outside this zone, the system will automatically activate shipping options for customer delivery, providing convenient and efficient delivery services.</p>
                    </div>
                    <div className="commonTable pickuptable_ table-responsive mt-3">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th>Location</th>
                                    <th>Address</th>
                                    <th>Radius</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sellerAddressesDetails?.map((item, index) => (
                                        <tr key={index}>
                                            <td className='fontSize14 txtLightBlue'>
                                                {item?.address_type}
                                            </td>
                                            <td className="fontSize14 txtLightBlue">{item?.format_address}</td>
                                            <td className="reactSelectParent commonSelectParent">
                                                <ReactSelect
                                        options={deliveryRadius}
                                        value={deliveryRadius.find(option => option.value === item?.delivery_area_radius)}
                                        onChange={(event)=>{
                                            handleLocationChange(index,"delivery_area_radius",event.value);
                                        }}
                                        placeholder="Manual"
                                        classNamePrefix="react-select"
                                        className="react-select-container anlyticSelect"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                maxWidth: "100% !important",
                                                width: "100%",
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: "1px solid #D7DEFF",
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                paddingLeft: "unset !important",
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected
                                                    ? "#263682"
                                                    : state.isFocused
                                                        ? "#f5f6fc"
                                                        : "#fff",

                                                color: state.isSelected ? "#fff" : "#263682",
                                                fontSize: "18px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: "#636E9F",
                                                fontSize: "18px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator: () => (
                                                <img
                                                    src={Images.selectImg}
                                                    width={24}
                                                    height={24}
                                                    alt="drop_icon"
                                                    className="mt-1"
                                                />
                                            ),
                                        }}
                                    /></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Feature settings</h3>
                        <p className='textInnerHead2'>Tell us more about how you operate your business to automatically calculate the pickup/delivery times</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox" checked={userSettingInfo?.auto_assign_pickup_delivery_time}
                                onChange={(event)=>{
                                    handleUserSetting("auto_assign_pickup_delivery_time",event.target.checked)
                                }}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue m-0">
                                        Calculate & assign pickup/delivery times automatically
                                    </h3>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox"
                                checked={userSettingInfo?.allow_custmr_to_schdl_pickup_delivery}
                                onChange={(event)=>{
                                    handleUserSetting("allow_custmr_to_schdl_pickup_delivery",event.target.checked)
                                }}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue m-0">
                                        Allow customers to schedule pickup/delivery orders for future days
                                    </h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Timing</h3>
                        <p className='textInnerHead2'>When can the customer pick up their order? Please specify the order time and items for an accurate estimate.</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox"
                                 checked={userSettingInfo?.pickup_order_same_day}
                                 onChange={(event)=>{
                                     handleUserSetting("pickup_order_same_day",event.target.checked);
                                 }}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue m-0">
                                    The same day they place the order
                                    </h3>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox"
                                 checked={userSettingInfo?.pickup_order_following_day}
                                 onChange={(event)=>{
                                    handleUserSetting("pickup_order_following_day",event.target.checked);
                                 }}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue m-0">
                                        On a following working day
                                    </h3>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Minimum order amount</h3>
                        <p className='textInnerHead2'>When can the customer pick up their order? Please specify the order time and items for an accurate estimate.</p>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className='serviceSettingBoxes'>
                                <div className=" d-flex align-items-start  gap-3">
                                    <input type="checkbox" 
                                    checked={userSettingInfo?.min_amt_order_enable}
                                    onChange={(event)=>{
                                        handleUserSetting("min_amt_order_enable",event.target.checked);
                                    }}
                                    />
                                    <div>
                                        <h3 className="fontSize14 txtDarkblue m-0">
                                            Require a minimum amount for orders to eligible for delivery
                                        </h3>
                                    </div>
                                </div>
                                <div className='amountvalue_ mt-3'>
                                    <input type="text" 
                                     value={userSettingInfo?.min_amt_order_amount}
                                     disabled={!(userSettingInfo?.min_amt_order_enable)}
                                     onChange={(event)=>{
                                        if (debounceTimeout) {
                                            clearTimeout(debounceTimeout);
                                          }
                                          setuserSettingInfo(prev=>({
                                             ...prev,
                                              min_amt_order_amount: event.target.value,
  
                                          }))
                                      
                                          // Set a new timeout to call handleUserSetting after the user stops typing for 500ms (debounce delay)
                                          const timeoutId = setTimeout(() => {
                                            handleUserSetting("min_amt_order_amount", event.target.value);
                                            setDebounceTimeout(null);
                                          }, 1000); // Adjust the debounce delay as needed (e.g., 500ms)
                                          setDebounceTimeout(timeoutId);
                                         
                                     }}
                                    className='customform-control' placeholder='100' />
                                    <span className='mindollr'>$</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='processignOrder featureseting mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Delivery rates</h3>
                        <p className='textInnerHead2'>Set up delivery rates for each region you want to ship to, or for specific items. </p>
                    </div>
                    {/* <div className="row mt-4">
                        <div className="col-md-12">
                            <div className='serviceSettingBoxes'>
                                <div className="reactSelectParent commonSelectParent w-100">
                                    <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                        Delivery fee
                                        <img src={Images.labelImg} alt="logo" className="ms-2" />
                                    </label>
                                    <ReactSelect
                                        options={resultSelectOption}
                                        onChange={(event)=>{
                                            let value=event.value;
                                        setOrderPickupDelivery(prev=>({
                                            ...prev,
                                            delivery_rates:{
                                               ...prev.delivery_rates,
                                               delivery_fee:value
                                            }
                                        }))
                                        }}
                                        placeholder="Manual"
                                        classNamePrefix="react-select"
                                        className="react-select-container anlyticSelect"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                maxWidth: "100% !important",
                                                width: "100%",
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: "1px solid #D7DEFF",
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                paddingLeft: "unset !important",
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected
                                                    ? "#263682"
                                                    : state.isFocused
                                                        ? "#f5f6fc"
                                                        : "#fff",

                                                color: state.isSelected ? "#fff" : "#263682",
                                                fontSize: "18px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: "#636E9F",
                                                fontSize: "18px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator: () => (
                                                <img
                                                    src={Images.selectImg}
                                                    width={24}
                                                    height={24}
                                                    alt="drop_icon"
                                                    className="mt-1"
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                                <div className='amountvalue_ mt-3'>
                                    <input type="text"
                                     value={OrderPickupDelivery.delivery_rates.order_amount}
                                     onChange={(event)=>{
                                         setOrderPickupDelivery(prev=>({
                                             ...prev,
                                             delivery_rates:{
                                                ...prev.delivery_rates,
                                                order_amount:event.target.checked
                                             }
                                         }))
                                     }}
                                    
                                    className='customform-control' placeholder='100' />
                                    <span className='mindollr'>$</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className='serviceSettingBoxes'>
                                <div className=" d-flex align-items-start  gap-3">
                                    <input type="checkbox" 
                                    
                                    checked={userSettingInfo?.service_fees_charge_enable}
                                    onChange={(event)=>{
                                       handleUserSetting("service_fees_charge_enable",event.target.checked);
                                    }}
                                    />
                                    <div>
                                        <h3 className="fontSize14 txtDarkblue">
                                            Charge a service fee on delivery orders
                                        </h3>
                                        <h3 className="fontSize12 txtLightBlue mb-0">
                                            This service fee is charged in addition to the delivery fee. You keep the amount charged, minus standard processing fees.
                                        </h3>
                                    </div>
                                </div>
                                <div className='service_fee'>
                                    <div className='amountvalue_'>
                                        <input type="text"
                                         value={userSettingInfo?.service_fees_charge_amount}
                                         disabled={!(userSettingInfo?.service_fees_charge_enable)}
                                         onChange={(event)=>{
                                            if (debounceTimeout) {
                                                clearTimeout(debounceTimeout);
                                              }
                                              setuserSettingInfo(prev=>({
                                                ...prev,
                                                service_fees_charge_amount:event.target.value,
                                              }))
                                          
                                              // Set a new timeout to call handleUserSetting after the user stops typing for 500ms (debounce delay)
                                              const timeoutId = setTimeout(() => {
                                                handleUserSetting("service_fees_charge_amount", event.target.value);
                                                setDebounceTimeout(null);
                                              }, 1000); // Adjust the debounce delay as needed (e.g., 500ms)
                                              setDebounceTimeout(timeoutId);
                                         }}
                                        className='customform-control' placeholder='100' />
                                        <span className='mindollr'>$</span>
                                    </div>

                                    <ul className="discountTab m-0">
                                        <li
                                            className={`discountTabList ${discountOption === "dollar" ? "active" : ""
                                                }`}
                                            onClick={() => handleDiscountOption("dollar")}
                                        >
                                            $
                                        </li>
                                        <li
                                            className={`discountTabList discountTabListTwo ${discountOption === "percentage"
                                                ? "active"
                                                : ""
                                                }`}
                                            onClick={() =>
                                                handleDiscountOption("percentage")
                                            }
                                        >
                                            %
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Pickup and Delivery */}
        </>
    )
}

export default OrderpickupDelivery