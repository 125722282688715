import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeAppointmentStatusApiAsync, getAppointemntApiAsync } from '../../dashboard/dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import { TimePeriodNav } from '../../../constants/timeperiod.constant';
import DatePickerCustomComponent from '../../../components/UI/Orders/DatePickerCustomComponent';
import ReactDatePicker from "react-datepicker";
import { Link } from '@mui/material';
import {Link as Href} from "react-router-dom"

import * as Images from "../../../../utilities/images"
import { getDateTimeStampConvertor, getFormatedDate } from '../../../../utilities/helpers';
import { BookingColumns, filterOptions } from '../../../constants/bookings/allbooking';
import { resultSelectOption } from '../../../constants/common.constant';
import SelectMenu from '../../../components/UI/Orders/SelectMenu';
import { Dropdown } from 'react-bootstrap';
import DeliveryOptions from '../../../components/UI/Orders/DeliveryOptions';
import DeliveryStatus from '../../../components/UI/Orders/DeliveryStatus';
import BookingStatus from '../../../components/UI/Bookings/BookingStatus';
const AllBookings = () => {
  const dispatch = useDispatch();
  const [Bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sellerAddress, setsellerAddress] = useState('');
  const [filterValue, setFilterValue] = useState("week")
  // time period
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnsNames, setColumnNames] = useState(BookingColumns);
  const [allStatus, setAllStatus] = useState(null);
  const [allServices, setAllServices] = useState(null)
  const [allStuffs, setAllStuffs] = useState(null);
  const [totalData, settotalData] = useState(0)

  const auth = useSelector(selectLoginAuth);
  const resetFilters = () => {
    setBookings(null);
  }
  const getAllBookings = () => {
    setLoading(true);
    resetFilters();
    const prams = {
      postData: {
        page: currentPage,
        limit: pageLimit,
        filter_by: filterValue,
        seller_id: "29d0e7bc-af42-4505-bcea-3e54817fcb2d",
        // auth?.payload?.uniqe_id
        //   ? auth?.payload?.uniqe_id
        //   : auth?.payload?.user?.unique_uuid,
        ...(allStatus&&{status:allStatus}),

        ...(endDate && { start_date:getFormatedDate(startDate,"YYYY-MM-DD"), end_date: getFormatedDate(endDate,"YYYY-MM-DD") }),
        ...(sellerAddress && { seller_address_id: sellerAddress }),

      },
      tokenData: auth?.payload?.token,
    }
    dispatch(getAppointemntApiAsync(prams))
      .then(unwrapResult)
      .then(res => {
        setBookings(res?.payload?.data);
        setTotalPages(res?.payload?.total_pages)
        settotalData(res?.payload?.total);
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })

  }
 const handleAppointmentStatus=(id,status)=>{
    let orderStatus = {
        postData: {
          id: id,
          status: status,
        },
        tokenData: auth?.payload?.token,
      };
     setLoading(true);
      dispatch(changeAppointmentStatusApiAsync(orderStatus)).then(res=>{
        setLoading();
      })
      .catch(err=>{
        setLoading(false);
      })
 }
  const handleFilterOptions = (value, key) => {
    if (key == "All Stuffs") {
      console.log("all orders", value)
      setAllStuffs(value);
    }
    else if (key == "All Status") setAllStatus(value);
    else setAllServices(value);
  }
    
  const handleCurrentPage = (value) => {
    setCurrentPage(prev => {
        if (prev + value <= totalPages && prev + value >= 1) return prev + value;
        return prev;
    })
}
useEffect(()=>{
  getAllBookings()
},[currentPage])
  useEffect(() => {
    setCurrentPage(1);
    getAllBookings()
  }, [filterValue, endDate, sellerAddress, pageLimit, allServices, allStatus, allStuffs]);
  return <>
    <div className="walletStats ">
      <div className="productOfferHead_Container">
      {/* header */}
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="fontSize24 txtDarkblue fw500 mb-0">All Bookings <span className='countLight_'>(1200)</span></h3>
          <div className="d-flex align-items-center gap-4">
            <ReactDatePicker
              onChange={(dates) => {
                const [start, end] = dates;
                if (start == end) {
                  setStartDate(null);
                  setEndDate(null);
                  return;
                }
                setStartDate(start);
                setEndDate(end);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              maxDate={new Date()}
              customInput={<DatePickerCustomComponent startDate={startDate} />}
            />
            <ul className="weekNav">
              {
                TimePeriodNav.map((data, index) => (
                  <li key={index} onClick={() => setFilterValue(data.value)}>
                    <Link
                      className={`weekMenu ${filterValue == data.value ? "active" : ""
                        }`}
                      to="#"
                    >
                      {data.label}
                    </Link>
                  </li>
                ))
              }
            </ul>
            <img src={Images.NotificationIcon} alt="NotificationIcon" />
            <img src={Images.SearchIcon} alt="SearchIcon" />
          </div>
        </div>
      {/* header */}
      {/* menu */}
<div className="row align-items-center mt-4">

<div className="col-md-7">
    <div className="d-flex align-items-center gap-3">
        {
            filterOptions.map((filter, index) => (
                <div className="reactSelectParent offerHeaderSelect gapDiffer_">
                    <SelectMenu
                        options={filter.data}
                        placeholder={filter.title}
                        onChange={(event) => { handleFilterOptions(event.value, filter.title) }}
                    />
                </div>
            ))
        }

        

    </div>
</div>
<div className='col-md-5'>
    <div className='d-flex justify-content-end gap-3'>
        <div className="d-flex align-items-center gap-2">
            <h3 className="fontSize14 txtDarkblue mb-0">Showing Results</h3>
            <div className="reactSelectParent offerHeaderSelect">
                <SelectMenu
                    options={resultSelectOption}
                    placeholder="10"
                    onChange={(e) => {
                        setCurrentPage(1);
                        setPageLimit(e.value);
                    }}
                />
            </div>
        </div>
        <div className="paginationParent">
            <div className="d-flex align-items-center gap-1">
                <div className={`prevPagebtn ${currentPage <= 1 && "disablePrevbtn"} `} onClick={() => {
                    if (currentPage <=1) return;
                    handleCurrentPage(-1);
                }}>
                    <img src={Images.arrowDoubleLeft} alt="arrowDoubleRight" />
                </div>
                <div className={`prevPagebtn ${currentPage <= 1 && "disablePrevbtn"} `} onClick={() => {
                    if (currentPage <=1) return;
                    handleCurrentPage(-1);
                }}>
                    <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                </div>
            </div>
            <h6 className="fontSize14 txtDarkblue mb-0">{(((currentPage-1)*pageLimit)+1)>Bookings?.total?Bookings?.total:(((currentPage-1)*pageLimit)+1)}-{(currentPage*pageLimit)>Bookings?.total==true?Bookings?.total:(currentPage*pageLimit)} of {totalData}</h6>
            <div className="d-flex align-items-center gap-1">
                <div className={`nextPagebtn ${currentPage === totalPages && "disableNextbtn"}`} onClick={() => {
                    if (currentPage === totalPages) return;
                    handleCurrentPage(1);
                }}>
                    <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                </div>
                <div className={`nextPagebtn ${currentPage === totalPages && "disableNextbtn"}`} onClick={() => {
                    if (currentPage === totalPages) return;
                    handleCurrentPage(1);
                }}>
                    <img src={Images.arrowDoubleRight} alt="arrowDoubleRight" />
                </div>
            </div>
        </div>
    </div>
</div>
</div>
{/* menu */}
      </div>
{/* table */}
<div className="commonTable sellingProductTable table-responsive mt-3">
                <table className="w-100 allorderTable_">
                    <thead>
                        <tr>
                            
                            <th>#</th>
                            {
                                columnsNames.map((column, index) => (
                                    <th
                                        key={index}
                                        className={!column.isVisible && "d-none"}
                                    >
                                        {column.title}
                                    </th>
                                ))
                            }

                            <th>
                                <div className="d-flex align-items-center gap-1 justify-content-end">
                                    <img src={Images.downloadOrder_} alt="icon" />
                                    <div>

                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic"
                                                className="variantsTable_Dropdown"
                                            >
                                                <img src={Images.plusIconWithBg} alt="icon" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="variantsDropdownMenu categoryDropdownMenu">
                                                <Link to="#" className='resetText_'>Reset</Link>
                                                {
                                                    columnsNames.map((column, index) => (

                                                        <Dropdown.Item onClick={() => {
                                                            let columns = columnsNames;
                                                            columns[index].isVisible = !column.isVisible;
                                                            setColumnNames([...columns]);
                                                        }}>
                                                            <div className="productAvailabilityChild align-items-center">
                                                                <div className="form-group">
                                                                    <input type="checkbox" checked={column.isVisible} />
                                                                    <label htmlFor="check3" className="me-0"></label>
                                                                </div>
                                                                <h3 className="fontSize14 mb-0 mt-1">{column.title}</h3>
                                                            </div>
                                                        </Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <img src={Images.moreHorizontalLogo} alt="icon" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? (
                                <tr className='text-center border-none' >
                                    {/* Full-width spinner row */}
                                    <td colSpan="100%" >
                                        <div className='d-flex'>
                                            <span className="spinner-border inner-spin spinner-border-sm"></span>
                                        </div>
                                    </td>
                                </tr>
                            ) :
                            Bookings?.length>0 && Bookings?.map((booking, index) => (
                                    <tr key={index}>
                                        
                                        <td>{((currentPage-1)*pageLimit)+ index + 1}</td>
                                        {/* customer profile */}
                                        <td className={`${!columnsNames[0].isVisible && 'd-none'}`}>
                                            <div className="d-flex align-items-center gap-3 ">
                                                <img
                                                    src={booking?.user_details?.profile_photo ||
                                                        Images.orderProfile}
                                                    alt="img"
                                                    className="productItemImg"
                                                />
                                                <div>
                                                    <h3 className="darkblack mb-0 pb-1 text-start">
                                                    {booking?.user_details?.firstname +
                      " " +
                      booking?.user_details?.lastname}
                                                    </h3>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <h6 className="orederLoc_ fontSize14 fw500 mb-0">
                                                            <img
                                                                src={Images.locateOrder}
                                                                alt="img"
                                                                className="location"
                                                            />
                                                           {booking?.user_details?.current_address?.street_address?.slice(0,10)}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        {/* time */}
                                        <td className={`fontSize12 darkblack ${!columnsNames[1].isVisible && 'd-none'}`}>
                                            {
                                            getDateTimeStampConvertor(booking?.date)?.[0]
                                            }
                                            <br />
                                            <span className='orderclock_'><img src={Images.Clockorder} alt="img" className="" />
                                                {getDateTimeStampConvertor(booking?.date)?.[1]}
                                            </span>
                                        </td>
                                        {/* invoice */}
                                        <td className={`fontSize12 darkblack ${!columnsNames[2].isVisible && 'd-none'}`}># {booking?.invoices?.invoice_number}</td>
                                        {/* staff */}
                                        <td className={`fontSize12 darkblack ${!columnsNames[3].isVisible && 'd-none'}`}> 
                                          {booking?.pos_user_details?.user?.user_profiles?.firstname +
                                            " " +
                                            booking?.pos_user_details?.user?.user_profiles?.lastname
                                        }</td>
                                         {/* Status */}
                                         <td className={`fontSize12 darkblack ${!columnsNames[4].isVisible && 'd-none'}`}>
                                            <DeliveryStatus statusOption={booking?.status} />
                                        </td>
                                        {/* Payment */}
                                        <td className={`fontSize12 darkblack ${!columnsNames[5].isVisible && 'd-none'}`}> {
                                            booking?.mode_of_payment

                                        }
                                        </td>
                                        {/* totalAmount */}
                                        <td className={`${!columnsNames[6].isVisible && 'd-none'}`}>

                                            <div className="deliverTableBx">
                                                <img
                                                    src={Images.moneyImg}
                                                    alt="location Image"
                                                    className="img-fluid ms-1"
                                                />
                                                <span className="locateDistance">{
                                                    booking?.price
                                                }</span>
                                            </div>
                                        </td>
                                       
                                        {/* Location */}
                                        <td className={`${!columnsNames[7].isVisible && 'd-none'}`}>
                                            <span>{booking?.user_details?.current_address?.state}</span>
                                        </td>
                                        {/* Tips */}
                                        <td className={`${!columnsNames[8].isVisible && 'd-none'}`}>
                                            <span>{booking?.tips||0.0}</span>
                                        </td>
                                        {/* taxes */}
                                        <td className={`${!columnsNames[9].isVisible && 'd-none'}`}>
                                            <span>{booking?.taxes||0.0}</span>
                                        </td>
                                        <td className={`${!columnsNames[0].isVisible && 'd-none'}`}>
                                            <BookingStatus
                                            handleAppointmentStatus={handleAppointmentStatus}
                                            status={booking?.status}
                                            appointmentID={booking?.id}
                                            />
                                        </td>
                                        
                                       { <td className="text-end">
                                        <Href to={booking?.status===3?`/orderReview/${booking?.status}`:"#"}>
                                           {booking?.status==3&& <img src={Images.arrow_next} alt="logo" />}
                                            </Href>
                                        </td>}
                                    </tr>
                                ))
                        }

                    </tbody>
                </table>
            </div>
{/* table */}
    </div>
  </>
}

export default AllBookings