import React, { useEffect, useRef, useState } from "react";
import {
  OrderDown,
  cancleorder,
  incoiceuser_,
  modalCancel,
  pickuporder,
  profileImg1,
  returnlogo,
  serviceuser_,
  unselectedOrder,
  drivefast_,
  upArrow,
  locateOrder,
  clockBlue,
  moneyImg,
  mapimg,
  deliveryMap,warningicon,
  fillradio_,
  verifyorder,
  orderDriver,done,cancelledstep,
  readystep,
  acceptstep,
  openTag,
  closeTag
} from "../../../../../utilities/images";
import {Link} from "react-router-dom"
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  changeStatusApiAsync,
  getOrderDetailByOrderIdAsync,
} from "../../../dashboard/dashboardSlice";
import { selectLoginAuth } from "../../../auth/authSlice";
import { useParams } from "react-router-dom";
import CustomModal from "../../../../components/shared/CustomModal";
import VerifyOrderModal from ".././VerifyOrderModal";
import PrintLabelModal from ".././PrintLabelModal";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import GoogleMapView from "../../../../components/UI/Orders/GoogleMapView";
import ".././orderReview.css"
import { getFormatedDate } from "../../../../../utilities/helpers"; 
import OrderTrackStatusCard from "../OrderTrackStatusCard";
import OrderInvoiceDetails from "../OrderInvoiceDetails";
const OrderReview = () => {
  const orderOveriewRef=useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [showModalContent, setShowModalContent] = useState(true)
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState("");
  const [hideOrderStatus, setHideOrderStatus] = useState(true);
  const [printingUrl, setPrintingUrl] = useState("");
  const [key, setKey] = useState(Math.random());
  const [isBackgroundVisible, setIsBackgroundVisible] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  // handle order overview background
  const handleOrderOverview = () => {
    let background = ( !showMap ) ? `url('${deliveryMap}') no-repeat center` : '';
    orderOveriewRef.current.style.background = background;
}
const handleOrderStatusView=()=>{
  setHideOrderStatus(prev=>!prev);
}
const checkStatusOrder={
  delivery:done,
  cancelleds:cancelledstep,
  ready:readystep,
  accept:acceptstep
}
useEffect(()=>{
  handleOrderOverview();
},[showMap]);
  const subTotal = parseFloat(orderData?.total_sale_price)
  const discount = parseFloat(orderData?.discount)
  const [map, setMap] = useState(/** @type google.maps.Map */(null));
  const [mapStatus, setMapStatus] = useState(false);
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const pickupLatitude = parseFloat(
    orderData?.order_delivery?.order_pickup_latitude ?? 0.0
  );
  const pickupLongitude = parseFloat(
    orderData?.order_delivery?.order_pickup_longitude ?? 0.0
  );
  const deliverLatitude = parseFloat(
    orderData?.order_delivery?.order_delivery_latitude ?? 0.0
  );
  const deliverLongitude = parseFloat(
    orderData?.order_delivery?.order_delivery_longitude ?? 0.0
  );
  const center = { lat: pickupLatitude, lng: pickupLongitude };
  const origin = { lat: pickupLatitude, lng: pickupLongitude };
  const Destination = { lat: deliverLatitude, lng: deliverLongitude };

  const directionsOptions = {
    destination: Destination,
    origin: origin,
    travelMode: "DRIVING",
  };
  const directionsCallback = (response) => {
    if (response !== null && map !== null) {
      if (response.status === "OK" && mapStatus == false) {
        setMapStatus(true);
        setDirectionsResponse(response);
        console.log("called");
      } else {
        console.error("Directions request failed:", response);
        setMapStatus(true);
      }
    }
  };
  console.log(pickupLatitude, pickupLongitude, deliverLatitude, deliverLatitude, 'cordinatesssssssss');
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const getOrderDataById = () => {
    let data = {
      id: id,
      token: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getOrderDetailByOrderIdAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        setOrderData(obj?.payload);
        let orderDetails=obj?.payload;
        console.log(orderDetails,"check now")
        if(orderDetails){
          let isDelivery=handleDeliveryOptionStatus([1,2,3,4],orderDetails.delivery_option);
          let isStatus=handleOrderStatus([0,1,2,3],orderDetails?.status);

          if(!(isDelivery&&isStatus)){
            setShowMap(true);
          }else{
            setShowMap(false);
          }
        }
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const changeStatusOfOrderHandle = (id, status) => {
    let orderStatus = {
      postData: {
        id: id,
        status: status,
      },
      tokenData: auth?.payload?.token,
    };

    status === 8 ? setLoading1(true) : setLoading2(true);
    dispatch(changeStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((obj) => {
        getOrderDataById();
        setLoading1(false);
        setLoading2(false);
      })
      .catch((obj) => {
        setLoading1(false);
        setLoading2(false);
      });
  };

  console.log(orderData, "order dtaaaaaaaaaaaaaaaaaa");


  useEffect(() => {
    getOrderDataById();
  }, []);

  //check order type
  const handleDeliveryOptionStatus=(option,orderType_)=>{
    //check if array
    if(Array.isArray(option) && option.length > 0){
      //check if delivery_option is in array
      return option.includes(Number(orderType_))
    }
    //if not array then check if delivery_option is greater than 0
    return option==orderType_;
  }
  //check order status
  const handleOrderStatus=(option,orderStatus_)=>{
    //check if array
    if(Array.isArray(option) && option.length > 0){
      //check if delivery_option is in array
      return option.includes(Number(orderStatus_))
    }
    //if not array then check if delivery_option is greater than 0
    return option==orderStatus_;
  }
  return (
    <div ref={orderOveriewRef} className="orderReview_">
      {/* {
        showMap&&
      } */}
      {loading ? (
        <span className="spinner-border inner-spin spinner-border-sm"></span>
      ) : (
        <div className="row">
          <div className={`col-md-${!showMap?'5':'3'}`}>
          {
              !showMap?  <div className="chartsOuter ">
              <div className="scheduleModal">
                <div className="orderReviewid_">
                  <Link to="/allOrder">
                  <img className="backarrow_" src={upArrow} /></Link>
                  <div className="oredreidText_">
                    <h3 className="blueHeading_"># {orderData?.invoices?.id }</h3>
                    <h6 className="bluesmall_">Review Order</h6>
                  </div>
                </div>
               <>
                <div className="scheduleModalContent">
                  <div className="invoiceUser_ row">
                    <div className="col-md-6">
                      <div className="reviewuserinfo_">
                        <img
                          className="invoveuser me-3"
                          style={{ width: "50px" }}
                          src={
                            orderData?.user_details?.profile_photo
                              ? orderData?.user_details?.profile_photo
                              : profileImg1
                          }
                        />
                        <article className="ivoiceuserDetail_">
                          <h4 className="monshead14">
                            {
                              orderData?.user_details?.firstname+" "+
                              orderData?.user_details?.lastname
                            }
                          </h4>
                          <div className="d-flex align-items-center gap-2">
                            <h6 className="orederLoc_ fontSize14 fw500 mb-0">
                              <img
                                src={locateOrder}
                                alt="img"
                                className="location"
                              />  {
                                orderData?.user_details?.current_address?.street_address
                              }
                            </h6>
                          </div>
                        </article>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="reviewuserinfo_">

                        <article className="ivoiceuserDetail_">
                          <h4 className="monshead14 ">
                          {orderData?.delivery_details?.title}
                          </h4>
                          {
                              orderData?.delivery_details?.deliver_in_hours&&

                          <h6 className="hourWindow_">
                            <img
                              src={clockBlue}
                              alt="img"
                              className="location"
                              />  {
                                orderData?.delivery_details?.deliver_in_hours
                            }
                          </h6>
                            }
                        </article>
                      </div>
                    </div>


                  </div>
                  {
                  orderData?.order_details?.length > 0
                    ? orderData?.order_details?.map((val, i) => {
                      return (
                        <div key={i} className="serviceDeatils_">
                          <div className="serviceinfo_">
                            <figure className="proservice_ me-3">
                              <img
                                className="img-fluid"
                                src={val?.product_image}
                              />
                            </figure>
                            <article className="duration_">
                              <h3 className="darkblack">
                               {
                                val?.product_name
                               }
                              </h3>
                              <div className="colorsBox_">
                                <div className="colopalat_">
                                  <span className="pinkcolor_"></span>
                                  <span className="bluecolor_"></span>
                                  <span className="blackcolor_"></span>
                                  <span className="withoutcolor_"></span>


                                </div>
                                <h6 className="lightgreyHeading_">Colors / Size</h6>
                              </div>
                              {/* <p className='designation_'>White / s</p> */}
                            </article>
                          </div>
                          <div className="servicechanges_">
                            <span className="serciceprice_">
                              {/* ${val?.offer_price && val?.offer_price != 0 ? Number(val?.offer_price / val?.qty)?.toFixed(2) : Number(val?.actual_price)?.toFixed(2)} */}
                              {Number(val?.actual_price)?.toFixed(2)}
                            </span>
                            <span className="serciceprice_">{val?.qty}</span>
                            <span className="serciceprice_">
                              {/* ${val?.offer_price && val?.offer_price != 0 ? Number(val?.offer_price)?.toFixed(2) : Number(val?.qty * val?.actual_price)?.toFixed(2)} */}
                              {/* {
                                val?.offer_price > 0 ? (
                                  <span className="dollar" style={{ fontSize: 10 }}>
                                    <span className=""
                                      style={{
                                        textDecorationLine: 'line-through',
                                        textDecorationStyle: 'solid',
                                      }}
                                    >
                                      {`$${((Number(val?.actual_price) + Number(val?.variant_price ? val?.variant_price : 0)) * val?.qty)?.toFixed(2)}`}
                                    </span>
                                    <span className="">{`$${(Number(val?.offer_price) + Number(val?.variant_price ? val?.variant_price : 0))?.toFixed(2)}`}</span>
                                    <span className=""> {
                                      `  ${Math.round(
                                        ((((((Number(val?.actual_price) + Number(val?.variant_price ? val?.variant_price : 0)) * val?.qty)) - ((Number(val?.offer_price) + Number(val?.variant_price ? val?.variant_price : 0)))) / (((Number(val?.actual_price) + Number(val?.variant_price ? val?.variant_price : 0)) * val?.qty))) * 100)
                                      )}% Less`}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="">{`$${(((Number(val?.actual_price) + Number(val?.variant_price ? val?.variant_price : 0)) * val?.qty))?.toFixed(2)}`}</span>
                                )
                              } */}
                                    <span className="">{`$${(Number(val?.actual_price||0) * Number(val?.qty|| 0))?.toFixed(2)}`}</span>

                            </span>
                            {/* <div className="productAvailabilityChild ">
                              <div className="form-group servicecheck_">
                                <input type="checkbox" id="id1" />
                                <label htmlFor="id1" className="me-0"></label>
                              </div>
                            </div> */}
                            {/* <span className='serciceprice_'> <i class="fa-regular fa-rectangle-xmark"></i></span> */}
                          </div>
                        </div>
                      )
                    })
                    : orderData?.return_detail?.return_details?.map(
                      (val, i) => {
                        return (
                          <div key={i} className="serviceDeatils_">
                            <div className="serviceinfo_">
                              <img
                                style={{ width: "50px" }}
                                className="img-fluid me-3"
                                src={val?.order_details?.product_image}
                              />
                              <article className="duration_">
                                <h3 className="lightgreyHeading_">
                                  {val?.order_details?.product_name}
                                </h3>
                                {/* <p className='designation_'>White / s</p> */}
                              </article>
                            </div>
                            <div className="servicechanges_">
                              <span className="serciceprice_">
                                $
                                {Number(val?.order_details?.price)?.toFixed(2)}
                              </span>
                              <span className="serciceprice_">
                                {val?.order_details?.qty}
                              </span>
                              <span className="serciceprice_">
                                $
                                {Number(
                                  val?.order_details?.qty *
                                  val?.order_details?.price
                                )?.toFixed(2)}
                              </span>
                              {/* <span className='serciceprice_'> <i class="fa-regular fa-rectangle-xmark"></i></span> */}
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
                <div className="grandTotal_ row">

                  {
                    (orderData?.order_delivery && orderData?.delivery_option == '1' && orderData?.order_delivery?.cancel_order_otp && !orderData?.order_delivery?.has_returned) ?
                      <div className="col-md-5 text-start">
                        <div className="orderinvoive">
                          <h4 className="monshead22">Return</h4>
                          <h4 className="monshead22">
                            {orderData?.order_delivery?.updated_at
                              ? moment(
                                orderData?.order_delivery?.updated_at
                              ).format("DD MMM YYYY | HH:mm A")
                              : ""}
                          </h4>
                          <h5 className="lightgreyHeading_">Cancel Order Otp</h5>
                          <h4 className="monshead22">
                            {orderData?.order_delivery?.cancel_order_otp ? orderData?.order_delivery?.cancel_order_otp : "1234"}
                          </h4>
                        </div>
                      </div>
                      :
                      <div className="col-md-3 text-start">
                        <div className="orderinvoive">
                          <h5 className="lightgreyHeading_">Total Item</h5>
                          <h4 className="monshead22">{orderData?.total_items}</h4>
                          <h5 className="lightgreyHeading_">Order Date</h5>
                          <h4 className="monshead22">
                            {moment(orderData?.created_at).format("MM/DD/YYYY")}
                          </h4>
                          <h5 className="lightgreyHeading_">Order Id #</h5>
                          <h4 className="monshead22">
                            {orderData?.invoices?.order_id}
                          </h4>
                          <h5 className="lightgreyHeading_">Payment <br /> Method</h5>
                          <h4 className="monshead22">
                            <div className="deliverTableBx">
                              <img
                                src={moneyImg}
                                alt="location Image"
                                className="img-fluid ms-1"
                              />
                              <span className="locateDistance">{
                                orderData?.mode_of_payment
                              }</span>
                            </div>
                          </h4>

                          {/* {orderData?.delivery_option == 3 &&
                        orderData?.customer_pickup_otp ? (
                        <>
                          <h5 className="lightgreyHeading_">Pickup OTP</h5>
                          <div className="pickupBtn">
                            <button className="countbtn">
                              {orderData?.customer_pickup_otp}
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )} */}
                        </div>
                      </div>}

                  <div className="col-md-9 text-start">
                    <div className="orderBill_">
                      <div className="Customertotal">
                        <div className="totalDetails">
                          <h5 className="lightgreyHeading_ ">
                            Sub Total
                          </h5>
                          <h5 className="personalinfoHead ">
                            {/* ${Number(orderData?.actual_amount)?.toFixed(2)} */}
                            {console.log(orderData?.total_sale_price, orderData?.order_details?.length > 0, "dsssssssssssssssssssss")}
                            {
                              `$${(orderData?.order_details?.length > 0 ? (orderData?.order_details?.reduce((acc, curr) => {
                                return (
                                  acc +
                                  parseFloat(
                                    (parseFloat(curr?.actual_price)) *
                                    curr?.qty
                                  )
                                );
                              }, 0))?.toFixed(2) : "")}`
                            }
                            {
                              orderData?.order_details?.length <= 0 && (Number(orderData?.total_sale_price)?.toFixed(2))
                            }
                          </h5>
                        </div>
                        <div className="totalDetails">
                          <h5 className="textInnerHead2">Discount </h5>
                          <h5 className="personalinfoHead">

                            {console.log(orderData, "order dataaaaaaaaa")}
                            {/* ${orderData?.discount
                              ? Number(orderData?.discount)?.toFixed(2)
                              : 0} */}
                            {orderData?.is_returned_order ? `$${orderData?.return_detail?.discount ? orderData?.return_detail?.discount : 0}` :
                              // `$${((orderData?.order_details?.length > 0 ? (orderData?.order_details?.reduce((acc, curr) => {
                              //   return (
                              //     acc +
                              //     parseFloat(
                              //       (parseFloat(curr?.actual_price) +
                              //         parseFloat(curr?.variant_price ?? 0)) *
                              //       curr?.qty
                              //     )
                              //   );
                              // }, 0)) : 0) - (subTotal) + (parseFloat(discount)))?.toFixed(2)}`
                              `${orderData?.discount}`
                            }
                            {console.log((orderData?.order_details ? (orderData?.order_details?.reduce((acc, curr) => {
                              return (
                                acc +
                                parseFloat(
                                  (parseFloat(curr?.actual_price) +
                                    parseFloat(curr?.variant_price ?? 0)) *
                                  curr?.qty
                                )
                              );
                            }, 0)) : 0), "1", subTotal, discount, "ddddddddddddddddddddd")}
                          </h5>
                        </div>
                        {/* <div className="totalDetails">
                                                        <h5 className="textInnerHead2"> Other Fee</h5>
                                                        <h5 className="personalinfoHead">${orderData?.delivery_charge}</h5>
                                                    </div> */}
                        {orderData?.delivery_option == 1 ||
                          orderData?.delivery_option == 4 ? (
                          <>
                            <div className="totalDetails">
                              <h5 className="textInnerHead2">
                                {" "}
                                {orderData?.delivery_option == 4
                                  ? "Shipping"
                                  : "Delivery"}{" "}
                                Charge
                              </h5>
                              <h5 className="personalinfoHead">
                                $
                                {orderData?.delivery_option == 4
                                  ? Number(orderData?.shipping_charge)?.toFixed(
                                    2
                                  )
                                  : Number(orderData?.delivery_charge)?.toFixed(
                                    2
                                  )}
                              </h5>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="totalDetails">
                          <h5 className="textInnerHead2"> Tips</h5>
                          <h5 className="personalinfoHead">
                            ${Number(orderData?.tips)?.toFixed(2)}
                          </h5>
                        </div>
                        <div className="totalDetails">
                          <h5 className="textInnerHead2">Tax</h5>
                          <h5 className="personalinfoHead">
                            ${Number(orderData?.tax)?.toFixed(2)}
                          </h5>
                        </div>
                        {orderData?.transaction_fee && <div className="totalDetails">
                          <h5 className="textInnerHead2">Transaction Fee</h5>
                          <h5 className="personalinfoHead">
                            ${Number(orderData?.transaction_fee)?.toFixed(2)}
                          </h5>
                        </div>}
                        <hr className="dashedrow"></hr>
                        <div className="totalDetails">
                          <h5 className="itemsText ">Total</h5>
                          <h5 className="itemsText">
                            ${Number(orderData?.payable_amount)?.toFixed(2)}
                          </h5>
                        </div>
                        {
                          ((orderData?.order_delivery && orderData?.delivery_option == '1' && orderData?.order_delivery?.has_returned) || orderData?.is_returned_order) ?
                            <div className="reviewbotBtn_ mt-2">
                              <button
                                className="acceptBtn_ me-2"
                                type="submit"
                                disabled={loading2}
                              >
                                {loading2 && (
                                  <span className="spinner-border spinner-border-sm"></span>
                                )}
                                &nbsp;&nbsp;
                                <span>Returned</span>
                              </button>
                            </div> :
                            orderData?.delivery_option == 1 &&
                              orderData?.status === 0 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  onClick={() =>
                                    changeStatusOfOrderHandle(orderData?.id, 8)
                                  }
                                  className="declineBtn me-3"
                                  type="submit"
                                  disabled={loading1}
                                >
                                  {loading1 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Decline</span>
                                </button>
                                <button
                                  onClick={() =>
                                    changeStatusOfOrderHandle(orderData?.id, 1)
                                  }
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Accept</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 1 &&
                              orderData?.status === 1 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  onClick={() =>
                                    changeStatusOfOrderHandle(orderData?.id, 2)
                                  }
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Prepairing</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 1 &&
                              orderData?.status === 2 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  onClick={() =>
                                    changeStatusOfOrderHandle(orderData?.id, 3)
                                  }
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Ready to Pickup</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 1 &&
                              orderData?.status === 3 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Assigning Driver</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 1 &&
                              orderData?.status === 4 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Picked Up</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 1 &&
                              orderData?.status === 5 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Completed</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 1 &&
                              orderData?.status === 6 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Pickup by Customer</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 1 &&
                              orderData?.status === 7 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="commonbtndesign_ cancelbtn_"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>
                                    {orderData?.is_returned_order
                                      ? "Refunded"
                                      : "Cancelled"}
                                  </span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 1 &&
                              orderData?.status === 8 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="commonbtndesign_ cancelbtn_"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Rejected</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 1 &&
                              orderData?.status === 9 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Returned</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 2 &&
                              orderData?.status === 0 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Service to Review</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 2 &&
                              orderData?.status === 1 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Accepted</span>
                                </button>
                              </div>
                            ) : (orderData?.delivery_option == 2 &&
                              orderData?.status === 2) ||
                              (orderData?.delivery_option == 2 &&
                                orderData?.status === 3) ||
                              (orderData?.delivery_option == 2 &&
                                orderData?.status === 4) ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>In Progress</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 2 &&
                              orderData?.status === 5 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Completed</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 2 &&
                              orderData?.status === 7 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="commonbtndesign_ cancelbtn_"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Cancelled</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 2 &&
                              orderData?.status === 8 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="commonbtndesign_ cancelbtn_"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Rejected</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 2 &&
                              orderData?.status === 9 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="commonbtndesign_ cancelbtn_"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Returned</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 3 &&
                              orderData?.status === 0 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  onClick={() =>
                                    changeStatusOfOrderHandle(orderData?.id, 8)
                                  }
                                  className="declineBtn me-3"
                                  type="submit"
                                  disabled={loading1}
                                >
                                  {loading1 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Decline</span>
                                </button>
                                <button
                                  onClick={() =>
                                    changeStatusOfOrderHandle(orderData?.id, 1)
                                  }
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Accept</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 3 &&
                              orderData?.status === 1 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  onClick={() =>
                                    changeStatusOfOrderHandle(orderData?.id, 2)
                                  }
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Prepairing</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 3 &&
                              orderData?.status === 2 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  onClick={() => {
                                    setModalDetail({
                                      show: true,
                                      flag: "verifyOtp",
                                    });
                                    setKey(Math.random());
                                  }}
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Ready to Pickup</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 3 &&
                              orderData?.status === 5 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Completed</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 3 &&
                              orderData?.status === 7 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="commonbtndesign_ cancelbtn_"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>
                                    {orderData?.is_returned_order
                                      ? "Refunded"
                                      : "Cancelled"}
                                  </span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 3 &&
                              orderData?.status === 8 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="commonbtndesign_ cancelbtn_"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Rejected</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 3 &&
                              orderData?.status === 9 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Returned</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 4 &&
                              orderData?.status === 0 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  onClick={() =>
                                    changeStatusOfOrderHandle(orderData?.id, 8)
                                  }
                                  className="declineBtn me-3"
                                  type="submit"
                                  disabled={loading1}
                                >
                                  {loading1 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Decline</span>
                                </button>
                                <button
                                  onClick={() =>
                                    changeStatusOfOrderHandle(orderData?.id, 3)
                                  }
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Accept</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 4 &&
                              orderData?.status === 3 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  onClick={() => {
                                    setPrintingUrl(orderData?.label_url);
                                    setModalDetail({
                                      show: true,
                                      flag: "printLabel",
                                    });
                                    setKey(Math.random());
                                    changeStatusOfOrderHandle(orderData?.id, 4);
                                  }}
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Print Label</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 4 &&
                              orderData?.status === 4 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Shipped</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 4 &&
                              orderData?.status === 5 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Completed</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 4 &&
                              orderData?.status === 7 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="commonbtndesign_ cancelbtn_"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>
                                    {orderData?.is_returned_order
                                      ? "Refunded"
                                      : "Cancelled"}
                                  </span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 4 &&
                              orderData?.status === 8 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="commonbtndesign_ cancelbtn_"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Rejected</span>
                                </button>
                              </div>
                            ) : orderData?.delivery_option == 4 &&
                              orderData?.status === 9 ? (
                              <div className="reviewbotBtn_ mt-2">
                                <button
                                  className="acceptBtn_ me-2"
                                  type="submit"
                                  disabled={loading2}
                                >
                                  {loading2 && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  &nbsp;&nbsp;
                                  <span>Returned</span>
                                </button>
                              </div>
                            ) : (
                              <></>
                            )}
                      </div>
                    </div>
                  </div>
                </div></>

              </div>
            
            </div>
              :
                
              <OrderInvoiceDetails
              orderDetails={orderData}
              />
                }
          </div>
          {/* <div className='col-md-6'>x
                            <div className='chartsOuter'>
                                No Data
                            </div>
                        </div> */}

          

          <div className={`col-md-${!showMap?'7':'9'}`}>

          {
           ( handleDeliveryOptionStatus([1,3,4],orderData?.delivery_option) &&
            handleOrderStatus([0,1,2],orderData?.status))?
            <div className="MapOrders_inner">
            <div className="nodataDisplay_">
              <p><img className="warningicon me-1" src={warningicon} /> No data Displayed</p>
            </div>
           </div>
            :
           <div className="consumerMap">
           {
            (handleDeliveryOptionStatus([1],orderData?.delivery_option) &&
            handleOrderStatus([3],orderData?.status))? <div className="loader-container">
            <div className="spinner">
              <svg className="circle" viewBox="0 0 50 50">
                <circle
                  className="track"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="4"
                ></circle>
                <circle
                  className="progress"
                  cx="25"
                  cy="25"                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                  r="20"
                  fill="none"
                  stroke-width="4"
                ></circle>
              </svg>

              <div className="icon">
                <img src={drivefast_} alt="Driver Icon" />
              </div>
            </div>
            <p className="loader-text mt-2">Looking for <br/>driver</p>
          </div>:<>
            
            <div className="MapOrders_inner"> 
             <GoogleMapView
             data={orderData?.order_delivery}
             />
              <OrderTrackStatusCard
              orderData={orderData}
              />
             </div>
</>
           }  
           

           
           
            
           </div>
            
          } 

          </div>

        </div>
      )}
      <CustomModal
        key={key}
        show={modalDetail.show}
        // backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "verifyOtp"
            ? "VerifyOtp"
            : modalDetail.flag === "printLabel"
              ? "PrintLabel"
              : ""
        }
        size={
          modalDetail.flag === "verifyOtp" || modalDetail.flag === "printLabel"
            ? "md"
            : "xl"
        }
        child={
          modalDetail.flag === "printLabel" ? (
            <PrintLabelModal
              handleChangeStatus={(id, status) =>
                changeStatusOfOrderHandle(id, status)
              }
              orderData={orderData}
              printingUrl={printingUrl}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "verifyOtp" ? (
            <VerifyOrderModal
              handleChangeStatus={(id, status) =>
                changeStatusOfOrderHandle(id, status)
              }
              orderData={orderData}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "verifyOtp" ? (
            <>
              <div className="headerLeft">
                <h4 className="modalHeading_ me-3">Verify Otp</h4>
              </div>

              <p
                style={{ cursor: "pointer" }}
                onClick={handleOnCloseModal}
                className="modal_cancel"
              >
                <img
                  src={modalCancel}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : modalDetail.flag === "printLabel" ? (
            <>
              <div className="headerLeft">
                <h4 className="modalHeading_ me-3">Print Label</h4>
              </div>

              <p
                style={{ cursor: "pointer" }}
                onClick={handleOnCloseModal}
                className="modal_cancel"
              >
                <img
                  src={modalCancel}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default OrderReview;