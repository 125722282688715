import React from 'react'
import ReactSelect from "react-select";
import * as Images from "../../../../utilities/images";

const AddratesModal = () => {
    const resultSelectOption = [
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "150", label: "150" },
        { value: "200", label: "200" },
    ];
    return (
        <div className='addreatesModal_'>
            <div className="col-md-12 mb-4">
                <div className="reactSelectParent commonSelectParent w-100">
                    <label className="fontSize14 txtDarkblue mb-2 ps-4">
                        Shipping rate type
                    </label>
                    <ReactSelect
                        options={resultSelectOption}
                        placeholder="Real time shipping rate"
                        classNamePrefix="react-select"
                        className="react-select-container anlyticSelect"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                maxWidth: "100% !important",
                                width: "100%",
                            }),
                            menu: (provided) => ({
                                ...provided,
                                border: "1px solid #D7DEFF",
                            }),
                            valueContainer: (provided) => ({
                                ...provided,
                                paddingLeft: "unset !important",
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                    ? "#263682"
                                    : state.isFocused
                                        ? "#f5f6fc"
                                        : "#fff",

                                color: state.isSelected ? "#fff" : "#263682",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                color: "#636E9F",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                            }),
                        }}
                        components={{
                            DropdownIndicator: () => (
                                <img
                                    src={Images.selectImg}
                                    width={24}
                                    height={24}
                                    alt="drop_icon"
                                    className="mt-1"
                                />
                            ),
                        }}
                    />
                </div>
            </div>
            <div className="mb-4 form-group col-md-12">
                <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">Rate Name</label>
                <input
                    type="number"
                    className="customform-control"
                    autoComplete="new-password"
                    placeholder="e.g. standard shipping"
                />
                <h3 className="fontSize12 txtLightBlue mb-0 ps-3 mt-2">This will be shown to your customers at checkout.  </h3>
            </div>

            <div className='processignOrder p-0'>
                <div className='ordrsettingHead_'>
                    <h3 className='normalHeading_ mb-1'>Eligibility </h3>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" />
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">
                                    Specific orders based on order total
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" />
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">
                                    Specific orders based on weight
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                            <input type="checkbox" />
                            <div>
                                <h3 className="fontSize14 txtDarkblue m-0">
                                    All orders
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-4'>
                <div className="col-md-6">
                    <div className="mb-4 form-group col-md-12">
                        <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">Min Order Total</label>
                        <div className='amountvalue_ mt-1'>
                            <input type="text" className='customform-control' placeholder='1,000.00' />
                            <span className='mindollr'>$</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-4 form-group col-md-12">
                        <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">Max Order Total</label>
                        <div className='amountvalue_ mt-1'>
                            <input type="text" className='customform-control' placeholder='1,000.00' />
                            <span className='mindollr'>$</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='col-md-12'>
                <div className='warningText_'>
                    <img className='warningIcon' src={Images.infoIcon} />
                    <h5 className='newheading24'>Real-time shipping lets you charge accurate, calculated rates at checkout based on weight, shipping destination and your connected carrier services.</h5>
                </div>
            </div> */}
            <div className='footerBtn_ d-flex align-items-center mt-4'>
                <button className='greyBtn_'>Cancel</button>
                <button className='blueButton_'>Done</button>
            </div>
        </div>
    )
}

export default AddratesModal