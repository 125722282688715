import React, { useState } from "react";

const LocationAndStockModal = ({ close }) => {
  const [activePos, setActivePos] = useState(false);
  const [inventoryPointCheck, setInventoryPointCheck] = useState("");

  const handleSaleToggle = (type) => (event) => {
    const isChecked = event.target.checked;
    console.log(type, event);
    if (type === "inventoryCheck") {
      setInventoryPointCheck(isChecked);
    }
  };
  return (
    <>
      <h3 className="fontSize24 fw500 txtDarkblue text-center">
        Locations and stocks
      </h3>

      <div className="modalOptionBox">
        <div className="innnerParent">
          <div className="productSale_Parent p-0 m-0">
            <input type="checkbox" onChange={() => setActivePos(!activePos)} />
          </div>
          <div>
            <h3 className="fontSize14 fw500 txtDarkblue">Daugherty LLC 01</h3>
            <h3 className="fontSize12 txtLightBlue mb-0">
              9 Indrio Blvd, Satellite Beach, Florida 32937, USA
            </h3>
          </div>
        </div>
        <div className="editVariant_SecondBox mt-3">
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Stock action</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="inputGroup">
                    <input
                      className="upcBox_Input p-0"
                      placeholder="Stock received"
                      name="text"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Add stock</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="inputGroup">
                    <input
                      className="upcBox_Input p-0"
                      placeholder="0"
                      name="text"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent totalStockContainer">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Total stock</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="inputGroup">
                    <input
                      className="upcBox_Input p-0"
                      name="text"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="innnerParent mt-3 align-items-center">
          <div className="productSale_Parent p-0 m-0">
            <input
              type="checkbox"
              checked={inventoryPointCheck}
              onChange={handleSaleToggle("inventoryCheck")}
            />
          </div>

          <h3 className="fontSize14 fw500 txtDarkblue mb-0">
            Inventory-Reorder Point
          </h3>
        </div>

        {inventoryPointCheck && (
          <div className="upcBoxParent inventoryPointBox mt-3">
            <div className="row">
              <div className="col-5 pe-0">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Inventory-Reorder Point</h3>
                </div>
              </div>
              <div className="col-7 ps-0">
                <div className="upcBox upcRightBox">
                  <div className="inputGroup">
                    <input
                      className="upcBox_Input p-0"
                      name="text"
                      type="text"
                      placeholder="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="d-flex align-items-center editVariantModal_Footer">
        <button className="modalDiscardBtn w-100" onClick={() => close()}>
          Discard
        </button>
        <button className={`modalNextBtn modalDiscardBtn w-100 ${inventoryPointCheck ? "active" : ""}`}>Save</button>
      </div>
    </>
  );
};

export default LocationAndStockModal;
