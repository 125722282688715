import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import CustomModal from '../../../components/shared/CustomModal';
import EditOfferModal from '../../myProducts/EditOfferModal';
import EditshipModal from './editshipModal';
import CarriersfedxModal from './carriersfedxModal';
import { Link} from "react-router-dom";
import { getSellerAddressesApiAsync, getShippingDatapiAsync, getUserSettingsApiAsync, updateSellerInfoApiAsync, updateShippingDataApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { selectLoginAuth } from '../../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { unwrapResult } from '@reduxjs/toolkit';
import Loader from '../../../components/UI/Loader/Loader';

const OrderShiping = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(selectLoginAuth);
    const shipping_details=[
        {title:"Allow domestic shipping orders",key:"allow_domestic_shipping_order",},
        {title:"Allow international shipping orders",key:"allow_international_shipping_order"},
        {title:"Allow shipping order cancellation",key:"allow_shipping_order_cancellation"},
        {title:"Shipping order Returns",key:"is_shipping_order_return_enable"}
    ];
    const [locationDetails, setlocationDetails] = useState({
        name:"Store",
        address:"",
        apartment:"",
        country_code:"",
        phone:"",
        email:"",
        state:"",
        city:"",zip:"",

    })
    const [shippingData, setShippingData] = useState({
        
        locations : [
            {
                name: "Store",
                address: "123 Main St",
                apartment: "Apt 101",
                country_code: "US",
                phone: "+1 (555) 123-4567",
                email: "store@example.com",
                state: "California",
                city: "Los Angeles",
                zip: "90001",
            },
            {
                name: "Miami Store",
                address: "456 Ocean Dr",
                apartment: "Apt 202",
                country_code: "US",
                phone: "+1 (555) 234-5678",
                email: "miami_store@example.com",
                state: "Florida",
                city: "Miami",
                zip: "33101",
            },
        ]
    })
    const resultSelectOption = [
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "150", label: "150" },
        { value: "200", label: "200" },
    ];
    const [loading, setLoading] = useState(false)
    // const handleDiscountOption = (options) => {
    //     setDiscountOption(options);
    // };
    const getOrderProcessingData=async()=>{
        let prams={
            postData: {
                // seller_id: auth?.payload?.uniqe_id
                //     ? auth?.payload?.uniqe_id
                //     : auth?.payload?.user?.unique_uuid,
                seller_id:"2db22768-195d-4b64-b0bf-060d980bba18",
                filter:"filterValue"
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(getShippingDatapiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        setShippingData(res.data);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    const updateOrderProcessingData=async()=>{
        let prams={
            postData: {
                // seller_id: auth?.payload?.uniqe_id
                //     ? auth?.payload?.uniqe_id
                //     : auth?.payload?.user?.unique_uuid,
                seller_id:"2db22768-195d-4b64-b0bf-060d980bba18",
                filter:"filterValue"
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(updateShippingDataApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        setShippingData(res.data);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    // Start Modal
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleOpenModal = (flag) => {
        setModalDetail({
            show: true,
            flag: flag,
            title: flag,
        });
        setKey(Math.random());
    };

    // End Modal
    const handleShippingDetails=(index,value)=>{
        let newShippingData=[...shippingData.shipping_details];
        newShippingData[index].value=value;
        setShippingData({...shippingData,shipping_details:newShippingData});
    }
    const [userSettingInfo, setuserSettingInfo] = useState(null)
    const getUserSetting=async()=>{
        let prams={
            postData: {
                app_name:"merchant",
                seller_id:"0abd886e-d6c4-4fe0-8be2-c928f41530ca",
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(getUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setuserSettingInfo(res?.payload);
        setLoading(false);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    useEffect(()=>{
        getUserSetting();
    },[])
    const updateUserSetting=async(data)=>{
        let prams={
            postData: {
                ...data
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(updateUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        // setOrderProcessingData(res.data);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    const handleUserSetting=(key,value)=>{
        setuserSettingInfo(prev=>({
            ...prev,[key]:value
        }));
        updateUserSetting({[key]:value});
    }
    return (
        <>
          {loading&&
                <Loader/>
               }
            <div className='orderShipping_'>
                <div className='processignOrder'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Shipping</h3>
                        <p className='textInnerHead2'>Use these settings to define plugin general settings and default settings for your services and appointments</p>
                    </div>
                    <div className="row mt-4">
                        {
                            shipping_details?.map((option,index)=>(
                                <div className={`col-12  ${index>=1?'mt-3':''}`}>
                            <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                                <input type="checkbox" 
                                checked={userSettingInfo?.[option.key]}
                                onChange={(event)=>handleUserSetting(option.key,event.target.checked)}
                                />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue m-0">
                                      {option.title}
                                    </h3>
                                    <h3 className="fontSize12 txtLightBlue mb-0">
                                        Allow customer shipping orders
                                    </h3>

                                </div>
                            </div>
                        </div>
                            ))
                        }
                    </div>
                </div>
                <div className='processignOrder mt-4'>
                    <div className='shiplocationhead_'>
                        <div className='ordrsettingHead_'>
                            <h3 className='normalHeading_ mb-1'>Shipping</h3>
                            <p className='textInnerHead2'>Use these settings to define plugin general settings and default settings for your services and appointments</p>
                        </div>
                        <button className='editbtn_' type='submit' onClick={() => {
                            setModalDetail({
                                show: true,
                                flag: "EditshipModal",
                            });
                            setKey(Math.random());
                        }}> <img className='editicon_' src={Images.editicon_} /> Edit</button>
                    </div>
                    {
                        shippingData?.locations?.map((location,index)=>(
                            <div key={index} className='storeLocation_ mt-3'>
                        <figure className='storeimg_'><img className='imgstore_' src={Images.storeimg_} /></figure>
                        <article className='storeInfo_'>
                            <h3 className='headblue18'>{location.name}</h3>
                            <h5 className='smallbluehead_'> <img className='editicon_' src={Images.phoneicoN} /> {location.phone}</h5>
                            <h5 className='smallbluehead_'><img className='editicon_' src={Images.locationIcon_} /> {location.state}</h5>
                        </article>
                    </div>
                        ))
                    }
                    
                </div>
                <div className='processignOrder mt-4'>
                    <div className='shiplocationhead_'>
                        <div className='ordrsettingHead_'>
                            <h3 className='normalHeading_ mb-1'>Shipping carriers</h3>
                            <p className='textInnerHead2'>Set up carrier accounts to purchase shipping labels through JOBR</p>
                        </div>
                    </div>
                    <div className='shipCrries mt-3'>
                        <div className='shipCarrier_'>
                            <div className='shipcarierLeft_'>
                                <img className='carrir_img' src={Images.upsimg_} />
                                <div className='rightcontentCarrer'>
                                    <h4 className='textInnerHead'>UPS</h4>
                                    <h5 className='textInnerHead2'>Connected </h5>
                                </div>
                            </div>
                        </div>
                        <div className='shipCarrier_ mt-2'>
                            <div className='shipcarierLeft_'>
                                <img className='carrir_img' src={Images.fedexicon} />
                                <div className='rightcontentCarrer'>
                                    <h4 className='textInnerHead'>FedEx</h4>
                                    <h5 className='textInnerHead2'>Not Connected  </h5>
                                </div>
                            </div>
                            <button className='connectedBtn_' type='submit' onClick={() => {
                                setModalDetail({
                                    show: true,
                                    flag: "CarriersfedxModal",
                                });
                                setKey(Math.random());
                            }}>Connect <img className='uparrow_' src={Images.upArrow} /></button>
                        </div>
                        <div className='shipCarrier_ mt-2'>
                            <div className='shipcarierLeft_'>
                                <img className='carrir_img' src={Images.dhlicon_} />
                                <div className='rightcontentCarrer'>
                                    <h4 className='textInnerHead'>DHL</h4>
                                    <h5 className='textInnerHead2'>Not Connected  </h5>
                                </div>
                            </div>
                            <button className='connectedBtn_' type='submit'>Connect <img className='uparrow_' src={Images.upArrow} /></button>
                        </div>

                    </div>
                </div>
                <div className='processignOrder mt-4 profileShip_'>
                    <div className='shiplocationhead_'>
                        <div className='ordrsettingHead_'>
                            <h3 className='normalHeading_ mb-1'>Shipping profile</h3>
                            <p className='textInnerHead2'>Set up shipping rates for each region you want to ship to, or for specific items. You can also add a free shipping coupon code in coupons.</p>
                        </div>
                        <Link to="/ShippingProfile" className='editbtn_' > <img className='editicon_' src={Images.addButtonImg} /> Add Profile</Link>
                    </div>
                    <div className='storeLocation_ mt-3'>
                        <div className='storeTop_'>
                            <h3 className='headblue18'>Store LLC <span className='defaulttext_'>Default</span></h3>
                            <figure className='editprofileIcon_'>
                                <img className='editicon_' src={Images.editIcon} />
                            </figure>
                        </div>
                        <div className='storeLocation_inner mt-2'>
                            <h5 className='innerItemstext_'><img className='loctionicn' src={Images.trackDeliveryicon} /> All Items  (233)</h5>
                            <h5 className='innerItemstext_'><img className='loctionicn' src={Images.locprofileimg} /> United states (50 states & 1 Property)</h5>
                            <div className='flatrateDesign_'>
                                <h5>Flat Rate</h5>
                                <h6><img className='editicon_' src={Images.moneyblueIcon} /> $10.00 per item</h6>
                            </div>
                        </div>
                    </div>
                    <div className='shiperror_'>
                        <img className='shiperror_img' src={Images.info_error} />
                        <p className='shipPapra_'>You have shipping enabled for one or more items, but don’t have any shipping profiles yet. Add a shipping profile to charge customers for shipping.</p>
                    </div>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                ids={
                    modalDetail.flag === "EditshipModal"
                        ? "editVariantModal"

                        : modalDetail.flag === "CarriersfedxModal"
                            ? "carriersfedxModal"

                            : ""
                }
                child={
                    modalDetail.flag === "EditshipModal" ? (
                        <EditshipModal locations={shippingData?.locations||[]} close={() => handleOnCloseModal()} />
                    ) :
                        modalDetail.flag === "CarriersfedxModal" ? (
                            <CarriersfedxModal close={() => handleOnCloseModal()} />
                        ) : (
                            <></>
                        )
                }
                header={
                    <>
                        {modalDetail.flag === "EditshipModal" ? (
                            <>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div>
                                        <h3 className="fontSize24 fw500 txtDarkblue mb-2">
                                            Edit ship from location
                                        </h3>
                                        <h6 className="fontSize12 txtLightBlue">
                                            Please update your ship from address.
                                        </h6>

                                    </div>
                                    <img
                                        src={Images.modalCross}
                                        alt="logo"
                                        onClick={() => handleOnCloseModal()}
                                    />
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

export default OrderShiping