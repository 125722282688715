import React, { useCallback, useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import * as Images from "../../../utilities/images";

const ManageCategories = () => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [fileError, setFileError] = useState("");
  const [activePos, setActivePos] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles, "acceptdedd");
    const uploadedFile = acceptedFiles?.[0];
    const validTypes = ["image/jpeg", "image/png"];
    const maxSize = 5 * 1024 * 1024;

    if (!uploadedFile) {
      setFileError("Please upload a file.");
      toast.error("No file uploaded.");
      return;
    }

    if (!validTypes.some((type) => uploadedFile.type === type)) {
      setFileError("Invalid file type.");
      toast.error("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }

    if (uploadedFile.size > maxSize) {
      setFileError("File size exceeds the 5MB limit.");
      toast.error("File size exceeds the 5MB limit.");
      return;
    }

    setFileError("");
    setFile(uploadedFile);

    const filePreview = URL.createObjectURL(uploadedFile);
    setPreview(filePreview);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
  });

  return (
    <div className="manageCategoriesSection">
      {/* Start Manage Categories Section */}
      <div className="verificationTotal homePage">
        <DashboardNavbar title="Manage Categories" show={false} />
      </div>

      <div className="container">
        <form>
          <div className="row">
            <div className="col-md-12 ps-0">
              <label className="fontSize16 txtDarkblue mb-2 ps-4">
                Upload Image
              </label>
              <div className="showToken_Img mt-0 mb-3">
                <div {...getRootProps({ className: "" })}>
                  <input name="image" type="file" {...getInputProps()} />
                  <div className="uploadImg_Parent">
                    {file ? (
                      <div className="showImg_Parent">
                        <img
                          src={preview}
                          alt="Preview"
                          className="detailsImg img-fluid h-100"
                        />
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <img
                          src={Images.CameraLogo}
                          alt="Camera"
                          style={{ cursor: "pointer" }}
                        />
                        <p className="dragAndDrop_Txt ms-2">
                          Click to browse or <br /> drag and drop your files
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {fileError && <p className="error-message">{fileError}</p>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 ps-0">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Service ID
                </label>
                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Service Id"
                    name="text"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">Name</label>
                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Name"
                    name="text"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 ps-0">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Description
                </label>
                <div className="inputGroup">
                  <textarea
                    className="customInput customTextarea"
                    placeholder="Text here"
                    id="floatingTextarea"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <button className="commonBtn mt-4">Create Category</button>
            </div>
          </div>
        </form>
      </div>
      {/* End Manage Categories Section */}
    </div>
  );
};

export default ManageCategories;
