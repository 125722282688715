import axios from "axios";
import { toast } from "react-toastify";
import { REACT_APP_ORDER_URL, REACT_APP_PRODUCT_URL } from "../../config";
import { getCurrentTimeZone } from './../../../utilities/timezone'
const queryString = require('query-string');
const timeZone = getCurrentTimeZone();


export function getProductListApi(data, token) {
  const sellerId = data.seller_id;
  delete data.seller_id
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/supplies/seller-info/${sellerId}` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getProductServiceListApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/products/merchant` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
      })
  )
}

export function getProductSalesGraphApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview/sales` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getTopSellingApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview/top-selling` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getProductListByIdApi(data, token) {
  console.log({ data, token })
  let id = data?.id;
  delete data?.id
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/products/${id}` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getCountApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/supplies/pos/seller-product/statistics` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getProductStatisticData(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/products/pos/data-list/statistics` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getSingleProductStatisticDataById(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/products/merchant/data-statistics` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getServiceCountDataApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/supplies/pos/seller-product/statistics` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getServiceSalesBarGraphApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview/weekly-sales` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function changeServiceAvailabilityStatusApi(data, token, status) {
  let id = data?.id
  delete data?.id
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_PRODUCT_URL}api/v1/products/merchant/service/${id}` + '?' + query, status, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getProductTrendingGraphApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview/trending-selling` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getLowStockInventoryApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/products/merchant` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function updateInventoryApi(data, bodyData, token) {
  let id = data.id;
  delete data.id
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_PRODUCT_URL}api/v1/supplies/merchant/inventory/${id}` + '?' + query, bodyData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function adjustInventoryApi(data, token) {
  let id = data.id;
  delete data.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_PRODUCT_URL}api/v1/supplies/merchant/inventory/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

// new

export function createCoupon(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_PRODUCT_URL}/api/v1/coupons`, data , {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
        // toast.error(error?.response?.data?.msg)

        console.log(error?.response, "errorrrrrrrrrrrrrrrrr");
      })
  )
}







