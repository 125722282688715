import React, { useEffect, useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import {
  shareMap,
  profileImg1,
  locationBlue,
  modalCancel,
  lossArrow,
} from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import ChartArea from "../dashboard/wallet/ChartArea";
import {
  changeAppointmentStatusApiAsync,
  getAppointemntApiAsync,
  getAppointmentCountApiAsync,
  getAppointmentGraphApiAsync,
} from "../dashboard/dashboardSlice";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";
import CustomModal from "../../components/shared/CustomModal";
import BookingSchedule from "./bookingSchedule";
import CheckinModal from "./checkinModal";
import ConfirmCompleteModal from "./ConfirmCompleteModal";
import HomePageInnerHeader from "../dashboard/HomePageInnerHeader";
import HeaderStatistics from "../dashboard/wallet/HeaderStatistics";
import * as Images from "../../../utilities/images";
import MainHeader from "../../components/UI/Orders/MainHeader";
import {
  HeaderStatisticsArr,
  options1,
} from "../../constants/bookings/overview";

const Bookings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [appointmentCountData, setAppointmentCountData] = useState({});
  const [appointmentGraphData, setAppointmentGraphData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sellerAddress, setsellerAddress] = useState("");
  const [filterValue, setFilterValue] = useState("week");
  // time period
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [singleOrderData, setSingleOrderData] = useState("");
  const auth = useSelector(selectLoginAuth);
  const [checked, setChecked] = useState({
    appointment: true,
    completed: true,
  });
  let [dataSets, setDataSets] = useState([]);
  const [key, setKey] = useState(Math.random());
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  function addThousandSeparator(number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const customStyles = {
    rows: {
      style: {
        cursor: "pointer",
      },
    },
  };

  const handleRowClick = (e) => {
    setSingleOrderData(e);
    setModalDetail({ show: true, flag: "PendingInvoiceModal" });
    setKey(Math.random());
  };

  const columns1 = [
    {
      name: "Date/Time",
      selector: (row) => {
        return (
          <>
            <div onClick={() => handleRowClick(row)} className=" text-left">
              <span className="textParagh">
                {moment.utc(row?.date).format("MM/DD/YYYY | ")}
                <span className="">
                  {row?.start_time + "-" + row?.end_time}
                </span>
              </span>
            </div>
          </>
        );
      },
      width: "270px",
    },
    {
      name: "Customer",
      selector: (row) => {
        return (
          <>
            {row?.user_details ? (
              <div
                onClick={() => handleRowClick(row)}
                className="d-flex align-items-center"
              >
                <img
                  className="userInnerImg"
                  src={
                    row?.user_details?.profile_photo
                      ? row?.user_details?.profile_photo
                      : profileImg1
                  }
                />
                <div className="ms-2">
                  <span className="contentSubtext_ fw-semibold">
                    {row?.user_details?.firstname +
                      " " +
                      row?.user_details?.lastname}
                  </span>
                  {row?.user_details?.current_address ? (
                    <p className="mb-0">
                      <img
                        src={locationBlue}
                        className="img-fluid me-2 custImg"
                      />
                      <span className="contentSmallTxt">
                        {row?.user_details?.current_address?.city},
                        {row?.user_details?.current_address?.country}
                      </span>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center">Not Available</div>
            )}
          </>
        );
      },
      width: "300px",
    },
    {
      name: "Assigned to",
      selector: (row) => {
        return (
          <>
            <div
              onClick={() => handleRowClick(row)}
              className="d-flex align-items-center"
            >
              <img
                className="userInnerImg"
                src={
                  row?.pos_user_details?.user?.user_profiles?.profile_photo
                    ? row?.pos_user_details?.user?.user_profiles?.profile_photo
                    : profileImg1
                }
              />
              <div className="ms-2">
                <span className="contentSubtext_ fw-semibold">
                  {row?.pos_user_details?.user?.user_profiles?.firstname +
                    " " +
                    row?.pos_user_details?.user?.user_profiles?.lastname}
                </span>
                <p className="mb-0 text-start">
                  {/* <img src={locationBlue} className='img-fluid me-2 custImg' /> */}
                  <span className="contentSmallTxt">
                    {row?.pos_user_details?.is_staff_member === true
                      ? "Staff"
                      : "Non-staff"}
                  </span>
                </p>
              </div>
            </div>
          </>
        );
      },
      width: "250px",
    },
    {
      selector: (row) => row?.product_name,
      name: "Service",
      width: "200px",
    },
    {
      selector: (row) => row?.approx_service_time + " min",
      name: "Duration",
      width: "150px",
    },
    {
      selector: (row) => "$" + addThousandSeparator(row?.price),
      name: "Price",
      width: "150px",
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <>
            <div className="ms-2 tableBtn__">
              <div className="col-lg-12 ">
                {row?.status === 0 ? (
                  <div className="stoclRight">
                    <button
                      onClick={() =>
                        changeStatusOfAppointmentHandle(row?.id, 4)
                      }
                      className=" me-3 checkBtnColor"
                      type="submit"
                      disabled={loading1}
                    >
                      {loading1 && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Decline</span>
                    </button>
                    <button
                      onClick={() =>
                        changeStatusOfAppointmentHandle(row?.id, 1)
                      }
                      className="acceptBtnColor me-2"
                      type="submit"
                      disabled={loading2}
                    >
                      {loading2 && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Approve</span>
                    </button>
                  </div>
                ) : row?.status === 1 ? (
                  <div className="stoclRight">
                    <button
                      onClick={() => {
                        setSingleOrderData(row);
                        setModalDetail({ show: true, flag: "checkInModal" });
                        setKey(Math.random());
                      }}
                      className="me-3 checkInBtnColor"
                      type="submit"
                    >
                      <span>Check-in</span>
                    </button>
                    <button
                      onClick={() => {
                        setSingleOrderData(row);
                        setModalDetail({ show: true, flag: "rescheduleModal" });
                        setKey(Math.random());
                      }}
                      className="acceptBtnColor me-2"
                      type="submit"
                    >
                      <span>Update</span>
                    </button>
                  </div>
                ) : row?.status === 2 ? (
                  <div className="stoclRight">
                    <button
                      onClick={() => {
                        setSingleOrderData(row);
                        setModalDetail({
                          show: true,
                          flag: "confirmCompleteModal",
                        });
                        setKey(Math.random());
                      }}
                      className=" me-2 w-100 checkInBtnColor"
                      type="submit"
                      disabled={loading2}
                    >
                      <span>Mark Complete</span>
                    </button>
                  </div>
                ) : row?.status === 3 ? (
                  <div className="stoclRight ">
                    <button
                      className="acceptBtnColor me-2 w-100"
                      type="submit"
                      disabled
                    >
                      <span>Completed</span>
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        );
      },
      width: "220px",
    },
  ];

  const lineData = {
    labels: appointmentGraphData?.labels,
    datasets: dataSets,
  };
  const handelDataSetChange = (e, value, num, color) => {
    if (e.target.checked) {
      setDataSets([
        ...dataSets,
        {
          fill: true,
          label: value,
          data: appointmentGraphData?.datasets
            ? appointmentGraphData?.datasets[num]?.data?.map(
                (value) => value + Math.round(Math.random() * 1000)
              )
            : "",
          borderColor: color,
          backgroundColor: "#FFFFFF00",
          cubicInterpolationMode: "monotone",
        },
      ]);
    } else {
      setDataSets(dataSets.filter((item) => item.label !== value));
    }
  };
  const changeStatusOfAppointmentHandle = (id, status) => {
    let orderStatus = {
      postData: {
        id: id,
        status: status,
      },
      tokenData: auth?.payload?.token,
    };
    status === 4 ? setLoading1(true) : setLoading2(true);
    dispatch(changeAppointmentStatusApiAsync(orderStatus))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          postData: {
            // page: 1,
            // limit: 10,
            // need_upcoming: true,
            status: "0,1,2",
            filter_by: filterValue,
            seller_id: "29d0e7bc-af42-4505-bcea-3e54817fcb2d",
            // auth?.payload?.uniqe_id
            //   ? auth?.payload?.uniqe_id
            //   : auth?.payload?.user?.unique_uuid,
            ...(startDate && { start_date: startDate, end_date: endDate }),
            ...(sellerAddress && { seller_address_id: sellerAddress }),
          },
          tokenData: auth?.payload?.token,
        };
        dispatch(getAppointemntApiAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setAppointmentData(obj?.payload?.data);
            setLoading1(false);
            setLoading2(false);
            handleOnCloseModal();
          })
          .catch((obj) => {
            setLoading1(false);
            setLoading2(false);
          });
      })
      .catch((obj) => {
        setLoading1(false);
        setLoading2(false);
      });
  };
  const appointmentCountHandle = () => {
    let params = {
      postData: {
        filter_by: filterValue,
        // seller_id: auth?.payload?.uniqe_id
        //   ? auth?.payload?.uniqe_id
        //   : auth?.payload?.user?.unique_uuid,
        seller_id: "29d0e7bc-af42-4505-bcea-3e54817fcb2d",
        // ...(startDate&&{start_date:startDate, end_date:endDate}),
        ...(sellerAddress && { seller_address_id: sellerAddress }),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getAppointmentCountApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentCountData(filterCountData(obj));
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  const filterCountData = (obj) => {
    try {
      let countData = obj?.payload?.map((data, index) => data?.value) || [];
      let keys = ["total_booking", "fulfillment", "rescheduled", "cancelled"];
      let data = {};
      let i = 0;
      countData.map((obj, index) => {
        if (index != 2) {
          data[keys[i++]] = obj;
        }
      });
      console.log(data, "check data");
      return data;
    } catch (error) {
      console.log(error.message, "error occur");
    }
  };
  const handleDataSet = (datasets) => {
    const colors = ["#263682", "#039855"];
    let labels = ["Booking", "Fulfillment"];
    return datasets?.map((data, index) => ({
      fill: true,
      label: labels[index],
      data: data?.data?.map(
        (value) => value + Math.round(Math.random() * 1000)
      ),
      borderColor: colors[index],
      backgroundColor: "#FFFFFF00",
      cubicInterpolationMode: "monotone",
    }));
  };
  const appointmentGraphHandle = () => {
    let params = {
      postData: {
        filter_by: filterValue,
        cancelled_nd_completed: true,
        // seller_id: auth?.payload?.uniqe_id
        //   ? auth?.payload?.uniqe_id
        //   : auth?.payload?.user?.unique_uuid,
        seller_id: "29d0e7bc-af42-4505-bcea-3e54817fcb2d",
        ...(startDate && { start_date: startDate, end_date: endDate }),
        ...(sellerAddress && { seller_address_id: sellerAddress }),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getAppointmentGraphApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentGraphData(obj?.payload);
        setDataSets(handleDataSet(obj?.payload?.datasets));
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleAppointmentData = () => {
    let params = {
      postData: {
        // need_upcoming:
        status: "0,1,2",
        filter_by: filterValue,
        // seller_id: auth?.payload?.uniqe_id
        //   ? auth?.payload?.uniqe_id
        //   : auth?.payload?.user?.unique_uuid,
        seller_id: "29d0e7bc-af42-4505-bcea-3e54817fcb2d",
        ...(startDate && { start_date: startDate, end_date: endDate }),
        ...(sellerAddress && { seller_address_id: sellerAddress }),
      },
      tokenData: auth?.payload?.token,
    };
    setLoading(true);
    dispatch(getAppointemntApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setAppointmentData(obj?.payload?.data);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    appointmentCountHandle();
    appointmentGraphHandle();
    handleAppointmentData();
  }, [filterValue, endDate, sellerAddress]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Bookings";
  }, []);

  const handleFilterValue = (value) => {
    setFilterValue(value);
  };
  const handleDateValue = (values) => {
    // if(values.startDate==values.endDate){
    //     setStartDate(null);
    //     setEndDate(null);
    //     return;
    // }
    setStartDate(values.startDate);
    setEndDate(values.endDate);
  };
  const handleSellerAddress = (value) => {
    setsellerAddress(value);
  };
  return (
    <>
      <DashboardNavbar title="Booking Overview" show={false} />
      <div className="walletStats bookingsOverview_">
        <MainHeader
          handleFilter={handleFilterValue}
          handleDate={handleDateValue}
          seller_id={"29d0e7bc-af42-4505-bcea-3e54817fcb2d"}
          tokenData={auth?.payload?.token}
          enableFutureDate={true}
          handleLocation={handleSellerAddress}
          subhead="vs. Same day last week"
        />
        {loading ? (
          <span className="spinner-border inner-spin spinner-border-sm"></span>
        ) : (
          <>
            <div className="verificationTotal">
              <div className="row mt-3">
                {HeaderStatisticsArr?.map((headerStatic, index) => (
                  <HeaderStatistics
                    className={headerStatic?.className}
                    img={Images?.[headerStatic?.img]}
                    value={appointmentCountData?.[headerStatic?.key]}
                    heading={headerStatic?.heading}
                  />
                ))}
              </div>
            </div>
            {/*  */}

            <div className="tradesValue">
              <div className="row">
                <div className="col-md-12">
                  <div className="totalOrders_">
                    <div className="chartHeader_ chartHeaderBox">
                      <div className="leftorderValue_">
                        <h3 className="textInnerHead mb-0">Booking</h3>
                        <h3 className="Innerhead">304</h3>
                        <button className="viewAllbtn_" type="submit">
                          View All
                        </button>
                      </div>
                      <div className="graphChecked_">
                        <div className="graphBox_ me-3">
                          <input
                            type="checkbox"
                            id="custom-checkbox-selectAll2"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                            value="custom-checkbox-selectAll"
                            onChange={(e) => {
                              handelDataSetChange(e, "Booking", 0, "#263682");
                              setChecked({
                                ...checked,
                                appointment:
                                  checked.appointment === true ? false : true,
                              });
                            }}
                            checked={checked.appointment}
                          />
                          <label
                            htmlFor="custom-checkbox-selectAll2"
                            className="checkBoxTxt masterChecked"
                          >
                            Bookings
                          </label>
                        </div>
                        <div className="checkGreen me-3">
                          <input
                            type="checkbox"
                            id="custom-checkbox-selectAll4"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                            value="custom-checkbox-selectAll"
                            checked={checked.completed}
                            onChange={(e) => {
                              handelDataSetChange(
                                e,
                                "Fulfillment",
                                1,
                                "#039855"
                              );
                              setChecked({
                                ...checked,
                                completed:
                                  checked.completed === true ? false : true,
                              });
                            }}
                          />
                          <label
                            htmlFor="custom-checkbox-selectAll4"
                            className="checkBoxTxt masterChecked"
                          >
                            Fulfilment
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <ChartArea
                          className="img-fluid"
                          header=""
                          options={options1}
                          data={lineData}
                          chartType="Line"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <section className="recentTransections table-responsive chartsOuter recentBookingTableBox">
        <div className="leftorderValue_">
          <h3 className="fontSize16 fw500 txtDarkblue">Recent booking</h3>
          <button className="viewAllbtn_" type="submit">
            View All
          </button>
        </div>
        <div className="commonTable recentBookingTable  table-responsive mt-3">
          <table className="w-100 allorderTable_">
            <thead>
              <tr>
                <th className="text-left">Client</th>
                <th>Staff</th>
                <th>Service</th>
                <th>Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {appointmentData?.map((appointment, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={
                          appointment?.user_details?.profile_photo ||
                          profileImg1
                        }
                        alt="img"
                        className="productItemImg"
                      />
                      <div>
                        <h3 className="darkblack mb-0 pb-1 text-left">
                          {appointment?.user_details?.firstname +
                            " " +
                            appointment?.user_details?.lastname}
                        </h3>
                        <div className="d-flex align-items-center gap-2">
                          <h6 className="orederLoc_ fontSize14 fw500 mb-0">
                            <img
                              src={Images.locateOrder}
                              alt="img"
                              className="location"
                            />
                            {appointment?.user_details?.current_address?.street_address?.slice(
                              0,
                              10
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="fontSize12 darkblack">
                    {appointment?.pos_user_details?.user?.user_profiles
                      ?.firstname +
                      " " +
                      appointment?.pos_user_details?.user?.user_profiles
                        ?.lastname}
                  </td>
                  <td className="fontSize12 darkblack">
                    {appointment?.product_name}
                  </td>
                  <td className="fontSize12 darkblack">
                    <div className="tableClockParent">
                      <img src={Images.clockIcon} alt="img" />
                      <h3 className="timeTxt">
                        {" "}
                        {appointment?.start_time}- {appointment?.end_time}
                      </h3>
                    </div>
                  </td>
                  <td>
                    <div className="tableClockParent justify-content-end gap-3">
                      <button
                        onClick={() => {
                          setSingleOrderData(appointment);
                          setModalDetail({ show: true, flag: "checkInModal" });
                          setKey(Math.random());
                        }}
                        className="checkInBtn tableClockParent"
                      >
                        Check-in
                        <img src={Images.checkInImg} alt="img" />
                      </button>
                      <button
                        onClick={() => {
                          setSingleOrderData(appointment);
                          setModalDetail({
                            show: true,
                            flag: "rescheduleModal",
                          });
                          setKey(Math.random());
                        }}
                        className="tableEditBtn"
                      >
                        <img src={Images.inputManually} alt="img" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <DataTable
          pagination
          columns={columns1}
          data={appointmentData}
          defaultSortFieldId={1}
          customStyles={customStyles}
          onRowClicked={handleRowClick}
          className="newOrderTable"
        /> */}
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "rescheduleModal"
            ? "RescheduleModal"
            : modalDetail.flag === "confirmCompleteModal"
            ? "ConfirmCompleteModal"
            : modalDetail.flag === "checkInModal"
            ? "CheckInModal"
            : ""
        }
        className={
          modalDetail.flag === "rescheduleModal"
            ? "commonWidth customContent"
            : ""
        }
        child={
          modalDetail.flag === "rescheduleModal" ? (
            <BookingSchedule
              handleAllAppointmentData={() => handleAppointmentData()}
              changeStatusOfAppointment={(id, status) =>
                changeStatusOfAppointmentHandle(id, status)
              }
              data={singleOrderData}
              loading1={loading1}
              loading2={loading2}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "checkInModal" ? (
            <CheckinModal
              changeStatusOfAppointment={(id, status) =>
                changeStatusOfAppointmentHandle(id, status)
              }
              data={singleOrderData}
              loading2={loading2}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "confirmCompleteModal" ? (
            <ConfirmCompleteModal
              changeStatusOfAppointment={(id, status) =>
                changeStatusOfAppointmentHandle(id, status)
              }
              data={singleOrderData}
              loading2={loading2}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "rescheduleModal" ? (
            <>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  style={{ cursor: "pointer" }}
                  src={modalCancel}
                  className="ModalCancel"
                />
              </p>
              <h4 className="modalHeading_">
                Booking #<span>{singleOrderData?.id}</span>
              </h4>
            </>
          ) : modalDetail.flag === "checkInModal" ||
            modalDetail.flag === "confirmCompleteModal" ? (
            <>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  style={{ cursor: "pointer" }}
                  src={modalCancel}
                  className="ModalCancel"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Bookings;
