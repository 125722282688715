import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Images from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import { getPosUsersAsync, selectLoginAuth } from "../auth/authSlice";
import { createProductApiAsync } from "../dashboard/dashboardSlice";
import { formValidator } from "../editProducts/formValidator";
import ProductImageAdd from "../product/productImageAdd";
import AddServiceHeader from "../service/AddServiceHeader";
import AddServiceVariationModal from "../service/AddServiceVariationModal";
import AddStaffModal from "../service/AddStaffModal";
import AssignedTeamMemberModal from "../service/AssignedTeamMemberModal";
import ProductPrice from "../service/productPrice";
import DigitalProductDetails from "./DigitalProductDetails";
import AddDigitalProductImages from "./addDigitalProductImages";

const DigitalProduct = (props) => {
  const location = useLocation();
  const userData = location?.state;
  console.log(userData, "location state");

  const history = useHistory();

  const [maximumValue, setMaximumValue] = useState(
    userData?.supplies[0]?.max_capacity
      ? userData?.supplies[0]?.max_capacity
      : "Max"
  );
  const [minimumValue, setMinimumValue] = useState(
    userData?.supplies[0]?.min_capacity
      ? userData?.supplies[0]?.min_capacity
      : "Min"
  );
  const [hoursValue, setHoursValue] = useState("0 hr");
  const [minutesValue, setMinutesValue] = useState("0 min");
  const [hoursValue1, setHoursValue1] = useState("0 hr");
  const [minutesValue1, setMinutesValue1] = useState("0 min");
  const [productDetails, setProductDetails] = useState({
    productName: userData?.name,
    service_id: userData?.service_id,
    category_id: userData?.category_id,
    sub_category_id: userData?.sub_category_id,
  });
  const [approxTime, setApproxTime] = useState(
    userData?.supplies ? userData?.supplies[0]?.approx_service_time : ""
  );
  const [posList, setPosList] = useState([]);
  const [costPrice, setcostPrice] = useState(userData?.price ?? "");
  const [productDescription, setProductDescription] = useState("");
  const [imageDetails, setImageDetails] = useState({});
  const [prices, setPrices] = useState(
    userData?.supplies[0].supply_prices ?? []
  );
  const [activePos, setActivePos] = useState(
    userData?.supplies[0]?.supply_prices?.find(
      (item) => item.app_name === "pos"
    )
      ? true
      : false
  );
  const [activeOnlineStore, setActiveOnlineStore] = useState(
    userData?.supplies[0]?.supply_prices?.find(
      (item) => item.app_name === "b2c" || item.app_name === "b2b"
    )
      ? true
      : false
  );
  const [activeBtoc, setActiveBtoc] = useState(
    userData?.supplies[0]?.supply_prices?.find(
      (item) => item.app_name === "b2c"
    )
      ? true
      : false
  );
  const [activeBtob, setActiveBtob] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [saleSame, setSaleSame] = useState(false);
  const [differentSale, setDifferentSale] = useState(false);
  const [successLoader, setSuccessLoader] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState(["reservation"]);
  const [type, setType] = useState("digital");
  const [posUserId, setPosUserId] = useState([]);
  const [skipPosDetails, setSkipPosDetails] = useState(false);
  const [syncGoogleCalendar, setSyncGoogleCalendar] = useState(false);
  const [selectedPosId, setSelectedPosId] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [sellerAddressIds, setSellerAddressIds] = useState([]);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  console.log(userData);
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const getIdsHandle = (data) => {
    setSelectedPosId(data);
    handleOnCloseModal();
  };

  const handleProductDetails = (e, flag) => {
    if (flag == "description") {
      setProductDescription(e);
    } else {
      setProductDetails(e);
    }
  };
  const handleImageDetails = (e) => {
    setImageDetails(e);
  };

  const handleprices = (e) => {
    setPrices(e);
  };
  console.log(selectedPosId, "pos iddssss");

  const handleSave = () => {
    console.log(selectedPosId, "prices from state");
    let product_attribute = {
      name: productDetails?.productName,
      category_id: productDetails?.category_id?.toString(),
      sub_category_id: productDetails?.sub_category_id?.toString(),
      service_id: productDetails?.service_id,
      description: productDescription,
      type: type,
      // "barcode": barCode,
      // "sku": sku,
      image: imageDetails?.image,
      approx_service_time: Number(approxTime),
      pos_staff_ids: posUserId,
      images: imageDetails?.images,
      price: costPrice,
      delivery_options: deliveryOptions,
      prices: prices,
      sync_google_calendar: syncGoogleCalendar,
      skip_detail_screen_in_pos: skipPosDetails,
      min_capacity: minimumValue,
      max_capacity: maximumValue,
      buffer_time_before:
        parseInt(minutesValue.split(" ")[0]) +
        parseInt(hoursValue.split(" ")[0]) * 60,
      buffer_time_after:
        parseInt(minutesValue1.split(" ")[0]) +
        parseInt(hoursValue1.split(" ")[0]) * 60,
      sync_google_calendar: syncGoogleCalendar,
      skip_detail_screen_in_pos: skipPosDetails,
      // "need_pos_user":true
    };
    let result = formValidator(
      product_attribute,
      activePos,
      activeBtob,
      activeBtoc
    );
    if (result) {
      setSuccessLoader(true);
      let params = {
        postData: product_attribute,
        tokenData: auth?.payload?.token,
      };

      dispatch(createProductApiAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setSuccessLoader(false);
          toast.success("Service Created Successfully");
          history.push(`/service-list`);
        })
        .catch((obj) => {
          setSuccessLoader(false);
        });
    }
  };

  const handleSelect = (e) => {
    let allIds = e.map((v) => {
      return v.pos_staff_details_id ? v.pos_staff_details_id : v?.id;
    });
    setPosUserId(allIds);
  };
  const setInitialData = () => {
    setMaximumValue(userData?.supplies[0]?.max_capacity);
    setMinimumValue(userData?.supplies[0]?.min_capacity);
    setApproxTime(
      userData?.supplies ? userData?.supplies[0]?.approx_service_time : ""
    );
    setImageDetails({
      image: userData?.image ?? "",
      images: userData?.product_images?.map((item) => {
        return item?.url ?? " ";
      }),
    });
    setHoursValue(
      Math.floor(userData?.supplies[0]?.buffer_time_before / 60) + " " + "hr"
    );
    setMinutesValue(
      (userData?.supplies[0]?.buffer_time_before % 60) + " " + "min"
    );
    setHoursValue1(
      Math.floor(userData?.supplies[0]?.buffer_time_after / 60) + " " + "hr"
    );
    setMinutesValue1(
      (userData?.supplies[0]?.buffer_time_after % 60) + " " + "min"
    );
    setSkipPosDetails(userData?.supplies[0]?.skip_detail_screen_in_pos);
    setSyncGoogleCalendar(userData?.supplies[0]?.sync_google_calendar);
    setProductDescription(userData?.description);
    setPrices(userData?.supplies[0].supply_prices ?? []);
    setPosUserId(
      userData?.pos_staff?.map((item) => item?.pos_staff_details?.id)
    );
  };

  useEffect(() => {
    let params = {
      postData: {
        seller_id: auth?.payload?.uniqe_id
          ? auth?.payload?.uniqe_id
          : auth?.payload?.user?.unique_uuid,
        need_staff_member: true,
      },
      tokenData: auth?.payload?.token,
    };

    setLoading(true);
    dispatch(getPosUsersAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        if (userData) {
          let staffIds = userData?.pos_staff?.map(
            (item) => item?.pos_staff_details_id
          );
          setSelectedPosId(
            obj?.payload?.pos_staff?.filter((value) =>
              staffIds?.includes(value?.id)
            )
          );
        }
        setPosList(obj?.payload?.pos_staff);
        setLoading(false);
      })
      .catch((obj) => {
        setLoading(false);
      });
    if (userData?.from === "duplicateService") {
      setInitialData();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Add-Services";
  }, []);

  const handleSaleToggle = (type) => (event) => {
    const isChecked = event.target.checked;

    if (type === "saleSame") {
      setSaleSame(isChecked);
      if (isChecked) {
        setDifferentSale(false);
      }
    } else if (type === "differentSale") {
      setDifferentSale(isChecked);
      if (isChecked) {
        setSaleSame(false);
      }
    }
  };

  const handleStoreToggle = (storeId) => (event) => {
    const isChecked = event.target.checked;

    setSellerAddressIds((prevIds) => {
      if (isChecked) {
        // Add store ID if checked
        return [...prevIds, storeId];
      } else {
        // Remove store ID if unchecked
        return prevIds.filter((id) => id !== storeId);
      }
    });
  };

  return (
    <div className="addProductManually addserviceManually">
      {/* Start FixedTopHeader */}
      <AddServiceHeader
        save={(e) => handleSave(e)}
        successLoader={successLoader}
        name="Add Digital Product"
      />
      {/* End FixedTopHeader */}
      <div className="productRightWrapper">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-8 p-0">
              <div className="productRightContainer">
                {/* Start Uploaded ProductImg Area */}
                <AddDigitalProductImages imageData={(e) => handleImageDetails(e)} />
                {/* End Uploaded ProductImg Area */}

                {/* Start ProductDetails Area */}

                <DigitalProductDetails
                  productDetails={(e, flag) => handleProductDetails(e, flag)}
                />
                {/* Start CostPrice Area */}
                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      Digital product cost
                    </h2>
                    <div className="col-md-6">
                      <div className="form_group">
                        <label className="fontSize16 fw500 txtDarkblue mb-2 ps-4">
                          Cost of Good Sold
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="Cost Price"
                            name="text"
                            type="number"
                            value={costPrice}
                            onChange={(e) => setcostPrice(e.target.value)}
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 ">
                          Unit
                        </label>

                        <Select
                          className="customSelect"
                          placeholder="Select one"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                {/* End CostPrice Area */}

                <div className="productDetailsContainer">
                  <form className="row costPrice">
                    <h2 className="fontSize18 fw500 txtDarkblue mb-0">
                      Digital product sale price
                    </h2>
                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={saleSame}
                          onChange={handleSaleToggle("saleSame")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            Use different sale price
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Sale price for B2B, Marketplace & POS
                          </h3>
                        </div>
                      </div>
                      {saleSame && (
                        <>
                          <div className="productSalePriceBox">
                            <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                              POS Pricing
                            </h3>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Sale Price
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      min="1"
                                      onKeyDown={blockInvalidChar}
                                      className="customInput costPriceInput"
                                      placeholder="1,000.00"
                                      name="posPrice"
                                      type="number"
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Profit
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      readOnly
                                      className="customInput costPriceInput"
                                      placeholder="Profit"
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Margin
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      readOnly
                                      className="customInput"
                                      placeholder="Margin"
                                    />

                                    <span className="dollrsign1_ usdTxt">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="productSalePriceBox">
                            <h3 className="fontSize18 txtDarkblue fw500 mb-0">
                              Marketplace Pricing
                            </h3>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Sale Price
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      min="1"
                                      onKeyDown={(e) => blockInvalidChar(e)}
                                      className="customInput costPriceInput"
                                      placeholder="1,000.00"
                                      name="marketplacePrice"
                                      type="number"
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Profit
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      readOnly
                                      className="customInput costPriceInput"
                                      placeholder="Profit"
                                    />
                                    <span className="dollrsign1_">$</span>
                                    <span className="dollrsign1_ usdTxt">
                                      USD
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form_group">
                                  <label className="fontSize16 txtDarkblue fw500 mb-2 ps-4 ">
                                    Margin
                                    <img
                                      src={Images.labelImg}
                                      alt="logo"
                                      className="ms-2"
                                    />
                                  </label>
                                  <div className="inputGroup">
                                    <input
                                      readOnly
                                      className="customInput"
                                      placeholder="Margin"
                                    />
                                    <span className="dollrsign1_ usdTxt">
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="productSale_Parent">
                        <input
                          type="checkbox"
                          checked={differentSale}
                          onChange={handleSaleToggle("differentSale")}
                        />

                        <div>
                          <h3 className="fontSize16 txtDarkblue mb-0 pb-1">
                            On sale
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Sale price would be same for all platforms
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* {differentSale && (
                      <> */}
                    <div className="col-md-4">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 d-flex align-items-center">
                          Sale Price
                          <img
                            src={Images.labelImg}
                            alt="logo"
                            className="ms-2"
                          />
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="1,000.00"
                            name="text"
                            type="number"
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 d-flex align-items-center">
                          Profit
                          <img
                            src={Images.labelImg}
                            alt="logo"
                            className="ms-2"
                          />
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput costPriceInput"
                            placeholder="1,000.00"
                            name="text"
                            type="number"
                          />
                          <span className="dollrsign1_">$</span>
                          <span className="dollrsign1_ usdTxt">USD</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form_group">
                        <label className="fontSize16 txtDarkblue mb-2 ps-4 d-flex align-items-center">
                          Margin
                          <img
                            src={Images.labelImg}
                            alt="logo"
                            className="ms-2"
                          />
                        </label>
                        <div className="inputGroup">
                          <input
                            min="1"
                            onKeyDown={blockInvalidChar}
                            className="customInput"
                            placeholder="Select one"
                            name="text"
                            type="number"
                          />

                          <span className="dollrsign1_ usdTxt">%</span>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div className="col-md-12">
                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0 justify-content-between align-items-start">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue pb-1">
                            Service Tax
                          </h3>
                          <h3 className="fontSize14 txtDarkblue mb-0">
                            No Product tax setup yet!
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div className="productDetailsContainer">
                      <div className="productSale_Parent mt-0">
                        <div>
                          <h3 className="fontSize18 fw500 txtDarkblue mb-2">
                            Attributes
                          </h3>
                          <p className="fontSize14  txtDarkblue mb-0">
                            Does your product come in different options, like
                            size, color or material? Add them here.
                          </p>
                          <button
                            className="commonBtn mt-4"
                            onClick={() => {
                              setModalDetail({
                                show: true,
                                flag: "serviceVariationModal",
                              });
                              setKey(Math.random());
                            }}
                          >
                            <img
                              src={Images.btnPlusIcon}
                              alt="logo"
                              className="me-2"
                            />
                            Add Variation
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Start JobrPos Area */}
                <ProductPrice
                  activePos={activePos}
                  activeBtoc={activeBtoc}
                  activeBtob={activeBtob}
                  activeOnlineStore={activeOnlineStore}
                  costPrice={costPrice}
                  prices={(e) => handleprices(e)}
                />

                {/* End ProductDetails Area */}
              </div>
            </div>

            <div className="col-md-4">
              {/* Start ProductAvailability Area */}
              <div className="productLeftContainer">
                <div className="outerArea">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    Product availability
                  </h3>

                  <div>
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.physicalStoreIcon}
                        alt="logo"
                        className="physicalStoreIcon me-2"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            Physical store
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            JOBR POS
                          </h3>
                        </div>
                      </div>
                    </div>
                    <ul className="productAvailabilityList">
                      <li className="productAvailabilityChild mt-4">
                        <div class="form-group">
                          <input
                            type="checkbox"
                            id="store1"
                            checked={sellerAddressIds.includes(1)}
                            onChange={handleStoreToggle(1)}
                          />
                          <label htmlFor="store1"></label>
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                            Store 1
                          </h3>
                          <p className="fontSize12 txtLightBlue mb-0">
                            88070 Torey Gateway Colorado Springs, CO 80924
                          </p>
                        </div>
                      </li>
                      <li className="productAvailabilityChild mt-3">
                        <div class="form-group">
                          <input
                            type="checkbox"
                            id="store2"
                            checked={sellerAddressIds.includes(2)}
                            onChange={handleStoreToggle(2)}
                          />
                          <label htmlFor="store2"></label>
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue mb-0 pb-1">
                            Store 2
                          </h3>
                          <p className="fontSize12 txtLightBlue mb-0">
                            88070 Torey Gateway Colorado Springs, CO 80924
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="productAvail_Second">
                    <div className="d-flex align-items-center">
                      <img
                        src={Images.physicalStoreIcon}
                        alt="logo"
                        className="physicalStoreIcon me-2"
                      />
                      <div className="innnerParent">
                        <div className="productSale_Parent p-0 m-0">
                          <input type="checkbox" checked={true} />
                        </div>
                        <div>
                          <h3 className="fontSize14 fw500 txtDarkblue">
                            JOBR Marketplace
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            Allow customers to book this service on JOBR
                            Marketplace app.
                          </h3>
                        </div>
                      </div>
                    </div>
                    <ul className="productAvailabilityList">
                      <li className="productAvailabilityChild mt-4 align-items-center">
                        <div class="form-group">
                          <input type="checkbox" id="pickup" />
                          <label for="pickup"></label>
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Pickup
                          </h3>
                        </div>
                      </li>
                      <li className="productAvailabilityChild mt-3 align-items-center">
                        <div class="form-group">
                          <input type="checkbox" id="delivery" />
                          <label for="delivery"></label>
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Delivery
                          </h3>
                        </div>
                      </li>
                      <li className="productAvailabilityChild mt-3 align-items-center">
                        <div class="form-group">
                          <input type="checkbox" id="shipping" />
                          <label for="shipping"></label>
                        </div>
                        <div>
                          <h3 className="fontSize14 txtDarkblue mb-0 ">
                            Shipping
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="outerArea mb-0">
                  <h3 className="fontSize18 fw500 txtDarkblue mb-4">
                    Marketing options
                  </h3>
                  <div className="createCoupen_container">
                    <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                      Create coupon
                    </h3>
                    <img src={Images.upArrow} alt="logo" />
                  </div>
                  <div className="createCoupen_container">
                    <h3 className="fontSize18 fw600 mb-0 txtDarkblue">
                      Promote this product
                    </h3>
                    <img src={Images.upArrow} alt="logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "ActivePos" ? "customContent" : ""}
        ids={
          modalDetail.flag === "ActivePos"
            ? "activePos"
            : modalDetail.flag === "addStaff"
            ? "addStaff"
            : modalDetail.flag === "assignedTeamMember"
            ? "addProductOptions"
            : modalDetail.flag === "serviceVariationModal"
            ? "editVariantModal"
            : ""
        }
        size={modalDetail.flag === "addStaff" ? "lg" : "md"}
        child={
          modalDetail.flag === "addStaff" ? (
            <AddStaffModal
              posIds={selectedPosId}
              close={() => handleOnCloseModal()}
              getIdsHandle={(e) => getIdsHandle(e)}
              handleSelect={(e) => handleSelect(e)}
            />
          ) : modalDetail.flag === "assignedTeamMember" ? (
            <AssignedTeamMemberModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "serviceVariationModal" ? (
            <AddServiceVariationModal close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        header={
          modalDetail.flag === "assignedTeamMember" ? (
            <h3 className="fontSize24 fw500 txtDarkblue mb-0">
              Assigned team members
            </h3>
          ) : modalDetail.flag === "serviceVariationModal" ? (
            <>
              <div></div>
              <img
                src={Images.modalCross}
                alt="logo"
                className="cursorPointer"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : (
            <div className="modalHeader_">
              <div className="common_">
                <h2 className="modalHeading">Staffs</h2>
                <button className="closeButton">
                  <i
                    className="las la-times"
                    onClick={() => handleOnCloseModal()}
                  ></i>
                </button>
              </div>
            </div>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default DigitalProduct;
