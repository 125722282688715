import React, { useEffect } from "react";
import * as Images from "../../utilities/images";

const ConnectedStatus = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        window.ReactNativeWebView.postMessage('paymentSuccess');
      } catch (err) {
        console.log(err);
      }
    }, 10000);

    // Cleanup the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []); 

 
  return (
    <div className="verifySuccess">
      <div className="verifyBox">
        <figure className="successImg">
          <img src={Images.success} alt="tickImage" className="img-fluid" />
        </figure>
        <h1 className="verifyHeading">Success !</h1>
        <p className="congratulations-text">
          Congratulations! Your bank account has been successfully connected.
        </p>
      </div>
    </div>
  );
};

export default ConnectedStatus;
