import React from "react";
import * as Images from "../../../utilities/images";

const DeleteVariationModal = () => {
  return (
    <div>
      <p className="fontSize16 txtDarkblue">
        Are you sure you want to delete option set{" "}
        <span className="fw700">Color</span> from this item? This will rename
        the item variations to no longer include the{" "}
        <span className="fw700">Color options</span>.
      </p>

      <p className="actionDeleteTxt fontSize16">
        This action will delete the 6 variations below to avoid duplicate
        variation names.
      </p>

      <div className="modalOptionBox deleteVariation_ModalBox">
        <h3 className="fontSize16 allItemVariationTxt">All item variations</h3>

        <div className="deleteVariation_innerBox">
          <img src={Images.productItemImg} alt="logo" />
          <h6 className="mb-0">Yellow / Small</h6>
        </div>
        <div className="deleteVariation_innerBox">
          <img src={Images.productItemImg} alt="logo" />
          <h6 className="mb-0">Blue / Small</h6>
        </div>
        <div className="deleteVariation_innerBox">
          <img src={Images.productItemImg} alt="logo" />
          <h6 className="mb-0">Pink / Small</h6>
        </div>
        <div className="deleteVariation_innerBox">
          <img src={Images.productItemImg} alt="logo" />
          <h6 className="mb-0">Green / Small</h6>
        </div>
        <div className="deleteVariation_innerBox">
          <img src={Images.productItemImg} alt="logo" />
          <h6 className="mb-0">Yellow / Small</h6>
        </div>
      </div>
    </div>
  );
};

export default DeleteVariationModal;
