import axios from "axios";
import { toast } from "react-toastify";
import { REACT_APP_USER_SERVICES, REACT_APP_PRODUCT_URL, REACT_APP_CHAT_URL } from "../../config";
import { REACT_APP_WALLET_URL, } from "../../config";
const queryString = require('query-string');


// export function imageUploadApi(data, token) {
//   return new Promise((resolve, reject) =>
//     axios
//       .post(`${REACT_APP_CHAT_URL}api/v1/chats/UploadImages`, data, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           'Accept': 'application/json',
//           'Authorization': token,
//           'app-name': 'merchant'
//         }
//       })
//       .then(function (response) {
//         // toast.success(response?.data?.msg)
//         resolve(response);
//       })
//       .catch(function (error) {
//         reject(error)
//         // toast.error(error?.response?.data?.msg)
//       })
//   )
// }

export function imageUploadApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_PRODUCT_URL}api/v1/products/upload/images`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getChatUserListApi(token) {

  // let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}/api/v1/user_profiles/merchantlist?search=${token.search}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token.token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function sendMessageApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_CHAT_URL}api/v1/chats`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        //toast.error(error?.response?.data?.payload[0] ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}


export function getMessageheadsApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_CHAT_URL}api/v1/chatheads` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}



export function getMessageListApi(data, queryData, token) {
  let query = queryString.stringify(queryData);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_CHAT_URL}api/v1/chatHeads/${data?.id}/${data?.idtype}` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}


export function endOfferApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_PRODUCT_URL}api/v1/offer`, data, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function deleteChatApi(data, token) {
  // let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_CHAT_URL}api/v1/chatheads/${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function markAsReadChatApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_CHAT_URL}api/v1/chatheads/markread/${data?.id}`,JSON.stringify({}), {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function deleteSingleMessageApi(data, token) {
  // let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_CHAT_URL}api/v1/chats/${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}