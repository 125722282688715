import React, { useState } from 'react'
import ReactSelect from "react-select";
import * as Images from "../../../../utilities/images";
import CustomModal from '../../../components/shared/CustomModal';
import ChooseitemModal from './chooseitemModal';
import AddratesModal from './addratesModal';
import StateselectModal from './stateselectModal';
import {Link} from "react-router-dom"
const ShippingProfile = () => {
  const resultSelectOption = [
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "150", label: "150" },
    { value: "200", label: "200" },
  ];
  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());
  };

  // End Modal
  return (
    <>
      <div className='shippingProfile_'>
        <div className='shippingInner_'>
          <div className='shipprofileHead_'>
            <h2 className='Innerhead'>New shipping profile</h2>
            <div className='right_shippingbtn_'>
              <Link to="/orderSettings?page=shipping">
              <button className='discardBtn_' type='button'>Discard</button></Link>
              <button className='saveBtn_' type='button'>Save</button>
            </div>
          </div>
          <div className='profileshipDetail_ mt-5'>
            <div className='row'>
              <div className='col-md-8'>
                <div className='profileleftInfo_'>
                  <div className="col-md-12">
                    <div className="reactSelectParent commonSelectParent w-100">
                      <label className="fontSize16 txtDarkblue mb-2 ps-4">
                        Profile Name
                      </label>
                      <ReactSelect
                        options={resultSelectOption}
                        placeholder="Store LLC"
                        classNamePrefix="react-select"
                        className="react-select-container anlyticSelect"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            maxWidth: "100% !important",
                            width: "100%",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            border: "1px solid #D7DEFF",
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            paddingLeft: "unset !important",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#263682"
                              : state.isFocused
                                ? "#f5f6fc"
                                : "#fff",

                            color: state.isSelected ? "#fff" : "#263682",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "#636E9F",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }),
                        }}
                        components={{
                          DropdownIndicator: () => (
                            <img
                              src={Images.selectImg}
                              width={24}
                              height={24}
                              alt="drop_icon"
                              className="mt-1"
                            />
                          ),
                        }}
                      />
                      <p className='settingText ps-3 mt-1'>This name will not show to customer</p>
                    </div>
                  </div>
                  <div className='processignOrder mt-4 p-0'>
                    <div className='ordrsettingHead_ '>
                      <h3 className='normalHeading_ ps-3'>Type of profile</h3>
                    </div>

                    <div className="row mt-3">
                      <div className="col-12">
                        <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                          <input type="checkbox" />
                          <div>
                            <h3 className="fontSize14 txtDarkblue">
                              Default (all items)
                            </h3>
                            <h3 className="fontSize12 txtLightBlue mb-0">
                              Includes all items available for shipping that don’t belong to a custom shipping profile. New items are included automatically.
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className='serviceSettingBoxes mt-3'>
                          <div className="customitemsBox_ d-flex align-items-start  gap-3">
                            <input type="checkbox" checked="true" />
                            <div>
                              <h3 className="fontSize14 txtDarkblue">
                                Custom (specific items)
                              </h3>
                              <h3 className="fontSize12 txtLightBlue mb-0">
                                Charge a custom rate or create a destination restriction for certain items.
                              </h3>
                            </div>
                          </div>
                          <button className='viewAllbtn_ chooseitemBtn_' type='button' onClick={() => {
                            setModalDetail({
                              show: true,
                              flag: "ChooseitemModal",
                            });
                            setKey(Math.random());
                          }}>Choose Item</button>

                          <div className='itemsselectedCase_'>
                            <div className='searchBox_ship'>
                              <div className='searchleft_'>
                                <img src={Images.SearchIcon} className='searchIcon_' />
                                <input className='searchinput_' type='search' placeholder='Search team member' />
                              </div>
                              <h3 className='normalHeading_'>2 Items</h3>
                            </div>
                            <div className="commonTable productTable table-responsive mt-3">
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="productAvailabilityChild align-items-center">

                                        <div className="d-flex align-items-center gap-3">
                                          <img
                                            src={Images.productItemImg}
                                            alt="img"
                                            className="productItemImg"
                                          />
                                          <div>
                                            <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                                              Lightweight Stylish Casual Daypack
                                            </h3>

                                            <div className="d-flex align-items-center gap-2">
                                              <span className="skyblueDot"></span>
                                              <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                                SKU 0199 - 3221
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="fontSize12 txtLightBlue"></td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="productAvailabilityChild align-items-center">
                                        <div className="d-flex align-items-center gap-3">
                                          <img
                                            src={Images.productItemImg}
                                            alt="img"
                                            className="productItemImg"
                                          />
                                          <div>
                                            <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                                              Lightweight Stylish Casual Daypack
                                            </h3>

                                            <div className="d-flex align-items-center gap-2">
                                              <span className="skyblueDot"></span>
                                              <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                                SKU 0199 - 3221
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="fontSize12 txtLightBlue"><img className='circlecross' src={Images.circlecross} /></td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='ratesShipping_'>
                    <h3 className='normalHeading_ mb-2'>Rates</h3>
                    <h3 className="fontSize12 txtLightBlue ">
                      If there are multiple items with different shipping profiles in one order, shipping rates will be added together.
                    </h3>
                    <button className='lightBluebtn_ mt-3' type="button" onClick={() => {
                      setModalDetail({
                        show: true,
                        flag: "AddratesModal",
                      });
                      setKey(Math.random());
                    }}> <img className='btnPlusIcon' src={Images.btnPlusIcon} /> Add Rate </button>
                    <div className='rateInfo_'>
                      <div className="commonTable productTable table-responsive mt-3">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Rate Type</th>
                              <th>Rate</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="fontSize12 txtLightBlue">Store LLC rate</td>
                              <td className="fontSize12 txtLightBlue">Free shipping</td>
                              <td className="fontSize12 txtLightBlue"><span className='redbg_'>$900.00</span></td>
                              <td className="fontSize12 txtLightBlue"><img className='circlecross' src={Images.circlecross} /></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='profileRightinfo_'>
                  <h3 className='normalHeading_ mb-2'>Shipping to</h3>
                  <h3 className="fontSize12 txtLightBlue ">
                    You cannot add countries or states already added to another default profile.
                  </h3>

                  <div className='countrySelect_ mb-3'>
                    <img className='countryFlag_' src={Images.countryflag} />
                    <h3 className='countryText_'>  United States of America</h3>
                  </div>

                  <h3 className='normalHeading_ mb-2'>Select states</h3>
                  <h3 className="fontSize12 txtLightBlue ">
                    By default, all states are pre-selected. Unselect states that you want to exclude from this shipping profile. Shipping profiles that apply to all items must have unique shipping destinations (two profiles cannot contain the same states).
                  </h3>
                  <div className='profiletoggle_'>
                    <div className="col-12">
                      <div className="serviceSettingBoxes d-flex align-items-start  gap-3">
                        <input type="checkbox" />
                        <div>
                          <h3 className="fontSize14 txtDarkblue">
                            All states
                          </h3>
                          <h3 className="fontSize12 txtLightBlue mb-0">
                            (50 States & 12 Properties)
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className='statesBoxes_'>
                        <div className="serviceSettingBoxes d-flex align-items-start mt-3 gap-3">
                          <input type="checkbox" onClick={() => {
                            setModalDetail({
                              show: true,
                              flag: "StateselectModal",
                            });
                            setKey(Math.random());
                          }} />
                          <div>
                            <h3 className="fontSize14 txtDarkblue">
                              Specify states
                            </h3>
                            <h3 className="fontSize12 txtLightBlue mb-0">
                              (50 States & 12 Properties)
                            </h3>
                          </div>
                        </div>
                        <div className='selectedBoxstate_'>
                          <div className='selectStates_list'>
                            <div className="listInside_ d-flex align-items-start mt-3 gap-3">
                                <h3 className='stateList_head'>Arizona</h3>
                                <img className='circlecross' src={Images.circlecross} />
                            </div>
                            <div className="listInside_ d-flex align-items-start mt-3 gap-3">
                                <h3 className='stateList_head'>Arkansas</h3>
                                <img className='circlecross' src={Images.circlecross} />
                            </div>
                          </div>
                          <button className='secondaryBorderbtn_ mt-4' type='submit'>Select State</button>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "ChooseitemModal"
            ? "editVariantModal"
            :
            modalDetail.flag === "AddratesModal"
              ? "editVariantModal"
              :
              modalDetail.flag === "StateselectModal"
                ? "editVariantModal"
                : ""
        }
        child={
          modalDetail.flag === "ChooseitemModal" ? (
            <ChooseitemModal close={() => handleOnCloseModal()} />
          ) :
            modalDetail.flag === "AddratesModal" ? (
              <AddratesModal close={() => handleOnCloseModal()} />
            ) :
              modalDetail.flag === "StateselectModal" ? (
                <StateselectModal close={() => handleOnCloseModal()} />
              ) :
                (
                  <></>
                )
        }
        header={
          <>
            {modalDetail.flag === "ChooseitemModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h6 className="fontSize12 txtLightBlue ">
                      0 items selected
                    </h6>
                    <h3 className="fontSize24 fw500 txtDarkblue mb-2 ">
                      Choose items
                    </h3>

                  </div>
                  <img
                    src={Images.modalCross}
                    alt="logo"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            ) : modalDetail.flag === "AddratesModal" ? (
              <>
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <h3 className="fontSize24 fw500 txtDarkblue mb-2 ">
                      Add rate
                    </h3>
                  </div>
                  <img
                    src={Images.modalCross}
                    alt="logo"
                    onClick={() => handleOnCloseModal()}
                  />
                </div>
              </>
            ) :
              modalDetail.flag === "StateselectModal" ? (
                <>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div>
                      <h6 className="fontSize12 txtLightBlue ">
                        2 states selected
                      </h6>
                      <h3 className="fontSize24 fw500 txtDarkblue mb-2 ">
                        Select state
                      </h3>
                    </div>
                    <img
                      src={Images.modalCross}
                      alt="logo"
                      onClick={() => handleOnCloseModal()}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default ShippingProfile