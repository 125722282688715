import React from 'react'
import * as Images from "../../../../utilities/images";

const StateselectModal = () => {
    return (
        <div className='stateselectModal_'>
            <p>By default, all states are pre-selected. Unselect states that you want to exclude from this shipping profile.
                Shipping profiles that apply to all items must have unique shipping destinations (two profiles cannot contain the same states).</p>
            <div className='chooseitemModal__content mt-5'>
                <div className='searchBox_ship'>
                    <img src={Images.SearchIcon} className='searchIcon_' />
                    <input className='searchinput_' type='search' placeholder='Search state by name' />
                </div>

                <div className='stateList_ mt-3'>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state1"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state1"
                            className="checkBoxTxt masterChecked"
                        >
                            States
                        </label>
                    </div>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state2"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state2"
                            className="checkBoxTxt masterChecked"
                        >
                            Alabama
                        </label>
                    </div>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state3"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state3"
                            className="checkBoxTxt masterChecked"
                        >
                            American Samoa
                        </label>
                    </div>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state4"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state4"
                            className="checkBoxTxt masterChecked"
                        >
                            Arizona
                        </label>
                    </div>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state5"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state5"
                            className="checkBoxTxt masterChecked"
                        >
                            Arkansas
                        </label>
                    </div>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state6"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state6"
                            className="checkBoxTxt masterChecked"
                        >
                            Armed Forces of the Americas
                        </label>
                    </div>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state7"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state7"
                            className="checkBoxTxt masterChecked"
                        >
                            Armed Forces of the Pacific
                        </label>
                    </div>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state8"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state8"
                            className="checkBoxTxt masterChecked"
                        >
                            California
                        </label>
                    </div>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state9"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state9"
                            className="checkBoxTxt masterChecked"
                        >
                            Colorado
                        </label>
                    </div>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state10"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state10"
                            className="checkBoxTxt masterChecked"
                        >
                            Connecticut
                        </label>
                    </div>
                    <div className="graphBox_ mb-3">
                        <input
                            type="checkbox"
                            id="custom-checkbox-state11"
                            className="checkBox "
                            name="custom-checkbox-selectAll"
                           
                        />
                        <label
                            htmlFor="custom-checkbox-state11"
                            className="checkBoxTxt masterChecked"
                        >
                            Delaware
                        </label>
                    </div>
                </div>
            </div>
            <div className='footerBtn_ d-flex align-items-center mt-4'>
                <button className='greyBtn_'>Cancel</button>
                <button className='blueButton_'>Add items</button>
            </div>
        </div>
    )
}

export default StateselectModal