import React, { useCallback, useState } from "react";
import { selectLoginAuth } from "../auth/authSlice";
import { imageUploadAsync } from "../message/messageSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { imgGallery, modalCancel } from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import ImgUploadedModal from "../service/imgUploadedModal";
import CustomLoader from "../../components/shared/CustomLoader";
import BrowseImgUpload from "../product/browseImgUpload";
import * as Images from "../../../utilities/images";
import { useDropzone } from "react-dropzone";

const AddDigitalProductImages = (props) => {
  // let imageData
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [selectedFlag, setSelectedFlag] = useState("");
  const [imageUrl, setImageUrl] = useState([]);
  const [fileBlob, setFileBlob] = useState();
  const [mainImage, setMainImage] = useState("");
  const [differentAngelsImage, setDifferentAngelsImage] = useState("");
  const [closeUpImage, setCloseUpImage] = useState("");
  const [inUseImage, setInUseImage] = useState("");
  const [styleImage, setStyleImage] = useState("");
  const [indexVal, setIndexVal] = useState("");
  const auth = useSelector(selectLoginAuth);
  const toastId = React.useRef(null);
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
  const dispatch = useDispatch();

  // Start Upload File
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [fileError, setFileError] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles, "acceptdedd");
    const uploadedFile = acceptedFiles?.[0];
    const validTypes = ["image/jpeg", "image/png"];
    const maxSize = 5 * 1024 * 1024;

    if (!uploadedFile) {
      setFileError("Please upload a file.");
      toast.error("No file uploaded.");
      return;
    }

    if (!validTypes.some((type) => uploadedFile.type === type)) {
      setFileError("Invalid file type.");
      toast.error("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }

    if (uploadedFile.size > maxSize) {
      setFileError("File size exceeds the 5MB limit.");
      toast.error("File size exceeds the 5MB limit.");
      return;
    }

    setFileError("");
    setFile(uploadedFile);

    const filePreview = URL.createObjectURL(uploadedFile);
    setPreview(filePreview);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
  });

  // End Upload File

  const handleChange = (file) => {
    const image = file;
    uploadImage(image);
    //setFile(file);
  };
  const handleUserProfile = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  const cropImageFunc = (file, blobData) => {
    let data = {
      filePath: file,
      mimeType: blobData?.type,
      size: blobData?.size,
      originalname: blobData?.name,
    };
    let result = [...imageUrl];
    result[indexVal] = data;
    setImageUrl(result);
  };

  const uploadImage = (image) => {
    let formData = new FormData();
    let fileBlobs = fileBlob ? [...fileBlob] : [];
    Object.values(image).forEach((val, index) => {
      formData.append(`file`, val);
      fileBlobs.push(val);
      setFileBlob(fileBlobs);
    });

    let params = {
      file: formData,
      token: auth?.payload?.token,
    };

    if (image?.length <= 10) {
      setLoading(true);
      dispatch(imageUploadAsync(params))
        .then(unwrapResult)
        .then((obj) => {
          setLoading(false);
          setImageUrl([...imageUrl, ...obj?.payload]);

          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please upload image less than 10");
      }
    }
  };

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleSelectedImage = (e) => {
    const imageurls = imageUrl?.map((val) => val.filePath);
    if (selectedFlag === "mainImage") {
      setMainImage(e);
      //    let imageData = {
      //         "image": e,
      //         "images": imageurls
      //     }
      //     props?.imageData(imageData)
    }
    if (selectedFlag === "styleImage") {
      setStyleImage(e);
    }
    if (selectedFlag === "differentAngelsImage") {
      setDifferentAngelsImage(e);
    }
    if (selectedFlag === "closeUpImage") {
      setCloseUpImage(e);
    }
    if (selectedFlag === "inUseImage") {
      setInUseImage(e);
    }
  };

  return (
    <div className="addProductContainer ">
      <div className="product_uploadParent">
        <h3 className="fontSize18 fw500 txtDarkblue">Digital file </h3>
        <p className="fontSize14  txtDarkblue">Upload your digital file.</p>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="showToken_Img mt-0 addDigitalProductfile">
            <div {...getRootProps({ className: "" })}>
              <input name="image" type="file" {...getInputProps()} />
              <div className="uploadImg_Parent">
                {file ? (
                  <div className="showImg_Parent">
                    <img
                      src={preview}
                      alt="Preview"
                      className="detailsImg img-fluid h-100"
                    />
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <img
                      src={Images.lightFileUploadLogo}
                      alt="Camera"
                      style={{ cursor: "pointer" }}
                    />
                    <p className="dragAndDrop_Txt ms-2">
                      Your customers will receive links to download their
                      digital products
                      <br />
                      in the thank you page, as well as by email.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {fileError && <p className="error-message">{fileError}</p>}
          </div>
        </div>
      </div>

      <div className="productDetailsContainer">
        <div className="product_uploadParent">
          <h3 className="fontSize18 fw500 txtDarkblue">
            Digital product Images
          </h3>
          <p className="fontSize14  txtDarkblue">
            Upload your digital product images{" "}
          </p>
        </div>
        {loading ? (
          //    <CustomLoader/>
          <span className="spinner-border inner-spin spinner-border-sm spinnerDark"></span>
        ) : (
          <div className="row">
            <div className="col-md-8">
              {/* <div className="product_Images"> */}
              <div
                onClick={() => {
                  handleUserProfile("browseImageUpload");
                }}
                className="inputfile-box active w-100"
              >
                {/* <input type="file" id="file" className="form-control inputfile d-none" name="images[]" data-id="1" accept="image/png, image/jpeg" multiple /> */}
                <label htmlFor="file">
                  <span id="file-name" className="file-box d-none">
                    Choose File
                  </span>
                  <div className="file-button">
                    {/* <img
                    src={imgGallery}
                    width="25"
                    id="profileImagePreview1"
                    alt=""
                    className="beforeUpload"
                  /> */}
                    <p className="uplImg_Inner">
                      Click to browse or <br /> drag and drop your files
                    </p>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-md-2">
              <div
                className={
                  mainImage
                    ? "fillUploadedFrames imgUploaded w-100"
                    : "fillUploadedFrames w-100"
                }
                onClick={() => {
                  setModalDetail({ show: true, flag: "ImageUpload" });
                  setKey(Math.random());
                  setSelectedFlag("mainImage");
                }}
              >
                {mainImage ? (
                  <img
                    src={mainImage}
                    className="uploadedImage"
                    alt="UploadImg"
                  />
                ) : (
                  <>
                    <p className="mainImage">Cover</p>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-2">
              <div
                className={
                  differentAngelsImage
                    ? "fillUploadedFrames imgUploaded w-100"
                    : "fillUploadedFrames w-100"
                }
                onClick={() => {
                  setModalDetail({ show: true, flag: "ImageUpload" });
                  setKey(Math.random());
                  setSelectedFlag("differentAngelsImage");
                }}
              >
                {differentAngelsImage ? (
                  <img
                    src={differentAngelsImage}
                    className="uploadedImage"
                    alt="UploadImg"
                  />
                ) : (
                  <>
                    <p className=" mainImage">Back</p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        // backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "ImageUpload"
            ? "imageUpload"
            : modalDetail.flag === "browseImageUpload"
            ? "addProductOptions"
            : ""
        }
        size={modalDetail.flag === "ImageUpload" ? "xl" : "md"}
        child={
          modalDetail.flag === "ImageUpload" ? (
            <ImgUploadedModal
              close={() => handleOnCloseModal()}
              selectedImage={(e) => handleSelectedImage(e)}
              imageData={imageUrl}
            />
          ) : modalDetail.flag === "browseImageUpload" ? (
            <BrowseImgUpload
              indexVal={setIndexVal}
              setMainImage={setMainImage}
              imageData={(e) => props.imageData(e)}
              loading={loading}
              uploadImageHandle={(e) => uploadImage(e)}
              selectedImage={(e) => handleSelectedImage(e)}
              images={imageUrl}
              fileBlob={fileBlob}
              setFileBlob={setFileBlob}
              indexId={indexVal}
              setImageUrl={setImageUrl}
              setCroppedImage={(e, data) => cropImageFunc(e, data)}
              setDifferentAngelsImage={setDifferentAngelsImage}
              setCloseUpImage={setCloseUpImage}
              setInUseImage={setInUseImage}
              setStyleImage={setStyleImage}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "browseImageUpload" ? (
            <>
              <h4 className="fontSize24 txtDarkblue fw500">Image Upload</h4>
              <p className="modal_cancel">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={handleOnCloseModal}
                  src={Images.modalCross}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default AddDigitalProductImages;
