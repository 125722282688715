import React, { forwardRef, useState } from "react";
import * as Images from "../../../utilities/images";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomModal from "../../components/shared/CustomModal";
import EditOfferModal from "./EditOfferModal";
import StopOfferModal from "./StopOfferModal";
import PauseOfferModal from "./PauseOfferModal";

const ProductOfferView = () => {
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <img src={Images.calenderLogo} alt="logo" className="me-2" />
        {value}
      </button>
    )
  );

  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  return (
    <div className="productDetailsWrapper">
      <div className="container-fluid h-100 p-0">
        <div className="row h-100">
          <div className="col-md-4">
            <div className="walletStats h-100 productDetailSidebar">
              <div className="d-flex align-items-start gap-3">
                <img
                  src={Images.backArrow}
                  alt="logo"
                  onClick={() => history.push("/productOffer")}
                />
                <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                  Lightweight Stylish Casual Daypack
                </h2>
              </div>
              <div className="productPreviewBox text-center">
                <img
                  src={Images.productItemImg}
                  alt="bagImg"
                  className="offerProductImg"
                />
                <h4 className="fontSize20 fw500 txtDarkblue mb-0 pt-4">
                  Lightweight Stylish Casual Daypack
                </h4>

                <div className="subCategoryTxtBox">
                  <span className="greyDot"></span>
                  <h6 className="greyDotLabel mb-0">
                    SKU 0199 - 3221
                  </h6>
                </div>
              </div>

              <div className="productSidebar_Bottom d-flex align-items-center gap-2">
                <button
                  className="headerDiscardBtn "
                  onClick={() => {
                    setModalDetail({
                      show: true,
                      flag: "stopOfferModal",
                    });
                    setKey(Math.random());
                  }}
                >
                  <img src={Images.stopIcon} alt="stopIcon" className="me-2" />
                  Stop
                </button>
                <button
                  className="activeProductBtn"
                  onClick={() => {
                    setModalDetail({
                      show: true,
                      flag: "pauseOfferModal",
                    });
                    setKey(Math.random());
                  }}
                >
                  <img src={Images.pauseIcon} alt="stopIcon" className="me-2" />
                  Pause
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-8 ps-0">
            <div className="walletStats productDetailsLeft">
              <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                Product details
              </h2>

              <div className="productDetailDataBox">
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Category</h5>
                  <h5>Grocery</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Details</h5>
                  <h5>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English. Many desktop publishing packages and web
                    page editors now use Lorem Ipsum as their default model
                    text, and a search for 'lorem ipsum' will uncover many web
                    sites still in their infancy. Various versions have evolved
                    over the years, sometimes by accident, sometimes on purpose
                    (injected humour and the like)
                  </h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">SKU</h5>
                  <h5>7044085C</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Barcode</h5>
                  <h5>003 01008 9999 0233</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Unit weight</h5>
                  <h5>800g</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Unit</h5>
                  <h5>Per Item</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Cost</h5>
                  <h5>$85.00</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">POS price</h5>
                  <h5>$90.00</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Marketplace price</h5>
                  <h5>$92.00</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">B2B price</h5>
                  <h5>$85.00</h5>
                </div>
              </div>

              <div className="offerDetailBox">
                <h2 className="fontSize24 fw500 txtBlue mb-0">Offer Details</h2>

                <div className="offerPeriodBox mt-3">
                  <h5 className="fontSize18 fw500 txtDarkblue mb-0">Period</h5>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="datePicker_Parent">
                        <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
                          Start from
                        </label>
                        <DatePicker
                          showIcon
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          customInput={
                            <ExampleCustomInput className="example-custom-input" />
                          }
                        />
                        <p className="pressEnterTxt pt-2 ps-4 mb-0">
                          Starts at 00:00 AM
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="datePicker_Parent">
                        <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
                          Start from
                        </label>
                        <DatePicker
                          showIcon
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          customInput={
                            <ExampleCustomInput className="example-custom-input" />
                          }
                        />
                        <p className="pressEnterTxt pt-2 ps-4 mb-0">
                          Ends at 11:59 PM
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 mb-3">
                  <h5 className="fontSize18 fw500 txtDarkblue mb-0">Pricing</h5>
                  <div className="editVariant_SecondBox editPricingBox mt-3">
                    <div className="upcBoxParent">
                      <div className="row">
                        <div className="col-4">
                          <div className="upcBox">
                            <h3 className="fontSize18 mb-0 txtDarkblue">
                              Offer price option
                            </h3>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="upcBox upcRightBox">
                            <h3 className="pressEnterTxt fontSize18 mb-0">
                              Different Offer Price
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="upcBoxParent">
                      <div className="row">
                        <div className="col-4">
                          <div className="upcBox">
                            <h3 className="fontSize18 mb-0 txtDarkblue">
                              Offer type
                            </h3>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="upcBox upcRightBox">
                            <h3 className="pressEnterTxt fontSize18 mb-0">
                              Amount
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="upcBoxParent">
                      <div className="row">
                        <div className="col-4">
                          <div className="upcBox">
                            <h3 className="fontSize18 mb-0 txtDarkblue">
                              POS price
                            </h3>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="upcBox upcRightBox">
                            <h3 className="pressEnterTxt fontSize18 mb-0">
                              $90.00
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="upcBoxParent">
                      <div className="row">
                        <div className="col-4">
                          <div className="upcBox">
                            <h3 className="fontSize18 mb-0 txtDarkblue">
                              Marketplace price
                            </h3>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="upcBox upcRightBox">
                            <h3 className="pressEnterTxt fontSize18 mb-0">
                              $95.00
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="upcBoxParent">
                      <div className="row">
                        <div className="col-4">
                          <div className="upcBox">
                            <h3 className="fontSize18 mb-0 txtDarkblue">
                              B2B Price
                            </h3>
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="upcBox upcRightBox">
                            <h3 className="pressEnterTxt fontSize18 mb-0">
                              $88.00
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className="commonBtn"
                  onClick={() => {
                    setModalDetail({
                      show: true,
                      flag: "editOfferModal",
                    });
                    setKey(Math.random());
                  }}
                >
                  <img
                    src={Images.lightBlueEditIcon}
                    alt="lightBlueEditIcon"
                    className="me-2"
                  />
                  Edit Offer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "editOfferModal"
            ? "editVariantModal"
            : modalDetail.flag === "stopOfferModal"
            ? "stopOfferModal"
            : modalDetail.flag === "pauseOfferModal"
            ? "stopOfferModal"
            : ""
        }
        child={
          modalDetail.flag === "editOfferModal" ? (
            <EditOfferModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "stopOfferModal" ? (
            <StopOfferModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "pauseOfferModal" ? (
            <PauseOfferModal close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        header={
          <>
            {modalDetail.flag === "editOfferModal" ? (
              <>
                <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                  {" "}
                  Edit Product Offer
                </h2>
                <img
                  src={Images.modalCross}
                  alt="logo"
                  className="cursorPointer"
                  onClick={() => handleOnCloseModal()}
                />
              </>
            ) : (
              ""
            )}
          </>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default ProductOfferView;
