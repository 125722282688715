import React, { useState } from "react";
import * as Images from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import ProductSidebar from "../../components/shared/productSidebar";
import SuccessfullyAddProductModal from "./SuccessfullyAddProductModal";

const AllProduct = () => {
  // Start Modal
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
  
    setKey(Math.random());
  };

  // End Modal
  return (
    <>
      <div className="allProductHeader">
        <button className="headerBackBtn d-flex">
          <img
            src={Images.blueBackArrow}
            alt="lightLeftArrowLogo"
            className="backbtnArrow me-1"
          />
          Back
        </button>
      </div>
      <ProductSidebar
      // subCategoryId={(e) => getSubCategoryId(e)}
      // brandId={setBrandIds}
      />

      <div className="productRightWrapperParent">
        <div className="rightWrapper productRightWrapper  ProductsBox_">
          <div className="content_areas mt-0">
            <div className="container-fluid p-0">
              {/* <ProductNavbar /> */}
              <div className="myProductHeader">
                <div className="row align-items-center">
                  <div className="col-6">
                    <h5 className="fontSize14 fw500 txtDarkblue mb-0">
                      All Products
                      <span className="allproductCount fontSize12 ps-1">
                        (33)
                      </span>
                    </h5>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center justify-content-end">
                      <button className="commonBtn fontSize14 addToShopBtn" onClick={()=>{
                        handleOpenModal("successfullyAddProduct")
                      }}>
                        Add to Shop
                        <img src={Images.upArrow} alt="logo" className="ms-2" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="commonTable allProductTable table-responsive mt-3">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>
                        <div className="productAvailabilityChild align-items-center">
                          <div className="form-group">
                            <input type="checkbox" id="id1" />
                            <label htmlFor="id1" className="me-0"></label>
                          </div>
                          <h3 className="fontSize12 mb-0">Product (33)</h3>
                        </div>
                      </th>

                      <th>Category</th>
                      <th>Sub-Category</th>
                      <th>Cost Price</th>
                      <th>Location</th>
                      <th>Stock</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="productAvailabilityChild align-items-center">
                          <div className="form-group">
                            <input type="checkbox" id="id3" />
                            <label htmlFor="id3" className="me-0"></label>
                          </div>

                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                                Lightweight Stylish Casual Daypack
                              </h3>

                              <div className="d-flex align-items-center gap-2">
                                <span className="skyblueDot"></span>
                                <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                  SKU 0199 - 3221
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="fontSize12 txtBlue">Grocery</td>
                      <td className="fontSize12 txtBlue">Breads</td>
                      <td className="fontSize12 txtLightBlue">$90.00</td>
                      <td className="fontSize12 txtBlue">30</td>
                      <td className="fontSize12 txtBlue">$90.00</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="productAvailabilityChild align-items-center">
                          <div className="form-group">
                            <input type="checkbox" id="id3" />
                            <label htmlFor="id3" className="me-0"></label>
                          </div>

                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                                Lightweight Stylish Casual Daypack
                              </h3>

                              <div className="d-flex align-items-center gap-2">
                                <span className="skyblueDot"></span>
                                <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                  SKU 0199 - 3221
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="fontSize12 txtBlue">Grocery</td>
                      <td className="fontSize12 txtBlue">Breads</td>
                      <td className="fontSize12 txtLightBlue">$90.00</td>
                      <td className="fontSize12 txtBlue">30</td>
                      <td className="fontSize12 txtBlue">$90.00</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="productAvailabilityChild align-items-center">
                          <div className="form-group">
                            <input type="checkbox" id="id3" />
                            <label htmlFor="id3" className="me-0"></label>
                          </div>

                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                                Lightweight Stylish Casual Daypack
                              </h3>

                              <div className="d-flex align-items-center gap-2">
                                <span className="skyblueDot"></span>
                                <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                  SKU 0199 - 3221
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="fontSize12 txtBlue">Grocery</td>
                      <td className="fontSize12 txtBlue">Breads</td>
                      <td className="fontSize12 txtLightBlue">$90.00</td>
                      <td className="fontSize12 txtBlue">30</td>
                      <td className="fontSize12 txtBlue">$90.00</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="productAvailabilityChild align-items-center">
                          <div className="form-group">
                            <input type="checkbox" id="id3" />
                            <label htmlFor="id3" className="me-0"></label>
                          </div>

                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="fontSize12 txtDarkblue fw500 mb-0 pb-1 text-left">
                                Lightweight Stylish Casual Daypack
                              </h3>

                              <div className="d-flex align-items-center gap-2">
                                <span className="skyblueDot"></span>
                                <h6 className="staffPositionTxt fontSize12 fw400 mb-0">
                                  SKU 0199 - 3221
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="fontSize12 txtBlue">Grocery</td>
                      <td className="fontSize12 txtBlue">Breads</td>
                      <td className="fontSize12 txtLightBlue">$90.00</td>
                      <td className="fontSize12 txtBlue">30</td>
                      <td className="fontSize12 txtBlue">$90.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="allProductPagination d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 cursorPointer">
                  <img
                    src={Images.lightLeftArrowLogo}
                    alt="img"
                    width={16}
                    height={16}
                  />
                  <span className="fontSize12 fw500 txtLightPurple">Prev</span>
                </div>
                <h3 className="fontSize12 fw500 txtLightBlue">Page 1 of 2</h3>
                <div className="d-flex gap-2 cursorPointer">
                  <span className="fontSize12 fw500 txtDarkblue">Next</span>
                  <img
                    src={Images.webPosArrow}
                    alt="img"
                    width={16}
                    height={16}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "successfullyAddProduct" ? "successfullyAddProduct" : ""}
        child={
          modalDetail.flag === "successfullyAddProduct" ? (
            <SuccessfullyAddProductModal close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default AllProduct;
