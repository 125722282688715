import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import PhoneInput from 'react-phone-input-2';

const EditshipModal = ({locations,close}) => {
    const [locationDetails, setlocationDetails] = useState(null);
    const [locationIndex, setlocationIndex] = useState(0);
    const handleLocation=(index)=>{
        setlocationIndex(index);
        setlocationDetails(locations[Number(index)]);
    }
    const [getAllLocations, setgetAllLocations] = useState([]);
    useEffect(()=>{
        let locationNames=locations.map((location)=>location.name);
        setgetAllLocations(locationNames);
    },[locations])
    const handleUpdate=()=>{

    }
    const handleDiscard=()=>{
        close();
    }
    useEffect(()=>{
        console.log(locationDetails,"check details");
    },[locationDetails])
    return (
        <div className='editshipModal_'>
            <form className="shipeditModal_form">
                <div className="mb-4 form-group col-md-12">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">Select the location to pull inventory from for shipping orders</label>
                    <select
                        autoComplete="new-password"
                        className="customform-control select"
                        value={locationIndex}
                        onChange={(event)=>{
                            handleLocation(event.target.value);
                        }}
                    >
                        {
                            getAllLocations.map((location, index) => (
                                <option key={index} value={index}>{location}</option>
                            ))
                        }
                        {/* <option value="operations" disabled>
                            Store LLC
                        </option>
                        <option value="0">Store LLC</option>
                        <option value="1">Online</option>
                        <option value="2">Both</option> */}
                    </select>
                </div>
                <div className="mb-4 form-group col-md-12">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">Street Address</label>
                    <div className='from-add_'>
                        <input
                            type="text"
                            disabled={locationDetails==null}
                            value={locationDetails?.address||''}
                            name='address'
                            onChange={(event)=>{
                                let key=event.target.name;
                                let value=event.target.value;
                                setlocationDetails({...locationDetails,[key]:value});
                            }}
                            className="customform-control ps-5"
                            autoComplete="new-password"
                            placeholder="Street, Ave, West..."
                        />
                        <span className='locationicon_'><img className='locicon_' src={Images.loctionicn} /></span>
                    </div>
                </div>
                <div className="mb-4 form-group col-md-12">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">Apartment/Suite</label>
                    <div className='from-add_'>
                        <input
                            type="text"
                            className="customform-control ps-5"
                            // autoComplete="new-password"
                            disabled={locationDetails==null}
                            value={locationDetails?.apartment||''}
                            name='apartment'
                            onChange={(event)=>{
                                let key=event.target.name;
                                let value=event.target.value;
                                setlocationDetails({...locationDetails,[key]:value});
                            }}
                            placeholder="Building, Interior"
                        />
                        <span className='locationicon_'><img className='locicon_' src={Images.loctionicn} /></span>
                    </div>
                </div>
                <div className='row'>
                    <div className="mb-4 form-group col-md-6">
                        <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">State</label>
                        <input
                            className="customform-control id_password"
                            placeholder="State"
                            autoComplete="new-password"
                            type="text"
                            disabled={locationDetails==null}
                            value={locationDetails?.state||''}
                            name='state'
                            onChange={(event)=>{
                                let key=event.target.name;
                                let value=event.target.value;
                                setlocationDetails({...locationDetails,[key]:value});
                            }}

                        />
                        {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                    <div className="mb-4 form-group col-md-6">
                        <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">City</label>
                        <input
                            className="customform-control id_password"
                            placeholder="City"
                            autoComplete="new-password"
                            type="text"
                            disabled={locationDetails==null}
                            value={locationDetails?.city||''}
                            name='city'
                            onChange={(event)=>{
                                let key=event.target.name;
                                let value=event.target.value;
                                setlocationDetails({...locationDetails,[key]:value});
                            }}
                        />

                        {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                </div>
                <div className="mb-4 form-group col-md-6">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">Zip code</label>
                    <input
                        type="number"
                        className="customform-control"
                        autoComplete="new-password"
                        placeholder="Zip code"
                        disabled={locationDetails==null}
                        value={locationDetails?.zip||''}
                        name='zip'
                        onChange={(event)=>{
                            let key=event.target.name;
                            let value=event.target.value;
                            setlocationDetails({...locationDetails,[key]:value});
                        }}
                    />
                </div>
                <div className="mb-4 form-group col-md-12">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">Phone Number</label>
                    {/* <div style={{ display: "flex" }}>
                        <PhoneInput
                            className=""
                            autoComplete="new-password"
                            country="us"
                            disabled={locationDetails===null}
                            disabled={locationDetails==null}
                            value={locationDetails.state}
                            name='state'
                            onChange={(event)=>{
                                let key=event.target.name;
                                let value=event.target.value;
                                setlocationDetails({...locationDetails,[key]:value});
                            }}
                            placeholder="Phone no."
                        // onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}

                        />

                    </div> */}
                    <div style={{ display: "flex" }}>
    <PhoneInput
        className=""
        autoComplete="new-password"
        country="us"
        disabled={locationDetails === null}
        value={locationDetails?.phone || ""}
        name="phone"
        onChange={(value) => {
            setlocationDetails({
                ...locationDetails,
                phone: value, // Updating the phone number field
            });
        }}
        placeholder="Phone no."
    />
</div>

                </div>
                <div className="mb-4 form-group col-md-12">
                    <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">Email Address</label>

                    <div className='from-add_' >
                        <input
                            type="email"
                            className="customform-control ps-5"
                            autoComplete="new-password"
                            placeholder="Email"
                            disabled={locationDetails==null}
                        value={locationDetails?.email||''}
                        name='email'
                        onChange={(event)=>{
                            let key=event.target.name;
                            let value=event.target.value;
                            setlocationDetails({...locationDetails,[key]:value});
                        }}

                        />
                        <span className='locationicon_'><img className='locicon_' src={Images.emailicn_} /></span>

                        {/* {phoneData?.is_email_verified === true ? <img style={{ width: "20px" }} src={check} alt="" /> : <></>} */}
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='formbuttons_'>
                        <button disabled={locationDetails===null} onClick={handleDiscard} className='greyBtn_' type='submit'>Discard</button>
                        <button disabled={locationDetails===null} onClick={handleUpdate} className='blueButton_' type='submit'>Update</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default EditshipModal