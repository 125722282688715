import axios from "axios";
import { toast } from "react-toastify";
import { REACT_APP_WALLET_URL, REACT_APP_PRODUCT_URL, REACT_APP_ORDER_URL, REACT_APP_USER_SERVICES, REACT_APP_SUPPORT_URL, REACT_APP_CHAT_URL } from "../../config"
import { getCurrentTimeZone } from './../../../utilities/timezone'
const queryString = require('query-string');
const timeZone = getCurrentTimeZone();


export function getAllPlans(token) {
  // let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/plans/stripe-plan`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getAllSaveCard(token) {
  // let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/subscription/paymentMethods`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getAddCard(data, token) {
  // let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/subscription/paymentMethods`,data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.error)
      })
  )
}

export function subscriptionBuy(data, token) {
  // let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/subscription`,data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function cardDeleteApi(data, token) {
  // let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_WALLET_URL}api/v1/subscription/paymentMethods/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function createWalletApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/wallets`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload?.message : error?.response?.data?.msg)
      })
  )
}
export function buySubscriptionApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/subscription`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function createProductApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_PRODUCT_URL}api/v1/products`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function getWalletProfile(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/wallets/user`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function requestKyc(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/wallets/request-kyc`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function checkKyc(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/wallets/request-kyc`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function getDocumentType(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/wallets/document-types?wallet_type=${data.wallet_type}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': data.tokenData,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function uploadKycDocument(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/wallets/upload-documents`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function generatePlaidTokenApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/wallets/plaid/token`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function getBankAccountApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/wallets/bank-accounts?wallet_type=business`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function getConnectBankAccountInfoApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/users/account-details`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}


export function linkBankAccount(data, token) {
  data = {
    ...data,
    wallet_type: 'business'
  };
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/wallets/link-bank-account`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function getIdCardPhoto(data) {
  let formData = new FormData();
  formData.append('document', data.document);
  return new Promise((resolve, reject) =>
    axios.post(`${REACT_APP_USER_SERVICES}api/v1/user_profiles/verification-document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
        'app-name': 'merchant'
      }
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function getBusinessTypes(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/wallets/business-types`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function getNaicsCategories(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/wallets/naics-categories`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function businessRegistrationApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/wallets/business/register`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.log(error?.response?.data, "dssssssssssssssssssssssssss");
        toast.error(error?.response?.data?.payload?.length > 0 ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function businessKycApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/wallets/business/request-kyc`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function uploadbusinessDocument(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/wallets/business/upload-documents`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function setupAddressApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/seller_addresses`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
      })
  )
}
export function getSellerAddresses(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/seller_addresses` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}

export function getUserSettings(data, token) {
  let query = queryString.stringify(data);
  //api/v1/user_settings
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/user_settings` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}
///
export function updateUserSettings(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/user_settings`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function updateSellerInfoApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/seller_addresses`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function updateInventoryApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/seller_addresses`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function changeSellerAddressStatus(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/seller_addresses/change-status`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function addYourDriverApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/merchant_drivers/send-otp`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function verifyDriverApi(data, token) {
  let addressId = data.address_id
  delete data.address_id
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/merchant_drivers/verify-otp?address_id=${addressId}`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function addFedexServiceApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_ORDER_URL}api/v1/shipping_seller_credentials`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getAllShippingServiceApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/shipping_service` + `?` + query, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function updateShippingAddressStatusApi(data, token) {
  let id = data?.id
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_ORDER_URL}api/v1/shipping_service/${id}`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function changeShippingStatusApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_ORDER_URL}api/v1/shipping_seller_credentials`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getCategories(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/categories` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}

export function getPluginsList() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/plugins`, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}

export function getBrandOfCategory(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/brands` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}

export function getWalletStatisticsApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/statistics` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}

export function getWalletInventoryGraphApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/pos/statistics/inventory-cost` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}
export function getAttributeApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/attributes` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}

export function getProductes(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/products/b2b` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}



export function getMerchantStatistics(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/statistics` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  )
}


export function getMerchantOrderGraph(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/pos/statistics/inventory-cost` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  )
}

export function getMerchantTotalCustomerGraph(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/pos/statistics/customers/count-new` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  )
}

export function getMerchantTotalOrderGraph(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/statistics/total-graph` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      })
  )
}

export function createAttributeApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_PRODUCT_URL}api/v1/attributes`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getAttributesApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/attributes` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}

export function getAttributesByIdApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/attributes` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}

export function getAllOrdersApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function searchByInvoiceIdApi(dataToSend, token) {
  const { invoiceId, seller_id, isScanned } = dataToSend;
  delete dataToSend.invoiceId;

  const invoiceKey = "invoices/by-invoice-number";
  const scanByBarCode = "invoices/scan-barcode";
  let query = queryString.stringify(dataToSend)



  let resp;
  if (isScanned) {
    const body = {
      barcode: invoiceId,
      seller_id,
    };
    // resp = yield call(
    //   ApiClient.post,
    //   `${ORDER_API_URL_V1}${scanByBarCode}`,
    //   (action.payload = body)
    // );
    resp = axios.post(`${REACT_APP_ORDER_URL}api/v1/${scanByBarCode}`, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'merchant',
        'timezone': timeZone
      }
    })

  } else {
    // resp = yield call(
    //   ApiClient.get,
    //   `${ORDER_API_URL_V1}${invoiceKey}/${invoiceId}?seller_id=${seller_id}`
    // );
    resp = axios.get(`${REACT_APP_ORDER_URL}api/v1/${invoiceKey}/${invoiceId}` + "?" + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'merchant',
        'timezone': timeZone
      }
    })
  }

  return new Promise((resolve, reject) =>
    resp
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function searchBySkuApi(dataToSend, token) {
  console.log(dataToSend, "dataToSendnaresh");
  // const { search } = dataToSend;
  // delete dataToSend?.search;

  let query = queryString.stringify(dataToSend)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/products/search-one` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}



export function changeStatusApi(data, token) {
  let id = data?.id
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_ORDER_URL}api/v1/orders/status/${id}`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getSevicesApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/services` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function getWalletTransectionsApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/transactions/user` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function getAppointemntApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/appointments` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function getAddedToInventoryProducts(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/products/merchant/to-add-in-inventory` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function addProductsToInventory(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_PRODUCT_URL}api/v1/products/merchant/add-in-inventory`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function importFileApi(data, file, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_PRODUCT_URL}api/v1/products/import` + "?" + query, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function changeAppointmentStatusApi(data, token) {
  let id = data?.id
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_ORDER_URL}api/v1/appointments/status/${id}`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getAppointmentCountApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/appointments/merchant/count` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getAppointmentGraphApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/appointments/merchant/graph` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getWalletBallanceApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/wallets/balance?wallet_type=business`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function issueSilaBalanceToYourWallet(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/transactions/issue-sila`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function redeemBalanceToYourBank(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/transactions/redeem`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getPosCountApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/statistics/pos_orders_count` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getPosGraphApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/statistics/pos_orders_graph` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getSalesPerPosApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/pos/pos-sales` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getPosListingApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getInventoryCountApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/supplies/merchant/overview/inventory_count` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getInventoryDetailsApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/supplies/merchant/overview/inventory_quantity` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}


export function getDriverCountApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/drivers` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getInventorySalePurchaseStatisticsApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview/inventory_graph` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getInventoryDetailsByIdApi(data, token) {
  let id = data?.id;
  delete data?.id
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/supplies/merchant/inventory-details/${id}` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
// Api for create folder in Catalogs 
export function createCatalogFolderApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/catalogFolder`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

// Api for get all Folders 
export function getAllFoldersApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/catalogFolder` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

// Api for delete folder 
export function deleteFolderApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_USER_SERVICES}api/v1/catalogFolder/delete/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

// Api for delete product 
export function deleteProductApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_PRODUCT_URL}api/v1/products/remove`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        },
        data
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

// Api for update folder
export function updateFolderApi(data, token) {
  let id = data?.id;
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/catalogFolder/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

// Api for get one Folder data
export function getSingleFolderDataApi(data, token) {
  let folderId = data.id
  delete data.id
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/catalogFolder/${folderId}` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function uploadFileInCatelog(data, token) {
  return new Promise((resolve, reject) =>
    axios.post(`${REACT_APP_USER_SERVICES}api/v1/catalog/upload-files`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': token,
        'app-name': 'merchant'
      }
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function createCatalogApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/catalog`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getAllCatalogsApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/catalog` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function deleteCatalogApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_USER_SERVICES}api/v1/catalog/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function updateCatalogApi(data, token) {
  let id = data?.id;
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/catalog/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getStorageDataApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/catalog/type/size`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getSubscriberApi(data) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/subscribers` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getDriverProfileApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/driver` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getManufactureRetailerWholeSellerApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/users-list` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getManufactureDetailsByIdApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/user-details` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getPurchaseGraphApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview/purchase/graph` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getConsumerDataApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/consumers` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getPurchaseListApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/purchases/all` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getSupportDataApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_SUPPORT_URL}api/v1/supports/tickets-respond-analysis` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getSupportTicketDataApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_SUPPORT_URL}api/v1/supports/merchant-tickets-status` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getDepartmentDataInSupportApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/departments`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getSupportAgentsApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/user_departments/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function assignSupportAgentApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_SUPPORT_URL}api/v1/supports/assign-aggent`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getSingleOrderPurchaseApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function reportAnIssueApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_SUPPORT_URL}api/v1/supports`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
        console.error(error?.response, "response error");
      })
  )
}
export function deleteSupportApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_SUPPORT_URL}api/v1/supports/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getSingleSupportDataApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_SUPPORT_URL}api/v1/supports/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getSupportTicketCountApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_SUPPORT_URL}api/v1/supports/user-tickets-statistics` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getCashFlowStatisticsApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/transactions/user/overview/statistics` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getMerchantStepStatus(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/users/merchant/step-status`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function getMerchantActiveSubscription(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/subscription/active`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

export function getTransactionStatisticsApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/transactions/user/overview/jbr_usd_statistics` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getOrderTrackingApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/order-tracking` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getSingleOrderTrackingApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/order_delivery/get-orders-with-delivery` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getAnalyticStatDataApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/pos/analytics` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getAnalyticOrdersApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/pos/analytics/count/graph-new` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}


export function totalAnalyticsOrderApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/statistics/orders/total-new` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function totalAnalyticsInventoryApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/statistics/inventory` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function totalAnalyticsProductSoldApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/statistics/product/sold` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getJbrUsdTransactionsApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/transactions/user/jbr_usd_statistics_transactions` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function deleteBankAccountApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_WALLET_URL}api/v1/wallets/bank-account/delete`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        },
        data
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function checkbankBalanceApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_WALLET_URL}api/v1/wallets/bank-account/balance`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function createProductOfferApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_PRODUCT_URL}api/v1/offer`, data, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
      })
  )
}
export function updateProductOfferApi(data, token) {
  let id = data?.product_id;
  delete data?.product_id
  return new Promise((resolve, reject) =>
    axios.put(`${REACT_APP_PRODUCT_URL}api/v1/offer/${id}`, data, {
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'app-name': 'merchant'
      }
    })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
      })
  )
}
export function getProductOfferListApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/offer` + "?" + query, {

        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getWithdrawalDataApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/transactions/user/withdrawals/jbr_usd_statistics_transactions` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getWalletRefundDataApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_WALLET_URL}api/v1/transactions/user/refunded/transactions` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getFaqDataApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_SUPPORT_URL}api/v1/faqs/get-by-admin` + "?" + query, {
        // .get(`${REACT_APP_SUPPORT_URL}api/v1/faqs` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getTrendingArticleApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_SUPPORT_URL}api/v1/faqs` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}


export function getFaqCategoryDataApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_SUPPORT_URL}api/v1/faqs_category` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function updateFaqCategoryApi(data, token) {
  let id = data?.id;
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_SUPPORT_URL}api/v1/faqs_category/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function updateFaqApi(data, token) {
  let id = data?.id;
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_SUPPORT_URL}api/v1/faqs/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getFaqCategoryTagsApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_SUPPORT_URL}api/v1/faqs_category/get-category-tags`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': data,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

// quickbook

export function quickbookGetUriApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/user_quickbooks/authuri`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': data,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function quickbookSyncDataApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/sync-order`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': data,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)

      })
  )
}

export function quickbookGetTokenApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/user_quickbooks/get-token`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

// quickbook end

export function staffPaymentApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/pos_staff_salary/merchant/salary-requests` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function staffPayApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/pos_staff_salary/merchant/pay-to-staff`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function createFaqCategoryApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_SUPPORT_URL}api/v1/faqs_category`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function createFaqApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_SUPPORT_URL}api/v1/faqs/create-by-admin`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function deleteFaqCategoryApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_SUPPORT_URL}api/v1/faqs_category/${data.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function deleteFaqyApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_SUPPORT_URL}api/v1/faqs/${data.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

export function getRecievedCatalogApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_CHAT_URL}api/v1/chats/received-catalogs`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getSingleRecievedCatalogApi(id, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_CHAT_URL}api/v1/chats/received-catalogs/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function deleteFolderInRecievedCatalogApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_CHAT_URL}api/v1/chats/delete-folder` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function deleteFileInRecievedCatalogApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_CHAT_URL}api/v1/chats/delete-file` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function createSupportAgentApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/users/agent`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
        console.error(error?.response, "response error");
      })
  )
}
export function getAllSupportAgentsApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/users/agent` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function deleteSupportAgentApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_USER_SERVICES}api/v1/users/agent-delete/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function updateSupportAgentApi(data, token) {
  let id = data?.id;
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/users/agent/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getNewUserStatisticsApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/pos/statistics/customers/count-new` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function resheduleBookingApi(data, token) {
  let id = data.id;
  delete data.id;
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_ORDER_URL}api/v1/appointments/reschedule/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function getBookingTimeSlotsApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/slots/pos/service-appointment-slots` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function editProductApi(data, token) {
  let id = data?.id;
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_PRODUCT_URL}api/v1/products/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function editProductSupplyApi(data, token) {
  let id = data?.id;
  let supplyId = data?.supplyId;
  delete data?.id
  delete data?.supplyId
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_PRODUCT_URL}api/v1/products/supply/${id}/${supplyId}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getOrderWalletTransactions(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/wallet/transaction/analysis` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}



export function getOrderDetails(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function getAllCustomers(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/customers/analysis` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function getOrderDetailById(id, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/pos/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function getOrderDetailByOrderId(id, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_ORDER_URL}api/v1/orders/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function returnToInventoryInOrder(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_ORDER_URL}api/v1/returns`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}

// Api for delete product 
export function deleteProductSupply(data, token) {
  let productId = data?.product_Id;
  let supplyId = data?.supply_Id;
  delete data?.productId
  delete data?.supplyId
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_PRODUCT_URL}/api/v1/products/supply/${productId}/${supplyId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        },
        data
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
      })
  )
}

export function createProductSupplyApi(data, token) {
  let id = data?.id;
  delete data?.id
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_PRODUCT_URL}api/v1/products/supply/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function verifyOtpInOrderApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_ORDER_URL}api/v1/orders/verify-pickup-otp`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
          'timezone': timeZone
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function deleteOfferApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_PRODUCT_URL}api/v1/offer/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}
export function createCustomerApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/invitations`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function getMarketStatusApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/marketings/status` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function getTotalOrdersGraphStatus(data,token){
  let query=queryString.stringify(data);
  //?seller_id=bef1ef45-b255-4b5b-8425-f03eefd71ee6&filter=month&channel=all&seller_address_id=19
  return new Promise((resolve, reject) =>
  axios
    .get(`${REACT_APP_ORDER_URL}api/v1/orders/pos/analytics/count/graph-new` + "?" + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'merchant'
      }
    })
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error)
    })
)
}
export function getTotalOrdersGraph(data,token){
  let query=queryString.stringify(data);
  //{{order_url}}/?seller_id=2db22768-195d-4b64-b0bf-060d980bba18&filter=month
  return new Promise((resolve, reject) =>
  axios
    .get(`${REACT_APP_ORDER_URL}api/v1/orders/statistics/total-graph` + "?" + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'merchant'
      }
    })
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error)
    })
)
}

export function getTotalOrdersGraphOverview(data,token){
  let query=queryString.stringify(data);
///?seller_id=2db22768-195d-4b64-b0bf-060d980bba18&filter=month
  return new Promise((resolve, reject) =>
  axios
    .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview` + "?" + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'merchant'
      }
    })
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error)
    })
)
}
export function updateMarketStatusApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/marketings`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

// get orderProcessingdata
export function getOrderProcessingdata(data,token){
  let query=queryString.stringify(data);
///?seller_id=2db22768-195d-4b64-b0bf-060d980bba18&filter=month
  return new Promise((resolve, reject) =>
  axios
    .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview` + "?" + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'merchant'
      }
    })
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error)
    })
)
}
export function updateOrderProcessingdata(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/marketings`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

// get PickupDeliveryData
export function getPickupDeliveryData(data,token){
  let query=queryString.stringify(data);
///?seller_id=2db22768-195d-4b64-b0bf-060d980bba18&filter=month
  return new Promise((resolve, reject) =>
  axios
    .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview` + "?" + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'merchant'
      }
    })
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error)
    })
)
}
export function updatePickupDeliveryData(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/marketings`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
// get ShippingData
export function getShippingData(data,token){
  let query=queryString.stringify(data);
///?seller_id=2db22768-195d-4b64-b0bf-060d980bba18&filter=month
  return new Promise((resolve, reject) =>
  axios
    .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview` + "?" + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'merchant'
      }
    })
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error)
    })
)
}
export function updateShippingData(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/marketings`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

// get CustomNotification
export function getCustomNotification(data,token){
  let query=queryString.stringify(data);
///?seller_id=2db22768-195d-4b64-b0bf-060d980bba18&filter=month
  return new Promise((resolve, reject) =>
  axios
    .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview` + "?" + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'merchant'
      }
    })
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error)
    })
)
}
export function updateCustomNotification(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/marketings`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
// get AdditionalSetting
export function getAdditionalSetting(data,token){
  let query=queryString.stringify(data);
///?seller_id=2db22768-195d-4b64-b0bf-060d980bba18&filter=month
  return new Promise((resolve, reject) =>
  axios
    .get(`${REACT_APP_ORDER_URL}api/v1/orders/merchant/overview` + "?" + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'merchant'
      }
    })
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      reject(error)
    })
)
}
export function updateAdditionalSetting(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/marketings`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function getNotificationList(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/notifications`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

// new

export function getProductCouponListApi(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_PRODUCT_URL}api/v1/coupons` + '?' + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}


export function deleteCouponApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_PRODUCT_URL}api/v1/coupons/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
        // toast.error(error?.response?.data?.msg)
      })
  )
}

// new
export function updateProductCouponApi(data, token) {
  let id = data?.id;
  delete data?.id
  return new Promise((resolve, reject) =>
    axios.put(`${REACT_APP_PRODUCT_URL}api/v1/coupons/${id}`, data, {
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
        'app-name': 'merchant'
      }
    })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
      })
  )
}

export function getAllGiftCard(data, token) {
  let query = queryString.stringify(data);
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/gifts/pagination` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant',
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}
export function createGiftCardApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/gifts`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function updateGiftCardApi(data, token) {
  let giftId = data?.id
  delete data.id
  return new Promise((resolve, reject) =>
    axios
      .put(`${REACT_APP_USER_SERVICES}api/v1/gifts/${giftId}`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

// Api for delete gift card 
export function deleteGiftCardApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .delete(`${REACT_APP_USER_SERVICES}api/v1/gifts/${data?.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}

export function getMerchantSettings(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${REACT_APP_USER_SERVICES}api/v1/settings`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
      })
  )
}

export function settingUpdateApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${REACT_APP_USER_SERVICES}api/v1/settings`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'merchant'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}