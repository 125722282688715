import React from 'react'
import { OrderDown,
    cancleorder,
    incoiceuser_,
    modalCancel,
    pickuporder,
    profileImg1,
    returnlogo,
    serviceuser_,
    unselectedOrder,
    drivefast_,
    upArrow,
    locateOrder,
    clockBlue,
    moneyImg,
    mapimg,
    deliveryMap,warningicon,
    fillradio_,
    verifyorder,
    orderDriver,done,cancelledstep,
    readystep,
    acceptstep,
    openTag,
    closeTag} from '../../../../utilities/images';
    import * as Images from '../../../../utilities/images';
import moment from 'moment-timezone';
const OrderTrackingDetails = ({orderDetails,setshowOrderInvoice}) => {
  return   <>
  {/* <div className="scheduleModalContent">
  <div className="invoiceUser_ row">
    <div className="col-md-6">
      <div className="reviewuserinfo_">
        <img
          className="invoveuser me-3"
          style={{ width: "50px" }}
          src={
            orderDetails?.user_details?.profile_photo
              ? orderDetails?.user_details?.profile_photo
              : profileImg1
          }
        />
        <article className="ivoiceuserDetail_">
          <h4 className="monshead14">
            {
              orderDetails?.user_details?.firstname+" "+
              orderDetails?.user_details?.lastname
            }
          </h4>
          <div className="d-flex align-items-center gap-2">
            <h6 className="orederLoc_ fontSize14 fw500 mb-0">
              <img
                src={locateOrder}
                alt="img"
                className="location"
              />  {
                orderDetails?.user_details?.current_address?.street_address
              }
            </h6>
          </div>
        </article>
      </div>
    </div>

    <div className="col-md-6">
      <div className="reviewuserinfo_">

        <article className="ivoiceuserDetail_">
          <h4 className="monshead14 ">
          {orderDetails?.delivery_details?.title}
          </h4>
          <h6 className="hourWindow_">
            <img
              src={clockBlue}
              alt="img"
              className="location"
            />  Immediately
          </h6>
        </article>
      </div>
    </div>


  </div>
  {
  orderDetails?.order_details?.length > 0
    ? orderDetails?.order_details?.map((val, i) => {
      return (
        <div key={i} className="serviceDeatils_">
          <div className="serviceinfo_">
            <figure className="proservice_ me-3">
              <img
                className="img-fluid"
                src={val?.product_image}
              />
            </figure>
            <article className="duration_">
              <h3 className="darkblack">
               {
                val?.product_name
               }
              </h3>
              <div className="colorsBox_">
                <div className="colopalat_">
                  <span className="pinkcolor_"></span>
                  <span className="bluecolor_"></span>
                  <span className="blackcolor_"></span>
                  <span className="withoutcolor_"></span>


                </div>
                <h6 className="lightgreyHeading_">Colors / Size</h6>
              </div>
            </article>
          </div>
          <div className="servicechanges_">
            <span className="serciceprice_">
              {Number(val?.actual_price)?.toFixed(2)}
            </span>
            <span className="serciceprice_">{val?.qty}</span>
            <span className="serciceprice_">
             
                    <span className="">{`$${(Number(val?.actual_price||0) * Number(val?.qty|| 0))?.toFixed(2)}`}
                    </span>

            </span>
          
          </div>
        </div>
      )
    })
    : orderDetails?.return_detail?.return_details?.map(
      (val, i) => {
        return (
          <div key={i} className="serviceDeatils_">
            <div className="serviceinfo_">
              <img
                style={{ width: "50px" }}
                className="img-fluid me-3"
                src={val?.order_details?.product_image}
              />
              <article className="duration_">
                <h3 className="lightgreyHeading_">
                  {val?.order_details?.product_name}
                </h3>
              </article>
            </div>
            <div className="servicechanges_">
              <span className="serciceprice_">
                $
                {Number(val?.order_details?.price)?.toFixed(2)}
              </span>
              <span className="serciceprice_">
                {val?.order_details?.qty}
              </span>
              <span className="serciceprice_">
                $
                {Number(
                  val?.order_details?.qty *
                  val?.order_details?.price
                )?.toFixed(2)}
              </span>
             
            </div>
          </div>
        );
      }
    )}
</div>
<div className="grandTotal_ row">

  {
    (orderDetails?.order_delivery && orderDetails?.delivery_option == '1' && orderDetails?.order_delivery?.cancel_order_otp && !orderDetails?.order_delivery?.has_returned) ?
      <div className="col-md-5 text-start">
        <div className="orderinvoive">
          <h4 className="monshead22">Return</h4>
          <h4 className="monshead22">
            {orderDetails?.order_delivery?.updated_at
              ? moment(
                orderDetails?.order_delivery?.updated_at
              ).format("DD MMM YYYY | HH:mm A")
              : ""}
          </h4>
          <h5 className="lightgreyHeading_">Cancel Order Otp</h5>
          <h4 className="monshead22">
            {orderDetails?.order_delivery?.cancel_order_otp ? orderDetails?.order_delivery?.cancel_order_otp : "1234"}
          </h4>
        </div>
      </div>
      :
      <div className="col-md-3 text-start">
        <div className="orderinvoive">
          <h5 className="lightgreyHeading_">Total Item</h5>
          <h4 className="monshead22">{orderDetails?.total_items}</h4>
          <h5 className="lightgreyHeading_">Order Date</h5>
          <h4 className="monshead22">
            {moment(orderDetails?.created_at).format("MM/DD/YYYY")}
          </h4>
          <h5 className="lightgreyHeading_">Order Id #</h5>
          <h4 className="monshead22">
            {orderDetails?.invoices?.order_id}
          </h4>
          <h5 className="lightgreyHeading_">Payment <br /> Method</h5>
          <h4 className="monshead22">
            <div className="deliverTableBx">
              <img
                src={moneyImg}
                alt="location Image"
                className="img-fluid ms-1"
              />
              <span className="locateDistance">{
                orderDetails?.mode_of_payment
              }</span>
            </div>
          </h4>

          
        </div>
      </div>}

  <div className="col-md-9 text-start">
    <div className="orderBill_">
      <div className="Customertotal">
        <div className="totalDetails">
          <h5 className="lightgreyHeading_ ">
            Sub Total
          </h5>
          <h5 className="personalinfoHead ">
            {console.log(orderDetails?.total_sale_price, orderDetails?.order_details?.length > 0, "dsssssssssssssssssssss")}
            {
              `$${(orderDetails?.order_details?.length > 0 ? (orderDetails?.order_details?.reduce((acc, curr) => {
                return (
                  acc +
                  parseFloat(
                    (parseFloat(curr?.actual_price)) *
                    curr?.qty
                  )
                );
              }, 0))?.toFixed(2) : "")}`
            }
            {
              orderDetails?.order_details?.length <= 0 && (Number(orderDetails?.total_sale_price)?.toFixed(2))
            }
          </h5>
        </div>
        <div className="totalDetails">
          <h5 className="textInnerHead2">Discount </h5>
          <h5 className="personalinfoHead">

            
            {orderDetails?.is_returned_order ? `$${orderDetails?.return_detail?.discount ? orderDetails?.return_detail?.discount : 0}` :
            
              `${orderDetails?.discount}`
            }
            
          </h5>
        </div>
        {orderDetails?.delivery_option == 1 ||
          orderDetails?.delivery_option == 4 ? (
          <>
            <div className="totalDetails">
              <h5 className="textInnerHead2">
                {" "}
                {orderDetails?.delivery_option == 4
                  ? "Shipping"
                  : "Delivery"}{" "}
                Charge
              </h5>
              <h5 className="personalinfoHead">
                $
                {orderDetails?.delivery_option == 4
                  ? Number(orderDetails?.shipping_charge)?.toFixed(
                    2
                  )
                  : Number(orderDetails?.delivery_charge)?.toFixed(
                    2
                  )}
              </h5>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="totalDetails">
          <h5 className="textInnerHead2"> Tips</h5>
          <h5 className="personalinfoHead">
            ${Number(orderDetails?.tips)?.toFixed(2)}
          </h5>
        </div>
        <div className="totalDetails">
          <h5 className="textInnerHead2">Tax</h5>
          <h5 className="personalinfoHead">
            ${Number(orderDetails?.tax)?.toFixed(2)}
          </h5>
        </div>
        {orderDetails?.transaction_fee && <div className="totalDetails">
          <h5 className="textInnerHead2">Transaction Fee</h5>
          <h5 className="personalinfoHead">
            ${Number(orderDetails?.transaction_fee)?.toFixed(2)}
          </h5>
        </div>}
        <hr className="dashedrow"></hr>
        <div className="totalDetails">
          <h5 className="itemsText ">Total</h5>
          <h5 className="itemsText">
            ${Number(orderDetails?.payable_amount)?.toFixed(2)}
          </h5>
        </div>
        <div className="reviewbotBtn_ mt-2">
              <button
                className="acceptBtn_ me-2"
                onClick={()=>{
                  setshowOrderInvoice(true);
                }}
              >
                
                <span>Track</span>
              </button>
            </div>
      </div>
    </div>
  </div>
</div> */}


{/* --------------------- */}
<div className="orderTrackLeft orderTrackRight d-flex flex-column">
                <div>
                  <h3 className="fontSize24 fw500 txtDarkblue"># {
                    orderDetails?.invoices?.id
                  }</h3>
                  <h6 className="fontSize12 fw500 activeProductofferItem">
                    On transit
                  </h6>

                  <div className="fedexContainer flexBox justify-content-between">
                    <div className="flexBox gap-2">
                      <img
                        src={orderDetails?.user_details?.profile_photo|| Images.avatar}
                        alt="logo"
                        className="avatarLogo"
                      />
                      <div>
                        <h3 className="fontSize16 txtDarkblue fw500 mb-1">
                        {
              orderDetails?.user_details?.firstname+" "+
              orderDetails?.user_details?.lastname
            }
                        </h3>
                        <p className="fontSize10 allproductCount">
                        {
                orderDetails?.user_details?.current_address?.street_address
              }
                        </p>
                      </div>
                    </div>
                    <div className="flexBox gap-2">
                      <img src={Images.fedex} alt="img" className="fed" />
                      <div>
                        <h3 className="orderTrackTableTxt mb-1">
                        {orderDetails?.delivery_details?.title}
                        </h3>
                        <h6 className="orderTrackLocation fontSize12 fw500 fedExSaver mb-0">
                          <img src={Images.fastLogo} alt="img" />3 Day Shipping
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="commonTable sellingProductTable table-responsive mt-2 mb-3">
                    <table className="w-100">
                      <tbody>
                        {
                          orderDetails?.order_details?.length > 0
                          ? orderDetails?.order_details?.map((val,i)=>(
<tr>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={val?.product_image||Images.productItemImg}
                                alt="img"
                                className="productItemImg"
                              />
                              <div>
                                <h3 className="topSellProductHead mb-0 pb-1">
                                {
                val?.product_name
               }
                                </h3>
                                <div className="d-flex align-items-center">
                                  <span className="dot"></span>
                                  <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                    Yellow / M
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                            $ {Number(val?.actual_price ||0)?.toFixed(2)}
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                          {val?.qty ||0}
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                            ${`${(Number(val?.actual_price||0) * Number(val?.qty|| 0))?.toFixed(2)}`}
                          </td>
                        </tr>
                          )):orderDetails?.return_detail?.return_details?.map((val,i)=>(
                            <tr>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <img
                                src={val?.order_details?.product_image||Images.productItemImg}
                                alt="img"
                                className="productItemImg"
                              />
                              <div>
                                <h3 className="topSellProductHead mb-0 pb-1">
                                {val?.order_details?.product_name}
                                </h3>
                                <div className="d-flex align-items-center">
                                  <span className="dot"></span>
                                  <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                    Yellow / M
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                            ${Number(val?.order_details?.price)?.toFixed(2)}
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                          {val?.order_details?.qty || 0}
                          </td>
                          <td className="fontSize12 fw400 mb-0 txtBlack text-end">
                            ${Number(
                  val?.order_details?.qty *
                  val?.order_details?.price
                )?.toFixed(2)}
                          </td>
                        </tr>
                          )) 
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-auto">
                  <div className="col-md-4 text-start pe-0">
                    <div className="orderinvoive">
                      <h5 className="lightgreyHeading_">Total Item</h5>
                      <h4 className="monshead22">{orderDetails?.total_items||0}</h4>
                      <div className="orderTrackBorderLine"></div>
                      <h5 className="lightgreyHeading_">Order Date</h5>
                      <h4 className="monshead22"> {moment(orderDetails?.created_at).format("MM/DD/YYYY")}</h4>
                      <div className="orderTrackBorderLine"></div>
                      <h5 className="lightgreyHeading_">Order Id #</h5>
                      <h4 className="monshead22">{orderDetails?.invoices?.order_id}</h4>
                      <h5 className="lightgreyHeading_">Payment <br /> Method</h5>
          <h4 className="monshead22">
            <div className="deliverTableBx">
              <img
                src={moneyImg}
                alt="location Image"
                className="img-fluid ms-1"
              />
              <span className="locateDistance">{
                orderDetails?.mode_of_payment
              }</span>
            </div>
          </h4>
                    </div>
                  </div>
                  <div className="col-md-8 text-start">
                    <div className="orderBill_">
                      <div className="Customertotal">
                        <div className="totalDetails">
                          <h5 className="lightgreyHeading_ ">Sub Total</h5>
                          <h5 className="personalinfoHead ">${`$${(orderDetails?.order_details?.length > 0 ? (orderDetails?.order_details?.reduce((acc, curr) => {
                return (
                  acc +
                  parseFloat(
                    (parseFloat(curr?.actual_price)) *
                    curr?.qty
                  )
                );
              }, 0))?.toFixed(2) : "")}`}</h5>
                        </div>
                        <div className="totalDetails">
                          <h5 className="textInnerHead2">Discount </h5>
                          <h5 className="personalinfoHead">-${Number(orderDetails?.discount||0).toFixed(2)}</h5>
                        </div>

                        <div className="totalDetails">
                          <h5 className="textInnerHead2">Other Fees</h5>
                          <h5 className="personalinfoHead">${(Number(orderDetails?.transaction_fee||0)+Number(orderDetails?.delivery_charge||0)+Number(orderDetails?.shipping_charge||0)).toFixed(2)}</h5>
                        </div>

                        <div className="totalDetails">
                          <h5 className="textInnerHead2"> Tax</h5>
                          <h5 className="personalinfoHead">${Number(orderDetails?.tax||0)?.toFixed(2)}</h5>
                        </div>

                        <hr className="dashedrow"></hr>
                        <div className="totalDetails">
                          <h5 className="itemsText ">Total</h5>
                          <h5 className="itemsText">$${Number(orderDetails?.payable_amount||0)?.toFixed(2)}</h5>
                        </div>

                        <div className="reviewbotBtn_ mt-2">
                          <button onClick={()=>{
                  setshowOrderInvoice(true);
                }} className="acceptBtn_ me-2" type="submit">
                            <span>Track</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</>
}

export default OrderTrackingDetails