import React, { forwardRef, useEffect, useState } from "react";
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";
import moment from "moment-timezone";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { getFormatedDate } from "../../../utilities/helpers";
import { getSellerAddressesApiAsync } from "./dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const HomePageInnerHeader = (props) => {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [filterValue, setFilterValue] = useState("week");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  
  const DatePickerCustomComponent = forwardRef(({ value, onClick }, ref) => (
    <div className="date-picker-container" onClick={onClick}>
      <img className="calendar-icon" src={Images.leftArrow} alt="Calendar" />
      <p className="date-text">
        {moment.utc(calendarDate).format("MMMM YYYY")}
      </p>
      <img
        className="arrow-icon"
        src={Images.newRightArrow}
        alt="Dropdown Arrow"
      />
    </div>
  ));
  const [sellerAddressesDetails, setsellerAddressesDetails] = useState(null);
  
  const selectOption = [
    { value: "all", label: "All" },
    { value: "pos", label: "Location 1" },
    { value: "b2c", label: "Location 2" },
    { value: "b2b", label: "Location 3" },
  ];
  const handleTimePeriodFilter = (value) => {
    setFilterValue(value);
    if (props.handleFilter) {
      props.handleFilter(value);
    }
  }
  let date_format = "YYYY-MM-DD";
  return (
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-md-2">
          {
            props.analyticSub ?
              (<div>
                <h6 className="headerHeading_ mb-0"><img src={Images.iconAnalytics_} alt=""/> {props?.analyticSub}</h6>
              </div>)
              :
              (<div>
                <h3 className="fontSize18 headerTxtColor mb-0 pb-1">Key Metrics</h3>
                <h6 className="homeHeader_Subheading mb-0">{props?.subhead}</h6>
              </div>)
          }

        </div>
        <div className="col-md-10 d-flex justify-content-end align-items-start">
          <div className="d-flex align-items-center gap-2">
            <div className="bookingLeftTwo">
              <ReactDatePicker
                onChange={(date) => {
                  const [start, end] = date;
                  setStartDate(start);
                  setEndDate(end);
                  if (props?.handleDate) {
                    if (start, end) props.handleDate({ startDate: getFormatedDate(start, date_format), endDate: getFormatedDate(end, date_format) });

                  }

                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                maxDate={new Date()}
                customInput={<DatePickerCustomComponent />}
              />
            </div>
            <div className="reactSelectParent">
              <ReactSelect
                options={sellerAddressesDetails?sellerAddressesDetails:selectOption}
                // defaultValue={channelSelected}
                // value={channelSelected}
                onChange={(event)=>{
                  if(props?.handleLocation){
                    props.handleLocation(event.value);
                  }
                }}
                placeholder="Location"
                classNamePrefix="react-select"
                className="react-select-container anlyticSelect"
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#263682" // Background color for selected option
                      : state.isFocused
                        ? "#f5f6fc" // Background color for hovered option
                        : "#fff", // Default background color
                    // color: state.isSelected || state.isFocused ? '#fff' : '#000', // Text color
                    color: state.isSelected
                      ? "#fff" // Background color for selected option
                      : "#000", // Text color
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#263682", // Customize placeholder color if needed
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }),
                }}
                // onChange={onChannelChange}
                components={{
                  DropdownIndicator: () => (
                    <img
                      src={Images.selectImg}
                      width={24}
                      height={24}
                      alt="drop_icon"
                      className="mt-1"
                    />
                  ),
                }}
              />
            </div>

            <ul className="weekNav">
              <li onClick={() => handleTimePeriodFilter("today")}>
                <Link
                  className={`weekMenu ${filterValue == "today" ? "active" : ""
                    }`}
                  to="#"
                >
                  Today
                </Link>
              </li>
              <li onClick={() => handleTimePeriodFilter("week")}>
                <Link
                  className={`weekMenu ${filterValue == "week" ? "active" : ""
                    }`}
                  to="#"
                >
                  Weekly
                </Link>
              </li>
              <li onClick={() => handleTimePeriodFilter("month")}>
                <Link
                  className={`weekMenu ${filterValue == "month" ? "active" : ""
                    }`}
                  to="#"
                >
                  Monthly
                </Link>
              </li>
              <li onClick={() => handleTimePeriodFilter("year")}>
                <Link
                  className={`weekMenu ${filterValue == "year" ? "active" : ""
                    }`}
                  to="#"
                >
                  Yearly
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageInnerHeader;
