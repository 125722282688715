import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { galleryImg, galleryUpload, infoImg, manage, user } from '../../../utilities/images'
// import ProductDetails from './serviceDetails'
import EditServiceHeader from './EditServiceHeader'
import ProductPrice from './productPrice'
import { unwrapResult } from '@reduxjs/toolkit'
import { getPosUsersAsync, selectLoginAuth } from '../auth/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { formValidator } from './formValidator'
import { createProductApiAsync, editProductSupplyApiAsync } from '../dashboard/dashboardSlice'
import CustomModal from '../../components/shared/CustomModal'
import AddStaffModal from './AddStaffModal'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const EditServiceSupply = (props) => {
    const history = useHistory();
    const location = useLocation();
    const selectedServiceDetails = location?.state;
    // const costPrices = selectedServiceDetails?.cost_price ? selectedServiceDetails?.cost_price : "";
    // console.log(selectedServiceDetails?.supplies[0], "selectedServiceDetails");
    const selectValuesMinMaxOptions = Array.from({ length: 20 }, (_, index) => index + 1);
    const selectValuesHoursOptions = Array.from({ length: 12 }, (_, index) => `${index} hr`);
    const selectValuesMinutesOptions = Array.from({ length: 60 }, (_, index) => `${index} min`);
    const [maximumValue, setMaximumValue] = useState(selectedServiceDetails?.max_capacity ? selectedServiceDetails?.max_capacity : "")
    const [minimumValue, setMinimumValue] = useState(selectedServiceDetails?.min_capacity ? selectedServiceDetails?.min_capacity : "")
    const [hoursValue, setHoursValue] = useState(`${Math.floor(selectedServiceDetails?.buffer_time_before / 60)} hr`)
    // Convert minutes to hours and minutes
    // const hours = Math.floor(apiResponse.buffer_time_after / 60);
    // const minutes = apiResponse.buffer_time_after % 60;
    const [minutesValue, setMinutesValue] = useState(`${selectedServiceDetails?.buffer_time_before % 60} min`)
    const [hoursValue1, setHoursValue1] = useState(`${Math.floor(selectedServiceDetails?.buffer_time_after / 60)} hr`)
    const [minutesValue1, setMinutesValue1] = useState(`${selectedServiceDetails?.buffer_time_after % 60} min`)
    const [approxTime, setApproxTime] = useState(selectedServiceDetails?.approx_service_time)
    const [posList, setPosList] = useState([])
    const [prices, setPrices] = useState("");
    const [activePos, setActivePos] = useState(selectedServiceDetails?.supply_prices?.find(item => item?.app_name === 'pos') ? true : false);
    const [activeOnlineStore, setActiveOnlineStore] = useState(selectedServiceDetails?.supply_prices?.find(item => item?.app_name === 'b2c') ? true : false || selectedServiceDetails?.supply_prices?.find(item => item?.app_name === 'b2c') ? true : false);
    const [activeBtoc, setActiveBtoc] = useState(selectedServiceDetails?.supply_prices?.find(item => item?.app_name === 'b2c') ? true : false);
    const [activeBtob, setActiveBtob] = useState(false);
    const auth = useSelector(selectLoginAuth);
    const toastId = React.useRef(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [successLoader, setSuccessLoader] = useState(false)
    const [deliveryOptions, setDeliveryOptions] = useState(["reservation"]);
    const [skipPosDetails, setSkipPosDetails] = useState(selectedServiceDetails?.skip_detail_screen_in_pos)
    const [syncGoogleCalendar, setSyncGoogleCalendar] = useState(selectedServiceDetails?.sync_google_calendar)
    const [costPrice, setcostPrice] = useState(selectedServiceDetails?.cost_price ? selectedServiceDetails?.cost_price : null)
    const [selectedPosId, setSelectedPosId] = useState([])
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    // Extracting numerical values from the strings
    const BufferTimeBeforeMinutes = parseInt(minutesValue.split(' ')[0]);
    const BufferTimeBeforeHours = parseInt(hoursValue.split(' ')[0]);
    const BufferTimeAfterMinutes = parseInt(minutesValue1.split(' ')[0]);
    const BufferTimeAfterHours = parseInt(hoursValue1.split(' ')[0]);

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    const getIdsHandle = (data) => {
        setSelectedPosId(data)
        handleOnCloseModal()
    }

    const handleSkipPOSDetails = (e) => {
        setSkipPosDetails(e.target.checked)
    }
    const handleSyncGoogleCalendar = (e) => {
        setSyncGoogleCalendar(e.target.checked)
    }
    const handleChangeJobrPos = () => {
        setActiveOnlineStore(!activeOnlineStore);
        if (activeBtoc) {
            setActiveBtoc(false);
        }
        if (activeBtob) {
            setActiveBtob(false);
        }
    }

    const handleprices = (e) => {
        if (e?.length > 0) {
            let filteredData = e?.filter(item => {
                if ((item.app_name === "b2b" && activeBtob) || (item.app_name === "pos" && activePos) || (item.app_name === "b2c" && activeBtoc)) {
                    return true;
                }
                return false;
            });

            setPrices(filteredData);
        }
    }


    console.log(selectedServiceDetails,"detailssssssssssssssssssssss");

    useEffect(() => {
        handleprices(prices)
    }, [activePos, activeBtob, activeBtoc])

    const handleSave = () => {

         if (!costPrice) {
            toast.error("Please enter cost price")
            return
        }
        if (!activePos) {
            toast.error("Please select Service availability")
            return
        }
        if (!approxTime) {
            toast.error("Please enter approx service time")
            return
        }
        if (!minimumValue) {
            toast.error("Please enter Minimum Capacity")
            return
        }
        if (!maximumValue) {
            toast.error("Please enter maximum Capacity")
            return
        }
        if (Number(minimumValue) > Number(maximumValue)) {
            toast.error("Minimum Capacity must be less Maximum capacity")
            return
        }
        // else if (!hoursValue) {
        //     toast.error("Please select buffer time before")
        //     return
        // }
        // else if (!hoursValue1) {
        //     toast.error("Please enter buffer time after")
        //     return
        // }

        let product_attribute = {
            "id": selectedServiceDetails?.product_id,
            "supplyId": selectedServiceDetails?.id,
            "approx_service_time": Number(approxTime),
            "delivery_options": deliveryOptions,
            "cost_price":costPrice,
            "prices": prices,
            "sync_google_calendar": syncGoogleCalendar,
            "skip_detail_screen_in_pos": skipPosDetails,
            "min_capacity": parseInt(minimumValue),
            "max_capacity": parseInt(maximumValue),
            "buffer_time_before": BufferTimeBeforeMinutes + BufferTimeBeforeHours * 60,
            "buffer_time_after": BufferTimeAfterMinutes + BufferTimeAfterHours * 60,
            "skip_detail_screen_in_pos": skipPosDetails,
        }
        setSuccessLoader(true)
        let params = {
            "postData": product_attribute,
            "tokenData": auth?.payload?.token
        }
        dispatch(editProductSupplyApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setSuccessLoader(false);
                // toast.success(obj?.msg);
                toast.success("Service updated!")
              history.push("/service-list")
            }
            )
            .catch((obj) => {
                setSuccessLoader(false)
            })
    }



    useEffect(() => {
        let params = {
            postData: {
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid,
                need_staff_member: true
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getPosUsersAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                const modifiedData = obj.payload.pos_staff.map((item) => ({
                    value: `${item.user.user_profiles.firstname} ${item.user.user_profiles.lastname}`,
                    id: item.id
                }));
                setPosList(modifiedData)
                setLoading(false)
            })
            .catch((obj) => {
                setLoading(false)
            })


        // Applying condition based on price_type
        let bundle_price = []
        const modifiedPrices = selectedServiceDetails?.supply_prices?.map((price) => {
            if (price.price_type === 'fixed') {
                const { id, min_qty, max_qty, ...modifiedFixedPrice } = price;
                return modifiedFixedPrice;
            }
            if (price.price_type === 'quantity_base') {
                const { min_qty, max_qty, selling_price, margin_percentage } = price;
                bundle_price.push({
                    min_qty,
                    max_qty,
                    selling_price,
                    margin_percentage
                })
            }
            return null
        });
        if (bundle_price?.length > 0) {
            modifiedPrices.push({ app_name: "b2b", price_type: "quantity_base", bundle_price: bundle_price })
        }
        const finalPrices = modifiedPrices?.filter((item) => item != null)
        setPrices(finalPrices);
   
    }, [])


    return (
        <div className='addProductManually addserviceManually'>
            {/* Start FixedTopHeader */}
            <EditServiceHeader save={(e) => handleSave(e)} successLoader={successLoader} />
            {/* End FixedTopHeader */}
            <div className="productRightWrapper">
                <div className='container-fluid pt-3'>
                    <div className='row justify-content-center'>
                        <div className='col-md-10 '>

                            <div className='mt-4'>
                                <div className='row'>
                                    <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                        <form className='costPrice'>
                                            <div className='form-group'>
                                                <label className="subheaderHeading mb-2">Cost Price
                                                    <img src={infoImg} alt='InfoImg' className='infoIcon ms-3' />
                                                </label>
                                                <div className='inputGroup'>
                                                    <input
                                                        min="1"
                                                        onKeyDown={blockInvalidChar}
                                                        className="customform-control"
                                                        placeholder="Cost Price"
                                                        name="text"
                                                        type="number"
                                                        value={costPrice}
                                                        onChange={(e) => setcostPrice(e.target.value)}
                                                    />
                                                    <span className='dollrsign_'>$</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* Start ProductDetails Area */}
                                    <div className='col-md-8'>
                                        <ProductPrice activePos={activePos} updatedPrice={prices} activeBtoc={activeBtoc} activeBtob={activeBtob} activeOnlineStore={activeOnlineStore} prices={(e) => handleprices(e)} costPrice ={costPrice}/>
                                    </div>

                                    {/* End ProductDetails Area */}
                                    <div className='col-md-4'>
                                        {/* Start ProductAvailability Area */}
                                        <div className='outerArea mb-3'>
                                            <h3 className='subheaderHeading mb-4'>Service availability</h3>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            checked={activePos}
                                                            onChange={() => setActivePos(!activePos)}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>JOBR POS</b> <i>(Physical store)</i></h3>
                                                </li>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input type="checkbox"
                                                            checked={activeOnlineStore}
                                                            onChange={() => handleChangeJobrPos()} />
                                                    </div>
                                                    <h3 className='labelTxt'><b>JOBR Online Store</b></h3>
                                                </li>
                                                {activeOnlineStore ?
                                                    <>
                                                        <li className='productToggleItems'>
                                                            <div className="form-check form-switch me-2 mb-0">
                                                                <input type="checkbox"
                                                                    checked={activeBtoc}
                                                                    value={false}
                                                                    onChange={() => setActiveBtoc(!activeBtoc)}
                                                                />
                                                            </div>
                                                            <h3 className='labelTxt'><b>JOBR B2C</b></h3>
                                                        </li>
                                                        {/* <li className='productToggleItems pl-2'>
                                                            <div className="form-check form-switch  me-2 mb-0 ">
                                                                <input type="checkbox"
                                                                    checked={activeBtob}
                                                                    value={false}
                                                                    onChange={() => setActiveBtob(!activeBtob)}
                                                                />
                                                            </div>
                                                            <h3 className='labelTxt'><b>JOBR B2B</b></h3>
                                                        </li> */}
                                                    </>
                                                    :
                                                    ""}
                                            </ul>
                                        </div>

                                        <div className='productDetailsContainer mt-3 ps-3 pe-3'>
                                            <form className='costPrice'>
                                                <div className='form-group'>
                                                    <label className="subheaderHeading mb-2">Approx Service Time
                                                    </label>
                                                    <div className='inputGroup'>
                                                        <input
                                                            min="1"
                                                            onKeyDown={blockInvalidChar}
                                                            className="customform-control"
                                                            placeholder="Service time in min"
                                                            name="serviceTime"
                                                            type="number"
                                                            value={approxTime}
                                                            onChange={(e) => setApproxTime(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                            <div className='row'>
                                                <label className="subheaderHeading mb-2 mt-4">Capacity
                                                </label>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label className="mb-2">Minimum
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <div className='inputGroup'>
                                                                <select className="cateSelectdFilter" placeholder='minimum' value={minimumValue} onChange={(e) => setMinimumValue(e.target.value)}>
                                                                    <option value="">Min</option>
                                                                    {selectValuesMinMaxOptions.map((number) => (
                                                                        <option key={number} value={number}>
                                                                            {number}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <label className="mb-2">Maximum
                                                        </label>
                                                        <div className='inputGroup'>
                                                            <select className="cateSelectdFilter" placeholder='maximum' value={maximumValue} onChange={(e) => setMaximumValue(e.target.value)} >
                                                                <option value="">Max</option>
                                                                {selectValuesMinMaxOptions.map((number) => (
                                                                    <option key={number} value={number}>
                                                                        {number}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <label className="subheaderHeading mb-2 mt-4">Buffer Time Before
                                                </label>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <div className='inputGroup'>
                                                            <div className='inputGroup'>
                                                                <select className="cateSelectdFilter" placeholder='hours' value={hoursValue} onChange={(e) => setHoursValue(e.target.value)}>
                                                                    {selectValuesHoursOptions.map((number) => (
                                                                        <option key={number} value={number}>
                                                                            {number}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <div className='inputGroup'>
                                                            <select className="cateSelectdFilter" placeholder='minutes' value={minutesValue} onChange={(e) => setMinutesValue(e.target.value)}>
                                                                {selectValuesMinutesOptions.map((number) => (
                                                                    <option key={number} value={number}>
                                                                        {number}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <label className="subheaderHeading mb-2 mt-4">Buffer Time After
                                                </label>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <div className='inputGroup'>
                                                            <div className='inputGroup'>
                                                                <select className="cateSelectdFilter" placeholder='hours' value={hoursValue1} onChange={(e) => setHoursValue1(e.target.value)}>
                                                                    {selectValuesHoursOptions.map((number) => (
                                                                        <option key={number} value={number}>
                                                                            {number}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='form-group'>
                                                        <div className='inputGroup'>
                                                            <select className="cateSelectdFilter" placeholder='minutes' value={minutesValue1} onChange={(e) => setMinutesValue1(e.target.value)}>
                                                                {selectValuesMinutesOptions.map((number) => (
                                                                    <option key={number} value={number}>
                                                                        {number}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
{/* 
                                        <div className='outerArea mt-3'>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="skipPOS"
                                                            checked={syncGoogleCalendar}
                                                            value={syncGoogleCalendar}
                                                            onChange={(e) => { handleSyncGoogleCalendar(e) }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Sync Google Calendar </b></h3>
                                                </li>
                                            </ul>
                                            <p className='descriptionPara_ mt-4'>
                                                Enable this option if you want to include your event in Google Calendar.
                                            </p>
                                        </div>

                                        <div className='outerArea mt-3'>
                                            <ul className='productToggleMenus'>
                                                <li className='productToggleItems'>
                                                    <div className="form-check form-switch me-2 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            id="skipPOS"
                                                            checked={skipPosDetails}
                                                            value={skipPosDetails}
                                                            onChange={(e) => { handleSkipPOSDetails(e) }}
                                                        />
                                                    </div>
                                                    <h3 className='labelTxt'><b>Skip Details Screen in POS</b></h3>
                                                </li>
                                            </ul>
                                            <p className='descriptionPara_ mt-4'>
                                                Enable this option if the product needs to be physically delivered to customers either via shipping or by self-pickup. If this product is a service or a downloadable item that doesn’t require delivery, keep this option disabled.
                                            </p>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                className={modalDetail.flag === "ActivePos" ? "customContent" : ""}
                ids={modalDetail.flag === "ActivePos" ? "activePos" : modalDetail.flag === "addStaff" ? "addStaff" : ""}
                size={
                    modalDetail.flag === "addStaff" ? "lg" : "md"
                }
                child={
                    modalDetail.flag === 'addStaff' ? (<AddStaffModal posIds={selectedPosId} close={() => handleOnCloseModal()} getIdsHandle={(e) => getIdsHandle(e)} />
                    ) :
                        <></>
                }
                header={
                    <div className='modalHeader_'>
                        <div className="common_">
                            <h2 className="modalHeading">
                                Staffs
                            </h2>
                            <button className="closeButton">
                                <i className="las la-times" onClick={() => handleOnCloseModal()}></i>
                            </button>
                        </div>
                    </div>
                }
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

export default EditServiceSupply