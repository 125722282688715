import React, { useEffect } from 'react'
import { success } from '../../../../utilities/images';

const SuccessFullyCreated = ({ handleClose, tittle }) => {
    useEffect(() => {
        setTimeout(() => {
            handleClose();
        }, 3000)
    }, []);

    return (
        <div className='' style={{
            display: "felx",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <img src={success} alt='success' />
            <div className='content_text'>
                <h1>{tittle}</h1>
            </div>
        </div>
    )
}

export default SuccessFullyCreated
