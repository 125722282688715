import React from 'react'
import * as Images from "../../../utilities/images";


const SuccessfullyAddProductModal = () => {
  return (
    <div>
        <img src={Images.successfullyLogo} alt='image'/>
      <h3 className='successfullyProductTxt mb-0'>Successfully added 32 products</h3>
    </div>
  )
}

export default SuccessfullyAddProductModal;
