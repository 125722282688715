import React, { useState } from 'react'
import {
    OrderDown,
    cancleorder,
    incoiceuser_,
    modalCancel,
    pickuporder,
    profileImg1,
    returnlogo,
    serviceuser_,
    unselectedOrder,
    drivefast_,
    upArrow,
    locateOrder,
    clockBlue,
    moneyImg,
    mapimg,
    deliveryMap,
    warningicon,
    fillradio_,
    verifyorder,
    orderDriver,
    done,
    cancelledstep,
    readystep,
    acceptstep,
    openTag,
    closeTag,
    jobrMainLogo,
    orderStartImg,
    productPickupImg,
    orderCancleImg,
    orderAcceptedImg,
    readyToPickupImg,
    orderAcceptDot,
    orderTrackingLine,
  } from "../../../../utilities/images";
import moment from 'moment-timezone';
const OrderTrackStatusCard = ({orderData}) => {
  const [hideOrderStatus, setHideOrderStatus] = useState(true);

    const handleOrderStatusView = () => {
        setHideOrderStatus((prev) => !prev);
      };
  return <>
<div className="deliveryStatus">
                        <div className="statusHead stausDown">
                          <div className="statusLeft orderFlex">
                            <img
                              alt="orderDriver Image"
                              loading="lazy"
                              width="24"
                              height="25"
                              decoding="async"
                              data-nimg="1"
                              class="img-fluid"
                              src={orderDriver}
                              style={{ color: "transparent" }}
                            />
                            <h4 class="customerLink ">Order Status</h4>
                          </div>
                          <figure>
                            <img
                              onClick={handleOrderStatusView}
                              src={hideOrderStatus ? openTag : closeTag}
                              alt="downSelect"
                            />
                          </figure>
                        </div>
                        {hideOrderStatus && (
                          <div className="orderStatus ">
                            {/* <div className="readyPickupstatus disableTrack">
                                                <div className='pickupBtnDetails'>
                                                    <figure>
                                                        <img src={fillradio_} alt="pickup" />
                                                    </figure>
                                                    <article>
                                                        <h3>Return CODE</h3>
                                                    </article>
                                                </div>
                                                <div className='pickupBtn'>
                                                    <button className='countbtn'>659X</button>
                                                </div>
                                            </div> */}

                            {orderData?.order_delivery &&
                              orderData?.delivery_option == "1" &&
                              orderData?.order_delivery?.cancel_order_otp &&
                              orderData?.order_delivery?.has_returned && (
                                <div className="readyPickupstatus pickupBtnDetails">
                                  <div className="readyPickupstatus">
                                    <figure>
                                      <img
                                        src={
                                          orderData?.order_delivery
                                            ?.has_returned
                                            ? pickuporder
                                            : unselectedOrder
                                        }
                                        alt="pickup"
                                      />
                                    </figure>
                                    <article>
                                      <h3>Returned</h3>
                                      {orderData?.order_delivery?.updated_at ? (
                                        <h6>
                                          {moment(
                                            orderData?.order_delivery
                                              ?.updated_at
                                          ).format("DD MMM YYYY | HH:mm A")}
                                        </h6>
                                      ) : (
                                        ""
                                      )}
                                    </article>
                                  </div>
                                  {orderData?.order_delivery?.customer_otp ? (
                                    <div className="pickupBtn">
                                      <button className="countbtn">
                                        {
                                          orderData?.order_delivery
                                            ?.customer_otp
                                        }
                                      </button>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              )}

                            {/* for delivery or pickup */}
                            {(orderData?.status <= 5 &&
                              orderData?.delivery_option == 1) ||
                            (orderData?.status <= 5 &&
                              orderData?.delivery_option == 3) ? (
                              <div className="readyPickupstatus">
                                <div className="positionImg">
                                <img
                                    src={
                                      orderData?.status == 5
                                        ? pickuporder
                                        : fillradio_
                                    }
                                    style={{width:"21px"}}
                                    alt="pickup"
                                  />
                                  <img
                                    src={orderTrackingLine}
                                    alt="img"
                                    className="lineStepImg"
                                  />
                                </div>
                                
                                <article>
                                  <h3>Completed</h3>
                                  {orderData?.status_desc
                                    ?.status_5_updated_at ? (
                                    <h6>
                                      {moment(
                                        orderData?.status_desc
                                          ?.status_5_updated_at
                                      ).format("DD MMM, YYYY | hh:mm a")}
                                    </h6>
                                  ) : (
                                    <></>
                                  )}
                                </article>
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* for shipping */}
                            {orderData?.status <= 4 &&
                            orderData?.delivery_option == 4 ? (
                              <div className="readyPickupstatus">
                                <figure>
                                  <img
                                    src={
                                      orderData?.status == 4
                                        ? pickuporder
                                        : unselectedOrder
                                    }
                                    alt="pickup"
                                  />
                                </figure>
                                <article>
                                  <h3>Shipped</h3>
                                  {orderData?.status_desc
                                    ?.status_4_updated_at ? (
                                    <h6>
                                      {moment(
                                        orderData?.status_desc
                                          ?.status_4_updated_at
                                      ).format("DD MMM, YYYY | hh:mm a")}
                                    </h6>
                                  ) : (
                                    <></>
                                  )}
                                </article>
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* status rejected  */}
                            {orderData?.status === 8 ? (
                              <div className="readyPickupstatus">
                                <figure>
                                  <img
                                    src={
                                      orderData?.status == 8
                                        ? cancleorder
                                        : unselectedOrder
                                    }
                                    alt="pickup"
                                  />
                                </figure>
                                <article>
                                  <h3>Rejected</h3>
                                  <h6>
                                    {moment(
                                      orderData?.status_desc
                                        ?.status_8_updated_at
                                    ).format("DD MMM, YYYY | hh:mm a")}
                                  </h6>
                                </article>
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* rejected or cancelled */}
                            {orderData?.status === 7 ||
                            orderData?.status === 9 ? (
                              <>
                                <div className="readyPickupstatus">
                                  <div className="positionImg">
                                    <img
                                      src={
                                        orderData?.status === 9
                                          ? productPickupImg
                                          : orderStartImg
                                      }
                                      alt="pickup"
                                    />
                                    <img
                                      src={orderTrackingLine}
                                      alt="img"
                                      className="lineStepImg"
                                    />
                                  </div>
                                  <div>
                                    <h3>Return to Shop</h3>
                                    <div className="reviewuserinfo_">
                                      <img
                                        style={{ width: "50px" }}
                                        className="invoveuser me-3"
                                        src={
                                          orderData?.seller_details
                                            ?.banner_image
                                            ? orderData?.seller_details
                                                ?.banner_image
                                            : returnlogo
                                        }
                                      />
                                      <article className="ivoiceuserDetail_">
                                        <h4 className="monshead14 mb-0 pb-1">
                                          {
                                            orderData?.seller_details
                                              ?.organization_name
                                          }
                                        </h4>
                                        <p className="monshead12">
                                          {
                                            orderData?.seller_details
                                              ?.current_address?.street_address
                                          }
                                        </p>
                                      </article>
                                    </div>
                                    {/* <div className="returnShop_">
                                      <img
                                        className="returnlogo_"
                                        src={returnlogo}
                                      />
                                    </div> */}
                                  </div>
                                </div>
                                <div className="readyPickupstatus">
                                  <div className="positionImg">
                                    <img
                                      src={
                                        orderData?.status === 9
                                          ? orderCancleImg
                                          : orderStartImg
                                      }
                                      alt="pickup"
                                    />
                                    <img
                                      src={orderTrackingLine}
                                      alt="img"
                                      className="lineStepImg"
                                    />
                                  </div>
                                  <div>
                                    <h3>Canceled</h3>
                                    <h6>
                                      {moment(
                                        orderData?.status_desc
                                          ?.status_8_updated_at
                                      ).format("DD MMM, YYYY | hh:mm a")}
                                    </h6>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {/* product pickup */}
                            <div className="readyPickupstatus">
                              <div className="positionImg">
                                <img
                                  src={
                                    orderData?.status >= 4 &&
                                    orderData?.status != 8
                                      ? productPickupImg
                                      : orderStartImg
                                  }
                                  alt="verifyorder"
                                />
                                <img
                                  src={orderTrackingLine}
                                  alt="img"
                                  className="lineStepImg"
                                />
                              </div>
                              <div>
                                <h3>Product Pickup</h3>
                                {orderData?.status >= 4 &&
                                  orderData?.status != 8 && (
                                    <h6>
                                      {moment(
                                        orderData?.status_desc
                                          ?.status_4_updated_at
                                      ).format("DD MMM, YYYY | hh:mm a")}
                                    </h6>
                                  )}
                              </div>
                              {orderData?.order_delivery?.seller_otp ? (
                                <div className="pickupBtn">
                                  <button className="countbtn">
                                    {orderData?.order_delivery?.seller_otp}
                                  </button>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            {/* Assign driver */}
                            {orderData?.delivery_option == 3 ? (
                              <div className="readyPickupstatus">
                                <figure>
                                  <img
                                    src={
                                      orderData?.driver_details
                                        ? productPickupImg
                                        : orderStartImg
                                    }
                                    alt="pickup"
                                  />
                                </figure>
                                <article>
                                  <h3>Assign Driver</h3>
                                  <h6>
                                    {moment(
                                      orderData?.status_desc
                                        ?.status_1_updated_at
                                    ).format("DD MMM, YYYY | hh:mm a")}
                                  </h6>
                                </article>
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* ready to pickup */}
                            {orderData?.status != 8 ? (
                              <div className="readyPickupstatus">
                                <div className="positionImg">
                                  <img
                                    src={
                                      orderData?.status >= 3
                                        ? readyToPickupImg
                                        : orderStartImg
                                    }
                                    alt="pickup"
                                  />
                                  <img
                                    src={orderTrackingLine}
                                    alt="img"
                                    className="lineStepImg"
                                  />
                                </div>
                                <div>
                                  <h3>Ready to pickup</h3>
                                  {orderData?.status_desc
                                    ?.status_3_updated_at ? (
                                    <h6>
                                      {moment(
                                        orderData?.status_desc
                                          ?.status_3_updated_at
                                      ).format("DD MMM, YYYY | hh:mm a")}
                                    </h6>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {/* order accepted */}
                            {orderData?.status != 8 ? (
                              <div className="readyPickupstatus">
                                <div>
                                  <img
                                    src={
                                      orderData?.status >= 1
                                        ? orderAcceptedImg
                                        : orderStartImg
                                    }
                                    alt="pickup"
                                  />
                                </div>
                                <div>
                                  <h3>Order accepted</h3>
                                  {orderData?.status_desc
                                    ?.status_1_updated_at ? (
                                    <h6>
                                      {moment(
                                        orderData?.status_desc
                                          ?.status_1_updated_at
                                      ).format("DD MMM, YYYY | hh:mm a")}
                                    </h6>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            {/* <div className="carrierbox">
                          <h5>Carrier:</h5>
                          <div className="shippingContact carriercontact">
                            <div className="shipcontactLeft">
                              <img className='me-2' src={singleOrderData?.driverDetails?.profile_photo ? singleOrderData?.driverDetails?.profile_photo : profileImg1} />
                              <div className="contctInfo">
                                <h4>{singleOrderData?.driverDetails?.user_profiles?.firstname + " " + singleOrderData?.driverDetails?.user_profiles?.lastname}</h4>
                              </div>
                            </div>
                            <button className="contactBtn">
                              <i className="las la-phone"></i>
                              <h3>Contact<br />{singleOrderData?.driverDetails?.user_profiles?.full_phone_number}</h3>
                            </button>
                          </div>
                        </div> : */}
                          </div>
                        )}
                      </div>
  </>
}

export default OrderTrackStatusCard