import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectLoginAuth } from '../../auth/authSlice';
import { getCustomNotificationApiAsync, getUserSettingsApiAsync, updateCustomNotificationApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Loader from '../../../components/UI/Loader/Loader';

const CustomerNotification = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(selectLoginAuth);
    const options={
        new_order:false,
        returned_order:true,
        cancelled_order:true
    }
    const [customNotification, setcustomNotification] = useState(null);
    const [notification, setNotification] = useState([
        {title:"In-app notifications",key:"app_notifications",
        options:{...options}},
        {
            title:"SMS Notification",options:{...options},key:"sms_notifications"
        },
        {
            title:"Email Notification",options:{...options},key:"email_notifications"
        },
    ]);
    const resultSelectOption = [
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "150", label: "150" },
        { value: "200", label: "200" },
    ];
    const [loading, setLoading] = useState(false)
    // const handleDiscountOption = (options) => {
    //     setDiscountOption(options);
    // };
    
    const handleCustomNotificaiton=(parentKey,key,value,index)=>{
        let newCustomNotification=[...notification];
        newCustomNotification[index].options[key]=value;
        setNotification(newCustomNotification);
        setcustomNotification(prev=>({
            ...prev,
            [parentKey]:{
               ...customNotification[parentKey],
                [key]:value
            }
        }))
        console.log({[parentKey]:{
            ...customNotification[parentKey],
             [key]:value
         }},"check")
        updateOrderProcessingData({[parentKey]:{
            [key]:value
        }});
    }
    const getCustomNotifications=async()=>{
        let titles=["SMs Notifications"]
        let prams={
            postData: {
                app_name:"merchant",
                seller_id:"0abd886e-d6c4-4fe0-8be2-c928f41530ca",
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(getUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setcustomNotification(res?.payload);
        setLoading(false);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    useEffect(()=>{
        getCustomNotifications();
    },[])
    const updateOrderProcessingData=async(data)=>{
        let prams={
            postData: {
                ...data
            },
            tokenData: auth?.payload?.token,
        }
    setLoading(true);
    dispatch(updateUserSettingsApiAsync(prams))
    .then(unwrapResult)
    .then((res)=>{
        setLoading(false);
        // setOrderProcessingData(res.data);
    })
    .catch((res)=>{
        setLoading(false);
    })
    }
    
    return (
        <>
          {loading&&
                <Loader/>
               }
        <div className='customerNotification_'>
            {
                notification?.map((notify,index)=>(
<div className={`allNotofication_ ${index>=1?'mt-5':''} `} key={index}>
                <h3 className='headblue18'>{notify.title}</h3>
                <div className='notification_'>
                    {
                        Object.entries(notify?.options).map((option,indexs)=>(
                            <div className='toggleNotification_ mb-2'>
                                <h5 className='headingblue14_ text-capitalize'>{`${option[0].split("_")[0]} ${option[0].split("_")[1]}`}</h5>
                                <input type="checkbox" checked={customNotification?.[notify?.key]?.[option[0]]}  onChange={(event)=>{
handleCustomNotificaiton(notify.key,option[0],event.target.checked,index)
                                }}/>
                            </div>
                        ))
                    }
                </div>
            </div>
                ))
            }
            
             <div className='allNotofication_ mt-5'>
                <h3 className='headblue18'>SMS Notification</h3>
                <div className='notification_'>
                    <div className='toggleNotification_ mb-2'>
                        <h5 className='headingblue14_'>New Order</h5>
                        <input type="checkbox" id="toggle" name="toggle" checked='true'/>
                    </div>
                    <div className='toggleNotification_ mb-2'>
                        <h5 className='headingblue14_'>Order Return </h5>
                        <input type="checkbox" id="toggle" name="toggle" checked='true'/>
                    </div>
                    <div className='toggleNotification_'>
                        <h5 className='headingblue14_'>Cancelled Order</h5>
                        <input type="checkbox" id="toggle" name="toggle" />
                    </div>
                </div>
            </div>
            <div className='allNotofication_ mt-5'>
                <h3 className='headblue18'>E-mail Notification</h3>
                <div className='notification_'>
                    <div className='toggleNotification_ mb-2'>
                        <h5 className='headingblue14_'>New Order</h5>
                        <input type="checkbox" id="toggle" name="toggle" checked='true'/>
                    </div>
                    <div className='toggleNotification_ mb-2'>
                        <h5 className='headingblue14_'>Order Return </h5>
                        <input type="checkbox" id="toggle" name="toggle" checked='true'/>
                    </div>
                    <div className='toggleNotification_'>
                        <h5 className='headingblue14_'>Cancelled Order</h5>
                        <input type="checkbox" id="toggle" name="toggle" />
                    </div>
                </div>
            </div> 
        </div>
        </>

    )
}

export default CustomerNotification