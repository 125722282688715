import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { exchangeTokenApiAsync, loginAsync } from "./authSlice";
import * as Images from "../../../utilities/images";
import PhoneInput from "react-phone-input-2";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
import CustomModal from "../../components/shared/CustomModal";

const Login = () => {
  console.log("login-page-donee");
  const history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneCode, SetPhoneCode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const handleSendOtp = (e) => {
    e.preventDefault();
    if (!phoneNo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter phone number");
      }
      return;
    } else if (phoneNo && phoneNo.length <= 9) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Phone number length should be of 10 digits"
        );
      }

      return;
    } else if (phoneNo && !phoneCode) {
      toastId.current = toast.error("Please select your phone code");
      return;
    }
    if (!password) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your password");
      }
      return false;
    }
    let data = {
      phone_code: `+${phoneCode}`,
      phone_number: phoneNo,
      password: password,
      need_merchant_step_status: true,
    };
    setLoading(true);
    dispatch(loginAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        if (obj?.status_code == 206) {
          history.push("/chooseProtection");
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(obj?.msg);
          }
        } else if (obj?.payload?.user?.user_profiles?.is_two_fa_enabled) {
          // history.push('/authenticate')
          setModalDetail({ show: true, flag: "TwoFactor" });
          setKey(Math.random());
        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg);
          }
          obj?.payload?.merchant_step_statuses?.wallet_status === true &&
          obj?.payload?.merchant_step_statuses?.subscription_status === true &&
          obj?.payload?.merchant_step_statuses?.address_status === true &&
          obj?.payload?.merchant_step_statuses?.product_status === true
            ? history.push("/wallet")
            : history.push("/plans");
        }
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const onChangePhoneNumber = (value, data) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  };
  // const togglePasswordVisiblity = () => {
  //   setPasswordShown(passwordShown ? false : true);
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Merchant Admin";
  }, []);
  return (
    <>
      <div className="commonLogin">
        <div className=" loginForm">
          <form autoComplete="nope" onSubmit={(e) => handleSendOtp(e)}>
            <div className="authFormHead">
              <h2 className="logintxt mb-0"> Login </h2>
              <h5 className="welcomeBackTxt mb-0">Welcome back</h5>
            </div>
            <div className="phone-numbpart">
              <div className="country-plugin">
                <label className="form-label ps-4">Phone number</label>
                <div id="result">
                  <PhoneInput
                    country="us"
                    // value={phoneCode + phoneNo}
                    enableSearch={true}
                    name={generateRandomName}
                    placeholder="Phone no."
                    autoComplete="off"
                    onChange={(value, data, event, formattedValue) =>
                      onChangePhoneNumber(value, data, event, formattedValue)
                    }
                  />
                </div>
              </div>
            </div>
         
            <div className="phone-numbpart">
              <label className="form-label ps-4">Password</label>
              <input
                className="customform-control id_password "
                placeholder="Password"
                name={generateRandomName}
                type={passwordShown ? "text" : "password"}
                value={password}
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <img src={Images.passIcon} alt="passIcon" className="passIcon"/>
              <span
                className="toggle_password_ info_icon"
                onClick={() => {
                  setPasswordShown((prev) => !prev);
                }}
              >
                <span
                  className={
                    passwordShown
                      ? "show-icon togglePassword"
                      : "hide-icon togglePassword"
                  }
                ></span>
              </span>
            </div>

            {/* <div className="form-group mt-2">
                          <input type={passwordShown ? "text" : "password"} placeholder="Password" name="password" value={loginData.password} onChange={(e)=>handleChange(e)}  className="form-control input_box" />
                         
                        </div> */}

            <p
              className="forgetPass mb-0 cursorPointer"
              onClick={() => {
                history.push("/forgetPassword");
              }}
            >
              Forgot Password?
            </p>

            <div className="">
              <button
                className="loginBtnSign w-100 active"
                type="submit"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
            
               Login <img src={Images.upArrow} alt="logo" className="ms-2"/>
              </button>
              <p className="gobacklink">
                Register Your Business Here
                <Link
                  to="#"
                  onClick={() => {
                    history.push("/verify-Phone");
                  }}
                >
                  Register
                  
                </Link>
              </p>
            </div>
          </form>
        </div>
        <div className="copyRight">
          <p>All Rights Reserved by JOBR LLC | 2022</p>
        </div>
        {/* <div className="col-lg-6 p-0 displayImg">
              <div className="loginhorrizontal-form ">
                <div className="login_left">
                  <img
                    src={loginBanner}
                    className="d-none d-md-block img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div> */}
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        className={modalDetail.flag === "ActivePos" ? "customContent" : ""}
        ids={modalDetail.flag === "ActivePos" ? "activePos" : ""}
        size={modalDetail.flag === "TwoFactor" ? "lg" : "md"}
        child={
          modalDetail.flag === "TwoFactor" ? (
            <TwoFactorAuthentication close={() => handleOnCloseModal()} />
          ) : (
            <></>
          )
        }
        header={
          <div className="modalHeader_">
            <div className="common_">
              <h2 className="modalHeading">Verify Two Factor Authentication</h2>
              <button className="closeButton">
                <i
                  className="las la-times"
                  onClick={() => handleOnCloseModal()}
                ></i>
              </button>
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Login;
