import React, { useEffect, useRef, useState } from "react";
import * as Images from "../../../utilities/images";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import CustomModal from "../../components/shared/CustomModal";
import ColorPickerModal from "./colorPickerModal";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { toast } from "react-toastify";

const defaultRow = {
  id: 1,
  activePlan: "list",
  displayName: "",
  productTags: [],
};

const AddOptionsModal = ({ onRowsChange, rowData, optionModalDetail }) => {
  const toastId = useRef(null);
  const [rows, setRows] = useState(rowData || [defaultRow]);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    rowIndex: "",
  });
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      rowIndex: "",
    });
    setKey(Math.random());
  };

  const handleOpenModal = (flag, index) => {
    setModalDetail({
      show: true,
      title: "",
      flag: flag,
      rowIndex: index, // Keep track of the row being edited
    });
    setKey(Math.random());
  };

  const handleTogglePlan = (index, plan) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const updatedRow = { ...updatedRows[index] };

      updatedRow.activePlan = plan;

      // Reset fields when switching plans
      if (plan === "list") {
        updatedRow.displayName = "";
        updatedRow.productTags = [];
      } else if (plan === "color") {
        updatedRow.displayName = "color"; // Set default for color plan
      }

      updatedRows[index] = updatedRow;
      return updatedRows;
    });
  };

  const handleDisplayNameChange = (index, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const updatedRow = { ...updatedRows[index] };
      const normalizedValue = value.trim().toLowerCase();

      if (normalizedValue === "color" || normalizedValue === "colour") {
        updatedRow.activePlan = "color";
        updatedRow.displayName = "color";
      } else {
        updatedRow.displayName =
          updatedRow.activePlan === "color" ? "color" : value.trim();
      }

      updatedRows[index] = updatedRow;
      return updatedRows;
    });
  };

  const handleProducttags = (index, tags) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const updatedRow = { ...updatedRows[index] };

      updatedRow.productTags = [...tags]; // Update productTags to a new array
      updatedRows[index] = updatedRow;
      return updatedRows;
    });
  };

  const handleColorChange = (index, color) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const updatedRow = { ...updatedRows[index] };

      // Avoid duplicate colors in the productTags
      if (!updatedRow.productTags.includes(color)) {
        updatedRow.productTags = [...updatedRow.productTags, color];
      }

      updatedRows[index] = updatedRow;
      return updatedRows;
    });
  };

  const handleAddOptionSet = () => {
    let isValid = true;

    // Check if max rows is exceeded
    if (rows.length > 4) {
      showToast("You can only add up to 4 options.");
      isValid = false;
      return false;
    }

    // Validate displayName is not empty
    if (
      rows.some((row) => {
        if (!row.displayName.trim()) {
          showToast("Display name is required");
          return true;
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    // Check for duplicate displayNames
    if (
      rows.some((row, idx) => {
        const isDuplicate = rows.some(
          (existingRow, i) =>
            existingRow.displayName === row.displayName && i !== idx
        );
        if (isDuplicate) {
          showToast("Display name must be unique");
          return true;
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    // Validate that productTags are not empty
    if (
      rows.some((row) => {
        if (row.productTags.length === 0) {
          showToast("Please add at least one option.");
          return true;
        }
        return false;
      })
    ) {
      isValid = false;
      return false;
    }

    if (!isValid) return;

    const newRow = {
      id: rows.length + 1,
      activePlan: "list",
      displayName: "",
      productTags: [],
    };
    setRows([...rows, newRow]);
  };

  const handleDeleteColor = (index, colorToDelete) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const updatedRow = { ...updatedRows[index] };

      updatedRow.productTags = updatedRow.productTags.filter(
        (color) => color !== colorToDelete
      );
      updatedRows[index] = updatedRow;
      return updatedRows;
    });
  };

  const handleDeleteRow = (indexToDelete) => {
    setRows(rows.filter((_, index) => index !== indexToDelete));
  };

  useEffect(() => {
    if (onRowsChange) {
      onRowsChange(rows); // Pass updated rows to parent component
    }
  }, [rows]);

  return (
    <>
      <p className="fontSize16 txtDarkblue">
        Add a custom set of options to an item to create variations. For
        example, a size option set can create variations small, medium, and
        large.
      </p>
      {optionModalDetail?.flag === "EditOption" ? (
        <div className="">
          <div className="modalOptionBox" key={optionModalDetail?.data.id}>
            <div className="row">
              <div className="col-md-9">
                <div className="form_group pt-0">
                  <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
                    Display name
                    {/* <img src={Images.labelImg} alt="logo" className="ms-2" /> */}
                  </label>
                  <div className="inputGroup">
                    <input
                      className="customInput txtDarkblue textCapital"
                      placeholder="name"
                      name="text"
                      type="text"
                      value={
                        rows[optionModalDetail?.rowIndex]?.displayName || ""
                      }
                      onChange={(e) =>
                        handleDisplayNameChange(
                          optionModalDetail?.rowIndex,
                          e.target.value
                        )
                      }
                      readOnly={
                        rows[optionModalDetail?.rowIndex]?.activePlan ===
                        "color"
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3 pe-0">
                <div className="pricingPara">
                  <label className="fontSize14 txtDarkblue mb-2 fw500">
                    Show in product page as
                  </label>
                  <ul className="planTabs_ m-0">
                    <li
                      className={`planttabslist ${
                        optionModalDetail?.data.activePlan === "list"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Images.listLogo}
                        alt="logo"
                        className="optionModal_ToggleLogo"
                      />
                      List
                    </li>
                    <li
                      className={`planttabslist ${
                        optionModalDetail?.data.activePlan === "color"
                          ? "active"
                          : ""
                      }`}
                    >
                      <img
                        src={Images.colorLogo}
                        alt="logo"
                        className="optionModal_ToggleLogo"
                      />
                      Color
                    </li>
                  </ul>
                </div>
              </div>

              {optionModalDetail?.data.activePlan === "list" && (
                <div className="col-md-12">
                  <div className="form_group">
                    <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
                      Options
                      {/* <img src={Images.labelImg} alt="logo" className="ms-2" /> */}
                    </label>
                    <div className="inputGroup autocompleteParent">
                      <Autocomplete
                        multiple
                        freeSolo
                        getOptionLabel={(option) => option}
                        id="tags-filled"
                        value={
                          rows[optionModalDetail?.rowIndex]?.productTags || []
                        }
                        options={[]}
                        onChange={(e, newValue) =>
                          handleProducttags(
                            optionModalDetail?.rowIndex,
                            newValue
                          )
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                              <Chip
                                key={key}
                                variant="outlined"
                                label={option}
                                size="small"
                                deleteIcon={
                                  <img
                                    src={Images.lightBlueCross}
                                    alt="delete"
                                    style={{
                                      width: 14,
                                      height: 14,
                                      marginLeft: "3px",
                                    }}
                                  />
                                }
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder="Type options"
                            className="custom-filled-input"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <p className="pressEnterTxt pt-2 ps-4">
                    Press <span className="fw700"> Enter </span> after each
                    choice.
                  </p>
                </div>
              )}
              {optionModalDetail?.data.activePlan === "color" && (
                <div className="col-md-12">
                  <div className="form_group">
                    <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
                      Color options
                    </label>
                    <div className="inputGroup">
                      <div
                        className="customInput txtDarkblue colorPickerParent roleFlex flex-wrap textCapital"
                        onClick={() =>
                          handleOpenModal(
                            "colorPickerModal",
                            optionModalDetail?.rowIndex
                          )
                        }
                      >
                        {rows[optionModalDetail?.rowIndex]?.productTags.map(
                          (color, colorIndex) => (
                            <div
                              className="showcolorBox"
                              key={colorIndex}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="d-flex align-items-center">
                                <div
                                  className="selectedColor"
                                  style={{ backgroundColor: color }}
                                ></div>
                                <h3 className="colorName fontSize14 fw500 ms-2 mb-0">
                                  {color}
                                </h3>
                              </div>
                              <img
                                src={Images.lightBlueCross}
                                alt="delete"
                                onClick={() =>
                                  handleDeleteColor(
                                    optionModalDetail?.rowIndex,
                                    color
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          {rows.map((row, index) => (
            <div className="modalOptionBox position-relative" key={row.id}>
              <div className="row">
                <div className="col-md-9">
                  <div className="form_group pt-0">
                    <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
                      Display name
                      {/* <img src={Images.labelImg} alt="logo" className="ms-2" /> */}
                    </label>
                    <div className="inputGroup">
                      <input
                        className="customInput textCapital"
                        placeholder="Name"
                        name="text"
                        type="text"
                        value={row.displayName}
                        onChange={(e) =>
                          handleDisplayNameChange(index, e.target.value)
                        }
                        readOnly={row.activePlan === "color"} // Disable input if plan is "color"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pe-0">
                  <div className="pricingPara">
                    <label className="fontSize14 txtDarkblue mb-2 fw500">
                      Show in product page as
                    </label>
                    <ul className="planTabs_ m-0">
                      <li
                        className={`planttabslist ${
                          row.activePlan === "list" ? "active" : ""
                        }`}
                        onClick={() => handleTogglePlan(index, "list")}
                      >
                        <img
                          src={Images.listLogo}
                          alt="logo"
                          className="optionModal_ToggleLogo"
                        />
                        List
                      </li>
                      <li
                        className={`planttabslist ${
                          row.activePlan === "color" ? "active" : ""
                        }`}
                        onClick={() => handleTogglePlan(index, "color")}
                      >
                        <img
                          src={Images.colorLogo}
                          alt="logo"
                          className="optionModal_ToggleLogo"
                        />
                        Color
                      </li>
                    </ul>
                  </div>
                </div>

                {row.activePlan === "list" && (
                  <div className="col-md-12">
                    <div className="form_group">
                      <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
                        Options
                        {/* <img
                          src={Images.labelImg}
                          alt="logo"
                          className="ms-2"
                        /> */}
                      </label>
                      <div className="inputGroup autocompleteParent">
                        <Autocomplete
                          multiple
                          freeSolo
                          getOptionLabel={(option) => option}
                          id="tags-filled"
                          value={row.productTags || []}
                          options={[]}
                          onChange={(e, newValue) =>
                            handleProducttags(index, newValue)
                          }
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                              const { key, ...tagProps } = getTagProps({
                                index,
                              });
                              return (
                                <Chip
                                  key={key}
                                  variant="outlined"
                                  label={option}
                                  size="small"
                                  deleteIcon={
                                    <img
                                      src={Images.lightBlueCross}
                                      alt="delete"
                                      style={{
                                        width: 14,
                                        height: 14,
                                        marginLeft: "3px",
                                      }}
                                    />
                                  }
                                  {...tagProps}
                                />
                              );
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              placeholder="Type options"
                              className="custom-filled-input"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <p className="pressEnterTxt pt-2 ps-4">
                      Press <span className="fw700"> Enter </span>
                      after each choice.
                    </p>
                  </div>
                )}

                {row.activePlan === "color" && (
                  <div className="col-md-12">
                    <div className="form_group">
                      <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
                        Color options
                        {/* <img
                          src={Images.labelImg}
                          alt="logo"
                          className="ms-2"
                        /> */}
                      </label>
                      <div className="inputGroup">
                        <div
                          className="customInput txtDarkblue colorPickerParent roleFlex flex-wrap textCapital"
                          onClick={() =>
                            handleOpenModal("colorPickerModal", index)
                          }
                        >
                          {row.productTags.map((color, colorIndex) => (
                            <div
                              className="showcolorBox"
                              key={colorIndex}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="d-flex align-items-center">
                                <div
                                  className="selectedColor"
                                  style={{ backgroundColor: color }}
                                ></div>
                                <h3 className="colorName fontSize14 fw500 ms-2 mb-0">
                                  {color}
                                </h3>
                              </div>
                              <img
                                src={Images.lightBlueCross}
                                alt="delete"
                                onClick={() => handleDeleteColor(index, color)} // Handle color deletion
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {rows.length > 1 && (
                <button
                  className="optionDelete"
                  onClick={() => handleDeleteRow(index)}
                >
                  <img
                    src={Images.Delete_Outline}
                    alt="Delete image"
                    className="img-fluid"
                  />
                </button>
              )}
            </div>
          ))}
        </div>
      )}

      {optionModalDetail?.flag !== "EditOption" && (
        <button className="addOptionSetBtn w-100" onClick={handleAddOptionSet}>
          <i className="fa-regular fa-plus addOptionPlusIcon me-2"></i>Add
          Option Set
        </button>
      )}

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "colorPickerModal" ? "colorPickerModal" : ""}
        child={
          modalDetail.flag === "colorPickerModal" ? (
            <ColorPickerModal
              onCloseModal={() => handleOnCloseModal()}
              onColorSelect={(color) =>
                handleColorChange(modalDetail.rowIndex, color)
              } // Pass selected color back
            />
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default AddOptionsModal;
