import React, { useEffect, useState } from "react";
import {
  deleteIcon,
  docupload,
  fileicons,
  sendIcon,
  imgGallery1,
  modalCancel,
  checksolid,
  circlecross,
  cropsolid,
  CameraLogo,
} from "../../../utilities/images";
import { Link, useHistory } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";                    
import { toast } from "react-toastify";
import CustomModal from "../../components/shared/CustomModal";
import CropImageModal from "./CropImageModal";

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const BrowseImgUpload = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const [imageUrl, setImageUrl] = useState(props?.images);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleChange = (file) => {
    console.log({ file } , "file in image upload =>>>>>>>")
    props?.uploadImageHandle(file);
  };
  // const sendFile = (file) => {
  //     if (props.flag === "catalog") {
  //         props.uploadHandleInCatelog(file)
  //         props.close();
  //     } else {
  //         props.file(file, "doc");
  //         props.close();
  //     }
  // }

  // function checkImageValidity(url) {
  //     return new Promise((resolve) => {
  //       const img = new Image();
  //       img.onload = function () {
  //         resolve(true); // Image is valid and loaded successfully
  //       };
  //       img.onerror = function () {
  //         resolve(false); // Image URL is invalid or failed to load
  //       };
  //       img.src = url;
  //     });
  //   }

  function checkImageValidity(imageUrl) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = function () {
        const isSquare = this.width === this.height;
        resolve(isSquare);
      };

      img.onerror = function () {
        resolve(false);
      };
      img.src = imageUrl;
    });
  }

  const deleteImage = (item, idx) => {
    const filterImage = imageUrl?.filter((image) => image.filePath != item);
    props?.setImageUrl(filterImage);
    let filteredBlob = props?.fileBlob?.filter((value, index) => index !== idx);
    props?.setFileBlob(filteredBlob);
  };

  const sendPhoto = (images) => {
    const containsInvalid = imageUrl.some((image) => image.valid === false);
    if (containsInvalid) {
      toast.error("Please upload square image only");
    } else {
      props?.setMainImage(images[0]?.filePath);
      let imageData = {
        image: images[0]?.filePath,
        images: images?.map((val) => val.filePath),
      };
      props.imageData(imageData);
      props?.setDifferentAngelsImage("");
      props?.setCloseUpImage("");
      props?.setInUseImage("");
      props?.setStyleImage("");
      props.close();
    }
  };
  const cropImageHandle = (e) => {
    e.preventDefault();
    setModalDetail({ show: true, flag: "cropImage" });
    setKey(Math.random());
  };

  useEffect(() => {
    // const updatedImageArray = Promise.all(props?.images?.map(async (imageObj) => {
    //     const isValid = await checkImageValidity(imageObj.filePath);
    //     return { ...imageObj, valid: isValid };
    //   }));
    //   setImageUrl(updatedImageArray)

    setLoading(true);

    async function getImageValidityArray() {
      const updatedImageArray = await Promise.all(
        props?.images.map(async (imageObj) => {
          const isValid = await checkImageValidity(imageObj.filePath);
          return { ...imageObj, valid: isValid };
        })
      );
      return updatedImageArray;
    }

    // Call the async function to get the promise array response
    getImageValidityArray()
      .then((result) => {
        setImageUrl(result);
        setLoading(false); // This will log the array response with image validity
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Log any errors encountered during the process
      });
  }, [props?.images]);

  return (
    <>
      <div className="browseUploadSection modalBodyBg p-0">
        <form className="emplyoeeDetails horizontalForms p-0">
          <div className="row">
            <div className="form-group col-md-12 text-start">
              <div className="mainCatalog p-0">
                <div id="dropzone">
                  <FileUploader
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    children={
                      <div>
                        {/* <input type="file" id="file" accept="image/*" multiple={true} className="form-control d-none" onChange={(e) => { handleChange(e) }} /> */}
                        <label htmlFor="file">
                          <span className="file-button uploadImages_">
                            <img
                              src={CameraLogo}
                              className="img-fluid editProfileImg"
                              alt="user img"
                            />
                            <div className="dropText ms-2">
                              <span className="dragAndDrop_Txt">
                                Click to browse or <br /> drag and drop your
                                files
                              </span>
                            </div>
                          </span>
                        </label>
                      </div>
                    }
                  />
                </div>

                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="supportImgTxt">
                    Support formats: png, Jpg, heic{" "}
                  </p>
                  <p className="supportImgTxt">Maximum image size: 1.5 MB</p>
                </div>
                {/* {props?.loading || loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm"></span>
                  </>
                ) : (
                  <>
                    {imageUrl && imageUrl?.length > 0 ? (
                      <>
                        {imageUrl?.map((item, index) => {
                          return (
                            <ul className="uploadProfile_" key={index}>
                              <img
                                className="w3-round"
                                src={item.filePath}
                                alt=""
                              ></img>
                              <div className="profileDetail_">
                                <h5 className="profileboldHead">
                                  {item.originalname ? item.originalname : ""}
                                </h5>
                                <p className="profileboldgraph">
                                  {" "}
                                  {item.size
                                    ? `${(item.size / (1024 * 1024)).toFixed(
                                        2
                                      )}MB`
                                    : ""}{" "}
                                </p>
                              </div>
                              <img
                                className="deleteIconn"
                                src={deleteIcon}
                                onClick={(e) => deleteImage(item.filePath)}
                                alt=""
                              />
                              {item?.valid ? (
                                <>
                                  <span style={{ color: "green" }}>Valid</span>
                                </>
                              ) : (
                                <>
                                  <>
                                    <span style={{ color: "red" }}>
                                      Not Valid
                                    </span>
                                  </>
                                  <button
                                    onClick={(e) => {
                                      cropImageHandle(e);
                                      setSelectedImg(item);
                                      props?.indexVal(index);
                                    }}
                                    className="btn btn-primary"
                                  >
                                    Crop
                                  </button>
                                  <></>
                                </>
                              )}
                            </ul>
                          );
                        })}
                        <button
                          type="button"
                          className="submitBtn"
                          onClick={() => sendPhoto(imageUrl)}
                        >
                          Send{" "}
                          <img className="sendFill" src={sendIcon} alt="" />
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )} */}

                {props?.loading || loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm spinnerDark"></span>
                  </>
                ) : (
                  <>
                    {imageUrl && imageUrl?.length > 0 ? (
                      <div className="row">
                        {imageUrl?.map((item, index) => {
                          return (
                            <div className="col-lg-3 col-12 my-3" key={index}>
                              <div className="galleryView">
                                <img
                                  className="image-gallery"
                                  src={item.filePath}
                                  alt=""
                                />
                              </div>
                              <div className="gallery-icons">
                                <img
                                  src={deleteIcon}
                                  onClick={(e) =>
                                    deleteImage(item.filePath, index)
                                  }
                                  alt=""
                                />
                                {item?.valid ? (
                                  <>
                                    <img
                                      src={checksolid}
                                      className="validImg"
                                      alt="user img"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <>
                                      <img
                                        src={cropsolid}
                                        className="validImg"
                                        alt="user img"
                                        onClick={(e) => {
                                          cropImageHandle(e);
                                          setSelectedImg(item);
                                          props?.indexVal(index);
                                        }}
                                      />
                                    </>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                        <div className="row">
                          <div className="col-md-12">
                            <button
                              type="button"
                              className="modalNextBtn modalDiscardBtn active w-100 ms-0"
                              onClick={() => sendPhoto(imageUrl)}
                            >
                              Send
                              <img className="sendFill" src={sendIcon} alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "cropImage" ? "CropImage" : ""}
        size={modalDetail.flag === "cropImage" ? "md" : "md"}
        child={
          modalDetail.flag === "cropImage" ? (
            <CropImageModal
              close={() => handleOnCloseModal()}
              handleData={(file) => handleChange(file)}
              selectedImg={selectedImg}
              fileBlob={props.fileBlob}
              indexId={props?.indexId}
              setCroppedImage={props.setCroppedImage}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "cropImage" ? (
            <>
              <h4 className="modalHeading_">Crop Image</h4>
              <p className="modal_cancel">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={handleOnCloseModal}
                  src={modalCancel}
                  className="ModalCancel"
                  alt="modalcancelImg"
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default BrowseImgUpload;
