import React, { useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as Images from "../../../utilities/images";
import { toast } from "react-toastify";
import { imageUploadAsync } from "../message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { deleteIcon, checksolid, cropsolid } from "../../../utilities/images";

const ImageUploader = (props) => {
  const { fileList, setFileList } = props;
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false);

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const validTypes = ["image/jpeg", "image/png"];
      const maxSize = 5 * 1024 * 1024; // 5MB max size

      // Process each file one at a time
      for (const file of acceptedFiles) {
        if (!validTypes.includes(file.type)) {
          showToast("Invalid file type. Only JPEG and PNG are allowed.");
          continue; // Skip invalid file
        }

        if (file.size > maxSize) {
          showToast("File size exceeds the 5MB limit.");
          continue; // Skip large file
        }

        // Prepare FormData for the API
        const formData = new FormData();
        formData.append("file", file);
        let params = {
          file: formData,
          token: auth?.payload?.token,
        };
        setLoading(true);
        dispatch(imageUploadAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setFileList((prevList) => [...prevList, obj?.payload[0]?.filePath]);
            setLoading(false);
          })
          .catch((obj) => {
            setLoading(false);
          });
      }
    },
    [dispatch, setFileList]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png", // Allow only JPEG and PNG files
    multiple: true, // Allow multiple files
  });

  // Delete an image
  const deleteImage = (filePath, index) => {
    setFileList((prevList) => prevList.filter((_, i) => i !== index));
    toast.success("Image removed successfully.");
  };

  return (
    <>
      <div className="showToken_Img">
        <div {...getRootProps({ className: "" })}>
          <input name="image" type="file" {...getInputProps()} />
          <div className="uploadImg_Parent">
            <div className="d-flex align-items-center">
              <img
                src={Images.CameraLogo}
                alt="Camera"
                style={{ cursor: "pointer" }}
              />
              <p className="dragAndDrop_Txt ms-2">
                Click to browse or <br /> drag and drop your files
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2">
        <p className="supportImgTxt">Support formats: png, Jpg, heic </p>
        <p className="supportImgTxt">Maximum image size: 1.5 MB</p>
      </div>
      {loading ? (
        <>
          <span className="spinner-border spinner-border-sm"></span>
        </>
      ) : (
        fileList &&
        fileList.length > 0 && (
          <div className="row">
            {fileList.map((item, index) => (
              <div className="col-lg-2 col-12 my-3" key={item.id || index}>
                <div className="galleryView fileListView">
                  <img
                    className="image-gallery"
                    src={item}
                    alt={`Uploaded file ${index + 1}`}
                  />
                </div>
                <div className="gallery-icons">
                  <img
                    src={deleteIcon}
                    alt="Delete"
                    role="button"
                    tabIndex="0"
                    onClick={() => deleteImage(item.filePath, index)}
                  />
                </div>
              </div>
            ))}
          </div>
        )
      )}
    </>
  );
};

export default ImageUploader;
