import React from 'react'
import * as Images from "../../../utilities/images"
const CheckedInModal = () => {
  return <>
  <div className='checkedInSec verifySuccess'>
      <div className='verifyBox'>
        <figure className='successImg'>
          {/* <img src={Images?.checkedImages} alt="checkedImage" className="img-fluid " /> */}
        </figure>
        <h1 className='verifyHeading'>Checked In</h1>
        <h6 className='fontEighteen text-center mt-2'>Successfully confirmed</h6>
      </div>
    </div>
  </>
}

export default CheckedInModal