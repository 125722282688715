export const TimePeriodNav=[
    {
        value:"today",label:"Today"
    },
    {
        value:"week",label:"Weekly"
    },
    {
        value:"month",label:"Monthly"
    },
    {
        value:"year",label:"Yearly"
    },
    
]