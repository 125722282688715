import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import LocationAndStockModal from "./LocationAndStockModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getProductListByIdAsync } from "./productSlice";

const MyProductDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const auth = useSelector(selectLoginAuth);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [key, setKey] = useState(Math.random());
  const [selectedVariants, setSelectedVariants] = useState([])
  const [productData, setProductData] = useState("")

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      title: "",
      flag: flag,
    });
    setKey(Math.random());
  };

  const handleVariantSelect = (event) => {
    const variantId = Number(event.target.id.replace('idcheck3 ', '')); 
    // Extract the ID from the checkbox

    if (event.target.checked) {
      // Add to selected variants if the checkbox is checked
      setSelectedVariants((prevState) => [...prevState, variantId]);
    } else {
      // Remove from selected variants if the checkbox is unchecked
      setSelectedVariants((prevState) => prevState.filter((id) => id !== variantId));
    }
  };

  const handelAllVariantsSelect = (event) => {
    const allVariantsId = productData?.supplies[0]?.supply_variants.map(e => e.id);
    if (event.target.checked) {
      if (allVariantsId.every(id => selectedVariants.includes(id))) {
        return setSelectedVariants([])
      } else {
        setSelectedVariants(allVariantsId)
      }
    } else {
      setSelectedVariants([])
    }
  }

  const getProductDetailsById = async (id) => {
    const postData = {
      id,
      app_name: "merchant"
    };
    dispatch(getProductListByIdAsync(
      {
        postData: postData,
        tokenData: auth?.payload?.token
      }
    ))
      .then(unwrapResult)
      .then((result) => {
        setProductData(result?.payload?.product_detail)
      }).catch((err) => {
        console.log(err)
      });
  }

  useEffect(() => {
    const productID = queryParams?.get("productID");
    if (productID !== "null" && productID) {
      setSelectedVariants([])
      setLoading(true)
      getProductDetailsById(productID)
      setLoading(false)
    }
  }, []);
  
  if(loading) {
    return "Loading..."
  }
  if (Object.keys(productData || {}).length <= 0) {
    return "No data found for selected product"
  }
  return (
    <div className="productDetailsWrapper myProductDetailSection">
      <div className="container-fluid h-100 p-0">
        <div className="row h-100">
          <div className="col-md-4">
            <div className="walletStats h-100 productDetailSidebar">
              <div className="d-flex align-items-start gap-3">
                <img
                  src={Images.backArrow}
                  alt="logo"
                  className="cursorPointer"
                  onClick={() => {
                    history.push("/products");
                  }}
                />
                <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                  {productData?.name}
                </h2>
              </div>
              <div className="productPreviewBox text-center">
                <img
                  src={productData?.image}
                  alt="bagImg"
                  className="offerProductImg"
                />
                <h4 className="fontSize20 fw500 txtDarkblue mb-0 pt-4">
                  {productData?.name}
                </h4>
                <div className="subCategoryTxtBox">
                  <span className="greyDot"></span>
                  <h6 className="greyDotLabel mb-0">SKU {productData?.supplies[0].supply_variants[0]?.sku}</h6>
                </div>
                <h5 className="fontSize16 fw500 txtDarkblue">
                  ${productData?.price?.toFixed(2)}
                </h5>
              </div>
              <div className="productSidebar_Bottom ">
                <button
                  className="modalNextBtn modalDiscardBtn active w-100 m-0 d-flex align-items-center justify-content-center gap-2"
                  onClick={() => {
                    handleOpenModal("locationAndStockModal");
                  }}
                >
                  <img src={Images.plusIcon} alt="plusIcon" />
                  Add Product
                </button>

                <p className="fontSize14 txtBlue d-flex align-items-center justify-content-center gap-2 mb-0 pt-2">
                  <img src={Images.infoDetailIcon} alt="product image- h" />
                  {selectedVariants?.length} of
                  {productData?.supplies[0]?.supply_variants.length} Variants
                  Selected
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-8 ps-0">
            <div className="walletStats productDetailsLeft">
              <h2 className="fontSize24 fw500 txtDarkblue mb-0">
                Product details
              </h2>
              <div className="productDetailDataBox">
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Category</h5>
                  <h5>{productData?.category?.name}</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Details</h5>
                  <h5>
                    {productData?.description?.replace("<p>", "").replace("</p>", "")}
                  </h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">SKU</h5>
                  <h5>{productData?.supplies[0].supply_variants[0]?.sku}</h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Barcode</h5>
                  <h5>
                    {productData?.supplies[0].supply_variants[0]?.barcode}
                  </h5>
                </div>
                <div className="productDetailRight_Inner">
                  <h5 className="productCategoryTxt">Unit weight</h5>
                  <h5>{productData?.weight + " " + productData?.weight_unit}</h5>
                </div>
              </div>

              <div className="productDetailDataBox mt-4  mb-3">
                <h3 className="fontSize18 fw500 txtBlue">Pricing</h3>
                <div className="pricingBorderBox mt-3">
                  <div className="productDetailRight_Inner">
                    <h5 className="productCategoryTxt">Selling Price</h5>
                    <h5>${productData?.price?.toFixed(2)}</h5>
                  </div>
                </div>
              </div>

              <div className="myProduct_VariantsContainer">
                <h3 className="fontSize18 fw500 txtBlue">Variants</h3>
                <div className="pricingBorderBox mt-3">
                  <div className="productAvailabilityChild productVariantsCount align-items-start ">
                    <div className="form-group d-flex">
                      <input type="checkbox"
                        checked={selectedVariants.length === productData?.supplies[0]?.supply_variants.length}
                        id="idcheck1" onClick={
                          handelAllVariantsSelect
                        } />
                      <label htmlFor="idcheck1" className="me-0"></label>
                    </div>
                    <h3 className="fontSize18 fw500 txtBlue mb-0">
                      {productData?.supplies[0]?.supply_variants.length} Variations
                    </h3>
                  </div>
                  <div className="commonTable sellingProductTable myProductVariantTable table-responsive">
                    <table className="w-100">
                      <tbody>
                        {productData?.supplies[0]?.supply_variants.length > 0 &&
                          productData?.supplies[0]?.supply_variants.map(
                            item => (
                              <>
                                <tr>
                                  <td key={item?.id}>
                                    <div className="productAvailabilityChild">
                                      <div className="form-group">
                                        <input
                                          checked={
                                            selectedVariants.includes(Number(item?.id))
                                          }
                                          key={item?.id}
                                          onChange={handleVariantSelect}
                                          type="checkbox"
                                          id={"idcheck3 " + item?.id} />
                                        <label
                                          htmlFor={"idcheck3 " + item?.id}
                                          className="me-0"
                                        ></label>
                                      </div>
                                      <div className="d-flex align-items-center gap-3">
                                        <img
                                          src={item?.image}
                                          alt="img"
                                          className="productItemImg"
                                        />
                                        <div>
                                          <h3 className="topSellProductHead mb-0 pb-1">
                                            {productData?.name}
                                          </h3>
                                          <div className="d-flex align-items-center">
                                            <span className="dot" style={{
                                              backgroundColor: JSON.parse(item?.attributes)?.["color"] || ""
                                            }}></span>
                                            <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                            {JSON.parse(item?.attributes)?.["color"]} / {JSON.parse(item?.attributes)?.["Size"]}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-end">
                                    <h5 className="fontSize12 fw400 mb-0 txtBlack">
                                      ${item?.cost_price.toFixed(2)}
                                    </h5>
                                  </td>
                                </tr>
                              </>
                            )
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        // backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "locationAndStockModal" ? "editVariantModal" : ""
        }
        child={
          modalDetail.flag === "locationAndStockModal" ? (
            <LocationAndStockModal
              type="product"
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "locationAndStockModal" ? (
            <>
              <div></div>
              <img
                src={Images.modalCross}
                alt="logo"
                className="cursorPointer"
                onClick={() => handleOnCloseModal()}
              />
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </div>
  );
};

export default MyProductDetails;
