import React from "react";
import HomePageInnerHeader from "../dashboard/HomePageInnerHeader";
import * as Images from "../../../utilities/images";
import HeaderStatistics from "../dashboard/wallet/HeaderStatistics";
import DashboardNavbar from "../dashboard/DashboardNavbar";

const MyProduct = () => {
  return (
    <>
      <DashboardNavbar title="Products Overview" backShow={false} />
      <div className="myProductPage homePage">
        <HomePageInnerHeader subhead="vs. Same day last week" />
        <div className="container-fluid p-0">
          <div className="row mt-3">
            <HeaderStatistics
              className="totalProductBox"
              img={Images.productBoxImg1}
              value="304"
              heading="Total Products"
            />
            <HeaderStatistics
              className="totalCategoryBox"
              img={Images.productBoxImg2}
              value="19"
              heading="Total Category"
            />
            <HeaderStatistics
              className="totalSubCategoryBox"
              img={Images.productBoxImg3}
              value="35"
              heading="Total Sub-Category"
            />
            <HeaderStatistics
              className="totalBrandBox"
              img={Images.grossSales}
              value="88"
              heading="Total Brand"
            />
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <div className="sellingProductContainer">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="fontSize20 txtBlue fw500 mb-0">
                    Top Selling Products
                  </h2>
                  <button className="viewAllBtn">View All</button>
                </div>

                <div className="commonTable sellingProductTable table-responsive mt-3">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sellingProductContainer">
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="fontSize20 txtBlue fw500 mb-0">
                    Least Selling Products
                  </h2>
                  <button className="viewAllBtn">View All</button>
                </div>

                <div className="commonTable sellingProductTable table-responsive mt-3">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="sellingProductContainer p-0 offerProductContainer">
                <div className="d-flex align-items-center justify-content-between offerProduct_Head">
                  <div className="d-flex align-items-center gap-2">
                    <img src={Images.discountLogo} alt="logo" />
                    <h2 className="fontSize20 txtBlue fw500 mb-0">
                      Offer On-going
                    </h2>
                  </div>
                  <button className="viewAllBtn">View All</button>
                </div>

                <div className="commonTable sellingProductTable table-responsive">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-start gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="topSellProductHead discountPrice fw500 mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtYellow mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                              <div className="d-flex align-items-center gap-3 pt-2">
                                <h6 className="marketPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                                <h6 className="discountPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-start gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead discountPrice fw500 mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtYellow mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                              <div className="d-flex align-items-center gap-3 pt-2">
                                <h6 className="marketPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                                <h6 className="discountPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-start gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead discountPrice fw500 mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtYellow mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                              <div className="d-flex align-items-center gap-3 pt-2">
                                <h6 className="marketPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                                <h6 className="discountPrice fontSize12 mb-0">
                                  $90.00
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sellingProductContainer p-0 recentlyProductContainer">
                <div className="d-flex align-items-center justify-content-between recentlyProduct_Head">
                  <h2 className="fontSize20 txtBlue fw500 mb-0">
                    Recently Added Products
                  </h2>
                  <button className="viewAllBtn">View All</button>
                </div>

                <div className="commonTable sellingProductTable table-responsive">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img
                              src={Images.productItemImg}
                              alt="img"
                              className="productItemImg"
                            />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                        <td>
                          <img
                            src={Images.plusCircleIcon}
                            alt="plusCircleIcon"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                        <td>
                          <img
                            src={Images.plusCircleIcon}
                            alt="plusCircleIcon"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                        <td>
                          <img
                            src={Images.plusCircleIcon}
                            alt="plusCircleIcon"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <img src={Images.productItemImg} alt="img" />
                            <div>
                              <h3 className="topSellProductHead mb-0 pb-1">
                                Name Product Gender and Quality
                              </h3>
                              <div className="d-flex align-items-center">
                                <span className="dot"></span>
                                <h6 className="fontSize12 fw400 txtLightBlue mb-0 ps-1">
                                  Yellow / M
                                </h6>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <h5 className="fontSize12 fw400 mb-0 txtBlack">
                            230
                          </h5>
                        </td>
                        <td>
                          <img
                            src={Images.plusCircleIcon}
                            alt="plusCircleIcon"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProduct;
