import React, { useEffect, useRef, useState } from "react";
import DashboardNavbar from "../dashboard/DashboardNavbar";
import { getAddressApiAsync, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import * as Images from "../../../utilities/images";
import CustomModal from "../../components/shared/CustomModal";
import AddCashDrawModal from "./addCashDrawModal";
import {
  deleteCashAsync,
  getAllCashAsync,
  updateCashAsync,
} from "./cashDrawSlice";
import ReactSelect from "react-select";
import { toast } from "react-toastify";

const CashDrawer = () => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const [allAddress, setAllAddress] = useState([]);
  const [cashDrawers, setCashDrawers] = useState([]);
  const auth = useSelector(selectLoginAuth);
  const [key, setKey] = useState(Math.random());
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editingDrawer, setEditingDrawer] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    rowIndex: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

   // show only one toast at one time
   const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const handleAddressChange = (selectedOption) => {
    setSelectedAddress(selectedOption.value);
  };

  const addressOptions = [
    { value: null, label: "Select" }, // Add this for the "No Select" option
    ...allAddress.map((address) => ({
      value: address.id,
      label: address.format_address,
    })),
  ];
  console.log({auth})
  const getAllCash = () => {
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
      selectedAddress: selectedAddress,
    };
    dispatch(getAllCashAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setCashDrawers(obj?.payload || []);
      })
      .catch((obj) => {});
  };

  const getAllAddress = () => {
    setLoading(true);
    const params = {
      sellerId: auth?.payload?.uniqe_id
        ? auth?.payload?.uniqe_id
        : auth?.payload?.user?.unique_uuid,
      token: auth?.payload?.token,
    };
    dispatch(getAddressApiAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);

        setAllAddress(obj?.payload);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleDelete = (deleteId) => {
    const params = {
      id: deleteId,
      token: auth?.payload?.token,
    };
    dispatch(deleteCashAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        getAllCash();
      })
      .catch((obj) => {});
  };

  const handleSave = (updateId) => {
    if (!editedTitle.trim()) {
      showToast("please enter cash drawer name");
      return;
    }
    setEditLoading(true);
    const params = {
      token: auth?.payload?.token,
      postData: {
        title: editedTitle,
        status: "1",
        id: updateId,
      },
    };
    dispatch(updateCashAsync(params))
      .then(unwrapResult)
      .then(() => {
        setEditLoading(false);
        getAllCash();
        setEditingDrawer(null);
      })
      .catch(() => {
        setEditLoading(false);
      });
  };

  const handleEdit = (id, title) => {
    setEditingDrawer(id);
    setEditedTitle(title);
  };

  const handleCancel = () => {
    setEditingDrawer(null);
  };

  useEffect(() => {
    getAllAddress();
  }, []);

  useEffect(() => {
    getAllCash();
  }, [selectedAddress]);

  return (
    <>
      <DashboardNavbar title="Cash Drawer" backShow={false} />
      <div className="chartsOuter cashDrawSection ">
        <div className="d-flex align-items-center justify-content-end">
          <div className="reactSelectParent mt-3 me-3">
            <ReactSelect
              options={addressOptions}
              onChange={handleAddressChange}
              autosize={true}
              placeholder="Select Address"
              classNamePrefix="react-select"
              className="react-select-container anlyticSelect"
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "#263682" // Background color for selected option
                    : state.isFocused
                    ? "#f5f6fc" // Background color for hovered option
                    : "#fff", // Default background color
                  // color: state.isSelected || state.isFocused ? '#fff' : '#000', // Text color
                  color: state.isSelected
                    ? "#fff" // Background color for selected option
                    : "#000", // Text color
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: "#263682", // Customize placeholder color if needed
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }),
              }}
              components={{
                DropdownIndicator: () => (
                  <img
                    src={Images.selectImg}
                    width={24}
                    height={24}
                    alt="drop_icon"
                    className="mt-1"
                  />
                ),
              }}
            />
          </div>
          <button
            className="commonBtn mt-3"
            onClick={() => {
              setModalDetail({
                show: true,
                flag: "addCash",
              });
              setKey(Math.random());
            }}
          >
            <img src={Images.btnPlusIcon} alt="logo" className="me-2" />
            Add Cash Drawer
          </button>
        </div>
        <div className="cashDrawOuter mt-4">
          {loading ? (
            <>
              <div className="d-flex align-items-center justify-content-center">
                <span className="spinner-border spinner-border-md spinnerDark"></span>
              </div>
            </>
          ) : cashDrawers && cashDrawers.length > 0 ? (
            cashDrawers.map((value, idx) => (
              <div
                className={`cashDrawInner ${
                  editingDrawer === value.id ? "active" : ""
                }`}
                key={idx}
              >
                {editingDrawer === value.id ? (
                  <input
                    type="text"
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    className="form-control editInput"
                  />
                ) : (
                  <h4 className="fontSize20 txtDarkblue m-0">{`${idx + 1}. ${
                    value?.title
                  }`}</h4>
                )}
                <div className="cashInnerRight">
                  {editingDrawer === value.id ? (
                    <>
                      <div className="d-flex align-items-center justify-content-end">
                        <button
                          className="modalDiscardBtn"
                          type="button"
                          onClick={handleCancel}
                        >
                          Discard
                        </button>
                        <button
                          className="modalNextBtn modalDiscardBtn active"
                          onClick={() => handleSave(value.id)}
                          disabled={editLoading}
                        >
                          {editLoading && (
                            <span className="spinner-border spinner-border-sm spinnerLight"></span>
                          )}{" "}
                          Save
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        className="cashBtnEdit"
                        onClick={() => handleEdit(value.id, value.title)}
                      >
                        <img
                          src={Images.editIcon}
                          alt="editIcon"
                          className="cashIcon"
                        />
                        Edit
                      </button>
                      <button
                        className="cashBtnDelete"
                        onClick={() => handleDelete(value.id)}
                      >
                        <img
                          src={Images.Delete_Outline}
                          alt="editIcon"
                          className="img-fluid"
                        />
                        Delete
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <>
              <div className="cashDrawInner justify-content-center">
                <h4 className="fontSize20 txtDarkblue m-0">
                  No cash drawer available
                </h4>
              </div>
            </>
          )}
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={modalDetail.flag === "addCash" ? "addCashDraw" : ""}
        child={
          modalDetail.flag === "addCash" ? (
            <AddCashDrawModal
              allAddress={allAddress}
              close={handleOnCloseModal}
              getAllCash={getAllCash}
            />
          ) : (
            <></>
          )
        }
        header={
          modalDetail.flag === "addCash" ? (
            <>
              <h3 className="fontSize24 fw500 txtDarkblue mb-0">
                Add cash Drawer
              </h3>
            </>
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default CashDrawer;
