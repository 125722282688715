import React from 'react'
import { useState } from 'react'

const ImgUploadedModal = (props) => {
    const data = [1, 2, 3, 4, 5, 6]
    const [activeData, setActiveData] = useState("")
    return (
        <div className='ImgUploadedModalArea'>
            <div className='row'>
                {
                    props?.imageData.length > 0 ?
                        <>
                            {
                                props?.imageData.map((val, index) => {
                                    return (
                                        <div className='col-md-3 mb-4' key={index}>
                                            <div className={`selectionArea ${activeData == val?.filePath ? "active" : ""}`}
                                                onClick={() => { setActiveData(val?.filePath); props?.selectedImage(val?.filePath); props?.close() }}>
                                                <img src={val?.filePath} alt='see-image' className='selectionImg' />
                                                {/* <span className='removeSelect'><i className="fas fa-times"></i></span> */}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                        : <div>No File Uploaded(please upload some file first)</div>
                }
            </div>
        </div>
    )
}

export default ImgUploadedModal