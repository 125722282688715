/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import { logout } from "../app/containers/auth/authSlice";

import axios from "axios";
import { productEmpty } from "../app/containers/myProducts/productSlice";
import { dashboardEmpty } from "../app/containers/dashboard/dashboardSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import history from "./historyHelper";
// import { toast } from "react-toastify"; 

/**
 * Define interceptor to handle api resoponse and set header value
 */
export default {
	setupInterceptors: (store) => {
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				// console.log("error", error?.response?.status === 401)
				if (error?.response?.status === 401) {
					// store.
					localStorage.clear();
					localStorage.removeItem("user");
					history.replace("/");
					store.dispatch(logout())
					store.dispatch(productEmpty())
					store.dispatch(dashboardEmpty())
				}
				return Promise.reject(error);
			}
		);
	},
};