import React from 'react'
import { DeliveryStatusOptions } from '../../../constants/orders/orders';

const DeliveryStatus = ({statusOption}) => {
  if(statusOption===''){
    return   <div className='d-flex justify-content-center'></div>
  }
    const statusDetails=DeliveryStatusOptions[parseInt(statusOption,10)];
    console.log(statusDetails,parseInt(statusOption,10)-1)
  return <>
  <div className='d-flex justify-content-center'>
  <div className={`deliveryStatus_  ${statusDetails.className+"Status"}`}>
  <span>
    {statusDetails.label}
  </span>
  </div>
  </div>
  </>
}

export default DeliveryStatus