import React from "react";
import * as Images from "../../../utilities/images";


const DeleteModal = () => {
  return (
    <div className="deleteModalParent text-center">
      <img src={Images.crossCircle} alt="cancleLogo" />
      <h3 className="stopOfferTxt mb-0 ">Deleted</h3>
    </div>
  );
};

export default DeleteModal;
