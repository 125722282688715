export const filterOrderDetails = (orders,getDateTimeStampConvertor) => {
    try {
     return orders?.map((order) => {
         let timeDetails = order?.date ? getDateTimeStampConvertor(order?.date) : ["", ""];
         return {
             id: order?.id || "",
             orderId:order?.order_details[0]?.order_id,

             profileImg: order?.user_details?.profile_photo ||null,
             customerProfile: {
                 name: `${order?.user_details?.firstname || ""} ${order?.user_details?.lastname || ""}`,
                 address: order?.user_details?.current_address?.street_address || "",
             },
             timedetails: {
                 fullDate: timeDetails[0],
                 time: timeDetails[1],
             },
             isChecked: false,
             invoiceNumber: order?.invoices?.invoice_number || "",
             delivery: order?.delivery_option || "",
             paymentMethod: order?.mode_of_payment || "",
             totalAmount: order?.payable_amount || 0,
             status: order?.status,
             location: order?.user_details?.current_address?.state || "",
             tips: order?.tips || 0,
             taxes: order?.tax || 0,
         };
     });
    } catch (error) {
        console.log("error occur",error.message)
     return [];
    }
 } 