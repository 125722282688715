import React from "react";
import Sidebar from "./Sidebar";

const DashboardLayout = (props) => {
  console.log(window.location.pathname, "pathname");
  return (
    <main>
      <div className="wrapper">
        <Sidebar />
        <div className="rightdashboard_">
          <div className="rightWrapper">
            <div className="container-fluid">
              <div
                className={
                  window.location.pathname === "/directMessage" ||
                  window.location.pathname === "/inquaries" ||
                  window.location.pathname === "/rfqMessage" ||
                  window.location.pathname === "/productOffer" ||
                  window.location.pathname === "/serviceOffer" ||
                  window.location.pathname === "/allOrder" ||
                  window.location.pathname === "/orderTrack" ||
                  window.location.pathname === "/orderTrack"
                    ? "content_areas message"
                    : // : window.location.pathname === "/allOrder" ||
                      //   window.location.pathname === "/orderTrack"
                      // ? "content_areas allOrders"
                      // : window.location.pathname.startsWith("/orderTrack/")
                      // ? "content_areas orderTracking_"
                      "content_areas"
                }
              >
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashboardLayout;
