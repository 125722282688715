import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createCashDrawer, deleteCashDrawer, getAllCashDrawer, updateCashDrawer } from "./cashDrawApi";

export const createCashAsync = createAsyncThunk(
  "cash/createCashDrawerApi",
  async (data) => {
    const response = await createCashDrawer(data.postData, data.tokenData);
    return response.data;
  }
);

export const getAllCashAsync = createAsyncThunk(
  "cash/getAllCashApi",
  async (data) => {
    const response = await getAllCashDrawer(data);
    return response.data;
  }
);

export const deleteCashAsync = createAsyncThunk(
  "cash/deleteCashApi",
  async (data) => {
    const response = await deleteCashDrawer(data.token, data.id);
    return response.data;
  }
);

export const updateCashAsync = createAsyncThunk(
  "cash/updateCashApi",
  async (data) => {
    const response = await updateCashDrawer(data.token, data.postData);
    return response.data;
  }
);

const initialState = {
  cashDrawers: [],
  loading: false,
  error: null,
};

const cashSlice = createSlice({
  name: "cash",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCashAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCashAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.cashDrawers.push(action.payload);
      })
      .addCase(createCashAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllCashAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCashAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.cashDrawers = action.payload;
      })
      .addCase(getAllCashAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default cashSlice.reducer;
