import React from 'react'
import OTPInput from "react-otp-input";

const VerifyModal = (props) => {
    const onCompleted = (data) => {
        props.setOtp(data);
        if (data?.length == 5) {
            props?.buttonActive(true)
        }
        else {
            props?.buttonActive(false)
        }
    };

    return (
        <>
            <div className='verifyModal'>
                <p className='fontSize18 txtLightBlue mb-0'>
                    Enter the code we’ve sent to +{props?.phoneCode} {props?.phoneNo}
                </p>
                <div className="mb-4 mt-3 1234 otp__ d-flex justify-content-center ">
                    <OTPInput
                        numInputs={5}
                        className="input_digits_"
                        value={props.otp}
                        data-cy="pin-field"
                        isInputNum={true}
                        onChange={(e) => onCompleted(e)}
                        isInputSecure={true}
                        autoComplete="false"
                        placeholder='00000'
                    // onComplete={(code) => onComplete(code)}
                    />
                </div>
            </div>
        </>
    )
}

export default VerifyModal