import React, { useState, useEffect } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useHistory } from "react-router";
import { check } from '../../../../utilities/images';
import { toast } from 'react-toastify';

const CreateAccountSetUp = () => {
  const toastId = React.useRef(null);
  const history = useHistory()
  const [consent, setConsent] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: localStorage.getItem("email_info") || "",
    password: "",
    confirmEmail: localStorage.getItem("email_info") || "",
  });
  const [errors, setErrors] = useState({});
  const [strength, setStrength] = useState(null); // Track password strength
  const { firstname, lastname, email, password, confirmEmail } = formData;
  const phoneData = localStorage.getItem("phone_details")
    ? JSON.parse(localStorage.getItem("phone_details"))
    : "";
  // Function to handle form field change
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle password strength change
  const handlePasswordStrengthChange = (strengthLevel) => {
    setStrength(strengthLevel); // Set password strength level (0 to 4)
  };

  // Function to validate the form fields
  const validateForm = () => {
    const errors = {};
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
    if (!firstname) errors.firstname = "First name is required";
    if (!lastname) errors.lastname = "Last name is required";
    if (!email) errors.email = "Email is required";

    var resultemail = pattern.test(email);
    if (resultemail === false) errors.email = "Please enter valid email";
    
    if (email !== confirmEmail) errors.confirmEmail = "Emails do not match";
    if (!password) errors.password = "Password is required";
    if (password.length < 8) errors.password = "Password must be at least 6 characters";
    if (!consent) errors.consent = "You must agree to the terms and conditions";

    return errors;
  };

  // Handling form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      const signUpData = JSON.stringify({ ...formData, consent });
      localStorage.setItem("sign_up_data", signUpData);
      // console.log('Form submitted successfully');
      history.push({ pathname: "onBoarding", state: signUpData });
    } else {
      setErrors(validationErrors);
    }
  };

  // Clear errors on input change
  useEffect(() => {
    setErrors({});
  }, [formData]);

  // Determine password strength text based on strength level
  const getPasswordStrengthText = () => {
    if (password.length > 2) return "";  // No strength text if password is less than 8 characters
    switch (strength) {
      case 0: return "Very Weak";
      case 1: return "Weak";
      case 2: return "Fair";
      case 3: return "Good";
      case 4: return "Strong";
      default: return "";
    }
  };
  return (
    <>
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F5F6FC"
      }}>
        <div className="" style={{
          width: "600px",
          backgroundColor: "#ffff",
          padding: "30px",
        }}>
          <div>
            <h1 className='logintxt mb-0 text-center'>Let’s create your account.</h1>
            <h2 className='welcomeBackTxt mb-0 text-center'>Signing up for JOBR is fast and easy.</h2>
          </div>
          {/* form section */}
          <form onSubmit={handleFormSubmit} style={{
            padding: "30px",
            display: "flex",
            flexDirection: "column",
          }}>
            <div style={{ display: "flex" }}>
              <div className="mb-2 form-group col-md-6">
                <label className="deatilsHead mb-3">First name</label>
                <input
                  type="text"
                  className="customform-control"
                  name="firstname"
                  autoComplete="new-password"
                  placeholder="First name"
                  value={firstname}
                  onChange={handleOnChange}
                />
                {errors.firstname && <div style={{ color: 'red' }}>{errors.firstname}</div>}
              </div>
              <div className="mb-2 form-group col-md-6">
                <label className="deatilsHead mb-3">Last name</label>
                <input
                  type="text"
                  className="customform-control"
                  name="lastname"
                  autoComplete="new-password"
                  placeholder="Last name"
                  value={lastname}
                  onChange={handleOnChange}
                />
                {errors.lastname && <div style={{ color: 'red' }}>{errors.lastname}</div>}
              </div>
            </div>
            <div className="mb-2 form-group col-md-12">
              <label className="deatilsHead mb-3">Email Address</label>
              <input
                type="email"
                className="customform-control"
                name="email"
                autoComplete="new-password"
                placeholder="Email"
                value={email}
                autoCorrect='off'
                autocomplete="off"
                onChange={handleOnChange}
              />
              {email || phoneData?.user_email ? (
                <img
                  className="checkarrow_"
                  style={{ width: "20px" }}
                  src={check}
                  alt=""
                />
              ) : (
                <></>
              )}
              {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
            </div>
            <div className="mb-2 form-group col-md-12">
              <label className="deatilsHead mb-3">Confirm Email Address</label>
              <input
                type="email"
                className="customform-control"
                name="confirmEmail"
                autoComplete="new-password"
                placeholder="Confirm Email address"
                value={confirmEmail}
                onChange={handleOnChange}
              />
              {email || phoneData?.user_email ? (
                <img
                  className="checkarrow_"
                  style={{ width: "20px" }}
                  src={check}
                  alt=""
                />
              ) : (
                <></>
              )}
              {errors.confirmEmail && <div style={{ color: 'red' }}>{errors.confirmEmail}</div>}
            </div>
            <div className="mb-2 form-group col-md-12">
              <label className="deatilsHead mb-3">Password</label>
              <input
                type="password"
                className="customform-control"
                name="password"
                autoComplete="new-password"
                placeholder="Enter Password"
                value={password}
                onChange={handleOnChange}
                maxLength={8}
              />
              <div style={{
                padding: "5px",
                margin: "5px"
              }}>
                {!password && <p className='ms-2' style={{
                  fontSize: "11px"
                }}>Your password must be at least 8 characters, and can’t begin or end with a space.</p>}
                {password && (
                  <>
                    <p>Password strength: {getPasswordStrengthText()}</p>
                    <PasswordStrengthBar
                      className="react-password-strength"
                      minLength={8}
                      password={password}
                      onChangeScore={handlePasswordStrengthChange} // Track strength
                    />
                  </>
                )}
              </div>
              {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
            </div>
            <div className="mb-2 form-group col-md-12">
              <input
                type="checkbox"
                id="register"
                name="consent"
                checked={consent}
                onChange={() => setConsent(!consent)}
              />
              <label htmlFor="register" className="ms-2" style={{
                fontSize: "11px"
              }}>
                I agree to JOBR's Terms, Privacy Policy, and E-Sign Consent.
              </label>
              {errors.consent && <div style={{ color: 'red' }}>{errors.consent}</div>}
            </div>
            <div className="col-md-12 mt-1">
              <button className="loginBtnSign w-100" type="submit">
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>

    </>
  );
};

export default CreateAccountSetUp;
