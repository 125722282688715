import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  distributor,
  manufacture,
  retailer,
  retailerActive,
  shops,
  manufacture1,
  distributer1,
  oneStep,
  modalCross,
} from "../../../../utilities/images";
import { getServicesApiAsync, sigupApiAsync } from "../authSlice";
import Multiselect from "multiselect-react-dropdown";
import CreateAccountSetUp from "../signup/CreateAccount";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CustomModal from "../../../components/shared/CustomModal";
import SuccessFullyCreated from "../successfulModal/SuccessFullyCreated";

const ChooseRoles = () => {
  const history = useHistory();
  const [organisationName, setOrganisationName] = useState("");
  const toastId = React.useRef(null);
  const [businessType, setBusinesType] = useState("");
  const [registerBusiness, setRegisterBusiness] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [operatingIndustry, setOperatingIndustry] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [operation, setOperations] = useState("operations");
  const userData = JSON.parse(history.location.state)

  console.log({ userData })
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      title: flag,
    });
    setKey(Math.random());
  };


  const existing_user = localStorage.getItem("existing_user")
    ? JSON.parse(localStorage.getItem("existing_user"))
    : "";
  const emailData = localStorage.getItem("email_details")
    ? JSON.parse(localStorage.getItem("email_details"))
    : "";
  const emailInfo = localStorage.getItem("email_info")
    ? localStorage.getItem("email_info")
    : "";
  const phoneInfo = localStorage.getItem("phone_info")
    ? JSON.parse(localStorage.getItem("phone_info"))
    : "";
  const phoneData = localStorage.getItem("phone_details")
    ? JSON.parse(localStorage.getItem("phone_details"))
    : "";
  const [phoneNo, setPhoneNo] = useState(
    existing_user?.user_profiles?.phone_no
      ? existing_user?.user_profiles?.phone_no
      : phoneInfo?.phone_no
  );
  const [phoneCode, SetPhoneCode] = useState(
    existing_user?.user_profiles?.phone_code
      ? existing_user?.user_profiles?.phone_code
      : phoneInfo?.phone_code
  );
  const generateRandomName = () => {
    return Math.random().toString(36).substr(2, 10);
  };
  const optionsOne = [
    { id: 1, value: "product", label: "Option 1" },
    { id: 2, value: "service", label: "Option 2" },
  ];
  const optionsTwo = [{ id: 1, value: "product", label: "Option 1" }];
  const handleSelect = (selectedList) => {
    let businessType = selectedList?.map((v) => v?.value);
    setSelectedOptions(businessType);
    if (businessType?.length > 0) {
      let data = {
        type: businessType?.toString(),
      };
      dispatch(getServicesApiAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          setServiceList(obj?.payload?.data);
        })
        .catch((obj) => { });
    }
  };

  const handleBusinessChange = (e) => {
    setBusinesType(e.target.value);
    let filteredData = serviceList?.filter(
      (val) => val.type === e.target.value
    );
    setFilteredList(filteredData);
  };

  const handleOperatingIndustryChange = (e) => {
    let operatingIndustryIds = e?.map((v) => v?.id);
    setOperatingIndustry(operatingIndustryIds);
  };
  const dispatch = useDispatch();

  const [address, setAddress] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [countryValue, setCountryValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [aptValue, setAptValue] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(Math.random());


  const extractStreetAddress = (address) => {
    // Extract the street address from the address
    const parts = address.split(",");
    if (parts.length >= 3) {
      return `${parts[0].trim()}, ${parts[1].trim()}`;
    }
  };

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });


  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const autoCompleteHandleChange = (address) => {
    console.log(address, "address");
    setFormattedAddress(address);
    setZipCode("");
    setCityValue("");
    setStateValue("");
    setCountryValue("");
    setAptValue("");
    setAddress("");
  };

  const [hide, setHide] = useState(false);
  const [displayValue, setDisplayValue] = useState();

  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        setDisplayValue(results[0]?.formatted_address?.split(",")[0]);
        fillAddressDetails(results);
        setLatitude(results[0].geometry.location.lat());
        setLongitude(results[0].geometry.location.lng());
        setHide(true);
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };

  const fillAddressDetails = (results) => {
    // setAddress(results[0].formatted_address);
    setFormattedAddress(results[0].formatted_address);

    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        setZipCode(results[0].address_components[j].short_name);
      } else if (results[0].address_components[j].types[0] == "locality") {
        setCityValue(results[0].address_components[j].long_name);
      } else if (
        results[0].address_components[j].types[0] ==
        "administrative_area_level_1" ||
        results[0].address_components[j].types[0] ===
        "administrative_area_level_3" ||
        results[0].address_components[j].types[0] === "locality"
      ) {
        setStateValue(results[0].address_components[j].long_name);
        setStateCode(results[0].address_components[j]?.short_name);
      } else if (results[0].address_components[j].types[0] == "country") {
        setCountryValue(results[0].address_components[j].long_name);
        setCountryCode(results[0].address_components[j]?.short_name);
      } else if (results[0].address_components[j].types[0] == "street_number") {
        setAptValue(results[0].address_components[j].long_name);
      } else if (results[0].address_components[j].types[0] == "route") {
        setAddress(results[0].address_components[j].long_name);
      }
    }
  };

  const handleGoToWebPos = () => {
    window.open("https://webpos.jobr.com/", "_blank");
  };

  const handleSubmit = (e) => {
    const fullAddress = `${aptValue} ${address}`.trim();
    e.preventDefault();
    let data = {
      seller_type: activeTab,
      organization_name: organisationName,
      is_business_registered: registerBusiness,
      business_type: selectedOptions,
      operations: operation,
      service_ids: operatingIndustry?.toString(),
      firstname: userData?.firstname,
      lastname: userData?.lastname,
      phone_code: phoneCode,
      phone_no: phoneNo,
      email: phoneData?.user_email ? phoneData?.user_email : emailInfo,
      password: userData?.password,
      current_address: {
        street_address: fullAddress
          ? fullAddress
          : extractStreetAddress(formattedAddress),
        country: countryValue,
        state: stateValue,
        city: cityValue,
        zipcode: zipCode,
        address_type: "current",
        longitude: longitude,
        latitude: latitude,
        country_code: countryCode,
        state_code: stateCode,
        format_address: formattedAddress,
      },
      is_default_address: defaultAddress,
    };
    if (!userData?.firstname) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your firstname");
      }
      return;
    }
    if (!userData?.lastname) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your lastname");
      }
      return;
    }
    if (!phoneCode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your phonecode");
      }
      return;
    }
    if (!phoneNo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your phone number");
      }
      return;
    }
    if (phoneNo.length < 10) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Phone no must be of 10 digits");
      }
      return;
    }

    if (businessWebsite) {
      var urlPattern = new RegExp(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
      );
      var resultUrl = urlPattern.test(
        businessWebsite?.split(":")[0] === "https"
          ? businessWebsite
          : `https://${businessWebsite}`
      );
    }
    if (resultUrl === false) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter valid webiste url");
      }
      return;
    }
    if (!formattedAddress) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your address");
      }
      return;
    }
    if (!countryValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your country");
      }
      return;
    }
    if (!stateValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your state");
      }
      return;
    }
    if (!cityValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your city");
      }
      return;
    }
    if (!zipCode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your zipcode");
      }
      return;
    }
    if (!latitude && !longitude) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Incorrect Address");
      }
      return;
    }
    if (aptValue) {
      data = {
        ...data,
        current_address: {
          ...data.current_address,
          apt: aptValue,
        },
      };
    }
    if (businessWebsite && businessWebsite != "") {
      data = {
        ...data,
        business_website:
          businessWebsite?.split(":")[0] === "https"
            ? businessWebsite
            : `https://${businessWebsite}`,
      };
    }
    if (phoneData?.user_id) {
      data = {
        ...data,
        user_id: phoneData?.user_id,
      };
    }
    setLoading(true);
    dispatch(sigupApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.success(obj?.msg);
        // }
        handleOpenModal("SuccessFullyCreated")
        setTimeout(() => {
          history.push("/connect-bank-account");
        }, 4000)
      })
      .catch((obj) => {
        setLoading(false);
      });
  };
  
  return (
    <>
      <div className="chooseRoles newRole_">
        <div className="commonLogin">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-4 ">
                <div className="loginhorrizontal-form ">
                  <div className="row text-center">
                    <div className="headsignup_">
                      <h4 className="subheading_ col-md-12 mb-2">
                        Tell us about your business
                      </h4>
                      <p className="para_Text_">Every business is unique. We want to hear about yours.
                        If you registered your business with IRS, make sure the information you submit matches what is on your IRS documents.</p>
                    </div>
                    <div className="tab-signup">
                      <h4 className="deatilsHead">Business Operation </h4>
                      <div className="row">
                        <div className="col-lg-4 ">
                          <div
                            className={`rolesContainer ${activeTab == "manufacturer" ? "active" : ""
                              }`}
                            onClick={() => setActiveTab("manufacturer")}
                          >
                            <img
                              src={manufacture}
                              className="rolesImg mb-2"
                              alt="manufacture"
                            />
                            <img
                              src={manufacture1}
                              className="rolesImgHoverImg rolesHoverImg mb-2"
                              alt="RetailerActive"
                            />
                            <h5 className="mainlightHead mt-2">Manufacturer</h5>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div
                            className={`rolesContainer ${activeTab == "whole_seller" ? "active" : ""
                              }`}
                            onClick={() => setActiveTab("whole_seller")}
                          >
                            <img
                              src={distributor}
                              className="rolesImg mb-2"
                              alt="Distributor"
                            />
                            <img
                              src={distributer1}
                              className="rolesImgHoverImg rolesHoverImg mb-2"
                              alt="RetailerActive"
                            />
                            <h5 className="mainlightHead mt-2">Distributor</h5>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div
                            className={`rolesContainer ${activeTab == "retailer" ? "active" : ""
                              }`}
                            onClick={() => setActiveTab("retailer")}
                          >
                            <img
                              src={retailer}
                              className="rolesImg mb-2"
                              alt="Retailer"
                            />
                            <img
                              src={retailerActive}
                              className="rolesImgHoverImg rolesHoverImg mb-2"
                              alt="RetailerActive"
                            />
                            <h5 className="mainlightHead mt-2">Retailer</h5>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <form
                    autoComplete="nope"
                    className="form_horizontal row mt-4"
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="mb-4 form-group col-md-12">
                      <label className="deatilsHead mb-2">Business Name</label>
                      <input
                        type="text"
                        className="customform-control"
                        name="text"
                        autoComplete="new-password"
                        placeholder="Business Name"
                        value={organisationName}
                        onChange={(e) => setOrganisationName(e.target.value)}
                      />
                      <p className="lightparagrph_text">Your business name is how we’ll identify you on emails, receipts, and messages to customers.</p>
                    </div>
                    <div className="mb-4 form-group col-md-12">
                      <label className="deatilsHead mb-2">Business Type </label>
                      <Multiselect
                        className="signupSelect customform-control"
                        displayValue="value"
                        options={
                          activeTab == "manufacturer" ||
                            activeTab == "whole_seller"
                            ? optionsTwo
                            : optionsOne
                        }
                        hidePlaceholder={false}
                        placeholder="Business Type"
                        onSelect={handleSelect}
                        onRemove={handleSelect}
                        disable={!activeTab}
                        showCheckbox
                      />
                      <p className="lightparagrph_text">Business type as Product or Service or Both</p>
                    </div>
                    <div className="mb-4 form-group col-md-12">
                      <label className="deatilsHead mb-2">
                        Business Industry
                      </label>
                      <Multiselect
                        className="signupSelect customform-control"
                        displayValue="name"
                        options={serviceList}
                        hidePlaceholder={true}
                        placeholder="Business Industry"
                        onSelect={handleOperatingIndustryChange}
                        onRemove={handleOperatingIndustryChange}
                        showCheckbox
                      />
                      <p className="lightparagrph_text">Business industry such as Apparel, Education, Consumer goods etc.</p>
                    </div>
                    {/* 
                  <div className="mb-4 form-group col-md-12">
                    <label className="deatilsHead mb-2">Operations</label>
                    <select
                      autoComplete="new-password"
                      className="customform-control select"
                      value={operation}
                      onChange={(e) => setOperations(e.target.value)}
                    >
                      <option value="operations" disabled>
                        Operations
                      </option>
                      <option value="0">Physical</option>
                      <option value="1">Online</option>
                      <option value="2">Both</option>
                    </select>
                  </div> */}

                    <div className="mb-4 form-group col-md-12">
                      <label className="deatilsHead mb-2">Sales Channel</label>
                      <select
                        autoComplete="new-password"
                        className="customform-control select"
                        value={operation}
                        onChange={(e) => setOperations(e.target.value)}
                      >
                        <option value="operations" disabled>
                          Sales Channel
                        </option>
                        <option value="0">Physical</option>
                        <option value="1">Online</option>
                        <option value="2">Both</option>
                      </select>
                      <p className="lightparagrph_text">Sales Channel as Physical or Online or Both</p>
                    </div>
                    <div className="mb-4 form-group col-md-12">
                      <label className="deatilsHead mb-3">Street Address</label>
                      <div className="inputGroup">
                        {hide ? (
                          <span
                            className="customform-control countrytags_ w-100"
                            onClick={() => setHide(!hide)}
                          >
                            {displayValue}
                          </span>
                        ) : (
                          <PlacesAutocomplete
                            className=""
                            autoComplete="off"
                            value={formattedAddress}
                            onChange={autoCompleteHandleChange}
                            onSelect={autoCompleteHandleSelect}
                            searchOptions={{
                              componentRestrictions: {
                                country: ["us", "ind"],
                              },
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Street Address",
                                    className:
                                      "location-search-input customform-control countrytags_",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#41b6e6",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={index}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        )}
                      </div>
                    </div>
                    <div className="mb-4 form-group col-md-12">
                      <label className="deatilsHead mb-3">Phone Number</label>
                      <div style={{ display: "flex" }}>
                        <PhoneInput
                          className=""
                          enableSearch={true}
                          name={generateRandomName()}
                          autoComplete="new-password"
                          country="us"
                          value={phoneCode + phoneNo}
                          disabled
                          placeholder="Phone no."
                        // onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                        />

                      </div>
                    </div>
                    <div className="mb-4 form-group col-md-6">
                      <label className="deatilsHead mb-3">Apt.</label>
                      <input
                        className="customform-control id_password"
                        placeholder="Apt."
                        name={generateRandomName}
                        autoComplete="new-password"
                        type="text"
                        value={aptValue}
                        onChange={(e) => setAptValue(e.target.value)}
                      />
                    </div>
                    <div className="mb-4 form-group col-md-6">
                      <label className="deatilsHead mb-3">Country</label>
                      <input
                        className="customform-control id_password"
                        placeholder="Country"
                        name={generateRandomName}
                        autoComplete="new-password"
                        type="text"
                        value={countryValue}
                        onChange={(e) => setCountryValue(e.target.value)}
                      />
                      {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                    <div className="mb-4 form-group col-md-6">
                      <label className="deatilsHead mb-3">State</label>
                      <input
                        className="customform-control id_password"
                        placeholder="State"
                        name={generateRandomName}
                        autoComplete="new-password"
                        type="text"
                        value={stateValue}
                        onChange={(e) => setStateValue(e.target.value)}
                      />
                      {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                    <div className="mb-4 form-group col-md-6">
                      <label className="deatilsHead mb-3">City</label>
                      <input
                        className="customform-control id_password"
                        placeholder="City"
                        name={generateRandomName}
                        autoComplete="new-password"
                        type="text"
                        value={cityValue}
                        onChange={(e) => setCityValue(e.target.value)}
                      />

                      {/* <span className="toggle_password_ info_icon"><span className="hide-icon togglePassword" id=""></span></span> */}
                    </div>
                    <div className="mb-4 form-group col-md-6">
                      <label className="deatilsHead mb-3">Zip code</label>
                      <input
                        type="number"
                        className="customform-control"
                        name={generateRandomName}
                        autoComplete="new-password"
                        placeholder="Zip code"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </div>
                    {/* <div className="mb-4 form-group col-md-12">
                      <label className="deatilsHead mb-3">
                        Business website{" "}
                        <span className="optionData_">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="customform-control"
                        placeholder="e.g. jobr.com"
                        value={businessWebsite}
                        name={generateRandomName}
                        autoComplete="new-password"
                        onChange={(e) => setBusinessWebsite(e.target.value)}
                      />
                    </div> */}
                    <div className="mb-4 form-group col-md-12">
                      <input
                        type="checkbox"
                        id="register"
                        autoComplete="new-password"
                        name="defaultAddress"
                        checked={defaultAddress}
                        onChange={(e) => setDefaultAddress(!defaultAddress)}
                      />
                      <label
                        htmlFor="register"
                        className="ms-2 mainlightPhragraph"
                      >
                        Set as default address
                      </label>
                      <br />
                    </div>
                    <div className="col-md-12 mt-5">
                      <button
                        className={`loginBtnSign w-100 ${activeTab &&
                          organisationName &&
                          selectedOptions?.length > 0 &&
                          operatingIndustry &&
                          operation != "operations"
                          ? "buttonActive"
                          : ""
                          }`}
                        type="submit"
                        disabled={
                          !(
                            activeTab &&
                            organisationName &&
                            selectedOptions?.length > 0 &&
                            operatingIndustry &&
                            operation != "operations"
                          )
                        }
                      >
                        <span>Next</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={false}
        mediumWidth={false}
        ids={
          modalDetail.flag === "SuccessFullyCreated"
            ? "SuccessFullyCreated"
            : modalDetail.flag === ""

        }
        child={
          modalDetail.flag === "SuccessFullyCreated" ? (
            <SuccessFullyCreated 
            tittle="Successfully created"
            handleClose={() => handleOnCloseModal()} />
          ) : ""
        }
        header={""}
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ChooseRoles;
