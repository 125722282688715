import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../utilities/images";

const Onboarding = (props) => {
  const history = useHistory();

  return (
    <>
      <div className="productPluginSection productAddingOption_Section d-flex align-items-center justify-content-center">
        <button
          className="createOfferCloseBtn d-flex previousPageBtn "
          onClick={() => history.goBack()}
        >
          <img
            src={Images.backArrow}
            alt="logo"
            className="closeCrossIcon me-1 "
          />
          Back
        </button>

        <button
          className="createOfferCloseBtn previousPageBtn backCloseBtn  d-flex gap-2"
          onClick={() => history.goBack()}
        >
          <img src={Images.modalCross} alt="logo" className="" />
        </button>

        <div className="productPluginsSection text-center w-100">
          <div className="container">
            <div className="row pb-3">
              <div className="col-12">
                <h1 className="fontSize36 fw400 txtBlue">
                  Welcome to JOBR, Your new{" "}
                  <span className="fw700">
                    Business <br /> Solutions Platform
                  </span>
                </h1>
                <p className="fontSize20 txtBlue">
                  To get the best experience, we recommend setting up following
                  steps. This is necessary!
                </p>
              </div>
            </div>
            <div className="optionBoxesParent">
              <div className="row justify-content-center">
                <div className="col-md-10 d-flex justify-content-end">
                  <Accordion className="commonAccordion w-100" flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="accordionHeader">
                            <figure className="accordionItemImg">
                              <img src={Images.jobrB2bIcon}  alt="jobbr"/>
                            </figure>
                            <div>
                              <h3 className="accordionItemHead mb-1">
                                Add Products or Services{" "}
                              </h3>
                              <p className="accordionItemPara">
                                Configure your wallet for financial tracking
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <button className="accordionLearMoreBtn" 
                            >
                              <h3 className="fontSize16 mb-0 txtDarkblue">
                                Learn more
                              </h3>
                              <img src={Images.accordionDownArrow} alt="img" />
                            </button>
                            <button
                            onClick={() => history.push("/product-adding-option")}
                            className="commonBlueBtn active gap-2">
                              Add Product/ Service
                              <img
                                src={Images.arrowTopRight}
                                alt="img"
                                className="lightUpRightImg"
                              />
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        The following disclaimer is intended to outline
                        important information and limitations regarding online
                        sales on our website. By engaging in online transactions
                        and making purchases through our platform, you
                        acknowledge and agree to the terms and conditions set
                        forth in this disclaimer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="accordionHeader">
                            <figure className="accordionItemImg">
                              <img src={Images.deliverLogo} alt="driver"/>
                            </figure>
                            <div>
                              <h3 className="accordionItemHead mb-1">
                                Setup Delivery, Shipping, Pickup
                              </h3>
                              <p className="accordionItemPara">
                                Configure your wallet for financial tracking
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <button className="accordionLearMoreBtn">
                              <h3 className="fontSize16 mb-0 txtDarkblue">
                                Learn more
                              </h3>
                              <img src={Images.accordionDownArrow} alt="img" />
                            </button>
                            <button className="commonBlueBtn gap-2">
                              Configure Now
                              <img
                                src={Images.arrowTopRight}
                                alt="img"
                                className="lightUpRightImg"
                              />
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        The following disclaimer is intended to outline
                        important information and limitations regarding online
                        sales on our website. By engaging in online transactions
                        and making purchases through our platform, you
                        acknowledge and agree to the terms and conditions set
                        forth in this disclaimer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="accordionHeader">
                            <figure className="accordionItemImg">
                              <img src={Images.walletLogo} alt="jobbr"/>
                            </figure>
                            <div>
                              <h3 className="accordionItemHead mb-1">
                                Configure JBR Business Wallet
                              </h3>
                              <p className="accordionItemPara">
                                Configure your wallet for financial tracking
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <button className="accordionLearMoreBtn">
                              <h3 className="fontSize16 mb-0 txtDarkblue">
                                Learn more
                              </h3>
                              <img src={Images.accordionDownArrow} alt="img" />
                            </button>
                            <button className="commonBlueBtn gap-2">
                              Configure Now
                              <img
                                src={Images.arrowTopRight}
                                alt="img"
                                className="lightUpRightImg"
                              />
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        The following disclaimer is intended to outline
                        important information and limitations regarding online
                        sales on our website. By engaging in online transactions
                        and making purchases through our platform, you
                        acknowledge and agree to the terms and conditions set
                        forth in this disclaimer.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="accordionHeader">
                            <figure className="accordionItemImg">
                              <img src={Images.lightCalenderLogo} alt="jobbr"/>
                            </figure>
                            <div>
                              <h3 className="accordionItemHead mb-1">
                                Choose Your Plan
                              </h3>
                              <p className="accordionItemPara">
                                Configure your wallet for financial tracking
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <button className="accordionLearMoreBtn">
                              <h3 className="fontSize16 mb-0 txtDarkblue">
                                Learn more
                              </h3>
                              <img src={Images.accordionDownArrow} alt="img" />
                            </button>
                            <button className="commonBlueBtn gap-2">
                              Choose Plan
                              <img
                                src={Images.arrowTopRight}
                                alt="img"
                                className="lightUpRightImg"
                              />
                            </button>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        The following disclaimer is intended to outline
                        important information and limitations regarding online
                        sales on our website. By engaging in online transactions
                        and making purchases through our platform, you
                        acknowledge and agree to the terms and conditions set
                        forth in this disclaimer.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
            <p className="allRightTxt mb-0">All Rights Reserved by JOBR LLC | 2022</p>
            <button className="commonBorderBtn helpCenterBtn gap-2">
              Help Center <img src={Images.upArrow} alt="img" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
