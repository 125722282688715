import { forwardRef } from "react";
import * as Images from "../../../../utilities/images";
import moment from "moment-timezone";

// Use forwardRef at the component level
const DatePickerCustomComponent = forwardRef(({ startDate, value, onClick }, ref) => {
    return (
        <div className="date-picker-container" onClick={onClick}>
            <img className="calendar-icon" src={Images.leftArrow} alt="Calendar" />
            <p className="date-text">
                {startDate ? moment.utc(startDate).format("MMMM YYYY") : "Select Date"}
            </p>
            <img
                className="arrow-icon"
                src={Images.newRightArrow}
                alt="Dropdown Arrow"
            />
        </div>
    );
});

export default DatePickerCustomComponent;
