import React, { useState } from "react";
import * as Images from "../../../utilities/images";
import ReactSelect from "react-select";

const EditSalePriceModal = (props) => {
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const handleDeliveryOptionToggle = (option) => (event) => {
    const isChecked = event.target.checked;

    setDeliveryOptions((prevOptions) => {
      if (isChecked) {
        return [...prevOptions, option];
      } else {
        return prevOptions.filter((opt) => opt !== option);
      }
    });
  };
  const resultSelectOption = [
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "150", label: "150" },
    { value: "200", label: "200" },
  ];

  return (
    <div>  
      <div className="outerArea EditVariation_ModalBox editSaleModalBox">
        <div className="reactSelectParent editSalePriceSelect  position-relative w-100">
          <label className="fontSize16 txtDarkblue mb-2 ps-3">
            Apply Sale Price by
          </label>
          <ReactSelect
            options={resultSelectOption}
            placeholder="Amount"
            classNamePrefix="react-select"
            className="react-select-container anlyticSelect"
            styles={{
              control: (provided) => ({
                ...provided,
                maxWidth: "100% !important",
                width: "100%",
              }),
              menu: (provided) => ({
                ...provided,
                border: "1px solid #D7DEFF",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "#263682"
                  : state.isFocused
                  ? "#f5f6fc"
                  : "#fff",

                color: state.isSelected ? "#fff" : "#263682",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "#636E9F",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }),
            }}
            components={{
              DropdownIndicator: () => (
                <img
                  src={Images.selectImg}
                  width={24}
                  height={24}
                  alt="drop_icon"
                  className="mt-1"
                />
              ),
            }}
          />
          <span className="reactSelectDollar">$</span>
        </div>
        <div className="reactSelectParent editSalePriceSelect  position-relative w-100">
          <label className="fontSize16 txtDarkblue mb-2 ps-3">
          New Sales Price Amount
          </label>
          <ReactSelect
            options={resultSelectOption}
            placeholder="0.00"
            classNamePrefix="react-select"
            className="react-select-container anlyticSelect"
            styles={{
              control: (provided) => ({
                ...provided,
                maxWidth: "100% !important",
                width: "100%",
              }),
              menu: (provided) => ({
                ...provided,
                border: "1px solid #D7DEFF",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "#263682"
                  : state.isFocused
                  ? "#f5f6fc"
                  : "#fff",

                color: state.isSelected ? "#fff" : "#263682",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "#636E9F",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }),
            }}
            components={{
              DropdownIndicator: () => (
                <img
                  src={Images.selectImg}
                  width={24}
                  height={24}
                  alt="drop_icon"
                  className="mt-1"
                />
              ),
            }}
          />
          <span className="reactSelectDollar">$</span>
          <span className="reactSelectDollar reactSelectUsd">USD</span>
        </div>
      </div>
      <div className="outerArea EditVariation_ModalBox mb-0">
        <h3 className="fontSize18 fw500 txtDarkblue">
          Updated Sales Price will effect on
        </h3>

        <div className="productAvail_Second">
          <div className="d-flex align-items-center">
            <img
              src={Images.physicalStoreIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input type="checkbox" checked={true} />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">Physical store</h3>
                <h3 className="fontSize12 txtLightBlue mb-0">JOBR POS</h3>
              </div>
            </div>
          </div>
          <div className="productAvailOption_Wrapper">
            <div className="productAvailabilityChild mt-3">
              <div className="form-group">
                <input
                  type="checkbox"
                  id={`store2`}
                  // checked={sellerAddressIds.includes(store.id)}
                  // onChange={handleStoreToggle(store.id)}
                />
                <label htmlFor={`store2`}></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 pb-1">Store 1</h3>
                <p className="fontSize12 txtLightBlue mb-0">
                  88070 Torey Gateway Colorado Springs, CO 80924
                </p>
              </div>
            </div>
            <div className="productAvailabilityChild mt-3">
              <div className="form-group">
                <input
                  type="checkbox"
                  id={`store1`}
                  // checked={sellerAddressIds.includes(store.id)}
                  // onChange={handleStoreToggle(store.id)}
                />
                <label htmlFor={`store1`}></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 pb-1">Store 2</h3>
                <p className="fontSize12 txtLightBlue mb-0">
                  88070 Torey Gateway Colorado Springs, CO 80924
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="jobrMarketBox">
          <div className=" d-flex align-items-center">
            <img
              src={Images.jobrMarketplaceIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input type="checkbox" checked={true} />
              </div>
              <div>
                <h3 className="fontSize14 fw500 txtDarkblue">
                  JOBR Marketplace
                </h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Online fulfillment methods
                </h3>
              </div>
            </div>
          </div>
          <div className="productAvailOption_Wrapper">
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="pickup"
                  checked={deliveryOptions.includes("pickup")}
                  onChange={handleDeliveryOptionToggle("pickup")}
                />
                <label for="pickup"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Pickup</h3>
              </div>
            </div>
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="delivery"
                  checked={deliveryOptions.includes("delivery")}
                  onChange={handleDeliveryOptionToggle("delivery")}
                />
                <label for="delivery"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Delivery</h3>
              </div>
            </div>
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="shipping"
                  checked={deliveryOptions.includes("shipping")}
                  onChange={handleDeliveryOptionToggle("shipping")}
                />
                <label for="shipping"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Shipping</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="jobrMarketBox">
          <div className=" d-flex align-items-center">
            <img
              src={Images.jobrB2bIcon}
              alt="logo"
              className="physicalStoreIcon me-3"
            />
            <div className="innnerParent">
              <div className="productSale_Parent p-0 m-0">
                <input type="checkbox" checked={true} />
              </div>
              <div>
                <h3 className="fontSize14 fw500 uncheckTxt">JOBR B2B</h3>
                <h3 className="fontSize12 txtLightBlue mb-0">
                  Online fulfillment methods
                </h3>
              </div>
            </div>
          </div>
          <div className="productAvailOption_Wrapper">
            <div className="productAvailabilityChild mt-3 align-items-center">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="shipping"
                  checked={deliveryOptions.includes("shipping")}
                  onChange={handleDeliveryOptionToggle("shipping")}
                />
                <label for="shipping"></label>
              </div>
              <div>
                <h3 className="fontSize14 txtDarkblue mb-0 ">Shipping</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
          className="modalDiscardBtn w-100"
          onClick={() => props?.close()}
        >
          Discard
        </button>
        <button
          className="modalNextBtn modalDiscardBtn active w-100"
          type="button"
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditSalePriceModal;
