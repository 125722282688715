import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Stepper } from "react-form-stepper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Images from "../../../utilities/images";
import CreateServiceOfferDetails from "./CreateServiceOfferDetails";
import CreateServiceOfferLocation from "./CreateServiceOfferLocation";
import CreateServiceOfferPublish from "./CreateServiceOfferPublish";

const CreateServiceOffer = () => {
  const [activeStep, setActiveStep] = useState(1);
  const history = useHistory();

  console.log(activeStep, "activstep");
  // Handle step changes
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleNext = () => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, 3));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const renderStepComponent = () => {
    switch (activeStep) {
      case 1:
        return <CreateServiceOfferDetails />;
      case 2:
        return <CreateServiceOfferLocation />;
      case 3:
        return <CreateServiceOfferPublish />;
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <div className="productDetailsWrapper createOfferSection">
      <div className="container-fluid h-100 p-0">
        <div className="row h-100">
          <div className="col-md-12">
            <div className="walletStats">
              <div className="createOfferHeader">
                <div className="row align-items-center">
                  <div className="col-10">
                    <h3 className="fontSize20 fw500 txtDarkblue">
                      Create Offer
                    </h3>
                    <p className="fontSize16 fw500 txtDarkblue mb-0">
                      Select one Service and offer price wont be less than cost
                      price.
                    </p>
                  </div>
                  <div className="col-2 text-end">
                    <button
                      className="createOfferCloseBtn"
                      onClick={() => history.push("/serviceOffer")}
                    >
                      Close
                      <img
                        src={Images.lightCrossIcon}
                        alt="logo"
                        className="closeCrossIcon ms-1"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="createOfferContainer">
                <div
                  className={
                    activeStep == 3
                      ? "row h-100 justify-content-center"
                      : "row h-100"
                  }
                >
                  <div
                    className={
                      activeStep == 3 ? "col-xl-8 col-lg-10 col-12" : "col-md-8"
                    }
                  >
                    <div className="createOfferLeft">
                      <div className="stepperParent">
                        <Stepper
                          activeStep={activeStep}
                          onStepChange={handleStepChange}
                          steps={[
                            {
                              label: "Service & Pricing",
                            },
                            { label: "Locations" },
                            { label: "Publish" },
                          ]}
                          disabledColor="#E4EBF1"
                          activeColor="red !important"
                        />
                      </div>

                      <div className="offerDetailBox createOffer_DetailBox">
                        {renderStepComponent()}

                        <div className="createOfferBtnsParent editVariantModal_Footer">
                          <button
                            className="modalDiscardBtn w-auto fontSize14"
                            onClick={handleBack}
                          >
                            <img
                              src={Images.backArrow}
                              alt="logo"
                              className="me-2"
                            />
                            Discard
                          </button>
                          <button
                            className="modalNextBtn modalDiscardBtn w-auto"
                            onClick={handleNext}
                          >
                            Next
                            <img
                              src={Images.arrowTopRight}
                              alt="logo"
                              className="ms-2"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {activeStep !== 3 && (
                    <div className="col-md-4">
                      <div className="createOfferRight">
                        <h3 className="fontSize24 fw500 txtBlue mb-0">
                          Service Preview
                        </h3>
                        <div className="productPreviewBox text-center">
                          <img
                            src={Images.productItemImg}
                            alt="img"
                            className="offerProductImg"
                          />
                          <h4 className="fontSize20 fw500 txtDarkblue mb-0 pt-4">
                            Lightweight Stylish Casual Daypack
                          </h4>

                          <div className="d-flex align-items-center justify-content-center gap-2 pt-3">
                            <span className="greyDot"></span>
                            <h6 className="greyDotLabel mb-0">
                              SKU 0199 - 3221
                            </h6>
                          </div>
                        </div>

                        <div>
                          <h3 className="fontSize16 fw500 txtDarkblue">
                            Product details
                          </h3>
                          <div className="row">
                            <div className="col-6">
                              <h3 className="productPreview_Detailtxt">
                                Category
                              </h3>
                            </div>
                            <div className="col-6">
                              <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                Grocery
                              </h3>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <h3 className="productPreview_Detailtxt">
                                Sub-category
                              </h3>
                            </div>
                            <div className="col-6">
                              <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                Bag
                              </h3>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <h3 className="productPreview_Detailtxt">SKU</h3>
                            </div>
                            <div className="col-6">
                              <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                7044085C
                              </h3>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <h3 className="productPreview_Detailtxt">
                                Barcode
                              </h3>
                            </div>
                            <div className="col-6">
                              <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                003 01008 9999 0233
                              </h3>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <h3 className="productPreview_Detailtxt">
                                Unit Weight
                              </h3>
                            </div>
                            <div className="col-6">
                              <h3 className="productPreview_Detailtxt ps-0 pe-0">
                                800g
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateServiceOffer;
