import { unwrapResult } from "@reduxjs/toolkit";
import { CKEditor } from "ckeditor4-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import * as Images from "../../../utilities/images";
import { selectLoginAuth } from "../auth/authSlice";
import {
  getBrandOfCategoryAsync,
  getCategoriesAsync,
  getServicesAsync,
} from "../dashboard/dashboardSlice";

const DigitalProductDetails = (props) => {
  console.log(props, "props");
  const dispatch = useDispatch();
  let productResponse;
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [typedValue, setTypedValue] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = useSelector(selectLoginAuth);
  const [services, setServices] = useState([]);

  const [servicesId, setServicesId] = useState("");
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryId, setSubcategoryId] = useState("");
  const [brand, setBrand] = useState([]);
  console.log(brand, "brand");
  const [brandId, setBrandId] = useState("");
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
  const [brandLoading, setBrandLoading] = useState(false);
  const [barCode, setBarCode] = useState("");
  const [upc, setUpc] = useState("");

  const [dimensions, setDimensions] = useState({
    weight: "",
    length: "",
    breadth: "",
    height: "",
  });

  const handleServicesChange = (e) => {
    setCategoryLoading(true);
    setSelectedService(e);
    setServicesId(e.value);
    props?.setServiceIdforAttributes(e.value);
    setSelectedSubCategory("");
    setSelectedBrand("");
    setSelectedCategory("");
    setCategory([]);
    setCategoryId("");
    setSubCategory([]);
    setSubcategoryId("");
    setBrand([]);
    setBrandId("");
    setSelectedBrand("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        service_id: e.value,
        main_category: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setCategory(filterResult);
        setCategoryLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: e.value,
          category_id: categoryId,
          sub_category_id: subCategoryId,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleCategoryChange = (e) => {
    setSubCategoryLoading(true);
    setSelectedCategory(e);
    setCategoryId(e.value);
    setSubCategory([]);
    setSubcategoryId("");
    setSelectedSubCategory("");
    setSelectedBrand("");
    setBrand([]);
    setBrandId("");
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.value,
        // main_category: true,
        need_subcategory: true,
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getCategoriesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setSubCategory(filterResult);
        setSubCategoryLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: servicesId,
          category_id: e.value,
          sub_category_id: subCategoryId,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleSubCategoryChange = (e) => {
    setBrandLoading(true);
    setSelectedSubCategory(e);
    setSubcategoryId(e.value);
    setBrand([]);
    setBrandId("");
    setSelectedBrand("");
    let brandData = {
      tokenData: auth?.payload?.token,
      postData: {
        category_id: e.value,
        check_product_existance: false,
      },
    };
    dispatch(getBrandOfCategoryAsync(brandData))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setBrand(filterResult);
        setBrandLoading(filterResult ? false : true);
        productResponse = {
          productName: productName,
          service_id: servicesId,
          category_id: categoryId,
          sub_category_id: e.value,
          brand_id: brandId,
        };
        props.productDetails(productResponse, "");
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleBrandChange = (e) => {
    setBrandLoading(true);
    setSelectedBrand(e);
    productResponse = {
      productName: productName,
      service_id: servicesId,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      brand_id: e.value,
    };
    props.productDetails(productResponse, "");
    setBrandId(e.value);
  };

  const handleProductDescription = (e) => {
    props.productDetails(e, "description");
    setProductDescription(e);
  };

  const getServices = () => {
    let data = {
      tokenData: auth?.payload?.token,
      postData: {
        type: "product",
        check_product_existance: false,
      },
    };
    setLoading(true);
    dispatch(getServicesAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false);
        let filterResult = obj?.payload?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setServices(filterResult);
      })
      .catch((obj) => {
        setLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const newValue = event.target.value.trim();
      if (newValue !== "") {
        // Check if the entered value already exists
        const existingOption = brand?.find(
          (option) => option.label.toLowerCase() === newValue.toLowerCase()
        );
        if (!existingOption) {
          const newOption = { value: newValue.toLowerCase(), label: newValue };
          if (brand?.length > 0) {
            setBrand([...brand, newOption]);
          } else {
            setBrand([newOption]);
          }
        }
      }
    }
  };

  const handleKeyDownSubcategory = (event) => {
    console.log("called");
    if (event.key === "Enter") {
      const newValue = event.target.value.trim();
      if (newValue !== "") {
        // Check if the entered value already exists
        const existingOption = subCategory?.find(
          (option) => option.label.toLowerCase() === newValue.toLowerCase()
        );
        if (!existingOption) {
          const newOption = { value: newValue.toLowerCase(), label: newValue };
          if (subCategory?.length > 0) {
            setSubCategory([...subCategory, newOption]);
          } else {
            setSubCategory([newOption]);
          }
        }
      }
    }
  };

  useEffect(() => {
    getServices();
  }, []);
  useEffect(() => {
    setSelectedService(selectedService);
  }, [selectedService]);

  return (
    <>
      <div className="productDetailsContainer">
        {/* Start ProductName Area */}
        <h2 className="fontSize18 fw500 txtDarkblue">
          Digital product details
        </h2>
        <form className="productName">
          <div className="row">
            <div className="col-md-12 ">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Product Name
                </label>
                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Product name"
                    name="text"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  Barcode (ISBN, UPC, GTIN, etc.)
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>
                <div className="inputGroup">
                  <input
                    className="customInput"
                    maxLength={14}
                    placeholder="Optional"
                    name="barcode"
                    type="number"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_group">
                <label className="fontSize16 txtDarkblue mb-2 ps-4">
                  UPC (Unique product code)
                  <img src={Images.labelImg} alt="logo" className="ms-2" />
                </label>

                <div className="inputGroup">
                  <input
                    className="customInput"
                    placeholder="Optional"
                    name="weight"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* End ProductName Area */}
        {/* Start ProductCategory Area */}
        <form className="ProductCategory mt-4 mb-4">
          <h2 className="fontSize18 fw500 txtDarkblue mb-0">
            Service category
          </h2>
          <div className="row">
            <div className="col-md-6">
              <div className="form_group">
                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    Master Category
                  </label>
                  <ReactSelect
                    value={selectedService}
                    options={services}
                    onChange={handleServicesChange}
                    isSearchable={true}
                    placeholder="Select one"
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_group">
                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    Category
                  </label>
                  <ReactSelect
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    options={category}
                    isDisabled={!servicesId}
                    isSearchable={true}
                    placeholder={
                      category && categoryLoading
                        ? "Loading Categories..."
                        : "Select one"
                    }
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_group">
                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    Subcategory
                  </label>
                  <ReactSelect
                    value={selectedSubCategory}
                    onChange={handleSubCategoryChange}
                    onKeyDown={handleKeyDownSubcategory}
                    options={subCategory}
                    isDisabled={!categoryId}
                    isSearchable={true}
                    placeholder={
                      subCategory && subCategoryLoading
                        ? "Loading Categories..."
                        : "Select one"
                    }
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_group">
                <div className="reactSelectParent commonSelectParent w-100">
                  <label className="fontSize16 txtDarkblue mb-2 ps-4">
                    Brand
                  </label>
                  <ReactSelect
                    value={selectedBrand}
                    onChange={handleBrandChange}
                    onKeyDown={handleKeyDown}
                    options={brand}
                    isDisabled={!subCategoryId}
                    isSearchable={true}
                    placeholder={
                      brand && brandLoading ? "Loading Brand..." : "Select one"
                    }
                    classNamePrefix="react-select"
                    className="react-select-container anlyticSelect"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxWidth: "100% !important",
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        border: "1px solid #D7DEFF",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        paddingLeft: "unset !important",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                          ? "#263682"
                          : state.isFocused
                          ? "#f5f6fc"
                          : "#fff",

                        color: state.isSelected ? "#fff" : "#263682",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#636E9F",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }),
                    }}
                    components={{
                      DropdownIndicator: () => (
                        <img
                          src={Images.selectImg}
                          width={24}
                          height={24}
                          alt="drop_icon"
                          className="mt-1"
                        />
                      ),
                    }}
                  />
                  {/* {!brand && subCategoryId && (
                    <p className="messageTxt ps-4">
                      No options available. Please type a new brand and press
                      Enter to create one.
                    </p>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* End ProductCategory Area */}
        {/* Start CK EditorArea */}
        <div>
          <label className="fontSize16 txtDarkblue ps-4 ">Description</label>
          <div className="productDetailsSpc">
            <CKEditor
              initData={productDescription}
              config={{
                versionCheck: false,
                removePlugins: [
                  "EasyImage",
                  "ImageUpload",
                  "MediaEmbed",
                  "Table",
                  "image",
                ],
              }}
              name="editor"
              readOnly={false}
              onChange={(event, editor) => {
                const data = event.editor.getData();
                handleProductDescription(data);
              }}
              type="classic"
            />
            <button className="ckEditorBtn">
              <img src={Images.ckBtnLogo} alt="logo" /> Generate AI Text{" "}
            </button>
          </div>
        </div>
        {/* End CK EditorArea */}
      </div>
    </>
  );
};

export default DigitalProductDetails;
