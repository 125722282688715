export const UserHeaderStatisticsArr = [
  {
    heading: "New Customers",
    key: "rescheduled",
    img: "newCustomerLogo",
    className: "transectionsBox",
  },
  {
    heading: "Returning Customers",
    key: "total_booking",
    img: "returningCustomerLogo",
    className: "totalProductBox",
  },
  {
    heading: "Online Customers",
    key: "fulfillment",
    img: "onlineCustomerLogo",
    className: "totalBrandBox ",
  },

  {
    heading: "Walk-in Customers",
    key: "cancelled",
    img: "walkInCustomerLogo",
    className: "totalSubCategoryBox",
  },
];
export const options1 = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      align: "start",
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        pointStyle: "circle",
        usePointStyle: true,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
