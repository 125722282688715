import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginAuth } from '../auth/authSlice';
import { getAnalyticStatDataApiAsync } from '../dashboard/dashboardSlice';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import moment from 'moment-timezone';
import HomePageInnerHeader from '../dashboard/HomePageInnerHeader';
import * as Images from "../../../utilities/images";
import ReactSelect from "react-select";
import AnalyticsHeader from './AnalyticsHeader';
import DataTable from 'react-data-table-component';
import { addThousandSeparator } from '../../../utilities/helpers';


const GrossProfits = () => {
    const dispatch = useDispatch();
    const today = new Date();
    const auth = useSelector(selectLoginAuth);
    const [filterValue, setFilterValue] = useState("week");
    const [channelFilter, setChannelFilter] = useState("all")
    const [analyticsProfitData, setAnalyticsProfitsData] = useState("")
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    console.log({ analyticsProfitData, perPage })

    const handelPerPageResult = (e) => {
        setPerPage(e?.value)
    }

    const handlePageChange = (code) => {
        if (code === "+") {
            // setPerPage((prev => prev > 0 ? prev + 10 : prev))
            setCurrentPage((prev => prev >= 1 ? prev + 1 : prev))
        } else {
            // setPerPage((prev => prev > 10 ? prev - 10 : prev))
            setCurrentPage((prev => prev > 1 ? prev - 1 : prev))
        }

    };

    const resultSelectOption = [
        { value: "10", label: "10" },
        { value: "50", label: "50" },
        { value: "100", label: "100" },
        { value: "150", label: "150" },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Analytics";
    }, []);

    const newUserDataHandle = () => {
        let params = {
            "postData": {
                // is_admin: true,
                filter: filterValue,
                channel: channelFilter,
                page: currentPage,
                limit: perPage,
                // seller_id: "016b1b3a-d7d3-4fc3-a76b-995b23c43852"
                seller_id: auth?.payload?.uniqe_id ? auth?.payload?.uniqe_id : auth?.payload?.user?.unique_uuid

            },
            "tokenData": auth?.payload?.token
        }
        if (startDate && endDate) {
            let newData = {
                ...params.postData,
                "start_date": moment(startDate).format('YYYY-MM-DD'),
                "end_date": moment(endDate).format('YYYY-MM-DD')
            }
            params = {
                tokenData: auth?.payload?.token,
                postData: newData
            }
        }
        setLoading(true)
        dispatch(getAnalyticStatDataApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setAnalyticsProfitsData(obj?.payload)
                setLoading(false)
                setTotalRows(obj?.payload?.orderData?.total)
            })
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        newUserDataHandle()
    }, [filterValue, channelFilter, endDate, currentPage, perPage])

    return (
        <>
            <DashboardNavbar title="Analytics" backShow="/analytics" />
            <div className='analyticGross chartsOuter'>
                <AnalyticsHeader
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setFilterValue={setFilterValue}
                    filterValue={filterValue}
                    setChannelFilter={setChannelFilter}
                    channelFilter={channelFilter}
                    subhead="vs. Same day last week"
                />
                <div className='grossProfitData'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6  pe-2'>
                            <div className='grossTotalorder'>
                                <img src={Images.totalordericon} className='img-fluid grossImages' alt='totalorderImage' />
                                <p className='groSub mt-3 mb-1'>Total Orders</p>
                                <h4 className='groMain'>{analyticsProfitData?.overView?.total_orders}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6  ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={Images.totalvolumeicon} className='img-fluid grossImages' alt='volumeImage' />
                                <p className='groSub mt-3 mb-1'>Total Volume</p>
                                <h4 className='groMain'>${addThousandSeparator(analyticsProfitData?.overView?.transaction ? analyticsProfitData?.overView?.transaction?.toFixed(2) : "0.00")}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 pe-2'>
                            <div className='grossTotalorder'>
                                <img src={Images.averageOrder} className='img-fluid grossImages' alt='analysisImage' />
                                <p className='groSub mt-3 mb-1'>Average Order Value</p>
                                <h4 className='groMain'>{analyticsProfitData?.overView?.average_value ? `$${addThousandSeparator((analyticsProfitData?.overView?.average_value).toFixed(2))}` : "$0.00"}</h4>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 ps-2 '>
                            <div className='grossTotalorder'>
                                <img src={Images.grossgreenIcon} className='img-fluid grossImages' alt='grossprofitimage' />
                                <p className='groSub mt-3 mb-1'>Gross profit</p>
                                <h4 className='groMain'>{analyticsProfitData?.overView?.profit_sum ? `$${addThousandSeparator((analyticsProfitData?.overView?.profit_sum).toFixed(2))}` : "$0.00"}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="productOfferHead_Container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-end gap-3">
                                <div className="d-flex align-items-center gap-2">
                                    <h3 className="fontSize14 txtDarkblue mb-0">
                                        Showing Results
                                    </h3>
                                    <div className="reactSelectParent offerHeaderSelect">
                                        <ReactSelect
                                            options={resultSelectOption}
                                            value={perPage}
                                            placeholder={perPage}
                                            classNamePrefix="react-select"
                                            className="react-select-container anlyticSelect"
                                            onChange={handelPerPageResult}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    minWidth: "75px",
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    border: "1px solid #D7DEFF",
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected
                                                        ? "#263682"
                                                        : state.isFocused
                                                            ? "#f5f6fc"
                                                            : "#fff",

                                                    color: state.isSelected ? "#fff" : "#636E9F",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "normal",
                                                }),
                                                placeholder: (provided) => ({
                                                    ...provided,
                                                    color: "#636E9F",
                                                    fontSize: "14px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "normal",
                                                }),
                                            }}
                                            components={{
                                                DropdownIndicator: () => (
                                                    <img
                                                        src={Images.blueDownArrow}
                                                        width={24}
                                                        height={24}
                                                        alt="drop_icon"
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="paginationParent">
                                    <div className="d-flex align-items-center gap-1" onClick={
                                        () => handlePageChange("-")
                                    }>
                                        <div className="backPageBox ">
                                            <img
                                                src={Images.arrowDoubleLeft}
                                                alt="arrowDoubleRight"
                                            />
                                        </div>
                                        <div className="backPageBox backPageBox2">
                                            <img src={Images.lightArrowLeft} alt="arrowDoubleRight" />
                                        </div>
                                    </div>
                                    <h6 className="fontSize14 txtDarkblue mb-0">
                                        {currentPage}-{analyticsProfitData?.orderData?.data.length} of {analyticsProfitData?.orderData?.data.length}
                                    </h6>
                                    <div className="d-flex align-items-center gap-1" onClick={
                                        () => handlePageChange("+")
                                    }>
                                        <div className="nextPageBox nextPageBox2" >
                                            <img src={Images.blueArrowRight} alt="arrowDoubleRight" />
                                        </div>
                                        <div className="nextPageBox ">
                                            <img
                                                src={Images.arrowDoubleRight}
                                                alt="arrowDoubleRight"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loading ? (
                        <span className="spinner-border inner-spin spinner-border-sm"></span>
                    ) : (
                        <div className="commonTable productTable table-responsive mt-3">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Total Orders</th>
                                        <th>Transaction Volume</th>
                                        <th>Average Order Value</th>
                                        <th>Total Cost</th>
                                        <th>Margin</th>
                                        <th>Gross Profit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {analyticsProfitData?.orderData?.data.length > 0 && (
                                        analyticsProfitData?.orderData?.data.map(
                                            row => (
                                                <tr>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {moment(row?.order_date).format("MM/DD/YYYY")}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {row?.total_orders}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        ${addThousandSeparator((row?.transaction).toFixed(2))}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        ${addThousandSeparator((row?.average_value).toFixed(2))}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        ${addThousandSeparator((row?.cost_sum).toFixed(2))}
                                                    </td>
                                                    <td className="fontSize12 txtLightBlue">
                                                        {`${Math.round(row?.margin)}%`}
                                                    </td>
                                                    <td className="fontSize12 grosshead_">
                                                        ${addThousandSeparator((row?.profit_sum).toFixed(2))}
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default GrossProfits