import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../../dashboard/DashboardNavbar'
import * as Images from "../../../../utilities/images";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import GeneralSetting from './generalSetting';
import StaffManagement from './StaffManagement';
import CustomerNotification from './CustomerNotification';
import AdditionalSetting from './AdditionalSetting';

const BookingSetting = () => {
  const [bookingSetting, setBookingSetting] = useState("General Setting")
  const location = useLocation();

  // Extract query parameters from location.search
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page'); 
 
    /*
    get prams from page and check
    */
useEffect(()=>{
    if(page){
        setBookingSetting("Shipping");
    }
},[])
  return (
    <div className='bookingSetting_'>
        <div className='orderSetting_'>
            <DashboardNavbar title="Booking Settings" backShow={false} />
            <div className='row'>
                <div className='col-md-3'>
                    <div className='orderleft'>
                        {/* <OrdersettingSidebar /> */}
                        <div className="leftsidebarOrder">
                            <ul className='ordersettingmenu_'>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${bookingSetting === "Order Processing" ? "active" : ""}`}
                                        onClick={() => { setBookingSetting("General Setting") }}>
                                        <img className='ordersetting' src={Images.generalSetting} /> General
                                    </a>
                                </li>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${bookingSetting === "Staff Management" ? "active" : ""}`} onClick={() => { setBookingSetting("Staff Management") }}>
                                        <img className='ordersetting' src={Images.staffIcon} /> Staff Management 
                                    </a>
                                </li>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${bookingSetting === "Customer Notifications" ? "active" : ""}`}
                                        onClick={() => { setBookingSetting("Customer Notifications") }}>
                                        <img className='ordersetting' src={Images.orderring} /> Customer Notifications
                                    </a>
                                </li>
                                <li className='listinner_ mb-3'>
                                    <a href='#' className={`listinner_a ${bookingSetting === "Additional Notifications" ? "active" : ""}`}
                                        onClick={() => { setBookingSetting("Additional Notifications") }}>
                                        <img className='ordersetting' src={Images.detailorder} /> Additional Settings
                                    </a>
                                </li>
                                
                            </ul>


                        </div>
                    </div>

                </div>
                <div className='col-md-9'>
                    <div className={`position-relative ${bookingSetting === "General Setting" ? 'additionalsetmain_ rightContentorder_' : ""}`}>
                    {(bookingSetting === "General Setting") && <GeneralSetting />}
                        
                        {(bookingSetting === "Staff Management") && <StaffManagement />}
                        {(bookingSetting === "Customer Notifications") && <CustomerNotification />}
                        {(bookingSetting === "Additional Notifications") && <AdditionalSetting />}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}


export default BookingSetting