import React from 'react';
import { APPOINTMENT_STATUS } from '../../../constants/bookings/allbooking';
import * as imgs from "../../../../utilities/images";

const BookingStatus = ({ handleAppointmentStatus, status,appointmentID }) => {
console.log("Status",status)
  const renderReviewingStatus = () => (
    <div className="checkinBg">
      <button
        className="rejectBtn mr-6"
        type="submit"
        onClick={async () => {
          handleAppointmentStatus(
            appointmentID,
            APPOINTMENT_STATUS.REJECTED_BY_SELLER
          );
        }}
      >
        Decline
      </button>

      <button
        className="acceptBtn"
        type="submit"
        onClick={async () => {
          handleAppointmentStatus(
            appointmentID,
            APPOINTMENT_STATUS.ACCEPTED_BY_SELLER
          );
        }}
      >
        Accept
      </button>
    </div>
  );

  const renderAcceptedBySellerStatus = () => (
    <div className="checkinBg">
      <button
        className="greyBtn w-100 mr-6"
        onClick={() => {
          // Implement check-in logic here if needed
        }}
      >
        Check-in
        <img
          src={imgs?.checkImg}
          alt="money"
          className="moneyImg ml-6"
        />
      </button>

      <button
        className="editBtn"
        onClick={() => {
          // Implement edit logic here if needed
        }}
      >
        <img src={imgs?.editIcon} alt="edit" className="editImg" />
      </button>
    </div>
  );

  const renderCheckedInStatus = () => (
    <button
      className="greyBtn w-100"
      type="submit"
      onClick={() => {
        handleAppointmentStatus(appointmentID, APPOINTMENT_STATUS.COMPLETED);
      }}
    >
      {getCalendarActionButtonTitle(status)}
    </button>
  );

  const renderCompletedStatus = () => (
    <button className="greenBtn" type="submit">
      Completed
      <img
        src={imgs?.company2}
        alt="complete"
        className="completeimg ml-6"
      />
    </button>
  );

  const renderCancelledByCustomerStatus = () => (
    <button className="greyBtn w-100" type="submit">
      {getCalendarActionButtonTitle(status)}
    </button>
  );

  const renderRejectedBySellerStatus = () => (
    <button className="greyBtn w-100" type="submit">
      {getCalendarActionButtonTitle(status)}
    </button>
  );

  // Switch case for different appointment statuses
  switch (status) {
    case APPOINTMENT_STATUS.REVIEWING:
      return renderReviewingStatus();
    case APPOINTMENT_STATUS.ACCEPTED_BY_SELLER:
      return renderAcceptedBySellerStatus();
    case APPOINTMENT_STATUS.CHECKED_IN:
      return renderCheckedInStatus();
    case APPOINTMENT_STATUS.COMPLETED:
      return renderCompletedStatus();
    case APPOINTMENT_STATUS.CANCELLED_BY_CUSTOMER:
      return renderCancelledByCustomerStatus();
    case APPOINTMENT_STATUS.REJECTED_BY_SELLER:
      return renderRejectedBySellerStatus();
    default:
      return <span>Status unknown</span>;
  }
};

const getCalendarActionButtonTitle = (status) => {
  switch (status) {
    case 1:
      return "Check-in";
    case 2:
      return "Mark Completed";
    case 3:
      return "Completed";
    case 4:
      return "Declined";
    case 5:
      return "Cancelled";
    default:
      return "Status unknown";
  }
};

export default BookingStatus;
