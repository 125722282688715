import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Images from "../../../utilities/images";
import ReactSelect from "react-select";

const EditServiceOfferModal = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        <img src={Images.calenderLogo} alt="logo" className="me-2" />
        {value}
      </button>
    )
  );
  const selectOption = [
    { value: "all", label: "All" },
    { value: "pos", label: "Location 1" },
    { value: "b2c", label: "Location 2" },
    { value: "b2b", label: "Location 3" },
  ];
  return (
    <>
      <div>
        <h5 className="fontSize14 fw500 txtDarkblue">Service Name</h5>
        <h2 className="fontSize24 fw500 txtDarkblue mb-0 pt-2">
          Lightweight Stylish Casual Daypack
        </h2>
      </div>
      <div>
        <h2 className="fontSize24 fw500 txtBlue commonPadding mb-0">
          Offer Details
        </h2>
        <h5 className="fontSize18 fw500 txtDarkblue mb-0">Period</h5>
      </div>
      <div className="row commonPadding">
        <div className="col-md-6">
          <div className="datePicker_Parent pt-0 offerModalDatePicker">
            <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
              Start from
            </label>
            <DatePicker
              showIcon
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={
                <ExampleCustomInput className="example-custom-input" />
              }
            />
            <p className="pressEnterTxt pt-2 ps-4 mb-0">Starts at 00:00 AM</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="datePicker_Parent pt-0 offerModalDatePicker">
            <label className="fontSize14 fw500 txtDarkblue ps-4 pb-2">
              Start from
            </label>
            <DatePicker
              showIcon
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={
                <ExampleCustomInput className="example-custom-input" />
              }
            />
            <p className="pressEnterTxt pt-2 ps-4 mb-0">Ends at 11:59 PM</p>
          </div>
        </div>
      </div>
      <div className="periodTxtParent">
        <h5 className="fontSize18 fw500 txtDarkblue commonPadding pt-0 mb-0">
          Pricing
        </h5>
        <div className="editVariant_SecondBox">
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Offer price option</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="reactSelectParent offerModalSelect">
                    <ReactSelect
                      options={selectOption}
                      // value={channelSelected}
                      placeholder="Different Offer Price"
                      classNamePrefix="react-select"
                      className="react-select-container anlyticSelect"
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          border: "1px solid #D7DEFF",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#263682" // Background color for selected option
                            : state.isFocused
                            ? "#f5f6fc" // Background color for hovered option
                            : "#fff", // Default background color
                          // color: state.isSelected || state.isFocused ? '#fff' : '#000', // Text color
                          color: state.isSelected
                            ? "#fff" // Background color for selected option
                            : "#263682", // Text color
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "#636E9F",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0px",
                        }),
                      }}
                      // onChange={onChannelChange}
                      components={{
                        DropdownIndicator: () => (
                          <img src={Images.blueDownArrow} alt="drop_icon" />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Offer type</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox">
                  <div className="reactSelectParent offerModalSelect">
                    <ReactSelect
                      options={selectOption}
                      placeholder="Amount"
                      classNamePrefix="react-select"
                      className="react-select-container anlyticSelect"
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          border: "1px solid #D7DEFF",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#263682"
                            : state.isFocused
                            ? "#f5f6fc"
                            : "#fff",
                          color: state.isSelected ? "#fff" : "#263682",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "#636e9f",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          padding: "0px",
                        }),
                      }}
                      // onChange={onChannelChange}
                      components={{
                        DropdownIndicator: () => (
                          <img src={Images.blueDownArrow} alt="drop_icon" />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">POS price</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox inputUpcBox">
                  <div className="inputGroup">
                    <input
                      className="productVariant_Input"
                      name="upc"
                      type="number"
                      maxLength={14}
                    />
                    <span className="inputDollarSign">$</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="upcBoxParent">
            <div className="row">
              <div className="col-4">
                <div className="upcBox">
                  <h3 className="fontSize18 mb-0">Marketplace price</h3>
                </div>
              </div>
              <div className="col-8">
                <div className="upcBox upcRightBox inputUpcBox">
                  <div className="inputGroup">
                    <input
                      className="productVariant_Input"
                      name="upc"
                      type="number"
                      maxLength={14}
                    />
                    <span className="inputDollarSign">$</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center editVariantModal_Footer">
        <button
          className="modalDiscardBtn w-100"
          onClick={() => {
            props?.close();
          }}
        >
          Discard
        </button>
        <button className={`modalNextBtn modalDiscardBtn active w-100 ${props?.flag == "reActiveServiceOfferModal" ? "reActiveServiceOfferBtn" : ""}`}>
          {props?.flag == "editServiceOfferModal" ? "Update Offer" : "Reactive Offer"} 
        </button>
      </div>
    </>
  );
};

export default EditServiceOfferModal;
