import React from 'react'
import { OrderDown,
    cancleorder,
    incoiceuser_,
    modalCancel,
    pickuporder,
    profileImg1,
    returnlogo,
    serviceuser_,
    unselectedOrder,
    drivefast_,
    upArrow,
    locateOrder,
    clockBlue,
    moneyImg,
    mapimg,
    deliveryMap,warningicon,
    fillradio_,
    verifyorder,
    orderDriver,done,cancelledstep,
    readystep,
    acceptstep,
    openTag,
    closeTag,
    teamMember4} from '../../../../utilities/images';
    import *as Images from '../../../../utilities/images';
import moment from 'moment-timezone';
import { Col, Row } from 'react-bootstrap';
const OrderList = ({showOrderInvoice,orderDetails,setorderIndex,setshowOrderInvoice,orderIndex,setopenOrderDetails}) => {
  return <> 
           <div className="commonTable table-responsive orderTrackTable">
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th
                          onClick={() => {
                            // handleOpenModal("returnToInventoryModal");
                          }}
                        >
                          #
                        </th>
                        <th>Client/Items</th>
                        <th>Driver/Timing</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        orderDetails?.map((order,index)=>(
                          <tr className={`${index===orderIndex?'border':''}`} onClick={()=>{
                            setorderIndex(index)
                          }}>
                        <td className="orderTrackTableTxt">#{order?.invoices?.id}</td>
                        <td>
                          <div>
                            <h3 className="orderTrackTableTxt">
                            {`${order?.user_details?.firstname} ${order?.user_details?.lastname}`}
                            </h3>
                            <h6 className="orderTrackLocation fontSize12 fw500 mb-0">
                              <img
                                src={Images.locateOrder}
                                alt="img"
                                className="location"
                              />
                              {order?.distance} miles
                            </h6>
                          </div>
                          <div className="pt-3">
                            <h3 className="orderTrackTableTxt">{order?.order_details?.length} items</h3>
                            <h6 className="orderMoney orderTrackLocation fontSize12 fw500 mb-0">
                              <img
                                src={Images.moneyIcon_}
                                alt="img"
                                className="location"
                              />
                              ${Number(order?.payable_amount || 0).toFixed(2)}
                            </h6>
                          </div>
                        </td>
                        <td>
                          <div onClick={()=>{
          console.log("show invoice")
        setshowOrderInvoice(false);
        }}>
                            <div className="d-flex align-items-center gap-2 mb-1">
                              <img
                                src={order?.driver_details?.profile_photo||teamMember4}
                                alt="img"
                                className="orderTrackUserImg"
                              />
                              <h3 className="orderTrackTableTxt mb-0">
                              {`${order?.driver_details?.firstname} ${order?.driver_details?.lastname}`}
                              </h3>
                            </div>
                            <h6 className="orderTrackLocation orderTrackingTime fontSize12 fw500 mb-0">
                              <img
                                src={clockBlue}
                                alt="img"
                                className="location"
                              />
                              {order?.delivery_details?.title} 
                            </h6>
                          </div>
                          <div className="pt-3">
                            <h3 className="orderTrackTableTxt">
                              Driver Arrives in:
                            </h3>
                            <h6 className="orderTrackLocation orderTrackingTime fontSize12 fw500 mb-0">
                              <img
                                src={Images.clockBlue}
                                alt="img"
                                className="location"
                              />
                              {moment(order?.estimated_delivery_time).fromNow(true)}
                            </h6>
                          </div>
                        </td>
                        <td>
                          <img
                          onClick={() => {
                            if(!showOrderInvoice)return;
                              setopenOrderDetails(true);
                          }}
                            src={Images.blueArrowTopRight}
                            alt="img"
                            width={16}
                            height={16}
                            className="cursorPointer"
                          />
                        </td>
                      </tr>
                        ))
                      }
                      
                    </tbody>
                  </table>
                </div>
  </>
}

export default OrderList