import React, { useEffect, useState } from 'react'
import * as Images from "../../../../utilities/images";
import ReactSelect from "react-select";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectLoginAuth } from '../../auth/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { getOrderProcessingdataApiAsync, getUserSettingsApiAsync, updateOrderProcessingdataApiAsync, updateUserSettingsApiAsync } from '../../dashboard/dashboardSlice';
import Loader from '../../../components/UI/Loader/Loader';
const OrderProcessing = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(selectLoginAuth);
    const resultSelectOption = [
        { value: 5, label: "5" },
        { value: 10, label: "10" },
        { value: 15, label: "15" },
        { value: 20, label: "20" },
        { value: 25, label: "25" },
        { value: 30, label: "30" },
        { value: 35, label: "35" },
    ];
    const [loading, setLoading] = useState(false);
    const [orderProcessingData, setOrderProcessingData] = useState(null);
const getOrderProcessingData=async()=>{
    let prams={
        postData: {
            app_name:"merchant",
            seller_id:"0abd886e-d6c4-4fe0-8be2-c928f41530ca",
        },
        tokenData: auth?.payload?.token,
    }
setLoading(true);
dispatch(getUserSettingsApiAsync(prams))
.then(unwrapResult)
.then((res)=>{
    setOrderProcessingData(res?.payload);
    setLoading(false);
})
.catch((res)=>{
    setLoading(false);
})
}
useEffect(()=>{
    getOrderProcessingData();
},[])
const updateOrderProcessingData=async(data)=>{
    let prams={
        postData: {
            ...data
        },
        tokenData: auth?.payload?.token,
    }
setLoading(true);
dispatch(updateUserSettingsApiAsync(prams))
.then(unwrapResult)
.then((res)=>{
    setLoading(false);
    // setOrderProcessingData(res.data);
})
.catch((res)=>{
    setLoading(false);
})
}
const handleOrderProcessing=(key,value)=>{
    setOrderProcessingData(prev=>({
        ...prev,[key]:value
    }));
    updateOrderProcessingData({[key]:value});
}

    return (
        <>
            {/* Start Order Processing */}
            
               {loading&&
                <Loader/>
               }
            <div className='orderProcessing_ '>
                <div className='processignOrder'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Order Settings</h3>
                        <p className='textInnerHead2'>Use these settings to define plugin general settings and default settings for your services and appointments</p>
                    </div>
                    <div className='datalimitstatus_ mt-4'>
                        <div className="row">
                         {   [{title:"Default time slot step",options:resultSelectOption,key:"default_time_slot_step"},
                            {title:"Minimum time required before booking",
                            options:resultSelectOption,key:"min_time_required_before_booking"},
                            ].map((data,index)=>(
                                <div key={index} className="col-md-6">
                                <div className="reactSelectParent commonSelectParent w-100">
                                    <label className="fontSize16 txtDarkblue mb-2 ps-4">
                                       {data.title}
                                        <img src={Images.labelImg} alt="logo" className="ms-2" />
                                    </label>
                                    <ReactSelect
                                        options={data.options}
                                         value={resultSelectOption?.find(res=>res.value===orderProcessingData?.[data?.key])}
                                        onChange={(event)=>{
                                            handleOrderProcessing(data.key,event?.value);
                                        }}
                                        placeholder="Manual"
                                        classNamePrefix="react-select"
                                        className="react-select-container anlyticSelect"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                maxWidth: "100% !important",
                                                width: "100%",
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: "1px solid #D7DEFF",
                                            }),
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                paddingLeft: "unset !important",
                                            }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected
                                                    ? "#263682"
                                                    : state.isFocused
                                                        ? "#f5f6fc"
                                                        : "#fff",

                                                color: state.isSelected ? "#fff" : "#263682",
                                                fontSize: "18px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                color: "#636E9F",
                                                fontSize: "18px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "normal",
                                            }),
                                        }}
                                        components={{
                                            DropdownIndicator: () => (
                                                <img
                                                    src={Images.selectImg}
                                                    width={24}
                                                    height={24}
                                                    alt="drop_icon"
                                                    className="mt-1"
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            ))
                            }
                        </div>
                        <div className="row mt-4">
                            {
                                [
                                    {title:"Order Cancellation",subTitle:"Allow customer cancellations",key:"order_canceled_status",},
                                    {title:"Order Returns",subTitle:"If returns are enabled, set a time limit for customers to initiate a return.",key:"is_order_return_enable"},
                                    {title:"On-POS arrival alerts", subTitle:"Notify POS staff product pickup arrival",key:"push_notification_status"},
                                ].map((data,index)=>(
                                    <div key={index} className="col-12">
                                    <div className={`serviceSettingBoxes d-flex align-items-start  gap-3 ${index>=1?'mt-3':''}`}>
                                        <input type="checkbox" checked={orderProcessingData?.[data?.key]}
                                        onChange={(event)=>{
                                            handleOrderProcessing(data.key,event.target.checked);
                                        }}
                                        />
                                        <div>
                                            <h3 className="fontSize14 txtDarkblue">
                                                {data.title}
                                            </h3>
                                            <h3 className="fontSize12 txtLightBlue mb-0">
                                                {data.subTitle}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='processignOrder mt-4'>
                    <div className='ordrsettingHead_'>
                        <h3 className='normalHeading_ mb-1'>Advance</h3>
                        <p className='textInnerHead2'>Use these settings to define plugin general settings and default settings for your services and appointments</p>
                    </div>

                    <div className="row mt-4">
                        {
                            [{
                               title:"Large order settings",subTitle:"Set a quantity limit to prevent unexpected large pickup or delivery orders. Include a custom message explaining how to place orders that are over the quantity limit.",key:"large_order_setting" ,
                              
                               
                            },
                            {title:"Enable Pre-Order",subTitle:"Clients can Pre-Order for a desire Product",key:"enable_pre_order",
                        
                        },
                            {title:"Enable 24/7 Online Order",subTitle:"Clients can place orders 24/7",key:"enable_24_7_online_orders",
                            
                        },
                            
                            ].map((data,index)=>(
<div key={index} className="col-12">
                            <div className={`serviceSettingBoxes d-flex align-items-start  gap-3 ${index>=1?'mt-3':''}`}>
                                <input checked={orderProcessingData?.[data?.key]}
                                onChange={(event)=>{
                                    handleOrderProcessing(data.key,event.target.checked);
                                }}
                                type="checkbox" />
                                <div>
                                    <h3 className="fontSize14 txtDarkblue">
                                        {data.title}
                                    </h3>
                                    <h3 className="fontSize12 txtLightBlue mb-0">
                                        {data.subTitle}
                                    </h3>
                                </div>
                            </div>
                        </div>
                            ))
                        }
                       
                    </div>
                </div>
            </div>

            {/* End Order Processing */}
        </>

    )
}


export default OrderProcessing