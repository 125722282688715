import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images";

const CreateVariationsModal = ({ rowData, costPrice , onCombinationChange }) => {
  const [productCombinations, setProductCombinations] = useState([]); // Store generated combinations
  const [selectedGroup, setSelectedGroup] = useState(""); // Store the selected group (e.g., size, color)
  const [openDropdown, setOpenDropdown] = useState(null); // Track dropdown state
  const [availableGroupOptions, setAvailableGroupOptions] = useState([]); // Store group options for the dropdown
  const [groupedCombinations, setGroupedCombinations] = useState([]);
  const [selectedCombinations, setSelectedCombinations] = useState([]); // State to store selected combinations

  // Function to generate combinations dynamically based on tags in rowData
  const generateCombinations = (data) => {
    const tagGroups = data.reduce((acc, item) => {
      acc[item.displayName] = item.productTags; // Assuming each row has a displayName and productTags
      return acc;
    }, {});

    const combinations = createCombinations(tagGroups);
    setProductCombinations(combinations);

    // Set available group options based on keys in tagGroups
    const keys = Object.keys(tagGroups);
    setAvailableGroupOptions(keys);
    setSelectedGroup(keys[0]); // Default to the first available group
  };

  // Helper function to create combinations based on tag groups
  const createCombinations = (tagGroups) => {
    const keys = Object.keys(tagGroups);
    let result = [{}];

    keys.forEach((key) => {
      const temp = [];
      result.forEach((combination) => {
        tagGroups[key].forEach((tag) => {
          temp.push({ ...combination, [key]: tag });
        });
      });
      result = temp;
    });

    return result;
  };

  // Function to handle dropdown selection
  const handleDropDown = (id) => {
    setOpenDropdown((prev) => (prev === id ? null : id));
  };

  const handleGroupSelection = (e) => {
    const selected = e.target.value;
    setSelectedGroup(selected); // Updates the state
    const groupedData = groupBy(productCombinations, selected);
    setGroupedCombinations(groupedData);
    setSelectedCombinations([]) // Clear previously selected combinations
  };

  // Helper function to group data by a specific property
  const groupBy = (arr, key) => {
    return arr.reduce((result, item) => {
      const groupKey = item[key];
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(item);
      return result;
    }, {});
  };

  // Handle selection (either individual combination or select all for a group)
  const handleCombinationSelection = (combination) => {
    setSelectedCombinations(
      (prev) =>
        prev.includes(combination)
          ? prev.filter((item) => item !== combination) // Deselect if already selected
          : [...prev, combination] // Add if not selected
    );
  };

  // Handle select all option for a group
  const handleSelectAll = (groupKey) => {
    const groupItems = groupedCombinations[groupKey] || [];
    const isSelected = groupItems.every((item) =>
      selectedCombinations.includes(item)
    );

    if (isSelected) {
      // Deselect all if already all are selected
      setSelectedCombinations((prev) =>
        prev.filter((item) => !groupItems.includes(item))
      );
    } else {
      // Select all combinations in the group
      setSelectedCombinations((prev) => [
        ...prev,
        ...groupItems.filter((item) => !prev.includes(item)),
      ]);
    }
  };

  // Handle "All Options" selection for all groups
  const handleAllOptionsSelection = () => {
    if (selectedCombinations.length === productCombinations.length) {
      // Deselect all combinations if they are all selected
      setSelectedCombinations([]);
    } else {
      // Select all combinations
      setSelectedCombinations(productCombinations);
    }
  };

  useEffect(() => {
    if (rowData?.length) {
      generateCombinations(rowData); // Generate combinations when rowData is available
    }
  }, [rowData]);

  useEffect(() => {
    if (selectedGroup) {
      const groupedData = groupBy(productCombinations, selectedGroup);
      setGroupedCombinations(groupedData);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (onCombinationChange) {
      onCombinationChange(selectedCombinations); // Pass the updated rows to the parent
    }
  }, [selectedCombinations]);

  return (
    <>
      <p className="fontSize16 txtDarkblue mt-4">
        The variations below will be created from your options.
      </p>
      <div className="row">
        <div className="col-md-3">
          <div className="form_group pt-4">
            <label className="fontSize14 txtDarkblue mb-2 ps-4 fw500">
              Group By
            </label>
            <div className="inputGroup">
              <select
                name="select"
                id="select"
                className="commonSelect"
                onChange={handleGroupSelection}
                value={
                  selectedGroup ||
                  (availableGroupOptions.length > 0
                    ? availableGroupOptions[0]
                    : "")
                }
              >
                {availableGroupOptions.map((option) => (
                  <option key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="modalOptionBox mt-4">
        <div className="modalOptionInner mb-4">
          <div className="row">
            <div className="col-5">
              <div className="productAvailabilityChild align-items-center">
                <div className="form-group">
                  <input
                    type="checkbox"
                    id="check-all"
                    checked={selectedCombinations.length === productCombinations.length} // Select all if all combinations are selected
                    onChange={handleAllOptionsSelection} // Select/Deselect all combinations
                  />
                  <label htmlFor="check-all"></label>
                </div>
                <h3 className="fontSize14 fw500 txtLightBlue mb-0">
                  All Options
                </h3>
              </div>
            </div>
            <div className="col-7">
              <h3 className="fontSize14 fw500 txtLightBlue mb-0">
                Cost Price
              </h3>
            </div>
          </div>
        </div>

        {Object.entries(groupedCombinations).map(
          ([groupKey, items], groupIndex) => (
            <div
              className={`createVariationBox mt-2 ${
                openDropdown === groupIndex && "createVariation_SecBox"
              } `}
              key={groupKey}
            >
              {/* Main Group Header */}
              <div className="row">
                <div className="col-5">
                  <div className="productAvailabilityChild align-items-center">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id={`check-group-${groupIndex}`}
                        checked={items.every((item) =>
                          selectedCombinations.includes(item)
                        )}
                        onChange={() => handleSelectAll(groupKey)}
                      />
                      <label htmlFor={`check-group-${groupIndex}`}></label>
                    </div>
                    <div>
                      <h3 className="fontSize14 fw500 txtDarkblue mb-0 pb-1">
                        {groupKey.toUpperCase()}
                      </h3>
                      <p className="fontSize12 txtLightBlue mb-0">
                        {items.length} Options{" "}
                        <i
                          className={`fa-solid fa-angle-down ms-2 dropDown_handleBtn ${
                            openDropdown === groupIndex ? "active" : ""
                          }`}
                          onClick={() => handleDropDown(groupIndex)}
                        ></i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-7">
                  <h3 className="fontSize14 fw500 txtLightBlue mb-0">
                    {costPrice}
                  </h3>
                </div>
              </div>

              {/* Subgroup Details */}
              {openDropdown === groupIndex &&
                items.map((item, index) => (
                  <div className="row pt-3" key={`${groupKey}-${index}`}>
                    <div className="col-5">
                      <div className="productAvailabilityChild align-items-center dropdownOptions">
                        <div className="form-group">
                          <input
                            type="checkbox"
                            id={`check-subgroup-${groupIndex}-${index}`}
                            checked={selectedCombinations.includes(item)}
                            onChange={() => handleCombinationSelection(item)}
                          />
                          <label
                            htmlFor={`check-subgroup-${groupIndex}-${index}`}
                          ></label>
                        </div>
                        <div>
                          {/* Dynamic Attributes */}
                          <div className="fontSize14 fw500 txtDarkblue mb-0 pb-1">
                            {Object.entries({ colorItem: groupKey, ...item })
                              .filter(
                                ([key]) =>
                                  key !== "costPrice" && key !== selectedGroup
                              ) // Exclude costPrice
                              .map(([key, value]) => value.toUpperCase())
                              .join(" / ")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-7">
                      <h3 className="fontSize14 fw500 txtLightBlue mb-0 dropdownOptionPrice">
                        {costPrice}
                      </h3>
                    </div>
                  </div>
                ))}
            </div>
          )
        )}
      </div>
    </>
  );
};

export default CreateVariationsModal;
